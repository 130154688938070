import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PyraNftMarket721 from '../../../ABI/PyraNftMarket.json';
//import PyraNftMarketCollection from '../../../ABI/PyraNftCollection.json';
import commonFunction from '../../../utils/common';
import biddingOrder from '../../../nft/BiddingNFT';
import loadWeb3 from '../../../utils/loadWeb3';
import config from '../../../lib/config';
import { toast } from 'react-toastify';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import shortenAccount from '../../../utils/shortenAccount';

import wETHABI from '../../../ABI/WETHABI.json';
import { _updateBid } from '../../../services/mainApi';
import { useNavigate } from 'react-router-dom';

const BidAcceptlModal = props => {
  let navigate = useNavigate();
  const { show, onHide, explorelist, bidObjectValue, acceptBidData } = props;
  console.log('===>', acceptBidData);

  const { ConnectedAccount, netWorkSymbol } = React.useContext(ConnectWalletContext);
  const [progress, setprogress] = useState(false);

  let wrappedSymbol = `W${netWorkSymbol}`;

  //get the contract address
  let contractAddress = explorelist?.collectionAddress;
  if (contractAddress == undefined) {
    if (explorelist?.CollectionData?.length > 0) {
      contractAddress = explorelist?.CollectionData[0]?.address;
    } else contractAddress = config.NFTContractAddress;
  }

  let bidderAddress = bidObjectValue.bidderAddress;
  const acceptNftBid = async () => {
    const payload = {
      price: 0, //while transfer the ownership here make the current price as zero
      nftId: acceptBidData.nftId,
      status: 'accept',
      _id: acceptBidData._id,
      tokenId: acceptBidData.tokenId,
    };
    console.log('Testing approveBid process!!', explorelist);
    const web3 = await loadWeb3();
    const biddingOrderObj = await biddingOrder(
      explorelist?.tokenId,
      bidderAddress,
      contractAddress, //explorelist?.collectionAddress,
      0,
      explorelist?.blockChainName,
    );
    let wETHBalance = parseFloat(biddingOrderObj?.wETHBalance / 10 ** 18).toFixed(3);
    console.log('Bidder Wrapper token Balance from Bids details', wETHBalance);

    //check the wETH balance for the bidder if not enough balance then throw error
    if (wETHBalance == 0) {
      toast.error('Insufficient Balance to accept the Bid', commonFunction.toastsettings);
      return;
    }
    let weth = new web3.eth.Contract(wETHABI, config.wrapperContractAddress);

    //get contract address if collection then collection contract address else NFT contract
    // let contractAddress = explorelist?.collectionAddress
    //   ? explorelist?.collectionAddress
    //   : config.NFTContractAddress;

    //get the existing allowance
    let getAllowance = await weth.methods.allowance(bidderAddress, contractAddress).call();

    //end of getting allowance
    //continue for bid accept process and call the API to update the bidding details
    //const instance = new web3.eth.Contract(PyraNftMarket721, contractAddress);
    let instance;
    try {
      instance = new web3.eth.Contract(PyraNftMarket721, contractAddress);
    } catch (err) {
      console.log();
    }

    console.log(instance, ConnectedAccount);

    setprogress(true);
    let biddingAmount = web3.utils.toWei(bidObjectValue?.bidAmount?.toString(), 'ether');
    console.log('Bidding accept process started-----');
    console.log('Bidder tokenSymbol', wrappedSymbol);
    console.log('Bidder address', bidderAddress);
    console.log('Bidding accpeted amount', biddingAmount / 10 ** 18);
    console.log('Biddeing token id', explorelist.tokenId);
    console.log('Exisitng allowance for biider', getAllowance / 10 ** 18);
    console.log('NFT contract address', contractAddress);

    //--
    //if allowance is exist then proceed else ignore to accept the bid
    if (getAllowance > 0 && getAllowance / 10 ** 18 >= biddingAmount / 10 ** 18) {
      try {
        let acceptTxResult = await instance.methods
          .acceptBId(
            wrappedSymbol, //bidtoken - token currency
            bidderAddress, //bidderAddress //take from the API
            biddingAmount, //bid amount //take from teh API
            explorelist.tokenId, // nft tokenId
          )
          .send({ from: ConnectedAccount }); //caller - a token Owner
        //once bidding process is accepted successfully then call the API to update bidder details
        if (acceptTxResult && acceptTxResult.transactionHash) {
          //toast.success('Bid Accepted successfully!', commonFunction.toastsettings);
          _updateBid(payload)
            .then(res => {
              if (!res.data.error) {
                toast.success('Bid Accepted successfully', commonFunction.toastsettings);
                navigate('/mynft');
              } else {
                toast.error(res.data.msg, commonFunction.toastsettings);
              }
            })
            .catch(() => {
              toast.error('Bid not accepted un sucessfull', commonFunction.toastsettings);
            });
          setprogress(false);
        }
      } catch {
        toast.error('Bid not accepted and reverted your gas amount!', commonFunction.toastsettings);
        setprogress(false);
      }
    } else {
      toast.warning('Bidder has Insufficient balance to accept bid!');
      setprogress(false);
    }
    //--
    setprogress(false);
  };

  return (
    <Modal show={show} onHide={onHide} style={{ marginTop: '10rem' }}>
      <Modal.Header closeButton className="center">
        <h3 className="alignin-center">NFT for Bid Accept</h3>
      </Modal.Header>
      <div className="modal-body">
        <div className="card-media center">
          <img className="cardimg_size" src={explorelist?.fileUrl} alt="axies" />
        </div>

        <p className="text-center">You are about to Accept the bid</p>
        <p className="text-center mt-0">
          <span className="price color-popup">{explorelist?.title} </span> {' from '}
          <span className="price color-popup">{shortenAccount(bidderAddress)}</span>
        </p>
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p> Service fee:</p>
          <p className="text-right price color-popup">{bidObjectValue.serviceFee / 10 ** 18}% </p>
        </div>

        <div className="d-flex justify-content-between">
          <p>You will get:</p>
          <p className="text-right price color-popup">
            {bidObjectValue.netAmount} &nbsp;
            {`W${netWorkSymbol}`}
          </p>
        </div>
        {progress ? (
          <div className="center">
            {' '}
            <i className="fa fa-spinner fa-spin fa-2x mr-2" aria-hidden="true" id="circle1"></i>
          </div>
        ) : (
          <button className="btn btn-primary" onClick={acceptNftBid}>
            Accept Bid
          </button>
        )}
      </div>
    </Modal>
  );
};

export default BidAcceptlModal;
