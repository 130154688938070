import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import MyNft_items from '../components/layouts/MyItems/MyNft_items';
import MyNft_itemsOnSale from '../components/layouts/MyItems/MyNft_itemsOnSale';
import MyFavouriteNft from '../components/layouts/MyItems/MyFavouriteNft';
import {
  _exploreCollectionNft,
  _exploreAllNft,
  // _addToFav,
  _getMyFavNft,
  _myNft,
  _searchNFT,
  _myNftOnSale,
  _removeFromFav,
} from '../services/mainApi';
import imga1 from '../assets/images/avatar/avt-1.jpg';
import imgCollection1 from '../assets/images/avatar/avt-18.jpg';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import { copytoclipboard } from '../utils/helpers';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import previewThumb from '../assets/images/avatar/previewThumb.png';
import { useParams } from 'react-router-dom';

const MyItem = () => {
  const [selectedCategoryid, setselectedCategoryid] = useState('');
  const [explorelist, setexplorelist] = useState([]);
  const [onSaleTabData, setOnSaleTabData] = useState([]);
  // const [ownedvalue, setOwnedvalue] = useState([]);
  const [favouritesTabData, setFavouritesTabData] = useState([]);
  const [totalItems, settotalItems] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [currentPageowned, setcurrentPageowned] = useState(1);
  const [currentPagefav, setcurrentPagefav] = useState(1);
  const [variant, setvariant] = useState('onsale');
  const [progress, setprogress] = useState(true);
  const { ConnectedAccount, userProfileData } = React.useContext(ConnectWalletContext);
  const [copysttaustext, setcopysttaustext] = useState('Copy');
  const [explorelistfilter, setexplorelistfilter] = useState([]);
  const [searchdata, setsearchdata] = useState('');
  const { collectionId } = useParams();
  const [loading, setLoading] = useState(false);

  let shortenAccount;
  if (ConnectedAccount) {
    shortenAccount = `${ConnectedAccount.slice(0, 5)}...${ConnectedAccount.slice(
      ConnectedAccount.length - 4,
    )}`;
  }
  const handlecategoryselect = id => {
    setselectedCategoryid(id);
  };
  const handlesearchinput = value => {
    setsearchdata(value);
    onsearchnft(value);
  };
  const increaseexplorepagenumber = () => {
    setcurrentPage(currentPage + 1);
  };
  const previouspagenumber = () => {
    setcurrentPage(currentPage - 1);
    // setcurrentPageowned(currentPageowned - 1);
    // setprogress(true);
  };
  const previouspagenumberowned = () => {
    setcurrentPageowned(currentPageowned - 1);
    setprogress(true);
    // progress === true;
  };
  const increaseownedpagenumber = () => {
    setcurrentPageowned(currentPageowned + 1);
  };
  const increasefavpagenumber = () => {
    setcurrentPagefav(currentPagefav + 1);
  };
  const onvariantselect = value => {
    setvariant(value);
    setcurrentPage(1);
    setcurrentPageowned(1);
    setcurrentPagefav(1);
  };
  console.log(explorelist, 'resdata');
  const storedata = (res, status) => {
    console.log(explorelist, 'resdata2');
    let temp = res;
    let resdata = [];
    if (status) {
      temp?.length > 0 &&
        temp.map(item => {
          return resdata.push({
            categoryId: item?.categoryId ? item.categoryId : '',
            description: item.description,
            id: item._id,
            img: item.fileUrl,
            imgAuthor: item?.userdetails?.userImage ? item?.userdetails?.userImage : imga1,
            imgCollection: imgCollection1,
            metadataUrl: item?.metadataUrl ? item.metadataUrl : '',
            nameAuthor: item.userdetails.username,
            nameCollection: '',
            noOfCopies: item.noOfCopies,
            properties: item?.properties ? item.properties : [],
            price: item.price,
            priceChange: '',
            putOnMarketplace: item.putOnMarketplace,
            tags: item.CategoryData.name,
            title: item.title,
            tokenId: item?.tokenId ? item.tokenId : '',
            currentAddress: item.currentAddress,
            walletAddress: item?.userdetails?.walletAddress,
            wishlist: item.favCount,
            favStatus: item.favStatus,
            collectionAddress: item?.CollectionData[0]?.address,
            collectionSymbol: item?.CollectionData[0]?.symbol,
            collectionDescription: item?.CollectionData[0]?.name,
            userImage: item?.userdetails?.userImage,
            blockChainName: item?.blockChainName,
          });
        });
      if (resdata) {
        let data = [...explorelist, ...resdata];
        let uniqueData = [...data.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];

        if (uniqueData.length > 0 && variant === 'onsale') {
          uniqueData = uniqueData.filter(item => item.putOnMarketplace === true);
        } else if (uniqueData.length > 0 && variant === 'owned') {
          uniqueData = uniqueData.filter(item => item.putOnMarketplace === false);
        }
        setexplorelist(uniqueData);
      }
    } else {
      settotalItems(0);
      console.log(temp, 'tempdata');
      if (variant != 'owned' && !temp) {
        setexplorelist([]);
        // uniqueData = uniqueData.filter(item => item.putOnMarketplace === false);
      }
    }
  };
  const onsearchnft = req => {
    if (req?.length >= 1) {
      const promise = _searchNFT({ text: req });
      promise
        .then(
          res => {
            if (!res.error) {
              let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
              let resdata = [];
              temp?.length > 0 &&
                temp.map(item => {
                  return resdata.push({
                    categoryId: item?.categoryId ? item.categoryId : '',
                    description: item.description,
                    id: item._id,
                    img: item.fileUrl,
                    imgAuthor: imga1,
                    imgCollection: imgCollection1,
                    metadataUrl: item?.metadataUrl ? item?.metadataUrl : '',
                    nameAuthor: item.userdetails.username,
                    nameCollection: '',
                    noOfCopies: item.noOfCopies,
                    properties: item?.properties ? item.properties : [],
                    price: item.price,
                    priceChange: '',
                    putOnMarketplace: item.putOnMarketplace,
                    tags: item.CategoryData.name,
                    title: item.title,
                    tokenId: item?.tokenId ? item.tokenId : '',
                    walletAddress: item.currentAddress,
                    currentAddress: item.currentAddress,
                    wishlist: item.favCount,
                    // favStatus: item.favStatus,
                    ownerAddress: item.ownerAddress,
                    minBidValue: item.minBidValue,
                    isBid: item.isBid,
                    favStatus: item.favStatus,
                    multiToken: item.multiToken,
                    blockChainName: item?.blockChainName,
                  });
                });

              setsearchdata(req);
              console.log(req, 'searchlisttttreqin');
              if (res?.data?.data?.nftList) {
                setexplorelistfilter(
                  res?.data?.data?.nftList,
                  // explorelist?.filter(one => temp?.some(two => two?._id === one?.id)),
                );
              } else {
                setexplorelistfilter([]);
              }

              // setnftlist(uniqueData);
              settotalItems(res?.data?.data?.totalItems);
            }
            setprogress(false);
            // setsearchdata('');
            // ExploreAllCoinsData();
          },
          () => {
            setprogress(false);
          },
        )
        .catch(e => {
          console.log('errorLogged', e);
        });
    } else if (req?.length == 0) {
      console.log(req, 'searchlisttttreqout');

      const _exploreAllNftpromise = _exploreAllNft(currentPage, currentPageowned);
      _exploreAllNftpromise.then(
        res => {
          if (!res.error) {
            storedata(res);
            console.log('empty');
          }
          setprogress(false);
        },
        () => {
          setprogress(false);
        },
      );
    }
  };
  const onSaleTab = () => {
    setprogress(true);
    _myNftOnSale(currentPage)
      .then(res => {
        console.log('neeeee', res?.data);
        if (!res.data.error && res.msg != 'No data found') {
          setOnSaleTabData(res.data.data.nftList);
          // setPreviouspageData(res.data.data.error);
        } else {
          setOnSaleTabData([]);
          // setPreviouspageData([])
          // setcurrentPageowned(1);
          console.log(res.data.data.msg);
        }
      })
      .catch(err => console.log(err.data))
      .finally(() => setprogress(false));
  };

  const onFavouriteTab = () => {
    setprogress(true);
    _getMyFavNft(1)
      .then(res => {
        if (!res.data.error) {
          setFavouritesTabData(res.data.data.nftList);
        } else {
          console.log(res.data.data.msg);
        }
      })
      .catch(err => console.log(err.data))
      .finally(() => setprogress(false));
  };

  const ExploreAllCoinsData = () => {
    setLoading(true);
    const _exploreAllNftpromise = _exploreAllNft(currentPage, currentPageowned);
    _exploreAllNftpromise.then(
      res => {
        if (!res.error) {
          storedata(res);
          console.log(res, 'exploreALl');
        }
        setprogress(false);
        setLoading(false);
      },
      () => {
        setprogress(false);
        setLoading(false);
      },
    );
  };
  useEffect(() => {
    if (collectionId) {
      setprogress(true);
      const _exploreNftpromise = _exploreCollectionNft(collectionId, currentPage, currentPageowned);
      _exploreNftpromise.then(
        res => {
          if (!res.error) {
            storedata(res);
          }
          setprogress(false);
        },
        () => {
          setprogress(false);
        },
      );
    } else {
      ExploreAllCoinsData();
    }
  }, [collectionId, currentPage, currentPageowned]);

  const [load, setLoad] = React.useState('initial');
  const addOrRemoveFav = item => {
    console.log('adre', item);
    if (item?.favStatus === true && (load === 'initial' || load === 'completed')) {
      removeFav(item?.id || item?._id);
    } else {
      if (load === 'initial' || load === 'completed') {
        // addToFav(item?.id || item?._id);
      }
    }
  };

  // const addToFav = id => {
  //   setLoad('pending');
  //   const payload = {
  //     nftId: id,
  //   };
  //   _addToFav(payload)
  //     .then(res => {
  //       console.log(res, 'favvvvv');
  //       if (!res?.data?.error) {
  //         //     // let favStatusTemp = data;
  //         // var addChange = function change() {
  //         // // favStatusTemp[index].favStatus = true;
  //         // favStatusTemp[index].wishlist = favStatusTemp[index].wishlist + 1;
  //         // };
  //         ExploreAllCoinsData();
  //         toast.success('Added to favorites');
  //         onsearchnft(location?.state);
  //         // setsearchdata(location?.state);
  //       }
  //       setLoad('completed');
  //     })
  //     .catch(err => err.message);
  // };
  // console.log(addToFav,"favorite")
  const removeFav = id => {
    setLoad('pending');

    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        console.log(res, 'remmmove');
        if (!res?.data?.error) {
          toast.error('Removed from favorites', '');
          onFavouriteTab();
        }
        setLoad('completed');
      })
      .catch(err => err?.message);
  };

  useEffect(() => {
    if (variant == 'owned') {
      settotalItems(0);
      setprogress(true);
      const _myNftpromise = _myNft(currentPageowned);
      _myNftpromise
        .then(
          res => {
            console.log(res?.data?.error);
            if (res?.data?.error) {
              storedata([], 0);
              setprogress(false);
              setexplorelist([]);
              return;
            } else if (res?.data.data.nftList?.length > 0) {
              setexplorelist(res?.data?.data?.nftList);
              settotalItems(res?.data?.data?.totalItems);
              setprogress(false);
            } else {
              // storedata([], 0);
              setexplorelist([]);
            }
          },
          err => {
            console.log('fail', err.message);
            storedata([], 0);
            settotalItems(0);
            setprogress(false);
          },
        )
        .catch(err => {
          console.log('myitem failed', err.message);
          storedata([], 0);
          setprogress(false);
        });
    } else if (variant == 'onsale') {
      onSaleTab();
    } else if (variant == 'favourite') {
      onFavouriteTab();
    }
  }, [selectedCategoryid, currentPage, currentPageowned, variant, ConnectedAccount]);
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center"></h1>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Explore 1</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <div className="avatar">
          <img src={imga1} alt="axies" />
        </div> */}
      <div className="center m-3">
        <h3 className="heading text-center" id="myInput">
          {/* {ConnectedAccount} */}
        </h3>
        <br />
        <div className="col-md-12">
          <div className="d_profile">
            <div className="profile_avatar">
              <div className="d_profile_img">
                <img
                  src={userProfileData?.userImage ? userProfileData?.userImage : previewThumb}
                  alt=""
                  className="mb-3"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          onMouseOver={() => setcopysttaustext('Copy')}
          className="text-center"
          style={{ cursor: 'pointer' }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">{copysttaustext}</Tooltip>}>
            <p
              onMouseOver={() => setcopysttaustext('Copy')}
              onClick={() => {
                copytoclipboard(`${ConnectedAccount}`);
                setcopysttaustext('Copied');
              }}>
              {shortenAccount}
            </p>
          </OverlayTrigger>
        </div>
      </div>
      <div className="center m-3 ">
        <button
          className="sc-button style bag fl-button pri-3 no-bg m-2"
          style={variant === 'onsale' ? {} : { color: '#7a798a' }}
          onClick={() => onvariantselect('onsale')}>
          <span>On sale</span>
        </button>
        <button
          className="sc-button style bag fl-button pri-3 no-bg m-2"
          style={variant === 'owned' ? {} : { color: '#7a798a' }}
          onClick={() => onvariantselect('owned')}>
          <span>Owned</span>
        </button>
        <button
          className="sc-button style bag fl-button pri-3 no-bg m-2"
          style={variant === 'favourite' ? {} : { color: '#7a798a' }}
          onClick={() => onvariantselect('favourite')}>
          <span> Favourite</span>
        </button>
        {/* <button
          className="sc-button style bag fl-button pri-3 no-bg m-2"
          style={variant === 'videos' ? {} : { color: '#7a798a' }}
          onClick={() => onvariantselect('videos')}>
          <span>Videos</span>
        </button> */}
        {/*<button
          className="sc-button style bag fl-button pri-3 no-bg m-2"
          style={variant === 'followers' ? {} : { color: '#7a798a' }}
          onClick={() => onvariantselect('followers')}>
          <span>Followers</span>
        </button> */}
      </div>
      {variant == 'onsale' && (
        <MyNft_itemsOnSale
          data={onSaleTabData}
          progress={progress}
          onsearchnft={value => onsearchnft(value)}
          increasepagenumber={() => increaseexplorepagenumber()}
          previouspage={() => previouspagenumber()}
          handlesearchinput={value => handlesearchinput(value)}
          exploretotalItems={totalItems}
          addOrRemoveFav={addOrRemoveFav}
        />
      )}

      {variant == 'owned' && (
        <MyNft_items
          data={searchdata === null || searchdata?.length === 0 ? explorelist : explorelistfilter}
          categoryselect={id => handlecategoryselect(id)}
          increasepagenumberowned={() => increaseownedpagenumber()}
          exploretotalItems={totalItems}
          previouspageowned={() => previouspagenumberowned()}
          variant={variant}
          progress={progress}
        />
      )}
      {variant == 'favourite' && (
        <MyFavouriteNft
          data={favouritesTabData}
          increasepagenumberfav={() => increasefavpagenumber()}
          progress={progress}
          addOrRemoveFav={addOrRemoveFav}
          loading={loading}
        />
      )}

      <Footer />
    </div>
  );
};

export default MyItem;
