import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import { _createCollection, _fileupload } from '../services/mainApi';
import commonFunction from '../utils/common';
import config from '../lib/config';
import loadWeb3 from '../utils/loadWeb3';
import PyraNftCollection from '../ABI/PyraNftCollection.json';
import img1 from '../assets/images/blog/thumb-8.png';

// import { useNavigate } from 'react-router-dom';

export default function CreateCollection({
  createCollection,
  handleCollectionClose,
  listcollection,
}) {
  // const navigate = useNavigate();
  function refreshPage() {
    window.location.reload();
  }

  // const [CollectionContractEstimateGas, setCollectionContractEstimateGas] = useState(0);
  //const [NewCollectionContractAddress, setNewCollectionContractAddress] = useState();
  const [createCollectionStatus, setCreateCollectionStatus] = useState('init');
  const { ConnectedAccount, netWrokChainName } = useContext(ConnectWalletContext);
  const [uploadedfilePath, setuploadedfilePath] = useState('');
  const [err, setErr] = useState(false);

  const [collectionobject, setcollectionobj] = useState({
    name: '',
    description: '',
    address: ConnectedAccount,
    shortURL: '', //http://test.com
    symbol: '',
    collectionImage: '', //http://test.com
  });
  const [onsave, setonsave] = useState(false);
  console.log('Upload file path', uploadedfilePath);
  //----------------------------NFT Collection ----------------------------//
  //get input collection values
  //CollectionDescription CollectionSymbol CollectionName
  const inputCollectionChange = e => {
    e.preventDefault();
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      let value = e.target.value;
      setcollectionobj(prev => {
        let temp = prev;
        switch (e.target.name) {
          case 'CollectionName':
            temp.name = value;
            break;
          case 'CollectionSymbol':
            temp.symbol = value;
            break;
          case 'CollectionDescription':
            temp.description = value;
            break;
          case 'CollectionShorturl':
            temp.shortURL = value;
            break;
          default:
        }
        return temp;
      });
    }
  };
  const callcreateCollection = param => {
    //Call API to post/create collection items
    if (uploadedfilePath) {
      var formData = new FormData();
      formData.append('file', uploadedfilePath);
      formData.append('name', collectionobject.name);
      formData.append('description', collectionobject.description);
      formData.append('attributes', '[]');
      const fileuploadpromise = _fileupload(formData);
      fileuploadpromise
        .then(
          res => {
            console.log('url result', res);
            console.log('url result', res?.data?.error);
            if (res?.data?.error === false) {
              //get imageURL
              if (res?.data?.data?.imageURL) {
                setcollectionobj(prev => {
                  let temp = prev;
                  temp.collectionImage = res.data.data.imageURL;
                  console.log('Collection object', temp);
                  return temp;
                });
              }
              const promise = _createCollection({
                ...param,
                collectionImage: res.data.data.imageURL,
              });
              promise.then(
                () => {
                  toast.success('Collection created successfully', commonFunction.toastsettings);
                  handleCollectionClose();
                  setuploadedfilePath('');
                  listcollection();
                  refreshPage();
                  // navigate('/mycollection');
                },
                error => {
                  toast.error(error.msg, commonFunction.toastsettings);
                },
              );
            }
          },
          err => {
            console.log('failerr', err);
          },
        )
        .catch(err => {
          console.log('File uploading error', err.message);
        });
    }
  };
  const Validation = () => {
    console.log(collectionobject, 'cccccc');
    setErr(true);
    if (
      collectionobject.name?.length === 0 ||
      collectionobject.symbol === '' ||
      uploadedfilePath === ''
    ) {
      setErr(true);
    } else {
      handlesaveCollection();
    }
  };
  //when user create the collection then deploy the contract with new instance
  const handlesaveCollection = async () => {
    setErr(false);
    setonsave(true);
    let web3 = await loadWeb3();
    setCreateCollectionStatus('processing');
    //get the estimatedGas to deploy the Collection contract
    const providerGasFee = +(await new web3.eth.getGasPrice());
    const contractEstimateGasPrice = await new web3.eth.Contract(PyraNftCollection.abi)
      .deploy({
        data: PyraNftCollection.bytecode,
        arguments: [
          1, //id
          config.NFTContractAddress, //oringinal contract pyranft 721 deployed
          config.biddingToken, //WETH - bidding token symbol
          config.wETHContractAddress, //address
          collectionobject.name, //Collection Title
          collectionobject.symbol, //Collection symbol
        ],
      })
      .estimateGas();
    // setCollectionContractEstimateGas(contractEstimateGasPrice);
    console.log(
      'Collection contract estimation gas value',
      contractEstimateGasPrice,
      providerGasFee,
    );

    //deploy the contract to get new collection contract address
    await new web3.eth.Contract(PyraNftCollection.abi)
      .deploy({
        data: PyraNftCollection.bytecode,
        arguments: [
          1, //id
          config.NFTContractAddress, //oringinal contract pyranft 721 deployed
          config.biddingToken, //WETH - bidding token symbol
          config.wETHContractAddress, //bidding token address
          collectionobject.name, //Collection Title
          collectionobject.symbol, //Collection symbol
        ],
      })
      .send({
        from: ConnectedAccount, //user connect with wallet and creating the collection
        gas: 6000000, //The maximum gas provided for a transaction (gas limit).
        // gasPrice: 10000000, //The gas price in wei to use for transactions.
      })
      .then(res => {
        if (res?.options?.address) {
          callcreateCollection({
            name: collectionobject.name,
            description: collectionobject.description,
            address: res.options.address,
            blockChainName: netWrokChainName,
            shortURL: collectionobject.collectionImage ? collectionobject.collectionImage : 'dummy',
            symbol: collectionobject.symbol,
            collectionImage: collectionobject.collectionImage
              ? collectionobject.collectionImage
              : 'dummy',
          });
        }
      })
      .catch(error => console.log('Collection contract deployment error message', error.message));
    setCreateCollectionStatus('done');
    setcollectionobj({
      name: '',
      description: '',
      address: ConnectedAccount,
      shortURL: '', //http://test.com
      symbol: '',
      collectionImage: '', //http://test.com
      blockChainName: '',
    });
    setuploadedfilePath('');
    // refreshPage();
    //setNewCollectionContractAddress(collectionContract.options.address);
    //window.localStorage.setItem('CollectionContractAddress', collectionobject.symbol);
  };

  //---------------------------END of NFT Collection -----------------------//

  const onImageChange = e => {
    if (e.target && e.target.files) {
      if (e.target.files[0].size > 209715200) {
        alert('File is too big!');
      } else {
        setuploadedfilePath(e.target.files[0]);
        console.log('uploade image file', e.target.files[0]);
      }
    }
  };
  return (
    <Modal
      show={createCollection}
      scrollable={true}
      onHide={() => {
        handleCollectionClose();
        setuploadedfilePath('');
        setErr(false);
      }}
      animation={false}
      backdrop="static"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Create Collection</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form action="#">
          <div className="mg-bt-10 uploadFilebtn flex">
            <div className="avatar" style={{ width: '100px', height: '100px' }}>
              <img
                src={uploadedfilePath ? URL.createObjectURL(uploadedfilePath) : img1}
                alt="axies"
              />
            </div>
            <h6 className="title-create-item ml-2">
              Banner image that appears at the top of your collection page.
            </h6>
            <br />
            <input
              disabled={onsave}
              type="file"
              className="inputfile form-control"
              name="file"
              accept="image/png, image/jpg ,image/gif, image/jpeg"
              onChange={onImageChange}
            />
          </div>{' '}
          {err && (
            <p className="required-font m-t-1">{uploadedfilePath != '' ? '' : 'Upload File'}</p>
          )}
          <div className="mg-bt-10">
            <h5 className="title-create-item">
              Collection Name<span className="required">*</span>
            </h5>
            <input
              disabled={onsave}
              type="text"
              placeholder="Name"
              onChange={e => inputCollectionChange(e)}
              id="CollectionName"
              name="CollectionName"
              required
            />
            {err && (
              <p className="required-font m-t-1">
                {collectionobject.name === '' ? 'Enter Collection Name' : ''}
              </p>
            )}
          </div>
          <div className="mg-bt-10">
            <h5 className="title-create-item">
              Symbol<span className="required">*</span>
            </h5>
            <input
              disabled={onsave}
              type="text"
              placeholder="Collection Symbol"
              onChange={e => inputCollectionChange(e)}
              minLength="3"
              id="CollectionSymbol"
              name="CollectionSymbol"
              required
            />
            {err && (
              <p className="required-font">
                {collectionobject.symbol === '' ? 'Enter Collection Symbol' : ''}
              </p>
            )}
          </div>
          <div className="mg-bt-10">
            <h5 className="title-create-item">Description</h5>
            <textarea
              disabled={onsave}
              placeholder="Description"
              onChange={e => inputCollectionChange(e)}
              id="CollectionDescription"
              name="CollectionDescription"
            />
          </div>
          {/* <div className="mg-bt-10">
          <h5 className="title-create-item">Short url</h5>
          <input
            type="text"
            placeholder="Short URL"
            onChange={e => inputCollectionChange(e)}
            id="CollectionShorturl"
            name="CollectionShorturl"
          />
        </div> */}
          <div className="text-center">
            {createCollectionStatus === 'processing' ? (
              <div className="center">
                <i className="fa fa-spinner fa-spin fa-2x mr-2" aria-hidden="true" id="circle1"></i>
              </div>
            ) : (
              <button type="button" className="sc-button fl-button pri-3 no-bg">
                <span onClick={() => Validation()}>Create Collection</span>
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
