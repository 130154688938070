import React from 'react';
import Header from '../components/header/HeaderStyle2';
import ItemContent from '../components/layouts/home-8/ItemContent';
import SideBar from '../components/layouts/home-8/SideBar';

const Home08 = () => {
  const [selectionFilter, setFilter] = React.useState([]);
  const [filterHide, setFilterHide] = React.useState(true);
  const [selectCoin, setSelectCoin] = React.useState('');
  const [selectCategory, setSelectCategory] = React.useState('');
  const [currentCategoryPage, setcurrentCategoryPage] = React.useState(1);

  return (
    <div className="home-8">
      <Header />
      <section className="tf-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            {filterHide && (
              <div className="col-box-17">
                <SideBar
                  setFilter={setFilter}
                  setSelectCoin={setSelectCoin}
                  setSelectCategory={setSelectCategory}
                  setcurrentCategoryPage={setcurrentCategoryPage}
                />
              </div>
            )}
            <div className={filterHide ? 'col-box-83' : 'col-box-83 explore2-width'}>
              <ItemContent
                selectionFilter={selectionFilter}
                setFilterHide={setFilterHide}
                selectCoin={selectCoin}
                selectCategory={selectCategory}
                currentCategoryPage={currentCategoryPage}
                setcurrentCategoryPage={setcurrentCategoryPage}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home08;
