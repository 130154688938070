import React from 'react';
import * as Mui from '@mui/material';
import Logo from '../../../assets/images/icon/Favicon copy.png';
import shortenAccount from '../../../utils/shortenAccount';
import Tabs from './tabs';
import SendMode from './sendmode';
import CreateAccount from './createaccount';
import ImportAccount from './importaccount';
import WalletBalance from '../../../wallet/WalletBalance';
import matic from '../../../assets/images/icon/polygon-matic-logo.png';

const Wallet = () => {
  const MenuProps = {
    PaperProps: {
      backgroundColor: 'red',
      style: {
        maxHeight: 48 * 4.5 + 8,
        // width: 220,
        backgroundColor: '#1a0a4a',
        color: 'white',
        fontSize: '15px',
      },
    },
  };
  const StyledSelect = Mui.styled(Mui.Select)`
    font-size: 15px;
    border-radius: 0.4rem;
    margin-left:10px;
    height:47px;
    /* border: 2px solid green; */
    & .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border: red;
    }
    & .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
      color: white;
    }
    & .MuiSelect-outlined {
      color: white;
      background-color: #1a0a4a;
      padding:1px 10px;
    }
    & .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
      // height: 47px !important,
    }
  `;
  const blockchainnames = [
    {
      id: 1,
      field: 'Avalanche',
    },
    {
      id: 2,
      field: 'Ethereum',
    },
    {
      id: 3,
      field: 'Polygon',
    },
    {
      id: 4,
      field: 'Binance',
    },
  ];
  const [walletMode, setWalletMode] = React.useState({
    send: false,
    create: false,
    import: false,
  });
  // const [walletDetails, setWalletDetails] = React.useState({
  //   createAccountDetails: '',
  // });
  const [createAccountDetails, setCreateAccountDetails] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [accountBalanceDetails, setAccountBalanceDetails] = React.useState('');
  const [copyClipboard, setCopyClipboard] = React.useState('Copy');
  const walletBalanceHere = async address => {
    console.log('WalletDetails bal', createAccountDetails);
    try {
      const walletBal = await WalletBalance(address);
      console.log('WalletDetails bal', walletBal);
      // setWalletDetails({ accountBalanceDetails: walletBal });
      setAccountBalanceDetails(walletBal);
    } catch (err) {
      console.log('WalletDetails err', err);
    }
  };
  React.useEffect(() => {
    if (createAccountDetails != '') {
      walletBalanceHere(createAccountDetails.address);
    }
  }, [createAccountDetails]);
  return (
    <Mui.Stack>
      <Mui.Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        bgcolor="#5a1986">
        <Mui.Stack sx={{ width: '30px', height: '30px' }}>
          <img src={Logo} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Mui.Stack>
        <Mui.Stack direction="row" justifyContent="space-between" spacing={2}>
          <StyledSelect
            // sx={{ width: '160px' }}
            sx={{ backgroundColor: '#1a0a4a', color: 'white', width: '150px' }}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            // multiple
            // value={coinNameBlockchain}
            // onChange={valueChange}
            defaultValue="Binance"
            MenuProps={MenuProps}>
            {blockchainnames.map(name => (
              <Mui.MenuItem key={name?.id} value={name?.field}>
                <p className="listitem-text" style={{ color: 'white' }}>
                  {name?.field}
                </p>
              </Mui.MenuItem>
            ))}
          </StyledSelect>
          <Mui.Avatar>P</Mui.Avatar>
        </Mui.Stack>
      </Mui.Stack>

      {walletMode.create ? (
        <CreateAccount
          setWalletMode={setWalletMode}
          setCreateAccountDetails={setCreateAccountDetails}
        />
      ) : (
        <>
          {walletMode.import ? (
            <ImportAccount setWalletMode={setWalletMode} />
          ) : (
            <>
              {walletMode.send ? (
                <SendMode walletMode={walletMode} setWalletMode={setWalletMode} />
              ) : (
                <>
                  <Mui.Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}>
                    <Mui.Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width="70%">
                      <Mui.Stack
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}>
                        {createAccountDetails != '' ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            fill="green"
                            className="bi bi-circle-fill"
                            viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            fill="green"
                            className="bi bi-circle"
                            viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          </svg>
                        )}

                        <Mui.Typography variant="subtitle1">
                          {createAccountDetails != '' ? 'Connected' : 'Not Connected'}
                        </Mui.Typography>
                      </Mui.Stack>
                      {createAccountDetails != '' && (
                        <Mui.Stack
                          spacing={1}
                          sx={{
                            '&:hover': {
                              background: '#a3abe1',
                              borderRadius: '10px',
                            },
                            padding: '5px 20px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(createAccountDetails.address);
                            setCopyClipboard('Copied');
                          }}>
                          <Mui.Typography
                            variant="h4"
                            fontWeight="500"
                            color="black"
                            textAlign="center">
                            {createAccountDetails.accountName}
                          </Mui.Typography>
                          <Mui.Stack direction="row" alignItems="center" spacing={1}>
                            <Mui.Typography variant="h6">
                              {shortenAccount(createAccountDetails.address)}
                            </Mui.Typography>
                            <Mui.Tooltip title={copyClipboard}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="currentColor"
                                className="bi bi-clipboard"
                                viewBox="0 0 16 16">
                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                              </svg>
                            </Mui.Tooltip>
                          </Mui.Stack>
                        </Mui.Stack>
                      )}
                    </Mui.Stack>

                    <Mui.Stack
                      sx={{ cursor: 'pointer' }}
                      onClick={event => setAnchorEl(event.currentTarget)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-three-dots-vertical"
                        viewBox="0 0 16 16">
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                      </svg>
                    </Mui.Stack>
                  </Mui.Stack>
                  <Mui.Divider />

                  <Mui.Stack alignItems="center" p={1} spacing={2}>
                    <Mui.Stack sx={{ width: '30px', height: '30px' }}>
                      <img src={matic} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Mui.Stack>
                    <Mui.Typography variant="h3" fontWeight="500">
                      {accountBalanceDetails === '' ? '0' : accountBalanceDetails?.maticBalance}
                      &emsp; MATIC
                    </Mui.Typography>
                    <Mui.Stack
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setWalletMode({ send: true })}>
                      <Mui.Avatar sx={{ backgroundColor: '#5a1986' }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-send"
                          viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                      </Mui.Avatar>
                      <Mui.Typography variant="h5" fontWeight="600" color="#5a1986">
                        Send
                      </Mui.Typography>
                    </Mui.Stack>
                  </Mui.Stack>
                  <Tabs accountBalanceDetails={accountBalanceDetails} />
                </>
              )}
              {/* sendisinside */}
            </>
          )}
          {/* importisinside */}
        </>
      )}
      <Mui.Popover
        // id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 250,
            // minHeight: 80,
            bgcolor: '#f4f4f4',
            overflow: 'visible',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            // borderRadius: theme => theme.spacing(1),
            marginTop: 1.5,
            '& .MuiAvatar-root': {
              // width: 32,
              // height: 32,
              // marginLeft: -0.5,
              // marginRight: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 6,
              width: 10,
              height: 10,
              bgcolor: '#f4f4f4',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}>
        <Mui.Stack sx={{ padding: '10px 15px' }} spacing={1}>
          <Mui.Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => {
              setWalletMode({
                send: false,
                create: true,
                import: false,
              }),
                handleClose();
            }}
            sx={{
              cursor: 'pointer',
              padding: '10px 15px',
              '&:hover': {
                background: 'grey',
              },
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus-lg"
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
              />
            </svg>
            <Mui.Typography variant="h5">Create Account</Mui.Typography>
          </Mui.Stack>
          <Mui.Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => {
              setWalletMode({
                send: false,
                create: false,
                import: true,
              });
              handleClose();
            }}
            sx={{
              cursor: 'pointer',
              padding: '10px 15px',
              '&:hover': {
                background: 'grey',
              },
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-down"
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
              />
            </svg>
            <Mui.Typography variant="h5">Import Account</Mui.Typography>
          </Mui.Stack>
        </Mui.Stack>
      </Mui.Popover>
    </Mui.Stack>
  );
};

export default Wallet;
