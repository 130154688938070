import React from 'react';
import * as Mui from '@mui/material';
import matic from '../../../assets/images/icon/polygon-matic-logo.png';
import usdc from '../../../assets/images/icon/usd-coin-usdc-logo.png';
import usdt from '../../../assets/images/icon/tether-usdt-logo.png';
import doodev from '../../../assets/images/icon/f6a07373f833cbdf3521a40214533cbc.jpg';

const Tabs = props => {
  const [value, setValue] = React.useState('Activity');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const AssetStack = (itembal, itemname, itemimg) => {
    return (
      <Mui.Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderTop: '1px solid grey',
          borderBottom: '1px solid grey',
          padding: '20px 15px',
          '&:hover': {
            background: 'grey',
          },
        }}>
        <Mui.Stack direction="row" spacing={4} alignItems="center">
          <Mui.Stack sx={{ width: '30px', height: '30px' }}>
            <img src={itemimg} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Mui.Stack>
          <Mui.Typography variant="h4" onClick={() => console.log('wallet tab', props)}>
            {itembal} &emsp;
            {itemname}
          </Mui.Typography>
        </Mui.Stack>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-caret-right-fill"
          viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
        </svg>
      </Mui.Stack>
    );
  };
  const Assets = () => {
    return (
      <Mui.Stack p="0px 0 20px 0 ">
        <Mui.Stack height="150px" overflow="auto">
          {AssetStack(props?.accountBalanceDetails?.DooDevBalance, 'DOODEV', doodev)}
          {AssetStack(props?.accountBalanceDetails?.maticBalance, 'MATIC', matic)}
          {AssetStack(props?.accountBalanceDetails?.usdcBalance, 'USDC', usdc)}
          {AssetStack(props?.accountBalanceDetails?.usdtBalance, 'USDT', usdt)}
        </Mui.Stack>
        <Mui.Stack direction="row" spacing={2} alignItems="center" margin="0 auto" mt={2}>
          <Mui.Typography variant="h6">Need Help? Contact </Mui.Typography>
          <Mui.Typography variant="h5" color="#5a1986">
            PyraNft_Support{' '}
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Stack>
    );
  };
  const Activity = () => {
    const Data = [
      {
        event: 'Mint',
        date: 'oct 17',
        server: 'localhost:3000',
        value1: '-0.002',
        value2: '-0.000',
      },
      {
        event: 'Set Approval',
        date: 'Nov 15',
        server: 'localhost:3000',
        value1: '-0.1022',
        value2: '-0.0120',
      },
      {
        event: 'Mint',
        date: 'Jan 07',
        server: 'localhost:3000',
        value1: '1.400',
        value2: '0.200',
      },
      {
        event: 'Set Approval',
        date: 'Dec 24',
        server: 'localhost:3000',
        value1: '-0.402',
        value2: '-0.040',
      },
    ];
    return (
      <Mui.Stack p="0px 0 20px 0" height="210px" overflow="auto" spacing={1}>
        {Data?.map((item, index) => {
          return (
            <Mui.Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderTop: '1px solid grey',
                borderBottom: '1px solid grey',
                padding: '20px 15px',
                '&:hover': {
                  background: '#4e00e81f',
                },
              }}>
              <Mui.Stack direction="row" spacing={4} alignItems="center">
                <Mui.Avatar sx={{ width: '30px', height: '30px', background: 'blue' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-left-right"
                    viewBox="0 0 16 16">
                    <path
                      fillRule="evenodd"
                      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                    />
                  </svg>
                </Mui.Avatar>
                <Mui.Stack>
                  <Mui.Typography variant="h5" fontWeight="600">
                    {item.event}
                  </Mui.Typography>
                  <Mui.Stack direction="row" alignItems="center">
                    <Mui.Typography variant="h6" color="green">
                      {item.date} .
                    </Mui.Typography>
                    <Mui.Typography variant="h6">{item.server}</Mui.Typography>
                  </Mui.Stack>
                </Mui.Stack>
              </Mui.Stack>
              <Mui.Stack textAlign="right">
                <Mui.Typography variant="h5">{item.value1} MATIC</Mui.Typography>
                <Mui.Typography variant="subtitle1">{item.value2} MATIC</Mui.Typography>
              </Mui.Stack>
            </Mui.Stack>
          );
        })}
        <Mui.Stack width="100%" alignItems="center">
          <Mui.Stack direction="row" spacing={2} alignItems="center" mt={2}>
            <Mui.Typography variant="h6">Need Help? Contact </Mui.Typography>
            <Mui.Typography variant="h5" color="#5a1986">
              PyraNft_Support{' '}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Stack>
      </Mui.Stack>
    );
  };
  return (
    <Mui.Box sx={{ width: '100%' }}>
      <Mui.Box>
        <Mui.Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example">
          <Mui.Tab
            value="Assets"
            label="Assets"
            sx={{
              fontSize: '15px',
              width: '50%',
              '&:hover': {
                border: 'none',
              },
              '&:focus': {
                border: 'none',
              },
            }}
          />
          <Mui.Tab
            value="Activity"
            label="Activity"
            sx={{
              fontSize: '15px',
              width: '50%',
              '&:focus': {
                border: 'none',
              },
              '&:hover': {
                border: 'none',
              },
            }}
          />
        </Mui.Tabs>
      </Mui.Box>
      {value === 'Activity' ? Activity() : Assets()}
    </Mui.Box>
  );
};

export default Tabs;
