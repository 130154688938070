import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import MyFavouriteNft from '../components/layouts/MyItems/MyFavouriteNft';
import { _addToFav, _getMyFavNft, _removeFromFav } from '../services/mainApi';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import { copytoclipboard } from '../utils/helpers';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import previewThumb from '../assets/images/avatar/previewThumb.png';
// import { toast } from 'react-toastify';

const MyFavNfts = () => {
  const [favouritesTabData, setFavouritesTabData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [progress, setprogress] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const { ConnectedAccount, userProfileData } = React.useContext(ConnectWalletContext);
  const [copysttaustext, setcopysttaustext] = useState('Copy');
  let shortenAccount;
  if (ConnectedAccount) {
    shortenAccount = `${ConnectedAccount.slice(0, 5)}...${ConnectedAccount.slice(
      ConnectedAccount.length - 4,
    )}`;
  }

  const onFavouriteTab = () => {
    setprogress(true);
    _getMyFavNft(currentPage)
      .then(res => {
        if (!res.data.error) {
          setFavouritesTabData(res.data.data.nftList);
        } else {
          console.log(res.data.data.msg);
        }
      })
      .catch(err => console.log(err.data))
      .finally(() => setprogress(false));
  };
  const addOrRemoveFav = (type, id) => {
    console.log(type, id);
    setLoadMore(true);
    if (type == 'add') {
      addToFav(id);
    } else if (type == 'remove') {
      removeFav(id);
    }
  };
  const addToFav = id => {
    const payload = {
      nftId: id,
    };
    _addToFav(payload)
      .then(res => {
        setLoadMore(true);
        if (!res.data.error) {
          // toast.success('Added to favorites', '');
          onFavouriteTab();
        }
      })
      .catch(err => err.message);
  };
  const removeFav = id => {
    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        setLoadMore(true);
        if (!res.data.error) {
          // toast.success('Removed from favorites', '');
          onFavouriteTab();
        }
      })
      .catch(err => err.message);
  };
  const increaseexplorepagenumber = () => {
    setCurrentPage(currentPage + 1);
    setLoadMore(true);
  };
  useEffect(() => {
    onFavouriteTab();
  }, [currentPage]);
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center"></h1>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Explore 1</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <div className="avatar">
          <img src={imga1} alt="axies" />
        </div> */}
      <div className="center m-3">
        <h3 className="heading text-center" id="myInput">
          {/* {ConnectedAccount} */}
        </h3>
        <br />
        <div className="col-md-12">
          <div className="d_profile">
            <div className="profile_avatar">
              <div className="d_profile_img">
                <img
                  src={userProfileData?.userImage ? userProfileData?.userImage : previewThumb}
                  alt=""
                  className="mb-3"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          onMouseOver={() => setcopysttaustext('Copy')}
          className="text-center"
          style={{ cursor: 'pointer' }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">{copysttaustext}</Tooltip>}>
            <p
              onMouseOver={() => setcopysttaustext('Copy')}
              onClick={() => {
                copytoclipboard(`${ConnectedAccount}`);
                setcopysttaustext('Copied');
              }}>
              {shortenAccount}
            </p>
          </OverlayTrigger>
        </div>
      </div>
      <MyFavouriteNft
        data={favouritesTabData}
        progress={progress}
        addOrRemoveFav={addOrRemoveFav}
        increasepagenumber={() => increaseexplorepagenumber()}
        loadMore={loadMore}
      />
      <Footer />
    </div>
  );
};

export default MyFavNfts;
