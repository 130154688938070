import { common } from './core';

export function copytoclipboard(str) {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str);
  return Promise.reject('The Clipboard API is not available.');
}
export function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != '') return false;
  }
  return true;
}
export const getshortcode = account => {
  let shortenAccount;
  if (account) {
    shortenAccount = `${account.slice(0, 5)}...${account.slice(account.length - 4)}`;
  }
  return shortenAccount;
};
export function returnResult(request) {
  return new Promise((resolve, reject) => {
    common(request)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          // alert('unauthorized');
        }
        reject(error);
      });
  });
}
export function srt(on, descending) {
  on = on && on.constructor === Object ? on : {};
  return function (a, b) {
    if (on.string || on.key) {
      a = on.key ? a[on.key] : a;
      a = on.string ? String(a).toLowerCase() : a;
      b = on.key ? b[on.key] : b;
      b = on.string ? String(b).toLowerCase() : b;
      // if key is not present, move to the end
      if (on.key && (!b || !a)) {
        return !a && !b ? 1 : !a ? 1 : -1;
      }
    }
    return descending
      ? ~~(on.string ? b.localeCompare(a) : a < b)
      : ~~(on.string ? a.localeCompare(b) : a > b);
  };
}
