import React from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../../../assets/images/icon/Wallet.png';
import icon2 from '../../../assets/images/icon/Category.png';
import icon3 from '../../../assets/images/icon/Image2.png';
import icon4 from '../../../assets/images/icon/Bookmark.png';

const Create = () => {
  const data = [
    {
      title: 'Add Your NFTs',
      description:
        'Upload your work (we support all art formats), add essential details and customize with properties and stats.',
      icon: icon3,
      link: '/create-item',
      colorbg: 'icon-color3',
    },
    {
      title: 'Create Your Collection',
      description:
        'You can click on create to set up your collection. Add all necessary details to make your list stand out and set up your sales fee.',
      icon: icon2,
      link: '/create-item',
      colorbg: 'icon-color2',
    },
    {
      title: 'Set Up Your Wallet',
      description:
        'Connect the wallet of your choice to PyraNFT. You can proceed to create, buy or sell NFTs.',
      icon: icon1,
      colorbg: 'icon-color1',
      link: '/wallet-connect',
    },
    {
      title: 'List Them For Sale',
      description:
        'List it for Auction or Fixed-price as per your preference. You are now set up for success!',
      icon: icon4,
      link: '/mynft',
      colorbg: 'icon-color4',
    },
  ];
  return (
    <section className="tf-box-icon live-auctions tf-section style7 bg-style">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="heading-live-auctions style2 mg-t-3 mg-bt-22">
              <h3 className="heading-fill mg-bt-16">Creat NFT</h3>
              <h2 className="tf-title text-left pb-15">Create And Sell Your NFTs</h2>
              <p className="content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin morbi donec
                venenatis sed eget pellentesque viverra ut. Elementum nam praesent mauris auctor
                amet, pulvinar adipiscing ultricies ut. Id dignissim tristique ultrices arcu tempor.
                Aenean quam odio fringilla amet, imperdiet.
              </p>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12">
            <div className="sc-box-icon-inner style3">
              {data.map((item, index) => (
                <CreateItem key={index} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CreateItem = props => (
  <div className="sc-box-icon">
    <div className="image">
      <div className={`icon-create ${props.item.colorbg}`}>
        <img src={props.item.icon} alt="" />
      </div>
    </div>
    <div className="wrap-box">
      <h3 className="heading">
        <Link to={props.item.link ? props.item.link : '#'}>{props.item.title}</Link>
      </h3>
      <p className="content">{props.item.description}</p>
    </div>
  </div>
);

export default Create;
