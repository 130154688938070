import config from '../lib/config';
const getChainContractAddress = async chainName => {
  console.log('PRK getting chain contract address..');
  let chainId;
  let cryptoSymbol;
  if (chainName == 'Ethereum') {
    config.NFTContractAddress = '0x32Fe2A605BDdB548d3D2b19596a4B762E594c017';
    chainId = '0x5'; //5 //Goerli testNet
    cryptoSymbol = 'ETH';
  }
  if (chainName == 'Avalanche') {
    config.NFTContractAddress = '0x5cFC9e7EC2d3f07Bf83934D20e64f2f657C8c48E';
    chainId = '0xa869';
    cryptoSymbol = 'AVAX';
  }

  if (chainName == 'Polygon') {
    config.NFTContractAddress = '0xd10B9e80c728f9E0E5C83e251C2Ea59e743D98F0';
    chainId = '0x13881';
    cryptoSymbol = 'MATIC';
  }
  if (chainName == 'Binance') {
    config.NFTContractAddress = '0x7B23af3109e98A93d54AE0826d7b4A717ddf0eAd';
    chainId = '0x61';
    cryptoSymbol = 'BNB';
  }

  console.log('PRK chain contract address return value..', config.NFTContractAddress);
  console.log('PRK chain Id ..', chainId);
  return {
    NFTcontractAddress: config.NFTContractAddress,
    chainId: chainId,
    cryptoSymbol: cryptoSymbol,
  };
};

export default getChainContractAddress;
