import React from 'react';
import { Modal } from 'react-bootstrap';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import buyingOrder from '../../../nft/BuyingNFT';
import loadWeb3 from '../../../utils/loadWeb3';
import PyraNftMarket721 from '../../../ABI/PyraNftMarket.json';
import PyraMultiToken1155 from '../../../ABI/PyraMultiToken.json';
import config from '../../../lib/config';
import { _updateNft } from '../../../services/mainApi';
//import commonFunction from '../../../utils/common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import shortenAccount from '../../../utils/shortenAccount';
//import getChainContractAddress from '../../../utils/getChainContractAddress';

const BuyModal = props => {
  let navigate = useNavigate();
  let { show, onHide, assetdata } = props;
  console.log('PRK Buy modeal data from buyModal.jsx assetdata', assetdata);
  //for testing hardcoded the tokenid
  //let tokenId = 1654795282;
  const { ConnectedAccount, netWorkSymbol } = React.useContext(ConnectWalletContext);
  const [nftServiceFee, setNftServiceFee] = React.useState(0);
  const [nftTokenPrice, setNftTokenPrice] = React.useState(0);
  const [nftTokenValue, setNftTokenValue] = React.useState(0);
  const [userWalletBalance, setUserWalletBalance] = React.useState(0);
  const [progress, setprogress] = React.useState(false);
  // let shortenAccount;
  // if (ConnectedAccount) {
  //   shortenAccount = `${ConnectedAccount.slice(0, 5)}...${ConnectedAccount.slice(
  //     ConnectedAccount.length - 4,
  //   )}`;
  // }
  //get the contract address
  console.log('Multi token flag', assetdata?.multiToken);
  let contractAddress = assetdata?.collectionAddress;
  let NFTtokenContract = PyraNftMarket721;
  // const getContractAddress = async () => {
  //   let contractChainAddress = await getChainContractAddress(assetdata?.blockChainName);
  //   contractAddress = contractChainAddress.NFTcontractAddress;
  //   console.log('PRK buying contract address from getchainContract..', contractAddress);
  // };
  if (contractAddress == undefined) {
    if (assetdata?.CollectionData?.length > 0) {
      contractAddress = assetdata?.CollectionData[0]?.address;
    } else if (assetdata?.multiToken === true) {
      //check for ERC-1155 then get the respective contract address
      contractAddress = config.NFTMultiTokenContract;
      NFTtokenContract = PyraMultiToken1155;
      console.log('PRK for buying modal getting ERC1155 Contract address', contractAddress);
    } else {
      //check for ERC-721 then get that contract address
      contractAddress = config.NFTContractAddress;
      //console.log('PRK chain name from buying nft', assetdata?.blockChainName);
      //getContractAddress();
      //console.log('PRK for buying modal getting ERC721 Contract address', contractAddress);
    }
  }

  React.useEffect(() => {
    const getBuyingData = async () => {
      let web3 = await loadWeb3();
      console.log('PRK Buying contract address', contractAddress);
      console.log('PRK Buying token id', assetdata?.tokenId);
      //get the contract address based on the selected chain
      // contractAddress = await getChainContractAddress(assetdata?.blockChainName);
      // console.log('PRK buying contract address for the selected chain', contractAddress);

      //need to pass the tokenId
      let buyingDetails;
      try {
        buyingDetails = await buyingOrder(assetdata?.tokenId, contractAddress); //assetdata?.collectionAddress); '0xA123bEeA66ACFE59945FD545a0E43E30c5d0CfB5',
        console.log('PRK Buying buyingDetails object value', buyingDetails);
      } catch (err) {
        console.log();
      }

      //get user wallet balance
      if (ConnectedAccount) {
        let userAccountBalance = await web3.eth.getBalance(ConnectedAccount);
        setNftServiceFee(buyingDetails?.NftServiceFee);
        setNftTokenPrice(buyingDetails?.NftTokenPrice);
        setNftTokenValue(buyingDetails?.NftTokenValue);
        setUserWalletBalance(
          parseFloat(web3.utils.fromWei(userAccountBalance, 'ether')).toFixed(3),
        );

        //checking data points
        console.log('Buying data points .........>');
        console.log('Buying price', buyingDetails?.NftTokenPrice);
        console.log('Buying NftServiceFee', buyingDetails?.NftServiceFee);
        console.log('Buying NftTokenValue', buyingDetails?.NftTokenValue);
      }
    };
    getBuyingData();

    //cleanup
    return () => {};
  }, [assetdata?.tokenId]);

  const buyingNftToken = async tokenId => {
    let web3 = await loadWeb3();
    setprogress(true);
    //checking data points
    console.log('Buying price', nftTokenPrice);
    console.log('Buying NftServiceFee', nftServiceFee);
    console.log('Buying NftTokenValue', nftTokenValue);
    console.log('Buying token Id', tokenId);

    //
    //get collection contract address else use sigleContract address as default
    // let NFTContractAddress = assetdata?.collectionAddress
    //   ? assetdata?.collectionAddress
    //   : config.NFTContractAddress;
    // let NFTContractAddress = assetdata?.CollectionData[0]?.address
    //   ? assetdata?.CollectionData[0]?.address
    //   : assetdata?.collectionAddress;

    // //get the collection address or  use erc721 or else erc1155
    // NFTContractAddress = NFTContractAddress ? NFTContractAddress : config.NFTContractAddress;
    let nftContract;
    let NftTokenOwnerOf;
    let tokenPrice;
    let tokenBuyingOrderValue;
    try {
      nftContract = new web3.eth.Contract(NFTtokenContract, contractAddress);

      NftTokenOwnerOf = await nftContract.methods.ownerOf(tokenId).call();
      tokenPrice = web3.utils.toWei(nftTokenPrice.toString(), 'ether');
      tokenBuyingOrderValue = web3.utils.toWei(nftTokenValue.toString(), 'ether');
      console.log('ERC1155 token owner', NftTokenOwnerOf);
      console.log('ERC1155 token price', tokenPrice);
      console.log('ERC1155 token buying value', tokenBuyingOrderValue);
    } catch (err) {
      console.log();
    }
    //----
    let result;
    //run the below method for the ERC-721 when its flagged

    try {
      result = await nftContract.methods
        .saleToken(
          NftTokenOwnerOf, //from owner account `tokenId` token must exist and be owned by `from`.
          assetdata.tokenId, //tokenId
          tokenPrice, // owner sale order token price
        )
        .send({
          from: ConnectedAccount,
          value: tokenBuyingOrderValue,
          //gas: 100,
        });
    } catch (err) {
      toast.error('Selling not success');
      setprogress(false);
    }

    //----

    //call updateNFT API once the above BuyingNFT process is done
    if (result && result.transactionHash) {
      const _myNftpromise = _updateNft({
        currentAddress: ConnectedAccount,
        fileUrl: assetdata?.fileUrl ? assetdata?.fileUrl : assetdata?.img,
        title: assetdata.title,
        description: assetdata.description,
        categoryId: assetdata.categoryId,
        putOnMarketplace: false,
        noOfCopies: 1,
        _id: assetdata?._id ? assetdata?._id : assetdata?.id,
        price: 0,
        properties: assetdata.properties,
        blockChainName: assetdata?.blockChainName,
        tokenId: assetdata.tokenId,
      });
      _myNftpromise
        .then(
          res => {
            if (!res.error) {
              console.log(res);
              navigate('/mynft');
            }
          },
          err => {
            console.log('fail', err.message);
          },
        )

        .catch(err => {
          console.log('update failed', err.message);
          setprogress(false);
        });
      onHide();
    }
    setprogress(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide(), setprogress(false);
      }}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-15 pd-40">
        <h3>Checkout</h3>
        <div className="card-media  center">
          <img
            className="cardimg_size"
            src={assetdata?.fileUrl ? assetdata?.fileUrl : assetdata?.img}
            alt="axies"
            // style={{ height: '100px' }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p> Seller:</p>
            <p className="text-right price color-popup">
              {shortenAccount(
                assetdata?.walletAddress ? assetdata?.walletAddress : assetdata?.currentAddress,
              )}
            </p>
          </div>
          <div>
            <p> Buyer</p>
            <p className="text-right price color-popup"> {shortenAccount(ConnectedAccount)}</p>
          </div>
        </div>
        <hr />
        <p className="text-center">
          <span className="price color-popup ">
            Your balance : {userWalletBalance} {netWorkSymbol}
          </span>
        </p>
        <hr />
        <div className="d-flex justify-content-between">
          <p> Price:</p>
          <p className="text-right price color-popup">
            {' '}
            {nftTokenPrice} {netWorkSymbol}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Service fee:</p>
          <p className="text-right price color-popup">{nftServiceFee}% </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Total payable amount:</p>
          <p className="text-right price color-popup">
            {nftTokenValue} {netWorkSymbol}
          </p>
        </div>
        {progress ? (
          <div className="center">
            {' '}
            <i className="fa fa-spinner fa-spin fa-2x mr-2" aria-hidden="true" id="circle1"></i>
          </div>
        ) : (
          <button className="btn btn-primary" onClick={() => buyingNftToken(assetdata.tokenId)}>
            Proceed to payment
          </button>
        )}
      </div>
    </Modal>
  );
};

export default BuyModal;
