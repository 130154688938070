import React from 'react';
import * as Mui from '@mui/material';

const Pagination = props => {
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <Mui.Pagination
        className="pagination"
        // Math.trunc(props?.exploretotalItems / 10 + 1)
        count={props.count}
        showFirstButton
        showLastButton
        size="large"
        onChange={props.HandlePageChange}
        defaultPage={props.defaultPage}
        page={props.page}
        sx={{
          '& .MuiButtonBase-root-MuiPaginationItem-root': {
            color: 'white',
          },
          '& .MuiPaginationItem-root': {
            // color: 'white',
          },
          '& .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
            border: 'none',
            background: '#5142fc !important',
          },
          '& .css-n8417t-MuiSvgIcon-root-MuiPaginationItem-icon': {
            fontSize: '3rem',
          },
          '& .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover': {
            background: '#5142fc !important',
          },
          '& .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root:hover': {
            background: '#5142fc !important',
          },
        }}
      />
      <br />
    </div>
  );
};

export default Pagination;
