import Web3 from 'web3/dist/web3.min';
//Polygon TestNet
const web3 = new Web3('https://rpc-mumbai.maticvigil.com/');
//for mainNet use rpc - https://polygon-rpc.com

//to create wallet
const createWallet = async () => {
  //create a new wallet with one account
  //pass as per request - usally will create one account at one time
  await web3.eth.accounts.wallet.create(1);

  //let hotWalletAddress = newWallet[0].address;
  //let hotWalletPrivateKey = newWallet[0].privateKey;
  //console.log('NewWallet', newWallet[0]);

  //get the key stroke from user for more protection..
  //store this encrypted keystroke in the stroage..
  //can pass thru env variable like - process.env.BLOCKCHAIN_CRYPTO_PASS
  let keyStroke = await web3.eth.accounts.wallet.encrypt('test!'); //password
  //return the keystroke to do decrypt
  return keyStroke;
};

//--------------------------------------
const getEncryptAccount = async (keyStroke, accountName) => {
  //call the createWallet function to get the encrypted wallet connection
  let keyStrokeValue = await createWallet();
  console.log('Encrypted Wallet Account Details..', keyStrokeValue);
  let walletAccount = await web3.eth.accounts.wallet.decrypt(
    keyStrokeValue,
    keyStroke, //Can receive from Wallet User
  );
  console.log('Name of the acount', accountName);
  console.log('Decrypted Wallet Account Details', walletAccount[0]);
  if (walletAccount.length > 0) {
    console.log('Wallet Created Successfully!');
  }
  return { ...walletAccount[0], accountName };
};
// getEncryptAccount('test!');

export default getEncryptAccount;

//using below wallet created account as sample
// Decrypted Wallet Account Details {
//     address: '0xeae429C633f672c5E75a7463b0271D4505f548DA',
//     privateKey: '0x905c5d586955dec39d0387f0c9315e0efe0ddd5a4175ecd986fda99694a82952',
//     signTransaction: [Function: signTransaction],
//     sign: [Function: sign],
//     encrypt: [Function: encrypt],
//     index: 0
//   }
//   Wallet Created Successfully!
