import React from 'react';
import BidingList from './BidingList';

export default function TopBids(props) {
  const { bidDetails } = props;

  return (
    <div>
      <div className={`sc-card-product explode style2 mg-bt`}>
        <div>
          <h4 className="text-center">List of top bids!</h4>
          {bidDetails.length >= 1 &&
            bidDetails
              .sort((a, b) => b.price - a.price)
              .filter((d, i) => i < 3)
              .map((item, index) => {
                return (
                  <>
                    <div key={index} className="mt-10">
                      <BidingList data={item} />
                    </div>
                  </>
                );
              })}
          {bidDetails.length == 0 && (
            <div className="text-center mt-10">
              <h6>No active bids!</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
