import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
//import buyingOrder from '../../../nft/BuyingNFT';
import loadWeb3 from '../../../utils/loadWeb3';
import PyraNftMarket721 from '../../../ABI/PyraNftMarket.json';
import PyraMultiToken1155 from '../../../ABI/PyraMultiToken.json';
import config from '../../../lib/config';
import { _updateNft } from '../../../services/mainApi';
//import commonFunction from '../../../utils/common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import shortenAccount from '../../../utils/shortenAccount';

const BuyMutiTokenModal = props => {
  let navigate = useNavigate();
  let { show, onHide, assetdata } = props;
  console.log('Buy modeal data from buyModal.jsx assetdata', assetdata);
  //for testing hardcoded the tokenid
  //let tokenId = 1654795282;
  const { ConnectedAccount, netWorkSymbol } = React.useContext(ConnectWalletContext);
  //   const [nftServiceFee, setNftServiceFee] = React.useState(0);
  //   const [nftTokenPrice, setNftTokenPrice] = React.useState(0);
  //   const [nftTokenValue, setNftTokenValue] = React.useState(0);
  const [userWalletBalance, setUserWalletBalance] = React.useState(0);
  const [progress, setprogress] = React.useState(false);
  const [servicefee, setservicefee] = useState(0);
  const [TotalQuantity, setTotalQuantity] = useState(0);
  const [youWillPay, setYouWillPay] = useState(0);
  const [NFTtokenPrice, setNFTTokenPrice] = useState(0);
  // let shortenAccount;
  // if (ConnectedAccount) {
  //   shortenAccount = `${ConnectedAccount.slice(0, 5)}...${ConnectedAccount.slice(
  //     ConnectedAccount.length - 4,
  //   )}`;
  // }
  //get the contract address
  let contractAddress = assetdata?.collectionAddress;
  let NFTtokenContract = PyraNftMarket721;
  if (contractAddress == undefined) {
    if (assetdata?.CollectionData?.length > 0) {
      contractAddress = assetdata?.CollectionData[0]?.address;
    } else if (assetdata.multiToken === true) {
      //check for ERC-1155 then get the respective contract address
      contractAddress = config.NFTMultiTokenContract;
      NFTtokenContract = PyraMultiToken1155;
    } else {
      //check for ERC-721 then get that contract address
      contractAddress = config.NFTContractAddress;
    }
  }

  async function getservicefee() {
    let web3 = await loadWeb3();
    let CoursetroContract;
    try {
      CoursetroContract = new web3.eth.Contract(NFTtokenContract, contractAddress);
    } catch (err) {
      console.log();
    }

    var fee = await CoursetroContract.methods.getServiceFee().call();
    setservicefee(fee / 10 ** config.decimalvalues);
    console.log('Multi token service fee', servicefee);
  }

  //   const PriceCalculate = async (data = {}) => {
  //     var price = typeof data.price != 'undefined' ? data.price : TokenPrice;
  //     var quantity =
  //       typeof data.quantity != 'undefined' ? data.quantity : NoOfToken;
  //     var newPrice =
  //       item.type == 721
  //         ? price * config.decimalvalues
  //         : quantity * (price * config.decimalvalues);
  //     var per = (newPrice * config.fee) / 1e20;
  //     var mulWei = newPrice + per;
  //     Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
  //     Set_MultipleWei(mulWei);
  //     Set_Price(newPrice);
  //     // //("PriceCalculate : ",data, price,quantity,newPrice,weii,per,mulWei,(mulWei / config.decimalvalues).toFixed(config.toFixed))
  //   };

  const inputChange = e => {
    console.log('Pyra Multi Token calc Process.. starts');
    if (typeof e.target.value != 'undefined') {
      setTotalQuantity(e.target.value);
      let totalQtyReceived = e.target.value;
      console.log('Pyra Muti totalQuantity from set', TotalQuantity);
      console.log('Pyra Muti totalQuantity', totalQtyReceived);
      //get totalQtyPrice by tokenPrice
      let totalQtyPrice = totalQtyReceived * assetdata.price;
      console.log('Pyra Multi token totalQtyprice', totalQtyPrice);
      let NftTokenServiceFeeCalc = (totalQtyPrice * (servicefee * 10 ** 18)) / 10 ** 20;
      console.log('Pyra Multi token serviceFeeCalc', NftTokenServiceFeeCalc);
      let newNFTPrice = parseFloat(parseFloat(totalQtyPrice) + parseFloat(NftTokenServiceFeeCalc));
      setYouWillPay(parseFloat(newNFTPrice).toFixed(4));
      setNFTTokenPrice(newNFTPrice);
      console.log('Multi token you will pay price value', newNFTPrice);
      //PriceCalculate({ quantity: value });
    }
    // ItemValidation({TokenPrice:value});
  };

  React.useEffect(() => {
    getservicefee();
    const getBuyingData = async () => {
      let web3 = await loadWeb3();
      //   console.log('Buying contract address', contractAddress);
      //   console.log('Buying token id', assetdata?.tokenId);
      //   //need to pass the tokenId
      //   let buyingDetails = await buyingOrder(assetdata?.tokenId, contractAddress); //assetdata?.collectionAddress); '0xA123bEeA66ACFE59945FD545a0E43E30c5d0CfB5',
      //   console.log('Buying buyingDetails object value', buyingDetails);
      //get user wallet balance
      if (ConnectedAccount) {
        let userAccountBalance = await web3.eth.getBalance(ConnectedAccount);
        setUserWalletBalance(
          parseFloat(web3.utils.fromWei(userAccountBalance, 'ether')).toFixed(3),
        );
      }
      //     //checking data points
      //     console.log('Buying data points .........>');
      //     console.log('Buying price', buyingDetails?.NftTokenPrice);
      //     console.log('Buying NftServiceFee', buyingDetails?.NftServiceFee);
      //     console.log('Buying NftTokenValue', buyingDetails?.NftTokenValue);
      //   }
    };
    getBuyingData();

    //cleanup
    return () => {};
  });

  const buyingNftToken = async tokenId => {
    let web3 = await loadWeb3();
    setprogress(true);
    //checking data points
    // console.log('Buying price', nftTokenPrice);
    // console.log('Buying NftServiceFee', nftServiceFee);
    // console.log('Buying NftTokenValue', nftTokenValue);

    //
    //get collection contract address else use sigleContract address as default
    // let NFTContractAddress = assetdata?.collectionAddress
    //   ? assetdata?.collectionAddress
    //   : config.NFTContractAddress;
    // let NFTContractAddress = assetdata?.CollectionData[0]?.address
    //   ? assetdata?.CollectionData[0]?.address
    //   : assetdata?.collectionAddress;

    // //get the collection address or  use erc721 or else erc1155
    // NFTContractAddress = NFTContractAddress ? NFTContractAddress : config.NFTContractAddress;
    let nftContract;
    try {
      nftContract = new web3.eth.Contract(NFTtokenContract, contractAddress);
    } catch (err) {
      console.log();
    }
    //let NftTokenOwnerOf = await nftContract.methods.ownerOf(tokenId).call();
    //let tokenPrice = web3.utils.toWei(nftTokenPrice.toString(), 'ether');
    //let tokenBuyingOrderValue = web3.utils.toWei(nftTokenValue.toString(), 'ether');

    let NftTokenOwnerOf = assetdata?.ownerAddress;
    let tokenPrice = assetdata?.price * TotalQuantity;
    let tokenAmount = web3.utils.toWei(tokenPrice.toString(), 'ether');
    let tokenBuyingOrderValue = web3.utils.toWei(NFTtokenPrice.toString(), 'ether');
    console.log('ERC1155 Buying token Id', tokenId);
    console.log('ERC1155 token owner', NftTokenOwnerOf);
    console.log('ERC1155 token price', tokenAmount);
    console.log('ERC1155 token quantity', TotalQuantity);
    console.log('ERC1155 token buying value', tokenBuyingOrderValue);
    console.log('ERC1155 token Buyer account', ConnectedAccount);

    //----
    let result;
    //fun the below method for multi Token ERC-1155 when it flagged

    console.log('ERC1155 buying process starts...');
    try {
      result = await nftContract.methods
        .saleToken(
          NftTokenOwnerOf, //from owner account `tokenId` token must exist and be owned by `from`.
          tokenId, //tokenId
          tokenAmount, // owner sale order token price
          TotalQuantity, // no. of Tokens
        )
        .send({
          from: ConnectedAccount,
          value: tokenBuyingOrderValue,
          //gas: 100,
        });
    } catch (err) {
      toast.error('Selling not success');
      console.log('ERC1155 Sale order error', err.message);
      setprogress(false);
    }

    //----

    //call updateNFT API once the above BuyingNFT process is done
    if (result && result.transactionHash) {
      const _myNftpromise = _updateNft({
        currentAddress: ConnectedAccount,
        fileUrl: assetdata?.fileUrl ? assetdata?.fileUrl : assetdata?.img,
        title: assetdata.title,
        description: assetdata.description,
        categoryId: assetdata.categoryId,
        putOnMarketplace: false,
        noOfCopies:
          assetdata?.noOfCopies > 0 ? assetdata?.noOfCopies - TotalQuantity : assetdata?.noOfCopies,
        _id: assetdata?._id ? assetdata?._id : assetdata?.id,
        price: 0,
        properties: assetdata.properties,
        //metadataUrl: assetdata.metadataUrl,
        tokenId: assetdata.tokenId,
        blockChainName: assetdata?.blockChainName,
      });
      _myNftpromise
        .then(
          res => {
            if (!res.error) {
              console.log(res);
              navigate('/mynft');
            }
          },
          err => {
            console.log('fail', err.message);
          },
        )

        .catch(err => {
          console.log('update failed', err.message);
          setprogress(false);
        });
      onHide();
    }
    setprogress(false);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-15 pd-40">
        <h3>Checkout</h3>
        <div className="card-media  center">
          <img
            className="cardimg_size"
            src={assetdata?.fileUrl ? assetdata?.fileUrl : assetdata?.img}
            alt="axies"
            // style={{ height: '100px' }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p> Seller:</p>
            <p className="text-right price color-popup">
              {shortenAccount(
                assetdata?.walletAddress ? assetdata?.walletAddress : assetdata?.currentAddress,
              )}
            </p>
          </div>
          <div>
            <p> Buyer</p>
            <p className="text-right price color-popup"> {shortenAccount(ConnectedAccount)}</p>
          </div>
        </div>
        <hr />
        <p className="text-center">
          <span className="price color-popup ">
            Your balance : {userWalletBalance} {netWorkSymbol}
          </span>
        </p>
        <hr />
        <div className="center">
          <input
            type="number"
            min="1"
            className="text-center"
            placeholder="Quantity"
            style={{ width: '10rem' }}
            onChange={inputChange}
          />
          <h6 className="mt-2 ml-2">Available {assetdata?.noOfCopies}</h6>
        </div>
        <div className="d-flex justify-content-between">
          <p> Price per unit:</p>
          <p className="text-right price color-popup">
            {' '}
            {assetdata?.price} {netWorkSymbol}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Service fee:</p>
          <p className="text-right price color-popup">{servicefee}% </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Total payable amount:</p>
          <p className="text-right price color-popup">
            {youWillPay} {netWorkSymbol}
          </p>
        </div>
        {progress ? (
          <div className="center">
            {' '}
            <i className="fa fa-spinner fa-spin fa-2x mr-2" aria-hidden="true" id="circle1"></i>
          </div>
        ) : (
          <button className="btn btn-primary" onClick={() => buyingNftToken(assetdata.tokenId)}>
            Proceed to payment
          </button>
        )}
      </div>
    </Modal>
  );
};

export default BuyMutiTokenModal;
