import './App.css';
import { Routes, Route } from 'react-router-dom';
import routes from './pages/index';
import { OnlineStatusProvider } from './context/networkconnection';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div>
      <OnlineStatusProvider>
        <ToastContainer />
        <Routes>
          {routes.map((data, index) => (
            <Route
              onUpdate={() => window.scrollTo(0, 0)}
              exact={true}
              path={data.path}
              element={data.component}
              key={index}
            />
          ))}
        </Routes>
      </OnlineStatusProvider>
    </div>
  );
}

export default App;
