import React from 'react';
import * as Mui from '@mui/material';
import AccountSelect from './accountselect';

const SendMode = props => {
  const [accountData, setAccountData] = React.useState();
  const [account, setAccount] = React.useState({
    showAccount: false,
    accountSelect: false,
  });
  const Data = [
    {
      avatar: 'R',
      accountName: 'Account 1',
      accountNo: '0x9640608d64fdd3c3167c227d74f25d2ca41b5926',
    },
    {
      avatar: 'R',
      accountName: 'Account 2',
      accountNo: '0x9640608d64fdd3c3167c227d74f25d2ca41b5926',
    },
    {
      avatar: 'R',
      accountName: 'Account 3',
      accountNo: '0x9640608d64fdd3c3167c227d74f25d2ca41b5926',
    },
    {
      avatar: 'R',
      accountName: 'Account 3',
      accountNo: '0x9640608d64fdd3c3167c227d74f25d2ca41b5926',
    },
    {
      avatar: 'R',
      accountName: 'Account 3',
      accountNo: '0x9640608d64fdd3c3167c227d74f25d2ca41b5926',
    },
  ];
  const SelectAccount = item => {
    setAccount({
      showAccount: false,
      accountSelect: true,
    });
    setAccountData(item);
  };
  return (
    <Mui.Stack>
      {account.accountSelect ? (
        <AccountSelect accountData={accountData} setAccount={setAccount} />
      ) : (
        <>
          <Mui.Stack direction="row" justifyContent="space-around" alignItems="center" mt={2}>
            <Mui.Typography variant="h4">Send To</Mui.Typography>
            <Mui.Typography
              variant="h5"
              color="darkcyan"
              sx={{ cursor: 'pointer' }}
              onClick={() => props.setWalletMode({ send: false })}>
              Cancel
            </Mui.Typography>
          </Mui.Stack>
          <Mui.Stack width="100%" p="15px 20px">
            <Mui.OutlinedInput
              startAdornment={
                <Mui.InputAdornment position="start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </Mui.InputAdornment>
              }
              sx={{
                border: '1px solid grey',
                '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  '&:focus': {
                    border: 'none',
                  },
                },
                '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
                  border: 'none',
                  '&:focus': {
                    border: 'none',
                  },
                },
                '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                  border: 'none',
                  '&:focus': {
                    border: 'none',
                  },
                },
              }}
              placeholder="Search,public address(0x)"
            />
          </Mui.Stack>

          {account.showAccount ? (
            <Mui.Stack spacing={2} sx={{ height: '280px', overflow: 'auto' }}>
              <Mui.Stack
                direction="row"
                alignItems="center"
                sx={{ background: 'grey', padding: '15px ' }}>
                <div
                  onClick={() =>
                    setAccount({
                      showAccount: false,
                      accountSelect: false,
                    })
                  }
                  style={{ cursor: 'pointer' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-left-fill"
                    viewBox="0 0 16 16">
                    <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                  </svg>
                </div>
                <Mui.Typography variant="h5" paddingLeft="10px">
                  My Accounts
                </Mui.Typography>
              </Mui.Stack>
              <Mui.Stack>
                {Data?.map((item, index) => {
                  return (
                    <Mui.Stack
                      direction="row"
                      key={index}
                      spacing={2}
                      sx={{
                        padding: '10px 5px',
                        borderBottom: '1px solid black',
                        cursor: 'pointer',
                        '&:hover': {
                          background: '#a3abe1',
                        },
                      }}
                      onClick={() => SelectAccount(item)}>
                      <Mui.Avatar>{item.avatar}</Mui.Avatar>
                      <Mui.Stack>
                        <Mui.Typography variant="h5">{item.accountName}</Mui.Typography>
                        <Mui.Typography variant="subtitle1">{item.accountNo}</Mui.Typography>
                      </Mui.Stack>
                    </Mui.Stack>
                  );
                })}
              </Mui.Stack>
            </Mui.Stack>
          ) : (
            <Mui.Typography
              variant="h5"
              fontWeight="550"
              textAlign="center"
              color="blue"
              sx={{ border: '1px solid grey', padding: '15px 0', cursor: 'pointer' }}
              onClick={() =>
                setAccount({
                  showAccount: true,
                  accountSelect: false,
                })
              }>
              Transfer between my accounts
            </Mui.Typography>
          )}
        </>
      )}
    </Mui.Stack>
  );
};

export default SendMode;
