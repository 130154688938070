const toastsettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
let CurrencySymbol = 'ETH';
let WrapperSymbol = 'WETH';
let cryptoLogo;
const commonFunction = {
  toastsettings,
  CurrencySymbol,
  WrapperSymbol,
  cryptoLogo,
};

export default commonFunction;
