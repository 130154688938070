import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import menus from '../../pages/menu';
import logodark2x from '../../assets/images/logo/pyranft_logo.png';
import logoheader from '../../assets/images/logo/pyranft_logo.png';
import logoheader2x from '../../assets/images/logo/pyranft_logo.png';
import DarkMode from './DarkMode';
import logodark from '../../assets/images/logo/logo_dark.png';
import avt from '../../assets/images/avatar/avt-2.jpg';
import coin from '../../assets/images/logo/coin.svg';
import { ConnectWalletContext } from '../../context/ConnectWalletContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { getshortcode } from '../../utils/helpers'; //copytoclipboard
import loadWeb3 from '../../utils/loadWeb3';
// import { useNavigate } from 'react-router-dom';

const HeaderStyle2 = () => {
  // let navigate = useNavigate();

  const [userWalletBalance, setUserWalletBalance] = React.useState(0);

  const { pathname } = useLocation();
  const { ConnectedAccount, netWrokChainName, netWorkSymbol, setSearchGlobally } =
    React.useContext(ConnectWalletContext);
  const [searchdata, setsearchdata] = useState('');
  // const getSearchData = () => {
  //   console.log(searchdata, 'header');
  //   if (searchdata?.length === 0) {
  //     setSearchGlobally(searchdata);
  //     // navigate('/explore-02');
  //   }
  // };
  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const isSticky = () => {
    const header = document.querySelector('.js-header');
    const scrollTop = window.scrollY;
    scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
    scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle('active');
    btnToggle.current.classList.toggle('active');
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = index => {
    setActiveIndex(index);
  };
  useEffect(() => {
    if (ConnectedAccount) {
      const getWalletBalance = async () => {
        let web3 = await loadWeb3();
        let getBalance = await web3.eth.getBalance(ConnectedAccount);
        setUserWalletBalance(parseFloat(getBalance / 10 ** 18).toFixed(4));
      };
      getWalletBalance();
    }
  }, [ConnectedAccount]);

  const popoverClickRootClose = (
    <Popover
      id="popover-trigger-click-root-close"
      title="Popover bottom"
      className="wallet-connect style-2">
      <div style={{ fontSize: '10px' }}>Wallet Connected</div>
    </Popover>
  );
  console.log('themeeee', localStorage.theme);
  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-containers">
        <div className={localStorage?.theme === 'light' ? 'header2-flex-light' : 'header2-flex'}>
          <div className="header-flex-logo-div">
            <Link to="/" rel="home" className="">
              <div className="logo-change-width">
                <img
                  className="logo-dark"
                  id="logo_header"
                  src={logodark}
                  srcSet={`${logodark2x}`}
                  alt="nft-gaming"
                />
              </div>
              <img
                className="logo-light"
                id="logo_header"
                src={logoheader}
                srcSet={`${logoheader2x}`}
                alt="nft-gaming"
              />
            </Link>
          </div>
          <div className="search-div">
            <input
              type="search"
              id="s"
              className="textfield-search"
              placeholder="Search..."
              name="s"
              title="Search for"
              onChange={e => {
                setSearchGlobally(e.target.value);
                setsearchdata(e.target.value);
              }}
              value={searchdata}
            />
            <Link to={searchdata.length >= 3 ? '/explore-02' : '#'}>
              <div
                className="search icon-search"
                // type="submit"
                title="Search"
                //  onClick={getSearchData}>
              >
                <i className="icon-fl-search-filled "></i>
              </div>
            </Link>
          </div>

          <nav id="main-nav" className="main-nav relative-change" ref={menuLeft}>
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, index) => (
                <li
                  style={{ display: data.id == 2 ? 'none' : '' }}
                  key={index}
                  onClick={() => handleOnClick(index)}
                  className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} 
                        ${activeIndex === index ? 'active' : ''} `}>
                  <Link to={data.links}>{data.name}</Link>
                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map(submenu => (
                        <li
                          key={submenu.id}
                          className={
                            pathname === submenu.links ? 'menu-item current-item' : 'menu-item'
                          }>
                          <Link to={submenu.links}>{submenu.sub}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="nav-btn-div">
            {ConnectedAccount ? (
              <div className="sc-btn-top mg-r-12" id="site-header">
                <Link
                  to="/create-item"
                  className="sc-button header-slider style style-1 note fl-button pri-1 mr-3">
                  {/* <span>{blockChainNetworkValue}</span> */}
                  <span>Create</span>
                </Link>
              </div>
            ) : (
              ''
            )}

            <div className="sc-btn-top mg-r-12" id="site-header">
              {ConnectedAccount ? (
                <OverlayTrigger rootClose placement="bottom" overlay={popoverClickRootClose}>
                  <div className="wallet-connect style-1">
                    <h6>{getshortcode(ConnectedAccount)} </h6>
                    <h6>
                      {userWalletBalance} {netWorkSymbol}
                    </h6>
                    {netWrokChainName ? (
                      <h6 style={{ fontSize: '12px' }}>{netWrokChainName}</h6>
                    ) : (
                      ''
                    )}
                  </div>
                </OverlayTrigger>
              ) : (
                <Link
                  to="/wallet-connect"
                  className="sc-button header-slider style style-1 wallet fl-button pri-1">
                  <span>Connect Wallet</span>
                </Link>
              )}
            </div>
          </div>
          <div className="flat-search-btn flex">
            <div className="admin_active" id="header_admin">
              <div className="header_avatar">
                <div className="price">
                  <span>
                    2.45 <strong>ETH</strong>{' '}
                  </span>
                </div>
                <img className="avatar" src={avt} alt="avatar" />
                <div className="avatar_popup mt-20">
                  <div className="d-flex align-items-center copy-text justify-content-between">
                    <span> 13b9ebda035r178... </span>
                    <Link to="/" className="ml-2">
                      <i className="fal fa-copy"></i>
                    </Link>
                  </div>
                  <div className="d-flex align-items-center mt-10">
                    <img className="coin" src={coin} alt="/" />
                    <div className="info ml-10">
                      <p className="text-sm font-book text-gray-400">Balance</p>
                      <p className="w-full text-sm font-bold text-green-500">16.58 ETH</p>
                    </div>
                  </div>
                  <div className="hr"></div>
                  <div className="links mt-20">
                    <Link to="#">
                      <i className="fab fa-accusoft"></i> <span> My items</span>
                    </Link>
                    <a className="mt-10" href="/edit-profile">
                      <i className="fas fa-pencil-alt"></i> <span> Edit Profile</span>
                    </a>
                    <a className="mt-10" href="/login" id="logout">
                      <i className="fal fa-sign-out"></i> <span> Logout</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
            <span></span>
          </div>
          <DarkMode />
        </div>
      </div>
    </header>
  );
};

export default HeaderStyle2;
