import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabPanel } from 'react-tabs';
import { _topSellers } from '../../../services/mainApi';
import img1 from '../../../assets/images/avatar/previewThumb.png';
import shortenAccount from '../../../utils/shortenAccount';

import 'react-tabs/style/react-tabs.css';

const TopSeller = () => {
  // const [dataTopSellerTab] = useState([
  //   {
  //     title: '24 Hours',
  //   },
  //   {
  //     title: 'Week',
  //   },
  //   {
  //     title: 'Month',
  //   },
  // ]);

  const [data, setdata] = useState([]);
  // const { ConnectedAccount, netWrokChainName } = useContext(ConnectWalletContext);
  // let navigate = useNavigate();
  const [loadingData, setLoadingData] = React.useState(false);

  React.useEffect(() => {
    setLoadingData(true);
    const promise = _topSellers();
    promise.then(res => {
      if (!res.error) {
        setdata(res?.data?.data);
        setLoadingData(false);
      } else {
        setLoadingData(false);
        setdata([]);
      }
    });
  }, []);
  return (
    <div>
      <section className="tf-section top-seller home5 bg-style">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="heading-live-auctions">
                <h2 className="tf-title pb-16">Top Seller</h2>
                {/* <a href="/explore-03" className="exp style2 see-all">
                  SEE ALL
                </a> */}
              </div>
              {loadingData ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}>
                  <i
                    className="fa fa-spinner fa-spin fa-3x mb-3"
                    aria-hidden="true"
                    id="circle1"></i>
                  <h2>Loading...</h2>
                </div>
              ) : (
                <>
                  {data?.length === 0 ? (
                    <h1
                      className="row-margin"
                      style={{
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '2rem',
                      }}>
                      No Data Found...
                    </h1>
                  ) : (
                    <>
                      <div className="flat-tabs seller-tab">
                        <Tabs>
                          {/* <TabList>
                    {dataTopSellerTab.map((item, index) => (
                      <Tab key={index}>{item.title}</Tab>
                    ))}
                  </TabList> */}

                          <div className="content-tab mg-t-24">
                            {data?.map(item => (
                              <TabPanel key={item._id}>
                                {/* {item.dataTopSellerContent.map((item, index) => ( */}
                                <div key={item._id} className="col-xl-3 col-lg-4 col-md-6">
                                  <div className="box-item">
                                    <div className="sc-author-box style-3">
                                      <div className="author-style2 flex">
                                        <div className="author-avatar">
                                          <Link to="#">
                                            <img
                                              src={item?.userImage || img1}
                                              alt="Axies"
                                              className="avatar"
                                            />
                                          </Link>
                                          <div className="badge">
                                            <i className="ripple"></i>
                                          </div>
                                        </div>
                                        <div className="author-infor">
                                          <h5>
                                            <Link to={`/user/${item.userId}`}>
                                              {shortenAccount(item.walletAddress)}
                                            </Link>
                                          </h5>
                                          {/* <div className="tag">{item.mail}</div> */}
                                          <span className="price">{item.noOfNftAmount}</span>
                                        </div>
                                      </div>
                                      {/* <div className="action">
                                    <div className="number">#{item.top}</div>
                                    <div className="btn-follow">
                                      <Link to="/login">Follow</Link>
                                    </div>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                                {/* ))} */}
                              </TabPanel>
                            ))}
                          </div>
                        </Tabs>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopSeller;
