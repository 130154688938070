import { commonFormData } from '../utils/core';
import { returnResult } from '../utils/helpers';

export const _fileupload = file => {
  let request = {
    reqmethod: 'POST',
    url: '/main/addFile',
    param: {},
    reqdata: file,
  };
  return new Promise((resolve, reject) => {
    commonFormData(request)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const _categoryList = payload => {
  let request = {
    reqmethod: 'GET',
    url: '/main/categoryList',
    param: payload,
    reqdata: payload,
  };
  return returnResult(request);
};
export const _createNft = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/createNft',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};
export const _updateNft = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/updateNft',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};

export const _bulkupdateNft = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/bulkupdateNft',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};

//get the list of mynfts
export const _myNft = pagenum => {
  let request = {
    reqmethod: 'GET',
    url: '/main/mynft/' + pagenum,
  };
  return returnResult(request);
};

export const _myNftOnSale = pagenum => {
  let request = {
    reqmethod: 'GET',
    url: '/main/nftOnSale/' + pagenum,
  };
  return returnResult(request);
};

export const _exploreNft = (id, pagenumber) => {
  let request = {
    reqmethod: 'GET',
    url: '/main/exploreNft/' + id + '/' + pagenumber,
  };
  return returnResult(request);
};
export const _exploreAllNft = (id, payload) => {
  let request = {
    reqmethod: 'GET',
    url: '/main/exploreNft/' + id,
    param: payload,
  };
  return returnResult(request);
};
export const _exploreCollectionNft = (id, pagenumber) => {
  let request = {
    reqmethod: 'GET',
    url: `/main/exploreCollectionNft/${id}/${pagenumber}`,
  };
  return returnResult(request);
};
//create collection
export const _createCollection = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/createCollection',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};
//list collection
export const _listCollection = pagenum => {
  let request = {
    reqmethod: 'GET',
    url: '/main/collectionList/' + pagenum,
    param: {},
    reqdata: {},
  };
  return returnResult(request);
};
export const _myCollectionList = pagenum => {
  let request = {
    reqmethod: 'GET',
    url: '/main/myCollectionList/' + pagenum,
    param: {},
    reqdata: {},
  };
  return returnResult(request);
};
export const _searchNFT = payload => {
  let request = {
    reqmethod: 'GET',
    url: '/main/search',
    param: payload,
    reqdata: {},
  };
  return returnResult(request);
};
export const _topSellers = payload => {
  let request = {
    reqmethod: 'GET',
    url: '/main/topSellers',
    param: payload,
    reqdata: {},
  };
  return returnResult(request);
};
export const _todaysPicks = pagenumber => {
  let request = {
    reqmethod: 'GET',
    url: `/main/todayPicks/${pagenumber}`,
  };
  return returnResult(request);
};
export const _popularCollectionList = () => {
  let request = {
    reqmethod: 'GET',
    url: `/main/popularCollectionList`,
  };
  return returnResult(request);
};

export const _topSellersDetails = (userId, pageNumber) => {
  let request = {
    reqmethod: 'GET',
    url: `/main/topSellersDetails/${userId}/${pageNumber}`,
  };
  return returnResult(request);
};

export const _updateProfileDetails = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/users/updateUserProfile',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};

export const _getProfileDetails = () => {
  let request = {
    reqmethod: 'GET',
    url: `/users/getUserProfile`,
  };
  return returnResult(request);
};

export const _raiseBid = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/raiseBid',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};

export const _updateBid = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/updateBid',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};

export const _getNftDetails = nftId => {
  let request = {
    reqmethod: 'GET',
    url: `/main/nftDetails/${nftId}`,
  };
  return returnResult(request);
};

export const _addToFav = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/addFavToNft',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};

export const _removeFromFav = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/main/removeFavToNft',
    param: {},
    reqdata: payload,
  };
  return returnResult(request);
};

export const _getMyFavNft = pageNo => {
  let request = {
    reqmethod: 'GET',
    url: `/main/myFavNft/${pageNo}`,
  };
  return returnResult(request);
};
