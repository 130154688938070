import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { _popularCollectionList } from '../../../services/mainApi';
import shortenAccount from '../../../utils/shortenAccount';

const PopularCollection = () => {
  const [datas, setDatas] = useState([]);
  const [loadingData, setLoadingData] = React.useState(false);

  React.useEffect(() => {
    setLoadingData(true);
    _popularCollectionList().then(res => {
      if (res.data.error == false) {
        setLoadingData(false);
        setDatas(res?.data?.data);
      } else {
        setLoadingData(false);
        setDatas([]);
      }
    });
  }, []);

  return (
    <section className="tf-section live-auctions style4 home4 live-auctions-style7">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-box-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title pb-40 text-left">Popular Collection</h2>
              <Link to={`/browse`} className="exp style2 mg-t-23">
                EXPLORE MORE
              </Link>
            </div>
          </div>
          {loadingData ? (
            <div
              style={{
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
              <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
              <h2>Loading...</h2>
            </div>
          ) : (
            <>
              {datas?.length === 0 ? (
                <h1
                  className="row-margin"
                  style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                  No Data Found...
                </h1>
              ) : (
                <>
                  {datas?.map((item, index) => (
                    <div key={index} className="fl-collection fl-item3 col-xl-4 col-md-6">
                      <div className="sc-card-collection style-2 sc-card-style7">
                        <Link to={`/explore-01/${item.collectionId}`}>
                          <div className="card-media-h7">
                            <img
                              src={item.collectionImage}
                              alt="Axies"
                              // style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                            />
                          </div>
                        </Link>
                        <div className="card-media-h7 style2">
                          {item?.NftItemArray.slice(0, 3).map((e, i) => {
                            {
                              /* <img src={item.imgBottom1} alt="Axies" />; */
                            }
                            return <img key={i} src={e.fileUrl} alt="Axies" />;
                          })}
                          {/* <img src={item.imgBottom2} alt="Axies" /> */}
                          {/* <img src={item.imgBottom3} alt="Axies" /> */}
                        </div>
                        <div className="card-bottom">
                          <div className="author">
                            <div className="content">
                              <h5>
                                <Link to={`/explore-01/${item.collectionId}`}>{item.name}</Link>
                              </h5>
                              <div className="infor">
                                <span>Created by</span>
                                <span className="name">
                                  <Link to={`/explore-01/${item.collectionId}`}>
                                    {shortenAccount(item.address)}
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <Link to="/login" className="wishlist-button public heart mg-t-6">
                    <span className="number-like">{item.wishlist}</span>
                  </Link> */}
                        </div>
                        {/* <Link to={`/explore-01/${item.collectionId}`}>
                          <div className="sc-author-box style-2">
                            <div className="author-avatar">
                              <img src={item.collectionImage} alt="Axies" className="avatar" />
                              <div className="badge">
                                <i className="ripple"></i>
                              </div>
                            </div>
                          </div>
                        </Link> */}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
          {/* {visible < data.length && (
            <div className="col-md-12 wrap-inner load-more text-center mt-10">
              <Link
                to="#"
                id="load-more"
                className="sc-button loadmore fl-button pri-3"
                onClick={showMoreItems}>
                <span>Load More</span>
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </section>
  );
};

export default PopularCollection;
