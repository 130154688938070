import { common } from '../utils/core';

export const _createwallet = payload => {
  let request = {
    reqmethod: 'POST',
    url: '/users/create',
    reqdata: payload,
  };
  return new Promise((resolve, reject) => {
    common(request)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};
