import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import MyNft_items from '../components/layouts/Users/MyNft_items';
// import { _myNft } from '../services/mainApi';
// import imga1 from '../assets/images/avatar/avt-1.jpg';
// import imgCollection1 from '../assets/images/avatar/avt-18.jpg';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import { copytoclipboard } from '../utils/helpers';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import previewThumb from '../assets/images/avatar/previewThumb.png';
import { useParams } from 'react-router-dom';
import { _topSellersDetails } from '../services/mainApi';
import shortenAccount from '../utils/shortenAccount';

const User = () => {
  const [explorelist, setexplorelist] = useState([]);
  const [totalItems, settotalItems] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  // const [variant, setvariant] = useState('onsale');
  const [progress, setprogress] = useState(true);
  const { ConnectedAccount, userProfileData } = React.useContext(ConnectWalletContext);
  const [copysttaustext, setcopysttaustext] = useState('Copy');
  const { id } = useParams();
  console.log(id);
  const increaseexplorepagenumber = () => {
    setcurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setexplorelist([]);
    settotalItems(0);
    setprogress(true);
    _topSellersDetails(id, 1)
      .then(res => {
        console.log(res.data);
        if (res?.data?.data?.nftList.length > 0) {
          setexplorelist(res?.data?.data?.nftList);
        }
        setprogress(false);
      })
      .catch(err => {
        console.log(err.data);
        setprogress(false);
      });
  }, [currentPage, ConnectedAccount]);
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center"></h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="center m-3">
        <h3 className="heading text-center" id="myInput">
          {/* {ConnectedAccount} */}
        </h3>
        <br />
        <div className="col-md-12">
          <div className="d_profile">
            <div className="profile_avatar">
              <div className="d_profile_img">
                <img
                  src={userProfileData?.userImage ? userProfileData?.userImage : previewThumb}
                  alt=""
                  className="mb-3"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          onMouseOver={() => setcopysttaustext('Copy')}
          className="text-center"
          style={{ cursor: 'pointer' }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">{copysttaustext}</Tooltip>}>
            <h5
              onMouseOver={() => setcopysttaustext('Copy')}
              onClick={() => {
                copytoclipboard(`${explorelist[0]?.ownerAddress}`);
                setcopysttaustext('Copied');
              }}>
              {shortenAccount(explorelist[0]?.ownerAddress)}
            </h5>
          </OverlayTrigger>
        </div>
      </div>

      <MyNft_items
        data={explorelist}
        increasepagenumber={() => increaseexplorepagenumber()}
        exploretotalItems={totalItems}
        progress={progress}
      />
      <Footer />
    </div>
  );
};

export default User;
