import loadWeb3 from '../utils/loadWeb3';
import wETHABI from '../ABI/WETHABI.json';
import PyraNftMarket721 from '../ABI/PyraNftMarket.json';
import config from '../lib/config';

//Calling smart contract for placing the buying order
const pERCent = async (value1, value2) => {
  //This division (10 **20) followed in the smart contract
  let result = (value1 * value2) / 10 ** 20;
  return result;
};

const biddingOrder = async (tokenId, connectedAccount, collectionAddress, amount, biddingToken) => {
  console.log('PK Bidding order process... ', collectionAddress);
  console.log('Pk Bidding order token', biddingToken);
  let web3 = await loadWeb3();
  //setBuyingTokenStatus('processing')
  let NftContractAddress = collectionAddress ? collectionAddress : config.NFTContractAddress;
  //get the correct bidding token based on  the NFT chain
  if (biddingToken == 'Avalanche') {
    config.wrapperContractAddress = config.wAVAXContractAddress;
  }
  if (biddingToken == 'Ethereum') {
    config.wrapperContractAddress = config.wETHContractAddress;
  }
  if (biddingToken == 'Polygon') {
    config.wrapperContractAddress = config.wMATICContractAddress;
  }
  if (biddingToken == 'Binance') {
    config.wrapperContractAddress = config.wBNBContracctAddress;
  }
  console.log('PK Bidding Wrapper contract Address...', config.wrapperContractAddress);

  //get the nft token details
  let nftContract;
  try {
    nftContract = new web3.eth.Contract(PyraNftMarket721, NftContractAddress);
  } catch (err) {
    console.log();
  }

  //get NFT service Fee
  let NftServiceFee = await nftContract.methods.getServiceFee().call();
  //get royality Fee
  let NftTokenRoyValue = await nftContract.methods._royal(tokenId).call();

  console.log('Bidding order process NftServiceFee. ', NftServiceFee);
  console.log('Bidding order process NftTokenRoyValue. ', NftTokenRoyValue);
  console.log('Bidding order process wethContractAddress. ', config.wETHContractAddress);
  //get the wETH balance of the given account
  let wETHBalance = 0;
  try {
    const wETHContract = new web3.eth.Contract(wETHABI, config.wrapperContractAddress);
    wETHBalance = await wETHContract.methods.balanceOf(connectedAccount).call();
    console.log('Bidding order process wETHBalance. ', wETHBalance);
  } catch (err) {
    console.log('Bidding error message ->', err.message);
  }

  let bidValue = 0;
  //derive the bidPyableValue from placed bid amount + serviceFee
  if (amount > 0) {
    const bidValueCalc = await pERCent(amount, NftServiceFee);
    console.log('Inside the Bidding process bidValueCalc', bidValueCalc);
    bidValue = parseFloat(parseFloat(bidValueCalc) + parseFloat(amount));
    console.log('Inside the Bidding process Bid value amount', bidValue);
  }

  //setNftTokenOwner(NftTokenOwnerOf)
  //get token selling price
  //   let NftSaleOrderPrice = await nftContract.methods.order_place(NftTokenOwnerOf, tokenId).call();
  //   //setNftTokenSaleOrderPrice(NftSaleOrderPrice.price / 10 ** 18)
  //   //proceed only when selling order price is placed
  //   if (NftSaleOrderPrice.price > 0) {
  //     //get serivce fee
  //     let NftServiceFee = await nftContract.methods.getServiceFee().call();
  //     let NftTokenServiceFeeCalc = await pERCent(NftSaleOrderPrice.price, NftServiceFee);
  //     let NftTokenValue = parseFloat(
  //       parseFloat(NftTokenServiceFeeCalc) + parseFloat(NftSaleOrderPrice.price),
  //     );
  //     //console.log('Prabu NFT token final value to pay', NftTokenValue)
  //     //showing in ethers
  //     //get royality
  //     let NftTokenRoyValue = await nftContract.methods._royal(tokenId).call();
  //     //calc the royality value
  //     let NftTokenRoy = await pERCent(NftSaleOrderPrice.price, NftTokenRoyValue);
  //     //console.log('Prabu NFT royality token value', NftTokenRoy / 10 ** 18)
  //     //net amount the seller will receive
  //     let NftFee = parseFloat(NftTokenServiceFeeCalc + NftTokenServiceFeeCalc);
  //     //console.log('Prabu NFT Fee', NftFee / 10 ** 18)
  //     let NftNetAmount = parseFloat(NftTokenRoy + NftTokenServiceFeeCalc);
  //     //console.log('Prabu nft chargeble amount (serviceFee+Roy)', NftNetAmount)
  //     let sellerReceiveNetAmt = parseFloat(NftSaleOrderPrice.price - NftNetAmount);
  //     console.log('admin fee', NftFee / 10 ** 18);
  //     console.log('royality', NftTokenRoy / 10 ** 18);
  //     //console.log('Prabu nft seller received amount', sellerReceiveNetAmt)
  //     console.log('seller received Net-amount in wei', sellerReceiveNetAmt / 10 ** 18);
  return {
    wETHBalance: wETHBalance,
    NftServiceFee: NftServiceFee,
    NftTokenRoyValue: NftTokenRoyValue,
    bidValue: bidValue,
  };
  //}
};

export default biddingOrder;
