import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getshortcode } from '../../../utils/helpers';
import commonFunction from '../../../utils/common';

const MyFavouriteNft = props => {
  const data = props.data;
  return (
    <Fragment>
      <section className="tf-section sc-explore-1">
        {props.progress ? (
          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
            <h2>Loading...</h2>
          </div>
        ) : (
          <div className="themesflat-container">
            <div className="row">
              {data?.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className={`sc-card-product ${item.feature ? 'comingsoon' : ''} `}>
                      <div className="card-media cardimg_size">
                        <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                          <img src={item.fileUrl} alt="axies" />
                        </Link>
                        <div
                          onClick={() => props.addOrRemoveFav(item)}
                          className="wishlist-button icon-fl-heart-filled">
                          <span className="number-like">{item.wishlist || item.favCount}</span>
                        </div>
                        <div className="coming-soon">{item.feature}</div>
                      </div>
                      <div className="card-title">
                        <h5 className="style2">
                          <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                            {item.title}
                          </Link>
                        </h5>
                        <div className="tags">{item.CategoryData.name}</div>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="info">
                            <span>Owned By</span>
                            <h6>{getshortcode(item.currentAddress)}</h6>
                          </div>
                        </div>
                        <div className="price">
                          <span>Current price</span>
                          <h5>
                            {item.price} {commonFunction.CurrencySymbol}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                  <h2>No data found...</h2>
                </div>
              )}
              {/* {data.length > 0 &&
                props.exploretotalItems > 0 &&
                props.exploretotalItems > data.length && (
                  <div className="col-md-12 wrap-inner load-more text-center">
                    <Link
                      to="#"
                      id="load-more"
                      className="sc-button loadmore fl-button pri-3"
                      onClick={() => props.increasefavpagenumber()}>
                      <span>Load More</span>
                    </Link>
                  </div>
                )} */}
              {data?.length === 10 && (
                <div className="col-md-12 wrap-inner load-more text-center">
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={() => props.increasepagenumber()}>
                    {props.loadMore ? <span>Loading..</span> : <span>Load More</span>}
                    {props.loadMore && (
                      <div className="wishlist-button-loading-loadmore">
                        <i
                          className="fa fa-spinner fa-spin fa-3x mb-3"
                          aria-hidden="true"
                          id="circle1"></i>
                      </div>
                    )}
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default MyFavouriteNft;
