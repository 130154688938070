import React from 'react';
//import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
//import logodark from '../../assets/images/logo/logo_dark.png';
//import logofooter from '../../assets/images/logo/logo2.png';
import logodark from '../../assets/images/logo/pyranft_logo.png';
//import logolight from '../../assets/images/logo/pyranft_logo.png';
const Footer = () => {
  // const accountList = [
  //   {
  //     title: 'Authors',
  //     link: '/authors-01',
  //   },
  //   {
  //     title: 'Collection',
  //     link: '/wallet-connect',
  //   },
  //   {
  //     title: 'Author Profile',
  //     link: '/edit-profile',
  //   },
  //   {
  //     title: 'Create Item',
  //     link: '/create-item',
  //   },
  // ];
  // const resourcesList = [
  //   {
  //     title: 'Help & Support',
  //     link: '/help-center',
  //   },
  //   {
  //     title: 'Live Auctions',
  //     link: '/live-auctions',
  //   },
  //   {
  //     title: 'Item Details',
  //     link: '/buy',
  //   },
  //   {
  //     title: 'Activity',
  //     link: '/activity-01',
  //   },
  // ];
  // const companyList = [
  //   {
  //     title: 'Explore',
  //     link: '/explore-01',
  //   },
  //   {
  //     title: 'Contact Us',
  //     link: '/contact-01',
  //   },
  //   {
  //     title: 'Our Blog',
  //     link: '/blog',
  //   },
  //   {
  //     title: 'FAQ',
  //     link: '/faq',
  //   },
  // ];
  // const socialList = [
  //   {
  //     icon: 'fab fa-twitter',
  //     link: '#',
  //   },
  //   {
  //     icon: 'fab fa-facebook',
  //     link: '#',
  //   },
  //   {
  //     icon: 'fab fa-telegram-plane',
  //     link: '#',
  //   },
  //   {
  //     icon: 'fab fa-youtube',
  //     link: '#',
  //   },
  //   {
  //     icon: 'icon-fl-tik-tok-2',
  //     link: '#',
  //   },
  //   {
  //     icon: 'icon-fl-vt',
  //     link: '#',
  //   },
  // ];

  // const [isVisible, setIsVisible] = useState(false);

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  // };

  // useEffect(() => {
  //   const toggleVisibility = () => {
  //     if (window.pageYOffset > 500) {
  //       setIsVisible(true);
  //     } else {
  //       setIsVisible(false);
  //     }
  //   };

  //   window.addEventListener('scroll', toggleVisibility);

  //   return () => window.removeEventListener('scroll', toggleVisibility);
  // }, []);

  return (
    <>
      <footer id="footer" className="footer-light-style clearfix bg-style">
        {/* <div className="col-lg-3 col-md-12 col-12"> */}

        <div className="footer-align">
          <Link to="/">
            <img
              className="logo-dark-footer mb-3"
              id="logo_footer"
              src={logodark}
              alt="nft-gaming"
            />
            <img
              className="logo-light-footer mb-3"
              id="logo_footer"
              src={logodark}
              alt="nft-gaming"
              // style={{ height: '50px', marginLeft: '650px' }}
            />
          </Link>
          <div className=" text-center font-italic">
            <p>PyraNFT is a NFT Marketplace Framework developed for companies to</p>
            <p>succeed in the web3 space.</p>
            <p className="text-white text-xs">@All rights reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
