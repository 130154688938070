import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TodayPicks from '../components/layouts/explore-01/TodayPicks';
// import TodayPicks from '../components/layouts/explore-01/TodayPicksDummy';
import {
  _exploreAllNft,
  _exploreCollectionNft,
  _searchNFT,
  _addToFav,
  _removeFromFav,
  _exploreNft,
} from '../services/mainApi';
import imga1 from '../assets/images/avatar/avt-1.jpg';
import imgCollection1 from '../assets/images/avatar/avt-18.jpg';
import { srt } from '../utils/helpers';
import { useParams } from 'react-router-dom';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import previewuser from '../assets/images/avatar/previewThumb.png';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const Explore01 = () => {
  const { ConnectedAccount, setAllData } = useContext(ConnectWalletContext);
  const location = useLocation();
  const { collectionId } = useParams();

  // const [selectedCategoryid, setselectedCategoryid] = useState('');
  const [explorelist, setexplorelist] = useState([]);
  const [explorelistfilter, setexplorelistfilter] = useState([]);
  const [nftlist, setnftlist] = useState([]);
  const [totalItems, settotalItems] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [searchdata, setsearchdata] = useState('');
  const [noData, setNoData] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [currentCategoryPage, setcurrentCategoryPage] = useState(1);
  const [filterPageCheck, setFilterPageCheck] = useState(false);
  const [categoryIDSet, setcategoryIDSet] = useState('');
  const [coinSet, setCoinIDSet] = useState('');
  // const handlecategoryselect = id => {
  //   setselectedCategoryid(id);
  // };
  const increaseexplorepagenumber = () => {
    setcurrentPage(currentPage + 1);
    setLoadMore(true);
  };
  const changePageNumber = number => {
    setcurrentPage(number);
    setPageLoad(true);
  };
  const changecategoryPageNumber = number => {
    setcurrentCategoryPage(number);
    setPageLoad(true);
  };
  const handlesearchinput = value => {
    setsearchdata(value);
    onsearchnft(value);
  };
  const StoreAllData = React.useCallback(res => {
    setNoData(false);
    setFilterLoad(false);
    let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
    let resdata = [];
    temp?.length > 0 &&
      temp.map(item => {
        return resdata.push({
          categoryId: item.categoryId ? item.categoryId : '',
          description: item.description,
          id: item._id,
          img: item.fileUrl,
          imgAuthor: previewuser,
          imgCollection: imgCollection1,
          metadataUrl: item?.metadataUrl ? item?.metadataUrl : '',
          nameAuthor: item.userdetails.username,
          nameCollection: '',
          noOfCopies: item.noOfCopies,
          properties: item?.properties ? item.properties : [],
          price: item.price,
          priceChange: '',
          putOnMarketplace: item.putOnMarketplace,
          tags: item.CategoryData.name,
          title: item.title,
          tokenId: item?.tokenId ? item.tokenId : '',
          walletAddress: item.currentAddress,
          wishlist: item.favCount,
          favStatus: item.favStatus,
          ownerAddress: item.ownerAddress,
          currentAddress: item.currentAddress,
          minBidValue: item.minBidValue,
          isBid: item.isBid,
          collectionAddress: item?.CollectionData[0]?.address,
          collectionSymbol: item?.CollectionData[0]?.symbol,
          collectionDescription: item?.CollectionData[0]?.name,
          multiToken: item.multiToken,
          userId: item?.userdetails?.userId,
          userImage: item?.userdetails?.userImage,
          unlockContentFlag: item?.unlockContentFlag,
          unlockContent: item?.unlockContent,
          blockChainName: item?.blockChainName,
        });
      });
    let data = [...explorelist, ...resdata];
    let uniqueData = [...data.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    console.log('locationnnn', res?.data?.data?.totalItems, 'stored');
    // setexplorelist(uniqueData);
    console.log(res?.data?.data?.nftList, 'data7654');
    if (res?.data?.data?.nftList) {
      setNoData(false);
      setFilterLoad(false);
      setPageLoad(false);
      settotalItems(res?.data?.data?.totalItems);
      setexplorelistfilter(res?.data?.data?.nftList);
    } else if (res?.data?.data?.nftList?.length >= 0) {
      setFilterLoad(true);
      setPageLoad(true);
      setNoData(false);
    } else {
      setFilterLoad(false);
      setPageLoad(false);
      setexplorelistfilter([]);
      setNoData(true);
    }
    setPageLoad(false);
    settotalItems(res?.data?.data?.totalItems);
    setexplorelist(resdata);
    setAllData(uniqueData);
    setnftlist(uniqueData);
    setPageSize(res?.data?.data?.pageSize);
  });
  const onsearchnft = req => {
    setFilterLoad(true);
    if (req?.length >= 1) {
      const promise = _searchNFT({ text: req });
      promise
        .then(res => {
          if (!res.error) {
            let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
            let resdata = [];
            temp?.length > 0 &&
              temp.map(item => {
                return resdata.push({
                  categoryId: item?.categoryId ? item.categoryId : '',
                  description: item.description,
                  id: item._id,
                  img: item.fileUrl,
                  imgAuthor: imga1,
                  imgCollection: imgCollection1,
                  metadataUrl: item?.metadataUrl ? item?.metadataUrl : '',
                  nameAuthor: item.userdetails.username,
                  nameCollection: '',
                  noOfCopies: item.noOfCopies,
                  properties: item?.properties ? item.properties : [],
                  price: item.price,
                  priceChange: '',
                  putOnMarketplace: item.putOnMarketplace,
                  tags: item.CategoryData.name,
                  title: item.title,
                  tokenId: item?.tokenId ? item.tokenId : '',
                  walletAddress: item.currentAddress,
                  currentAddress: item.currentAddress,
                  wishlist: item.favCount,
                  // favStatus: item.favStatus,
                  ownerAddress: item.ownerAddress,
                  minBidValue: item.minBidValue,
                  isBid: item.isBid,
                  favStatus: item.favStatus,
                  multiToken: item.multiToken,
                  blockChainName: item?.blockChainName,
                });
              });
            setsearchdata(req);
            if (res?.data?.data?.nftList) {
              // setNoData(false);
              setFilterLoad(false);
              settotalItems(res?.data?.data?.totalItems);
              setexplorelistfilter(res?.data?.data?.nftList);
            } else {
              setFilterLoad(false);
              setexplorelistfilter([]);
              setNoData(true);
            }
            settotalItems(res?.data?.data?.totalItems);
          }
        })
        .catch(e => {
          console.log('errorLogged', e);
        });
    } else if (req?.length == 0) {
      const _exploreAllNftpromise = _exploreAllNft(currentPage);
      _exploreAllNftpromise.then(res => {
        if (!res.error) {
          StoreAllData(res);
          console.log('empty');
        }
        if (res.data.error) {
          console.log('88888', res);
          setNoData(true);
        }
      });
    }
  };

  React.useEffect(() => {
    console.log(categoryIDSet, 'looogg', coinSet);

    if (categoryIDSet != '') {
      filterCategory(categoryIDSet);
    } else if (coinSet != '') {
      filterBlockChain(coinSet);
    }
  }, [currentCategoryPage]);
  const filterBlockChain = chainNameHere => {
    setFilterLoad(true);
    setFilterPageCheck(true);
    console.log('loooooooooolll', currentCategoryPage);
    if (chainNameHere === 'All') {
      setcurrentPage(1);
      ExploreAllCoinsData();
    } else {
      setFilterPageCheck(true);
      const _exploreAllNftpromise = _exploreAllNft(currentCategoryPage, {
        filter: { blockChainName: chainNameHere },
      });
      _exploreAllNftpromise.then(res => {
        if (!res?.data?.error) {
          StoreAllData(res, true);
        } else {
          setexplorelist([]);
          setFilterLoad(false);
          setNoData(true);
        }
      });
    }
  };
  const filterCategory = item => {
    console.log(item, 'looogg');
    if (item === 1234) {
      setcurrentPage(1);
      ExploreAllCoinsData();
    } else {
      setFilterPageCheck(true);
      // setcurrentCategoryPage(1);
      const _exploreNftpromise = _exploreNft(item, currentCategoryPage);
      _exploreNftpromise.then(res => {
        if (!res?.data?.error) {
          StoreAllData(res);
        } else {
          setexplorelist([]);
          setFilterLoad(false);
          setNoData(true);
        }
      });
    }
    setFilterLoad(true);
  };
  const handle_filter = (
    selectedcategoryId,
    selectedquantityId,
    selectedstatusId,
    selectedsortId,
    coinName,
  ) => {
    let temp = [...nftlist];
    console.log(...nftlist, 'coinnnnn');
    if (coinName) {
      setcurrentCategoryPage(1);
      filterBlockChain(coinName);
      setCoinIDSet(coinName);
      setcategoryIDSet('');
    }
    if (selectedcategoryId) {
      setcurrentCategoryPage(1);
      setcategoryIDSet(selectedcategoryId);
      filterCategory(selectedcategoryId);
      setCoinIDSet('');
    }
    temp = temp
      // .filter(item => {
      //   if (selectedcategoryId && item.categoryId === selectedcategoryId) return item;
      //   else if (selectedcategoryId === 0) return item;
      //   console.log(item, 'looo');
      // })
      .filter(item => {
        if (selectedquantityId === 0) return item;
        else if (selectedquantityId === 1) {
          if (parseInt(item.noOfCopies) === 1) return item;
        } else if (selectedquantityId === 2) {
          if (parseInt(item.noOfCopies) > 1) return item;
        }
      })
      .filter(item => {
        if (selectedstatusId === 1 && ConnectedAccount !== item.walletAddress && !item.isBid)
          return item;
        else if (selectedstatusId === 2 && item.isBid === true) return item;
        else if (selectedstatusId === 0) return item;
      })
      .sort(srt({ key: 'price', string: true }, selectedsortId === 1 ? false : true));
    setexplorelist([...temp]);
    setexplorelistfilter([...temp]);
  };

  const ExploreAllCoinsData = () => {
    setFilterPageCheck(false);

    const _exploreAllNftpromise = _exploreAllNft(currentPage);
    _exploreAllNftpromise.then(res => {
      setFilterLoad(false);
      setLoadMore(false);
      if (!res?.data?.error) {
        settotalItems(res?.data?.data?.totalItems);
        StoreAllData(res);
      }
      if (res.data.error) {
        setexplorelist([]);
        setNoData(true);
        setPageLoad(false);
        console.log(res, 'locationnnii');
      }
    });
  };
  console.log(collectionId, 'collectionId');
  useEffect(() => {
    if (collectionId) {
      const _exploreNftpromise = _exploreCollectionNft(collectionId, currentPage);
      _exploreNftpromise.then(res => {
        if (!res.error) {
          StoreAllData(res);
        }
      });
    } else {
      ExploreAllCoinsData();
    }
  }, [collectionId, currentPage]);
  const [load, setLoad] = React.useState('initial');
  const addOrRemoveFav = item => {
    setLoadMore(true);
    if (item?.favStatus === true && (load === 'initial' || load === 'completed')) {
      removeFav(item?.id || item?._id);
    } else {
      if (load === 'initial' || load === 'completed') {
        addToFav(item?.id || item?._id);
      }
    }
  };

  const addToFav = id => {
    setLoad('pending');
    const payload = {
      nftId: id,
    };
    _addToFav(payload)
      .then(res => {
        setLoadMore(true);
        if (!res?.data?.error) {
          //     // let favStatusTemp = data;
          // var addChange = function change() {
          // // favStatusTemp[index].favStatus = true;
          // favStatusTemp[index].wishlist = favStatusTemp[index].wishlist + 1;
          // };
          ExploreAllCoinsData();
          toast.success('Added to favorites');
          onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  // console.log(addToFav,"favorite")
  const removeFav = id => {
    setLoad('pending');

    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        setLoadMore(true);

        if (!res?.data?.error) {
          ExploreAllCoinsData();
          toast.error('Removed from favorites', '');
          onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err?.message);
  };

  useEffect(() => {
    if (location?.state?.length === 0) {
      console.log('nolength');
      setsearchdata('');
      onsearchnft('');
    } else {
      console.log('length', location?.state);
      setsearchdata('');
      onsearchnft(location?.state);
    }
  }, [location?.state]);
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Browse items</h1>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Explore 1</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <TodayPicks
        loadmore={
          location?.state === null || searchdata?.length === 0 ? 'all data' : 'filter data '
        }
        data={searchdata === null || searchdata?.length === 0 ? explorelist : explorelistfilter}
        // categoryselect={id => handlecategoryselect(id)}
        explorelistrange={StoreAllData}
        increasepagenumber={() => increaseexplorepagenumber()}
        changePageNumber={value => changePageNumber(value)}
        exploretotalItems={totalItems}
        onsearchnft={value => onsearchnft(value)}
        handlesearchinput={value => handlesearchinput(value)}
        filter={(
          selectedcategoryId,
          selectedquantityId,
          selectedstatusId,
          selectedsortId,
          coinName,
        ) =>
          handle_filter(
            selectedcategoryId,
            selectedquantityId,
            selectedstatusId,
            selectedsortId,
            coinName,
          )
        }
        globalSearch={location?.state}
        addOrRemoveFav={addOrRemoveFav}
        loadMore={loadMore}
        filterLoad={filterLoad}
        noData={noData}
        setNoData={setNoData}
        pageSize={pageSize}
        pageLoad={pageLoad}
        filterPageCheck={filterPageCheck}
        changecategoryPageNumber={changecategoryPageNumber}
        currentCategoryPage={currentCategoryPage}
        currentPage={currentPage}
        setcurrentCategoryPage={setcurrentCategoryPage}
      />
      <Footer />
    </div>
  );
};

export default Explore01;
