import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TodayPicks from '../components/layouts/explore-01/TodayPicks';
import {
  _exploreAllNft,
  _exploreCollectionNft,
  // _searchNFT,
  _addToFav,
  _removeFromFav,
} from '../services/mainApi';
// import { _exploreCollectionNft } from '../services/mainApi';
import imgCollection1 from '../assets/images/avatar/avt-18.jpg';
import { srt } from '../utils/helpers';
import { useParams } from 'react-router-dom';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import previewuser from '../assets/images/avatar/previewThumb.png';
import { toast } from 'react-toastify';

const ExploreCollections = () => {
  const { ConnectedAccount } = useContext(ConnectWalletContext);
  const { collectionId } = useParams();
  const [selectedCategoryid, setselectedCategoryid] = useState('');
  const [explorelist, setexplorelist] = useState([]);
  const [nftlist, setnftlist] = useState([]);
  const [totalItems, settotalItems] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [progress, setprogress] = useState(true);
  const [searchdata, setsearchdata] = useState('');
  const [loading, setLoading] = useState(false);
  const [noData, setNodata] = useState(false);
  console.log(selectedCategoryid, 'selectedCategoryid');
  const handlecategoryselect = id => {
    setselectedCategoryid(id);
  };
  const increaseexplorepagenumber = () => {
    setcurrentPage(currentPage + 1);
  };
  const handlesearchinput = value => {
    setsearchdata(value);
  };
  const storedata = res => {
    let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
    let resdata = [];
    temp?.length > 0 &&
      temp.map(item => {
        return resdata.push({
          categoryId: item?.categoryId ? item.categoryId : '',
          description: item.description,
          id: item._id,
          img: item.fileUrl,
          imgAuthor: previewuser,
          imgCollection: imgCollection1,
          metadataUrl: item?.metadataUrl ? item.metadataUrl : '',
          nameAuthor: item.userdetails.username,
          nameCollection: '',
          noOfCopies: item.noOfCopies,
          properties: item?.properties ? item.properties : [],
          price: item.price,
          priceChange: '',
          putOnMarketplace: item.putOnMarketplace,
          tags: item.CategoryData.name,
          title: item.title,
          tokenId: item?.tokenId ? item.tokenId : '',
          walletAddress: item.currentAddress,
          currentAddress: item.currentAddress,
          wishlist: item.favCount,
          favStatus: item.favStatus,
          minBidValue: item.minBidValue,
          isBid: item.isBid,
          collectionAddress: item?.CollectionData[0]?.address,
          collectionSymbol: item?.CollectionData[0]?.symbol,
          collectionDescription: item?.CollectionData[0]?.name,
          multiToken: item.multiToken,
          userId: item?.userdetails?.userId,
          userImage: item?.userdetails?.userImage,
          unlockContentFlag: item?.unlockContentFlag,
          unlockContent: item?.unlockContent,
          blockChainName: item?.blockChainName,
        });
      });
    let data = [...explorelist, ...resdata];
    let uniqueData = [...data.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    setexplorelist(uniqueData);
    setnftlist(uniqueData);
    settotalItems(res?.data?.data?.totalItems);
  };
  const onsearchnft = () => {
    const temp = nftlist.filter(obj => {
      return obj.title.toLowerCase().includes(searchdata.toLowerCase());
    });
    setexplorelist([...temp]);
  };

  const handle_filter = (
    selectedcategoryId,
    selectedquantityId,
    selectedstatusId,
    selectedsortId,
  ) => {
    let temp = [...nftlist];
    temp = temp
      .filter(item => {
        if (selectedcategoryId && item.categoryId === selectedcategoryId) return item;
        else if (selectedcategoryId === 0) return item;
      })
      .filter(item => {
        if (selectedquantityId === 0) return item;
        else if (selectedquantityId === 1) {
          if (parseInt(item.noOfCopies) === 1) return item;
        } else if (selectedquantityId === 2) {
          if (parseInt(item.noOfCopies) > 1) return item;
        }
      })
      .filter(item => {
        if (selectedstatusId === 1 && ConnectedAccount !== item.walletAddress) return item;
        else if (
          selectedstatusId === 2 &&
          item.putOnMarketplace === true &&
          ConnectedAccount === item.walletAddress
        )
          return item;
        else if (selectedstatusId === 0) return item;
      })
      .sort(srt({ key: 'price', string: true }, selectedsortId === 1 ? false : true));
    console.log(temp, 'temp');
    setexplorelist([...temp]);
  };

  const [load, setLoad] = React.useState('initial');
  const addOrRemoveFav = item => {
    console.log('adre', item);
    if (item?.favStatus === true && (load === 'initial' || load === 'completed')) {
      removeFav(item?.id);
    } else {
      if (load === 'initial' || load === 'completed') {
        addToFav(item?.id);
      }
    }
  };

  const addToFav = id => {
    setLoad('pending');
    const payload = {
      nftId: id,
    };
    _addToFav(payload)
      .then(res => {
        console.log(res, 'favvvvv');
        if (!res?.data?.error) {
          //     // let favStatusTemp = data;
          // var addChange = function change() {
          // // favStatusTemp[index].favStatus = true;
          // favStatusTemp[index].wishlist = favStatusTemp[index].wishlist + 1;
          // };
          ExploreAllCoinsData();
          toast.success('Added to favorites');
          onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  // console.log(addToFav,"favorite")
  const removeFav = id => {
    setLoad('pending');

    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        console.log(res, 'remmmove');
        if (!res?.data?.error) {
          ExploreAllCoinsData();
          toast.error('Removed from favorites', '');
          onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err?.message);
  };
  // console.log(removeFav,"remove");

  const ExploreAllCoinsData = () => {
    setLoading(true);
    const _exploreAllNftpromise = _exploreAllNft(currentPage);
    _exploreAllNftpromise.then(
      res => {
        if (!res.error) {
          storedata(res);
          console.log(res, 'exploreALl');
        }
        setprogress(false);
        setLoading(false);
      },
      () => {
        setprogress(false);
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (collectionId) {
      setprogress(true);
      const _exploreNftpromise = _exploreCollectionNft(collectionId, currentPage);
      _exploreNftpromise.then(
        res => {
          console.log('Explor01-collectionNFTdata', res);
          if (!res.error) {
            console.log('77777', res);
            storedata(res);
            if (res.data.error) {
              console.log('88888', res);
              setNodata(true);
            }
          }

          setprogress(false);
        },
        () => {
          console.log('fail');
          setprogress(false);
        },
      );
    } else {
      ExploreAllCoinsData();
    }
  }, [collectionId, currentPage]);
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Browse items</h1>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Explore 1</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <TodayPicks
        data={explorelist}
        categoryselect={id => handlecategoryselect(id)}
        increasepagenumber={() => increaseexplorepagenumber()}
        exploretotalItems={totalItems}
        progress={progress}
        noData={noData}
        onsearchnft={value => onsearchnft(value)}
        handlesearchinput={value => handlesearchinput(value)}
        filter={(selectedcategoryId, selectedquantityId, selectedstatusId, selectedsortId) =>
          handle_filter(selectedcategoryId, selectedquantityId, selectedstatusId, selectedsortId)
        }
        addOrRemoveFav={addOrRemoveFav}
        loading={loading}
      />
      <Footer />
    </div>
  );
};

export default ExploreCollections;
