import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ConnectWalletContext } from '../context/ConnectWalletContext';

const PutonsaleModal = props => {
  let { assetdata } = props;
  console.log('Buy modeal data from buyModal.jsx assetdata', assetdata);
  let data = props.selecteddata;
  console.log(data, 'newdata unlock');
  const { ConnectedAccount } = React.useContext(ConnectWalletContext);
  const [tokenPrice, setTokenPrice] = useState(0);
  console.log('datauserprofile--->', tokenPrice);
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide();
        setTokenPrice(0);
      }}>
      <Modal.Header closeButton className="center mt-5">
        <h3 className="alignin-center">Here Your Unlockable Content</h3>
      </Modal.Header>
      <div className="modal-body space-y-20">
        <h2 className="text-center mt-5 mb-5">
          {ConnectedAccount === data?.currentAddress ? ( // From Backend Authorized
            <p className="price color-popup">{data?.unlockContent}</p>
          ) : (
            <div
              className="price color-popup"
              style={{ filter: 'blur(8px)', pointerEvents: 'none' }}>
              {data?.unlockContent}
            </div>
          )}
        </h2>
      </div>
    </Modal>
  );
};

export default PutonsaleModal;
