import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CardModal from '../../CardModal';
import { useParams } from 'react-router-dom';
// import { Dropdown } from 'react-bootstrap';
import TodayPicks from '../../../components/layouts/explore-01/TodayPicks';
import TodayPicksHome from '../../../components/layouts/home-3/TodayPicks';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import { getshortcode } from '../../../utils/helpers'; //copytoclipboard

// import img1 from '../../../assets/images/box-item/image-box-47.jpg';
// import imga1 from '../../../assets/images/avatar/author_rank.jpg';
// import img2 from '../../../assets/images/box-item/image-box-48.jpg';
// import imga2 from '../../../assets/images/avatar/avt-3.jpg';
// import img3 from '../../../assets/images/box-item/image-box-34.jpg';
// import imga3 from '../../../assets/images/avatar/avt-27.jpg';
// import img4 from '../../../assets/images/box-item/image-box-35.jpg';
// import imga4 from '../../../assets/images/avatar/avt-10.jpg';
// import img5 from '../../../assets/images/box-item/image-box-36.jpg';
// import imga5 from '../../../assets/images/avatar/avt-5.jpg';
// import img6 from '../../../assets/images/box-item/image-box-32.jpg';
// import img7 from '../../../assets/images/box-item/image-box-33.jpg';
// import img8 from '../../../assets/images/box-item/image-box-52.jpg';
// import img9 from '../../../assets/images/box-item/image-box-53.jpg';
// import img10 from '../../../assets/images/box-item/image-box-49.jpg';
// import img11 from '../../../assets/images/box-item/image-box-54.jpg';
// import img12 from '../../../assets/images/box-item/image-box-55.jpg';
// import img13 from '../../../assets/images/box-item/image-box-56.jpg';
// import img14 from '../../../assets/images/box-item/image-box-50.jpg';
// import buyIcon from '../../../assets/images/icon/icons8-buying-48.png';
import previewuser from '../../../assets/images/avatar/previewThumb.png';
// import img1 from '../../../assets/images/avatar/previewThumb.png';

// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  _searchNFT,
  _exploreCollectionNft,
  _exploreAllNft,
  _addToFav,
  _removeFromFav,
  _exploreNft,
} from '../../../services/mainApi';
import { toast } from 'react-toastify';
import imga1 from '../../../assets/images/avatar/avt-1.jpg';
import imgCollection1 from '../../../assets/images/avatar/avt-18.jpg';

// import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
// import TodayPicks from '../home-3/TodayPicks';
import PopularCollection from '../home-3/PopularCollection';
import TopSeller from '../home-3/TopSeller';
import BrowseCollection from '../../../pages/BrowseCollection';
// import BuyMutiTokenModal from '../MyItems/BuyMutiTokenModal';
// import BuyModal from '../MyItems/BuyModal';

const ItemContent = props => {
  const [dataTab] = useState([
    {
      id: 1,
      title: 'All NFT',
    },
    {
      id: 2,
      title: "Today's Picks",
    },
    {
      id: 3,
      title: "Top Seller's",
    },
    {
      id: 4,
      title: 'Popular Collections',
    },
    {
      id: 5,
      title: 'Browse Collections',
    },
  ]);
  const { collectionId } = useParams();
  const [explorelist, setexplorelist] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  // const [currentCategoryPage, setcurrentCategoryPage] = useState(1);
  const [filterPageCheck, setFilterPageCheck] = useState(false);

  const [totalItems, settotalItems] = useState(0);
  const [TabsClassified, setTabsClassified] = useState('All NFT');
  const [load, setLoad] = React.useState('initial');
  const [noData, setNoData] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  // const [isFilter, setIsFilter] = useState(false);
  const [explorelistfilter, setexplorelistfilter] = useState([]);
  const increaseexplorepagenumber = () => {
    setcurrentPage(currentPage + 1);
    setLoadMore(true);
    // setIsFilter(false);
  };
  const changePageNumber = number => {
    setcurrentPage(number);
    setPageLoad(true);
  };
  const changecategoryPageNumber = number => {
    props.setcurrentCategoryPage(number);
    setPageLoad(true);
  };
  const { searchGlobally, ConnectedAccount } = React.useContext(ConnectWalletContext);
  console.log('searchGlobally', searchGlobally);
  const navigate = useNavigate();
  const storedata = res => {
    setNoData(false);
    setFilterLoad(false);
    let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
    console.log('temp data', temp, res);
    let resdata = [];
    temp?.length > 0 &&
      temp.map(item => {
        return resdata.push({
          categoryId: item.categoryId ? item.categoryId : '',
          categoryName: item.CategoryData.name ? item.CategoryData.name : '',
          description: item.description,
          id: item._id,
          img: item.fileUrl,
          imgAuthor: previewuser,
          // imgCollection: imgCollection1,
          metadataUrl: item?.metadataUrl ? item?.metadataUrl : '',
          nameAuthor: item.userdetails.username,
          nameCollection: '',
          noOfCopies: item.noOfCopies,
          properties: item?.properties ? item.properties : [],
          price: item.price,
          priceChange: '',
          putOnMarketplace: item.putOnMarketplace,
          tags: item.CategoryData.name,
          title: item.title,
          tokenId: item?.tokenId ? item.tokenId : '',
          walletAddress: item.currentAddress,
          wishlist: item.favCount,
          favStatus: item.favStatus,
          ownerAddress: item.ownerAddress,
          currentAddress: item.currentAddress,
          minBidValue: item.minBidValue,
          isBid: item.isBid,
          collectionAddress: item?.CollectionData[0]?.address,
          collectionSymbol: item?.CollectionData[0]?.symbol,
          collectionDescription: item?.CollectionData[0]?.name,
          multiToken: item.multiToken,
          userImage: item?.userdetails?.userImage,
          blockChainName: item?.blockChainName,
          userId: item?.userdetails?.userId,
        });
      });
    // let data = [...explorelist, ...resdata];
    // let uniqueData = [...data.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    // console.log('NFT data from explorer01', resdata, isFilter);
    setPageLoad(false);

    setexplorelist(resdata);

    settotalItems(res?.data?.data?.totalItems);
  };

  const listBtn = useRef(null);
  const gridBtn = useRef(null);
  const listContent = useRef(null);
  const gridContent = useRef(null);
  const listToggle = () => {
    listBtn.current.classList.add('active');
    gridBtn.current.classList.remove('active');
    listContent.current.classList.add('open');
    gridContent.current.classList.remove('open');
  };
  const gridToggle = () => {
    gridBtn.current.classList.add('active');
    listBtn.current.classList.remove('active');
    gridContent.current.classList.add('open');
    listContent.current.classList.remove('open');
  };

  const ExploreAllCoinsData = () => {
    setFilterPageCheck(false);
    const _exploreAllNftpromise = _exploreAllNft(currentPage);
    _exploreAllNftpromise.then(res => {
      setFilterLoad(false);
      setLoadMore(false);
      if (!res?.data?.error) {
        storedata(res);
      } else {
        setPageLoad(false);
        setexplorelist([]);
        setNoData(true);
      }
    });
  };
  const addOrRemoveFav = item => {
    if (item?.favStatus === true && (load === 'initial' || load === 'completed')) {
      removeFav(item?.id);
    } else {
      if (load === 'initial' || load === 'completed') {
        addToFav(item?.id);
      }
    }
  };

  const addToFav = id => {
    setLoad('pending');
    const payload = {
      nftId: id,
    };
    _addToFav(payload)
      .then(res => {
        setLoadMore(true);

        if (!res?.data?.error) {
          ExploreAllCoinsData();
          toast.success('Added to favorites');
          // onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  const removeFav = id => {
    setLoad('pending');

    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        setLoadMore(true);

        if (!res?.data?.error) {
          ExploreAllCoinsData();
          toast.success('Removed from favorites', '');
          // onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err?.message);
  };
  // useEffect(() => {

  // }, [TabsClassified]);
  useEffect(() => {
    if (collectionId) {
      const _exploreNftpromise = _exploreCollectionNft(collectionId, currentPage);
      _exploreNftpromise.then(res => {
        if (!res.error) {
          storedata(res);
        }
      });
    } else {
      if (TabsClassified === 'All NFT') {
        ExploreAllCoinsData();
      }
    }
  }, [collectionId, currentPage, TabsClassified]);

  const onsearchnft = req => {
    console.log(req, 'searchlistttt');
    if (req?.length >= 1) {
      const promise = _searchNFT({ text: req });
      promise
        .then(res => {
          if (!res.error) {
            let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
            let resdata = [];
            temp?.length > 0 &&
              temp.map(item => {
                return resdata.push({
                  categoryId: item?.categoryId ? item.categoryId : '',
                  description: item.description,
                  id: item._id,
                  img: item.fileUrl,
                  imgAuthor: imga1,
                  imgCollection: imgCollection1,
                  metadataUrl: item?.metadataUrl ? item?.metadataUrl : '',
                  nameAuthor: item.userdetails.username,
                  nameCollection: '',
                  noOfCopies: item.noOfCopies,
                  properties: item?.properties ? item.properties : [],
                  price: item.price,
                  priceChange: '',
                  putOnMarketplace: item.putOnMarketplace,
                  tags: item.CategoryData.name,
                  title: item.title,
                  tokenId: item?.tokenId ? item.tokenId : '',
                  walletAddress: item.currentAddress,
                  currentAddress: item.currentAddress,
                  wishlist: item.favCount,
                  // favStatus: item.favStatus,
                  ownerAddress: item.ownerAddress,
                  minBidValue: item.minBidValue,
                  isBid: item.isBid,
                  favStatus: item.favStatus,
                  multiToken: item.multiToken,
                  blockChainName: item?.blockChainName,
                });
              });
            // let data = [...resdata];
            // let uniqueData = [
            //   ...data.reduce((map, obj) => map.set(obj.id, obj), new Map()).values(),
            // ];
            // setexplorelist(uniqueData);
            // setsearchdata(req);
            console.log(req, 'searchlisttttreqin');
            if (res?.data?.data?.nftList) {
              setexplorelistfilter(res?.data?.data?.nftList);
            } else {
              setexplorelistfilter([]);
            }
            settotalItems(res?.data?.data?.totalItems);

            // setnftlist(uniqueData);
            settotalItems(res?.data?.data?.totalItems);
          }
          // setsearchdata('');
          // ExploreAllCoinsData();
        })
        .catch(e => {
          console.log('errorLogged', e);
        });
    } else if (req?.length == 0) {
      console.log(req, 'searchlisttttreqout');

      const _exploreAllNftpromise = _exploreAllNft(currentPage);
      _exploreAllNftpromise.then(res => {
        if (!res.error) {
          storedata(res);
          console.log('empty');
        }
      });
    }
  };
  React.useEffect(() => {
    if (!searchGlobally) {
      onsearchnft('');
    } else {
      onsearchnft(searchGlobally);
    }
  }, [searchGlobally]);
  const [modalShow, setModalShow] = useState(false);
  React.useEffect(() => {
    console.log('oliii', props.selectCoin, 'thids', props.selectCategory);
    if (props.selectCoin) {
      filterBlockChain(props.selectCoin);
    } else if (props.selectCategory) {
      filterCategory(props.selectCategory);
    }
  }, [props.selectCoin, props.selectCategory, props.currentCategoryPage]);

  const filterBlockChain = chainNameHere => {
    setFilterPageCheck(true);
    setFilterLoad(true);
    if (chainNameHere === 'All') {
      ExploreAllCoinsData();
      // setIsFilter(false);
    } else {
      // setIsFilter(true);
      const _exploreAllNftpromise = _exploreAllNft(props.currentCategoryPage, {
        filter: { blockChainName: chainNameHere },
      });
      _exploreAllNftpromise.then(res => {
        if (!res?.data?.error) {
          // setIsFilter(true);
          storedata(res, true);
          console.log('locationnnn', res);
        } else {
          setexplorelist([]);
          setFilterLoad(false);
          settotalItems(0);
          setNoData(true);
          console.log('locationnnni', res);
        }
      });
    }
  };
  const filterCategory = item => {
    console.log(noData, 'looogg');
    if (item === 1234) {
      setcurrentPage(1);
      ExploreAllCoinsData();
    } else {
      setFilterPageCheck(true);
      // setcurrentPage(1);
      setFilterLoad(true);

      const _exploreNftpromise = _exploreNft(item, props.currentCategoryPage);
      _exploreNftpromise.then(res => {
        if (!res?.data?.error) {
          storedata(res);
        } else {
          setexplorelist([]);
          setFilterLoad(false);
          setNoData(true);
        }
      });
    }
    setFilterLoad(true);
  };
  return (
    <Fragment>
      {/* <div className="col-box-17">
        <SideBar
          filter={(
            selectedcategoryId,
            selectedquantityId,
            selectedstatusId,
            selectedsortId,
            coinName,
          ) =>
            handle_filter(
              selectedcategoryId,
              selectedquantityId,
              selectedstatusId,
              selectedsortId,
              coinName,
            )
          }
        />
      </div> */}

      <div className="flat-tabs items">
        <Tabs>
          <TabList>
            {dataTab.map(data => (
              <Tab
                onClick={() => {
                  setTabsClassified(data.title);
                  console.log('filterhide2', data.title);

                  if (data.title == 'All NFT') {
                    props?.setFilterHide(true);
                  } else {
                    props?.setFilterHide(false);
                  }
                }}
                key={data.id}>
                {data.title}
              </Tab>
            ))}
          </TabList>
          <TabPanel>
            <div className="option">
              <h2 className="title">{totalItems} Items</h2>
              <div className="view">
                {TabsClassified === "Today's Picks" ||
                TabsClassified === "Top Seller's" ||
                TabsClassified === 'Browse Collections' ||
                TabsClassified === 'Popular Collections' ? (
                  ''
                ) : (
                  <ul>
                    <li onClick={listToggle} ref={listBtn} className="style1 grid active">
                      <Link to="#">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
                            fill="white"
                          />
                          <path
                            d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"
                            fill="white"
                          />
                          <path
                            d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                            fill="white"
                          />
                          <path
                            d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                            fill="white"
                          />
                        </svg>
                        <span>Grid</span>
                      </Link>
                    </li>
                    <li onClick={gridToggle} ref={gridBtn} className="style2 list">
                      <Link to="#">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21 8H3C2.59 8 2.25 7.09333 2.25 6C2.25 4.90667 2.59 4 3 4H21C21.41 4 21.75 4.90667 21.75 6C21.75 7.09333 21.41 8 21 8Z"
                            fill="#EBEBEB"
                          />
                          <path
                            d="M21 14H3C2.59 14 2.25 13.0933 2.25 12C2.25 10.9067 2.59 10 3 10H21C21.41 10 21.75 10.9067 21.75 12C21.75 13.0933 21.41 14 21 14Z"
                            fill="#EBEBEB"
                          />
                          <path
                            d="M21 20H3C2.59 20 2.25 19.0933 2.25 18C2.25 16.9067 2.59 16 3 16H21C21.41 16 21.75 16.9067 21.75 18C21.75 19.0933 21.41 20 21 20Z"
                            fill="#EBEBEB"
                          />
                        </svg>
                        <span>List</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {console.log(TabsClassified, 'totallll')}

            {TabsClassified === 'All NFT' && (
              <>
                <div className="content-item open" ref={listContent}>
                  <TodayPicks
                    loadmore={location?.state === null || 'all data'}
                    data={
                      searchGlobally === null || searchGlobally?.length === 0
                        ? explorelist
                        : explorelistfilter
                    }
                    increasepagenumber={() => increaseexplorepagenumber()}
                    exploretotalItems={totalItems}
                    home2={true}
                    addOrRemoveFav={addOrRemoveFav}
                    // filter={() => handle_filter(props.selectCoin)}
                    loadMore={loadMore}
                    filterLoad={filterLoad}
                    pageLoad={pageLoad}
                    changePageNumber={value => changePageNumber(value)}
                    noData={noData}
                    filterPageCheck={filterPageCheck}
                    changecategoryPageNumber={changecategoryPageNumber}
                    currentCategoryPage={props.currentCategoryPage}
                    currentPage={currentPage}
                  />
                </div>
                <div className="content-item2" ref={gridContent}>
                  {explorelist
                    ?.filter(
                      item =>
                        props?.selectionFilter == 'All' ||
                        (props?.selectionFilter != 'All' &&
                          props?.selectionFilter?.includes(item.categoryName)) ||
                        props?.selectionFilter.length == 0,
                    )
                    ?.map((item, key) => (
                      <div key={key} className="col-item">
                        <div className="sc-card-product menu_card style-h7">
                          <div className="widthList wrap-media">
                            <div className="card-media">
                              <Link to={`/buy/${item?.id}`}>
                                <img className="img2" src={item.img} alt="Axies" />
                              </Link>
                            </div>
                          </div>
                          <div className="widthList card-title titleList">
                            <p>Name</p>
                            <h6>
                              <Link to={`/buy/${item?.id}`}>{item.title}</Link>
                            </h6>
                          </div>
                          <div className="widthList meta-info style">
                            <p>Owned By</p>
                            {/* <div className="author">
                              <div className="avatar">
                                <img src={item.imgAuthor} alt="Axies" />
                              </div>
                              <div className="info">
                                <h6>
                                  {' '}
                                  <Link to="author02.html">{item.nameAuthor}</Link>{' '}
                                </h6>
                              </div>
                            </div> */}
                            <h6>
                              <Link to={`/authors-02/${item?.userId || item?.id}`}>
                                {getshortcode(item.walletAddress)}
                              </Link>
                            </h6>
                          </div>
                          {/* <div className="countdown">
                            <p>Countdown</p>
                            <div className="featured-countdown">
                              <span className="slogan"></span>
                              <Countdown date={Date.now() + 500000000}>
                                <span>You are good to go!</span>
                              </Countdown>
                            </div>
                          </div> */}
                          <div className="wrap-hear" onClick={() => addOrRemoveFav(item)}>
                            <button
                              className={
                                item.favStatus
                                  ? 'wishlist-button icon-fl-heart-filled home2-wish'
                                  : 'wishlist-button  home2-wish heart heart2'
                              }>
                              <span className="number-like"> {item.wishlist}</span>
                            </button>
                          </div>
                          {/* <div className="widthList wrap-tag">
                            <div className="tags">{item.tags}</div>
                          </div> */}
                          <div className="widthList card-title">
                            <p>Category</p>
                            <h6>
                              <span>{item.tags}</span>
                            </h6>
                          </div>
                          {/* <div className="meta-info">
                            <div className="author">
                              <div className="info">
                                <p>Current Bid</p>
                                <p className="pricing">{item.price}</p>
                              </div>
                            </div>
                          </div> */}
                          {ConnectedAccount === item.walletAddress && item.price != 0 ? (
                            <>
                              <div className="widthList button-place-bid">
                                <button
                                  // onClick={() => {
                                  // setbuyingModal({ type: 'changePrice', item: item });
                                  // setSwitchShow(true);
                                  // setChainName(data[index].blockChainName);
                                  // let chainAdress = await getChainContractAddress(
                                  //   data[index].blockChainName,
                                  // );
                                  // setChainContractAddress(chainAdress.NFTcontractAddress);
                                  // setNftChainId(chainAdress.chainId);
                                  // }}
                                  onClick={() => {
                                    navigate(`/buy/${item?.id}`);
                                  }}
                                  className="sc-button-list style bag fl-button pri-3 no-bg">
                                  <span>Change Price</span>
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              {item.isBid == true && item.minBidValue > 0 ? (
                                <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                  <div className="home2-btn-div">
                                    <button
                                      className="buyNowWholeButton"
                                      onClick={() => {
                                        // setselectedid(item);
                                        // setBuyModalShow(true);
                                        navigate(`/buy/${item?.id}`);
                                      }}>
                                      <div className="buyNowIcon">
                                        {/* <img className="buyIconDiv" src={buyIcon} alt="buy" /> */}
                                        <span className="buyNowFont">Bid now</span>
                                      </div>
                                    </button>
                                  </div>
                                </Link>
                              ) : (
                                <div className="widthList button-place-bid">
                                  <button
                                    className="buyNowWholeButton"
                                    onClick={() => {
                                      // setselectedid(item);
                                      // setBuyModalShow(true);
                                      navigate(`/buy/${item?.id}`);
                                    }}>
                                    <div className="buyNowIcon">
                                      {/* <img className="buyIconDiv" src={buyIcon} alt="buy" /> */}
                                      <span className="buyNowFont">Buy now</span>
                                    </div>
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  <div className="col-md-12 wrap-inner load-more text-center">
                    <Link
                      to="#"
                      id="load-more"
                      className="sc-button loadmore fl-button pri-3"
                      onClick={() => increaseexplorepagenumber()}>
                      <span>Load More</span>
                      {/* {loading && (
                          <div className="wishlist-button-loading-loadmore">
                            <i
                              className="fa fa-spinner fa-spin fa-3x mb-3"
                              aria-hidden="true"
                              id="circle1"></i>
                          </div>
                        )} */}
                    </Link>
                  </div>
                  {/* {visible < data.dataContent.length && (
                  <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
                    <Link
                      to="#"
                      className="sc-button loadmore fl-button pri-3"
                      onClick={showMoreItems}>
                      <span>Load More</span>
                    </Link>
                  </div>
                )} */}
                </div>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <div className="option">
              <h2 className="title">{totalItems} Items</h2>
            </div>

            {TabsClassified === "Today's Picks" && (
              <TodayPicksHome
                settotalItems={settotalItems}
                selectionFilter={props.selectionFilter}
                Home={2}
              />
            )}
            {/* ||
                (TabsClassified === 'Popular Collections' && (
                  <PopularCollection Home={2} settotalItems={settotalItems} />
                )) ||
                (TabsClassified === "Top Seller's" && (
                  <TopSeller settotalItems={settotalItems} />
                )) ||
                (TabsClassified === 'Browse Collections' && (
                  <BrowseCollection Home={2} settotalItems={settotalItems} />
                ))} */}
          </TabPanel>
          <TabPanel>
            <div className="option">
              <h2 className="title">{totalItems} Items</h2>
            </div>

            {(TabsClassified === "Top Seller's" && <TopSeller settotalItems={settotalItems} />) ||
              (TabsClassified === 'Browse Collections' && (
                <BrowseCollection Home={2} settotalItems={settotalItems} />
              ))}
          </TabPanel>
          <TabPanel>
            <div className="option">
              <h2 className="title">{totalItems} Items</h2>
            </div>
            {TabsClassified === 'Popular Collections' && (
              <PopularCollection Home={2} settotalItems={settotalItems} />
            )}
          </TabPanel>
          <TabPanel>
            <div className="option">
              <h2 className="title">{totalItems} Items</h2>
            </div>
            {TabsClassified === 'Browse Collections' && (
              <BrowseCollection Home={2} settotalItems={settotalItems} />
            )}
          </TabPanel>
        </Tabs>
      </div>
      {/* {selectedid?.multiToken === true ? (
        <BuyMutiTokenModal
          show={buyModalShow}
          onHide={() => setBuyModalShow(false)}
          assetdata={selectedid}
        />
      ) : (
        <BuyModal
          show={buyModalShow}
          onHide={() => setBuyModalShow(false)}
          assetdata={selectedid}
        />
      )}{' '} */}
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

export default ItemContent;
