import React from 'react';
import img1 from '../../../assets/images/avatar/previewThumb.png';
import shortenAccount from '../../../utils/shortenAccount';

function CancleBidingList({ cancel, data }) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <div className="mb-2">
          <div className="mr-3">
            <img
              style={{
                height: '50px',
                width: '50px',
                borderRadius: 100,
              }}
              src={data.userdetails.userImage ? data.userdetails.userImage : img1}
              alt="Axies"
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '10px',
            fontSize: '15px',
          }}>
          <span>Token id: {data.tokenId}</span>
          <span>Bidding added: {shortenAccount(data.bidderAddress)}</span>
          <span>Bid Amount: {data.price}</span>
        </div>

        {data.status == 'pending' && (
          <button className="sc-button  fl-button pri-3 no-bg m-2" onClick={() => cancel(data)}>
            <span>cancel</span>
          </button>
        )}
      </div>
    </>
  );
}

export default CancleBidingList;
