import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
//import img1 from '../../../assets/images/avatar/previewThumb.png';
import shortenAccount from '../../../utils/shortenAccount';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { copytoclipboard } from '../../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageNotifyModal from '../../../modal/MessageNotifyModal';
import getChainContractAddress from '../../../utils/getChainContractAddress';

export default function OwnerDetails(props) {
  const { ConnectedAccount, netWrokChainName } = React.useContext(ConnectWalletContext);
  const [copysttaustext, setcopysttaustext] = useState('Copy');
  const [switchShow, setSwitchShow] = useState(false);
  const [chainContractAddress, setChainContractAddress] = useState();
  const [chainName, setChainName] = useState();
  const [nftChainId, setNftChainId] = useState();
  console.log('PRK Change Price from ownerDetails -->', props.explorelist);

  // const onChangePrice = async () => {
  //   console.log(
  //     'PRK my item sale change price blockchain name..',
  //     props.explorelist.blockChainName,
  //   );
  //   console.log('PRK my item sale connected blockchain name', netWrokChainName);
  // };

  const handleSwitchNetworkClose = () => {
    setSwitchShow(false);
  };
  //to handle switch network
  const onHandleSwitchNetwork = async blockChainName => {
    setSwitchShow(true);
    setChainName(blockChainName);
    let chainAdress = await getChainContractAddress(blockChainName);
    setChainContractAddress(chainAdress.NFTcontractAddress);
    setNftChainId(chainAdress.chainId);
  };
  return (
    <div>
      <div className={`sc-card-product explode style2 mg-bt`}>
        {/* <div className="card-media "></div> */}
        <div className="card-title ml-3">
          <div className="row">
            <div className="infor-list mr-4">
              {/* <img src={props.explorelist?.imgAuthor} alt="axies" /> */}
              {/* <img src={img1} alt="axies" /> */}
            </div>
            <div>
              <div className="row" style={{ alignItems: 'center' }}>
                <p>
                  <Link to={`/authors-02/${props?.explorelist?.userId}`} state={props?.explorelist}>
                    {/* {props.explorelist?.walletAddress &&
                    `${props.explorelist?.walletAddress.slice(
                      0,
                      5,
                    )}...${props.explorelist.walletAddress.slice(
                      props.explorelist.walletAddress.length - 4,
                    )}`} */}
                    <span>Owned By : &nbsp;</span>
                    {shortenAccount(
                      props.explorelist?.currentAddress
                        ? props.explorelist?.currentAddress
                        : props.explorelist?.walletAddress,
                    )}
                  </Link>
                </p>
                <div className="ml-4" style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">{copysttaustext}</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faClipboard}
                      size="lg"
                      onClick={() => {
                        copytoclipboard(
                          props.explorelist?.currentAddress
                            ? props.explorelist?.currentAddress
                            : props.explorelist?.walletAddress,
                        );
                        setcopysttaustext('Copied');
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </div>
              <p>
                {props.explorelist?.noOfCopies}/{props.explorelist?.noOfCopies} on sale for&nbsp;
                {props.explorelist?.noOfCopies}&nbsp;each
              </p>
              {/* <button className="sc-button style fl-button pri-3 no-bg mt-4">
                        <span className="pl-0">Change Price</span>
                      </button> */}
              {ConnectedAccount ===
              (props.explorelist?.currentAddress
                ? props.explorelist?.currentAddress
                : props.explorelist?.walletAddress) ? (
                <button
                  onClick={async () => {
                    //onChangePrice();
                    if (props.explorelist.blockChainName == netWrokChainName)
                      props.modelopen('sale');
                    if (props.explorelist.blockChainName != netWrokChainName) {
                      onHandleSwitchNetwork(props.explorelist.blockChainName);
                    }
                  }}
                  className="sc-button style fl-button pri-3 no-bg mt-4">
                  {props.explorelist.isBid ? (
                    <span className="pl-0">Change bid value</span>
                  ) : (
                    <span className="pl-0">Change price</span>
                  )}
                  {/* <span className="pl-0">Change Price</span> */}
                </button>
              ) : props.explorelist?.price > 0 || props.explorelist?.minBidValue > 0 ? (
                <>
                  {props.explorelist.price > 0 ? (
                    <>
                      <button
                        className="sc-button style fl-button pri-3 no-bg mt-4"
                        onClick={async () => {
                          //props.modelopen('buy')
                          if (props.explorelist.blockChainName == netWrokChainName)
                            props.modelopen('buy');
                          if (props.explorelist.blockChainName != netWrokChainName) {
                            onHandleSwitchNetwork(props.explorelist.blockChainName);
                          }
                        }}>
                        <span className="pl-0">Buy now</span>
                      </button>
                      {props.explorelist.isBid ? (
                        <button
                          className="sc-button style fl-button pri-3 no-bg mt-4 ml-3"
                          onClick={async () => {
                            if (props.explorelist.blockChainName == netWrokChainName) {
                              props.modelopen('bid');
                            }

                            if (props.explorelist.blockChainName != netWrokChainName) {
                              onHandleSwitchNetwork(props.explorelist.blockChainName);
                            }
                          }}>
                          <span className="pl-0">Bid now</span>
                        </button>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}
                  {props.explorelist.minBidValue > 0 && props?.BidStatus !== true ? (
                    <button
                      className="sc-button style fl-button pri-3 no-bg mt-4 ml-3"
                      onClick={() => {
                        if (props.explorelist.blockChainName == netWrokChainName) {
                          props.modelopen('bid');
                        }

                        if (props.explorelist.blockChainName != netWrokChainName) {
                          onHandleSwitchNetwork(props.explorelist.blockChainName);
                        }
                      }}>
                      <span className="pl-0">Bid now</span>
                    </button>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <h4 className="sc-button style fl-button pri-3 no-bg mt-4">
                  <span className="pl-0">Unlisted</span>
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
      {!ConnectedAccount === props.explorelist?.walletAddress && (
        <div className={`sc-card-product explode style2 mg-bt`}>
          <div className="card-media"></div>
          <div className="card-title ml-3">
            <div className="row">
              <div className="infor-list mr-4"></div>
              <div>
                <h6 className="text-center">No active bids yet... Be the first to make a bid!</h6>
                <button
                  className="sc-button style fl-button pri-3 no-bg mt-4 mr-4"
                  onClick={() => props.modelopen('buy')}>
                  <span className="pl-0">Buy now</span>
                </button>
                <button
                  className="sc-button style fl-button pri-3 no-bg mt-4"
                  onClick={() => props.modelopen('bid')}>
                  <span className="pl-0">Bid now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <MessageNotifyModal
        show={switchShow}
        onHide={handleSwitchNetworkClose}
        chainName={chainName}
        chainId={nftChainId}
        chainIdAddress={chainContractAddress}
      />
    </div>
  );
}
