import React, { useState, useEffect } from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import img1 from '../../../assets/images/avatar/previewThumb.png';
import BuyModal from './BuyModal';
import BuyMutiTokenModal from './BuyMutiTokenModal';
import BidModal from './BidModal';
import OwnerDetails from './OwnerDetails';
import BidsDetails from './BidsDetails';
import History from './History';
import { Modal } from 'react-bootstrap';
import PutonsaleModal from '../../PutonsaleModal';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import { _getNftDetails, _addToFav, _removeFromFav } from '../../../services/mainApi';
import BidsDetailsView from './BidsDetailsView';
import TopBids from './TopBids';
import config from '../../../lib/config';
import shortenAccount from '../../../utils/shortenAccount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import {
  faChevronUp,
  faChevronDown,
  faRectangleList,
  faTag,
  faAlignLeft,
  faClipboard,
} from '@fortawesome/free-solid-svg-icons';
import { copytoclipboard } from '../../../utils/helpers';
import avaxLogo from '../../../assets/cryptoLogo/avalanche-avax-icon.svg'; //avalanche-avax-icon.svg'
import bnbLogo from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../../../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../../../assets/cryptoLogo/ethereum-eth-icon.svg';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnlockModal from '../../UnlockModal';
import * as Router from 'react-router-dom';

const NFTdetails = () => {
  const { ConnectedAccount } = React.useContext(ConnectWalletContext);
  const { id } = Router.useParams();
  // const [explorelist] = useState(data);
  console.log(id, 'datass');

  const [viewMore, setViewMore] = useState({
    description: false,
    properties: false,
    details: false,
  });
  const [modalShow, setModalShow] = useState(false);
  const [bidmodalShow, setbidModalShow] = useState(false);
  const [bidDetails, setBidDetails] = useState([]);
  const [modalView, setmodalView] = useState(false);
  const [saleModalShow, setSaleModalShow] = useState(false);
  const [variant, setvariant] = useState('owner');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [copysttaustext, setcopysttaustext] = useState('Copy');
  const [NFTDetails, setNFTDetails] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [load, setLoad] = useState('initial');
  const [explorelist, setExploreList] = useState({
    bidDetails: [],
    categoryDetails: [],
    collectionDetails: [],
    currentDetails: [],
    nftDetails: [],
    ownerDetails: [],
  });

  let tokenType = explorelist?.nftDetails[0]?.multiToken ? 'ERC-1155' : 'ERC-721';
  let chainUrl;
  let imgUrl;
  let currency;

  if (explorelist?.nftDetails[0]?.blockChainName == 'Avalanche') {
    chainUrl = 'https://testnet.snowtrace.io/address/';
    imgUrl = <img className="cryptoImgStyle" src={avaxLogo} />;
    currency = 'AVAX';
  }
  if (explorelist?.nftDetails[0]?.blockChainName == 'Ethereum') {
    chainUrl = 'https://goerli.etherscan.io/address/';
    imgUrl = <img className="cryptoImgStyle" src={ethLogo} />;
    currency = 'ETH';
  }
  if (explorelist?.nftDetails[0]?.blockChainName == 'Polygon') {
    chainUrl = 'https://mumbai.polygonscan.com/address/';
    imgUrl = <img className="cryptoImgStyle" src={maticLogo} />;
    currency = 'MATIC';
  }
  if (explorelist?.nftDetails[0]?.blockChainName == 'Binance') {
    chainUrl = 'https://testnet.bscscan.com/address/';
    imgUrl = <img className="cryptoImgStyle" src={bnbLogo} />;
    currency = 'BNB';
  }

  const getNftDetails = () => {
    _getNftDetails(id)
      .then(res => {
        setExploreList({
          bidDetails: res?.data?.data?.bidDetails,
          categoryDetails: res?.data?.data?.categoryDetails,
          collectionDetails: res?.data?.data?.collectionDetails,
          currentDetails: res?.data?.data?.currentDetails,
          nftDetails: res?.data?.data?.nftDetails,
          ownerDetails: res?.data?.data?.ownerDetails,
        });
        setBidDetails(res?.data?.data?.bidDetails),
          setNFTDetails(
            res?.data?.data?.nftDetails[0],
            setUserProfileData(res?.data?.data?.ownerDetails),
          );
        console.log(res, 'nftres');
      })
      .catch(() => console.log());
  };
  useEffect(() => {
    getNftDetails();
    //cleanUp to stop unwanted noice/warning
    return () => {};
  }, []);
  console.log('buy module data from NFTdetail', id, explorelist);

  const onvariantselect = value => {
    setvariant(value);
  };
  const modelopen = value => {
    if (value === 'buy') {
      setModalShow(true);
    } else if (value === 'bid') {
      setbidModalShow(true);
    } else if (value == 'sale') {
      setSaleModalShow(true);
    }
  };
  const contractAddress = () => {
    if (explorelist?.collectionAddress == undefined) {
      if (explorelist?.CollectionData?.length > 0) {
        return explorelist?.CollectionData[0]?.address;
      } else {
        console.log('PRK test chainUrl link', chainUrl + config.NFTContractAddress);
        return config.NFTContractAddress;
      }
    } else {
      return explorelist?.collectionAddress;
    }
  };

  console.log('buy data full NFTdetail owner address', NFTDetails?.ownerAddress);
  console.log('NFT detail profile data', userProfileData);

  const addOrRemoveFavbutton = item => {
    console.log('function called ==>', item);
    if (item?.favStatus === false && (load === 'initial' || load === 'completed')) {
      addToFav(item?._id);
    } else {
      if (load === 'initial' || load === 'completed') {
        removeFav(item?._id);
        setLoad('completed');
      }
    }
  };
  console.log('datasInvalid', bidDetails);
  const addToFav = id => {
    setLoad('pending');
    const payload = {
      nftId: id,
    };
    _addToFav(payload)
      .then(res => {
        if (!res.data.error) {
          toast.success('Added to favorites', '');
          getNftDetails();
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  const removeFav = id => {
    setLoad('pending');

    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        if (!res.data.error) {
          toast.success('Removed from favorites', '');
          getNftDetails();
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  const [bidStatus, setBidStatus] = React.useState(false);
  const BidStatusFetch = () => {
    bidDetails?.filter(e => {
      if (e?.bidderAddress === ConnectedAccount) {
        setBidStatus(true);
      }
    });
  };
  React.useEffect(() => {
    BidStatusFetch();
  }, [bidDetails]);

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          {/* <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Buy</h1>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {explorelist?.nftDetails?.length === 0 ? (
        <div
          className={localStorage?.theme === 'light' ? 'loading-Center-light' : 'loading-Center'}>
          <div>
            <i className="fa fa-spinner fa-spin fa-7x mb-3" aria-hidden="true" id="circle1"></i>
          </div>
          {/* <h2>Loading...</h2> */}
        </div>
      ) : (
        <section className="tf-section pt-5">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="content-left">
                  <div className="sc-card-product">
                    <div className="card-media mt-4" onClick={handleShow}>
                      {explorelist?.nftDetails[0]?.fileUrl.split('.')[4] == 'mp4' ? (
                        <div
                          style={{
                            marginLeft: '80px',
                            width: '200px',
                            height: '200px',
                          }}>
                          <video
                            style={{ cursor: 'pointer' }}
                            width="320"
                            height="240"
                            src={explorelist?.nftDetails[0]?.fileUrl}
                            controlsList="nodownload">
                            Your browser does not support the video tag.
                          </video>
                          <h5>Click to play the video</h5>
                        </div>
                      ) : explorelist?.nftDetails[0]?.fileUrl.split('.')[4] == 'mpeg' ? (
                        <>
                          <div
                            style={{
                              marginTop: '5rem',
                              marginLeft: '10rem',
                              padding: '2rem',
                              width: '150px',
                              height: '150px',
                              cursor: 'pointer',
                            }}>
                            <img src={img1} alt="Axies" />
                          </div>
                          <div style={{ marginLeft: '30px' }}>
                            <audio
                              controls
                              src={explorelist?.nftDetails[0]?.fileUrl}
                              controlsList="nodownload">
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            className="cardimg_size"
                            src={explorelist?.nftDetails[0]?.fileUrl || img1}
                            alt="Axies"
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      )}
                    </div>
                    <h4>
                      {explorelist?.nftDetails[0]?.isBid ? 'Min Bid Price' : 'Price'} &nbsp;
                      {explorelist?.nftDetails[0]?.isBid
                        ? explorelist?.nftDetails[0]?.minBidValue
                        : explorelist?.nftDetails[0]?.price}{' '}
                      &nbsp;
                      {currency}
                      {imgUrl}
                    </h4>
                  </div>
                </div>
                <div className="sc-card-product-item">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <FontAwesomeIcon icon={faAlignLeft} size="2x" />
                      <h5 className="tf-title-heading ml-4">Description</h5>
                    </div>
                    <FontAwesomeIcon
                      icon={viewMore.description ? faChevronUp : faChevronDown}
                      size="2x"
                      onClick={() =>
                        setViewMore(prevData => ({
                          ...prevData,
                          description: !prevData.description,
                        }))
                      }
                    />
                  </div>
                  {viewMore.description && (
                    <h6 className="sub-title mb-4 font-italic ml-4">
                      {explorelist?.nftDetails[0]?.description}
                    </h6>
                  )}
                </div>
                <div className="sc-card-product-item">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <FontAwesomeIcon icon={faTag} size="2x" />
                      <h5 className="tf-title-heading ml-4">Properties</h5>
                    </div>
                    <FontAwesomeIcon
                      icon={viewMore.properties ? faChevronUp : faChevronDown}
                      size="2x"
                      onClick={() =>
                        setViewMore(prevData => ({
                          ...prevData,
                          properties: !prevData.properties,
                        }))
                      }
                    />
                  </div>
                  {viewMore.properties && (
                    <div>
                      {explorelist?.nftDetails[0]?.properties?.length > 0 ? (
                        <div className="row col">
                          {explorelist?.nftDetails[0]?.properties?.length > 0 &&
                            explorelist?.nftDetails[0]?.properties.map((property, index) => {
                              if (typeof property == 'object')
                                return (
                                  <div className="col" key={`${index}prop`}>
                                    {property?.value ? (
                                      <div className="propertylists ">
                                        <h3
                                          className="title-create-item text-center mb-2"
                                          style={{ color: 'cyan', fontSize: '2rem' }}>
                                          {property?.trait_type}
                                        </h3>
                                        <h6 className="text-center" style={{ fontSize: '2rem' }}>
                                          {property?.value}
                                        </h6>
                                      </div>
                                    ) : (
                                      <h6 className="sub-title mb-4 font-italic">
                                        No properties exist
                                      </h6>
                                    )}
                                  </div>
                                );
                            })}
                        </div>
                      ) : (
                        <h6 className="sub-title mb-4"> Properties not found</h6>
                      )}
                    </div>
                  )}
                </div>
                <div className="sc-card-product-item">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <FontAwesomeIcon icon={faRectangleList} size="2x" />
                      <h5 className="tf-title-heading ml-4">Details</h5>
                    </div>
                    <FontAwesomeIcon
                      icon={viewMore.details ? faChevronUp : faChevronDown}
                      size="2x"
                      onClick={() =>
                        setViewMore(prevData => ({
                          ...prevData,
                          details: !prevData.details,
                        }))
                      }
                    />
                  </div>

                  {viewMore.details && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <h6 className="sub-title">Contract Address :</h6>
                        <h6 className="link">
                          <a
                            className="link"
                            href={`${chainUrl}${contractAddress()}`}
                            target="_blank"
                            rel="noreferrer noopener">
                            {shortenAccount(contractAddress())}
                          </a>
                        </h6>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <h6 className="sub-title">Token ID :</h6>
                        <h6 className="link">
                          <a
                            className="link"
                            href={NFTDetails?.metadataUrl}
                            target="_blank"
                            rel="noreferrer noopener">
                            {NFTDetails?.tokenId
                              ? NFTDetails?.tokenId
                              : explorelist?.nftDetails[0]?.tokenId}
                          </a>
                        </h6>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <h6 className="sub-title">Token Standard :</h6>
                        <h6 className="sub-title">{tokenType}</h6>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <h6 className="sub-title">Blockchain :</h6>
                        <h6 className="sub-title">{explorelist?.nftDetails[0]?.blockChainName}</h6>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <h6 className="sub-title">Service Fees :</h6>
                        <h6 className="sub-title">2.5%</h6>
                      </div>
                    </div>
                  )}
                </div>
                {NFTDetails?.unlockContentFlag === true ? (
                  <div
                    className="sc-card-product-item"
                    onClick={() => {
                      setExploreList(NFTDetails);
                      setmodalView(true);
                      // console.log(index,"msg");
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <FontAwesomeIcon icon={faLockOpen} size="2x" />
                        <h5 className="tf-title-heading ml-4">Reveal Unlock Content</h5>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="col-lg-1 col-12"></div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-mobile">
                <h2 className="tf-title-heading style-2 mg-bt-12">
                  {explorelist?.nftDetails[0]?.title ? explorelist?.nftDetails[0]?.title : ''}
                </h2>
                <div className="row mg-bt-12">
                  {/* <div className="sc-button mr-3"> */}
                  {/* <i className="fa fa-image"></i> */}
                  <h6>
                    Collection Type:&nbsp;
                    <span>
                      &nbsp;
                      {explorelist?.categoryDetails[0]?.name
                        ? explorelist?.categoryDetails[0]?.name
                        : ''}
                    </span>
                  </h6>
                  <h6 className="ml-4">
                    {/* {props.loading && cardIndex === index ? (
                      <div className="wishlist-button-loading">
                    
                        <i
                          className="fa fa-spinner fa-spin fa-3x mb-3"
                          aria-hidden="true"
                          id="circle1"></i>
                      </div> */}
                    {/* ) : ( */}
                    <div
                      onClick={() => {
                        addOrRemoveFavbutton(explorelist?.nftDetails[0]);
                      }}
                      className={
                        explorelist?.nftDetails[0]?.favStatus
                          ? 'wishlist-button icon-fl-heart-filled'
                          : 'wishlist-button heart'
                      }>
                      <span className="number-like">{explorelist?.nftDetails[0].favCount}</span>
                    </div>
                  </h6>
                </div>
                <div className="meta-info">
                  <div className="author">
                    <div className="info">
                      <h5 className="mb-4">
                        <span>Creator</span>
                      </h5>
                      <div className="row">
                        <div className="infor-list mr-4 ml-3">
                          <img
                            //src={explorelist?.img ? explorelist?.img : img1}
                            src={
                              explorelist?.ownerDetails?.userImage
                                ? explorelist?.ownerDetails?.userImage
                                : img1
                            }
                            alt="axies"
                          />
                        </div>
                        <div className="mt-2 mr-4 ">
                          <h5 className="address-mobile">
                            {NFTDetails?.ownerAddress
                              ? NFTDetails?.ownerAddress
                              : NFTDetails?.currentAddress
                              ? NFTDetails?.currentAddress
                              : explorelist?.ownerDetails?.walletAddress
                              ? explorelist?.ownerDetails?.walletAddress
                              : ''}
                          </h5>
                        </div>
                        <div style={{ cursor: 'pointer' }}>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip">{copysttaustext}</Tooltip>}>
                            <FontAwesomeIcon
                              icon={faClipboard}
                              size="2x"
                              onClick={() => {
                                copytoclipboard(
                                  NFTDetails?.ownerAddress
                                    ? NFTDetails?.ownerAddress
                                    : NFTDetails?.currentAddress
                                    ? NFTDetails?.currentAddress
                                    : explorelist?.ownerDetails?.walletAddress
                                    ? explorelist?.ownerDetails?.walletAddress
                                    : '',
                                );
                                setcopysttaustext('Copied');
                              }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-3 ">
                  <button
                    className="sc-button style bag fl-button pri-3 no-bg m-2"
                    style={variant === 'owner' ? {} : { color: '#7a798a' }}
                    onClick={() => onvariantselect('owner')}>
                    <span>Actions</span>
                  </button>
                  {ConnectedAccount == explorelist?.ownerDetails?.walletAddress ||
                  ConnectedAccount == explorelist?.currentDetails?.walletAddress ? (
                    <>
                      {explorelist?.nftDetails[0]?.isBid ? (
                        <button
                          className="sc-button style bag fl-button pri-3 no-bg m-2"
                          style={variant === 'ownerBids' ? {} : { color: '#7a798a' }}
                          onClick={() => onvariantselect('ownerBids')}>
                          <span>Bids</span>
                        </button>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      {explorelist?.nftDetails[0]?.isBid ? (
                        <button
                          className="sc-button style bag fl-button pri-3 no-bg m-2"
                          style={variant === 'viewerBids' ? {} : { color: '#7a798a' }}
                          onClick={() => onvariantselect('viewerBids')}>
                          <span>Bids</span>
                        </button>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                  {ConnectedAccount == explorelist?.ownerDetails?.walletAddress ||
                    (ConnectedAccount == explorelist?.currentDetails?.walletAddress ? (
                      <></>
                    ) : (
                      <>
                        {explorelist?.nftDetails[0]?.isBid ? (
                          <button
                            className="sc-button style bag fl-button pri-3 no-bg m-2"
                            style={variant === 'topBids' ? {} : { color: '#7a798a' }}
                            onClick={() => onvariantselect('topBids')}>
                            <span>Top Bids</span>
                          </button>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                </div>
                {variant === 'owner' && (
                  <OwnerDetails
                    BidStatus={bidStatus}
                    explorelist={explorelist?.nftDetails[0]}
                    modelopen={req => modelopen(req)}
                  />
                )}
                {variant === 'ownerBids' && (
                  <BidsDetails
                    explorelist={explorelist?.nftDetails[0]}
                    bidDetails={bidDetails}
                    modelopen={req => modelopen(req)}
                  />
                )}
                {variant === 'viewerBids' && (
                  <BidsDetailsView
                    explorelist={explorelist?.nftDetails[0]}
                    bidDetails={bidDetails}
                    modelopen={req => modelopen(req)}
                    connectedAccount={ConnectedAccount}
                  />
                )}
                {variant === 'topBids' && <TopBids bidDetails={bidDetails} />}
                {variant === 'history' && (
                  <History explorelist={explorelist} modelopen={req => modelopen(req)} />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {/*Looking for buying ERC-1155 token */}
      {explorelist?.nftDetails[0]?.multiToken === true && (
        <BuyMutiTokenModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          assetdata={explorelist}
        />
      )}
      {/*Looking for buying ERC-721 token */}
      {explorelist?.nftDetails[0]?.multiToken != true && (
        <BuyModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          assetdata={explorelist?.nftDetails[0]}
        />
      )}
      <BidModal
        show={bidmodalShow}
        onHide={() => setbidModalShow(false)}
        assetdata={explorelist?.nftDetails[0]}
        nftid={explorelist?.nftDetails[0]?._id}
      />
      <UnlockModal show={modalView} onHide={() => setmodalView(false)} selecteddata={NFTDetails} />
      <PutonsaleModal
        show={saleModalShow}
        onHide={() => setSaleModalShow(false)}
        selecteddata={explorelist?.nftDetails[0]}
      />
      <Footer />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <div
          className="modal-body"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
          {explorelist?.nftDetails[0]?.fileUrl.split('.')[4] == 'mp4' ? (
            <video
              width="320"
              height="240"
              controls
              src={explorelist?.nftDetails[0]?.fileUrl}
              controlsList="nodownload">
              Your browser does not support the video tag.
            </video>
          ) : explorelist?.nftDetails[0]?.fileUrl.split('.')[4] == 'mpeg' ? (
            <>
              <img
                src={img1}
                alt="Axies"
                style={{
                  width: '150px',
                  height: '150px',
                  cursor: 'pointer',
                }}
              />
              <audio controls src={explorelist?.nftDetails[0]?.fileUrl} controlsList="nodownload">
                Your browser does not support the audio element.
              </audio>
            </>
          ) : (
            <>
              <img
                src={
                  explorelist?.img
                    ? explorelist?.img
                    : explorelist?.nftDetails[0]?.fileUrl
                    ? explorelist?.nftDetails[0]?.fileUrl
                    : img1
                }
                style={{ height: '-webkit-fill-available', width: '-webkit-fill-available' }} //objectFit: 'cover'
                alt="Axies"
              />
            </>
          )}
          {/* <img
            src={
              explorelist?.img
                ? explorelist?.img
                : explorelist?.nftDetails[0]?.fileUrl
                ? explorelist?.nftDetails[0]?.fileUrl
                : img1
            }
            style={{ height: '500px', width: '500px' }} //objectFit: 'cover'
            alt="Axies"
          /> */}
        </div>
      </Modal>
    </div>
  );
};
export default NFTdetails;
