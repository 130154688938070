import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PutonsaleModal from '../../PutonsaleModal';
//import commonFunction from '../../../utils/common';
import shortenAccount from '../../../utils/shortenAccount';
import avaxLogo from '../../../assets/cryptoLogo/avalanche-avax-icon.svg';
import bnbLogo from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../../../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../../../assets/cryptoLogo/ethereum-eth-icon.svg';
const MyNft_items = props => {
  const data = props.data;
  console.log('Users MyNFT items', data);
  const [modalShow, setModalShow] = useState(false);
  const [selectedid, setselectedid] = useState();

  return (
    <Fragment>
      <section className="tf-section sc-explore-1">
        {props.progress && (
          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
            <h2>Loading...</h2>
          </div>
        )}
        <div className="themesflat-container">
          <div className="row">
            {data?.length > 0 ? (
              data.map((item, index) => (
                <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className={`sc-card-product ${item.feature ? 'comingsoon' : ''} `}>
                    <div className="card-media ">
                      <Link to={`/buy/${item?._id}`} state={item}>
                        <img src={item.fileUrl} className="cardimg_size" alt="axies" />
                      </Link>
                      {item.wishlist > 0 && (
                        <Link
                          to={`/buy/${item?._id}`}
                          state={item}
                          className="wishlist-button heart">
                          <span className="number-like">{item.wishlist}</span>
                        </Link>
                      )}
                      <div className="coming-soon">{item.feature}</div>
                    </div>
                    <div className="card-title">
                      <h5 className="style2">
                        {item.blockChainName == 'Avalanche' ? (
                          <img className="cryptoImg" src={avaxLogo} />
                        ) : item.blockChainName == 'Binance' ? (
                          <img className="cryptoImg" src={bnbLogo} />
                        ) : item.blockChainName == 'Ethereum' ? (
                          <img className="cryptoImg" src={ethLogo} />
                        ) : item.blockChainName == 'Polygon' ? (
                          <img className="cryptoImg" src={maticLogo} />
                        ) : item.blockChainName == undefined ? (
                          <img className="cryptoImg" src={ethLogo} />
                        ) : (
                          ''
                        )}
                        &nbsp;&nbsp;
                        {/* <Link to={'/buy'} state={item}> */}
                        {item.title}
                        {/* </Link> */}
                      </h5>
                      <div className="tags">{item.CategoryData.name}</div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Owned By</span>
                          <h6>{shortenAccount(item.ownerAddress)}</h6>
                        </div>
                      </div>
                      <div className="price">
                        <span>Current price</span>
                        <h5>
                          {item.price}{' '}
                          <h5>
                            {item.price} &nbsp;
                            {item.blockChainName == 'Avalanche'
                              ? 'AVAX'
                              : item.blockChainName == 'Binance'
                              ? 'BNB'
                              : item.blockChainName == 'Ethereum'
                              ? 'ETH'
                              : item.blockChainName == 'Polygon'
                              ? 'MATIC'
                              : item.blockChainName == undefined
                              ? 'ETH'
                              : ''}
                          </h5>
                        </h5>
                      </div>
                    </div>
                    <div className="card-bottom">
                      {item.putOnMarketplace ? (
                        <Link to={`/buy/${item?._id}`} state={item}>
                          {/* <button className="sc-button style bag fl-button pri-3 no-bg">
                            <span>Buy now</span>
                          </button> */}
                        </Link>
                      ) : (
                        <button
                          onClick={() => {
                            setselectedid(index);
                            setModalShow(true);
                          }}
                          className="sc-button style bag fl-button pri-3 no-bg">
                          <span>Put on sale</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : props.progress === false ? (
              <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <h2>No data found...</h2>
              </div>
            ) : (
              <></>
            )}
            {data.length > 0 &&
              props.exploretotalItems > 0 &&
              props.exploretotalItems > data.length && (
                <div className="col-md-12 wrap-inner load-more text-center">
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={() => props.increasepagenumber()}>
                    <span>Load More</span>
                  </Link>
                </div>
              )}
          </div>
        </div>
      </section>
      <PutonsaleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selecteddata={data[selectedid]}
      />
    </Fragment>
  );
};

export default MyNft_items;
