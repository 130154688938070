import React from 'react';
import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
//import config from '../lib/config';

const MessageNotifyModal = props => {
  const { networkChain } = useContext(ConnectWalletContext);
  const onHandleSwitchNetwork = async () => {
    //let web3 = window.ethereum;
    console.log('PRK logged chain id', networkChain);
    console.log('PRK chain selected address', props.chainIdAddress);
    console.log('PRK switch Chain changed', props.chainId);

    //set the chainId/ChainContractAddress based on the selected chains
    // config.NFTSingleTokenApproval = props?.chainIdAddress;
    // config.NFTContractAddress = props?.chainIdAddress;
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: props.chainId }], //passing hex value of the chainId
      });
    } catch (error) {
      if (error.code === 4902) {
        console.log('Adding chainId into metamask...starts');

        //Ethereum rinkeby test network
        if (props.chainName == 'Ethereum') {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: props.chainId, //'0x5' - 5
                  chainName: 'Goerli test network',
                  rpcUrls: ['https://goerli.infura.io/v3/'],
                  nativeCurrency: {
                    name: 'Ethereum',
                    symbol: 'GoerliETH',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://goerli.etherscan.io'],
                },
              ],
            });
          } catch (error) {
            console.log('Adding ethereum chain error', error.message);
          }
        }
        //end of Ethereum rinkeby Chain network

        //Binance test network
        if (props.chainName == 'Binance') {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: props.chainId, //'0x61', //97
                  chainName: 'Binance Smart Chain Testnet',
                  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                  nativeCurrency: {
                    name: 'BSC',
                    symbol: 'tBNB',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://testnet.bscscan.com/'],
                },
              ],
            });
          } catch (error) {
            console.log('Adding ethereum chain error', error.message);
          }
        }
        //end of Binance Chain network

        //polygon test network
        if (props.chainName == 'Polygon') {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: props.chainId, //'0x13881',
                  chainName: 'Mumbai',
                  rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
                  nativeCurrency: {
                    name: 'Polygon',
                    symbol: 'MATIC',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
                },
              ],
            });
          } catch (error) {
            console.log('Adding ethereum chain error', error.message);
          }
        } //end of polygon Chain network

        //Avalanche test network
        if (props.chainName == 'Avalanche') {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: props.chainId, //'0xa869', //43113
                  chainName: 'Avalanche Fuji Testnet',
                  rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
                  nativeCurrency: {
                    name: 'Avalanche',
                    symbol: 'AVAX',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://testnet.snowtrace.io/'],
                },
              ],
            });
          } catch (error) {
            console.log('Adding ethereum chain error', error.message);
          }
        } //end of AVAX Chain network
      } else {
        // If window.ethereum is not found then MetaMask is not installed
        // alert(
        //   'MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html',
        // );
      }

      // This error code means that the chain we want has not been added to MetaMask
      // In this case we ask the user to add it to their MetaMask
    }
    //when chain changed refresh the page with window.location
    window.ethereum.on('chainChanged', chainId => {
      //setBlockChainNetworkValue(props?.chainName);
      console.log('Chain changed', chainId);
      window.location.reload();
    });
  };

  return (
    <Modal show={props.show} onHide={props.onHide} style={{ marginTop: '20rem' }}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div
          className="modal-body space-y-20 pd-40"
          style={{ marginLeft: '4rem', marginBottom: '3rem' }}>
          <h4 style={{ fontStyle: 'italic' }}>
            {`In order to trade items, please switch "${props.chainName}" network within your MetaMask
            wallet.`}
            <br />
            <br />
            Please switch the account
          </h4>

          <button
            className="btn bitamt btn-primary ml-5"
            style={{ width: 'auto', borderRadius: '10px', color: '#FFF' }}
            onClick={onHandleSwitchNetwork}>
            Switch Network
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MessageNotifyModal;
