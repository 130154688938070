import React from 'react';
import img1 from '../../../assets/images/avatar/previewThumb.png';
import shortenAccount from '../../../utils/shortenAccount';
function AcceptBidingList({ accept, data }) {
  console.log(data);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <div className="mb-2">
          <div className="mr-3">
            {/* <Link
              to={`user/${props.item.userId}`}
              state={{ ownerAccount: shortenAccount(data.userdetails.walletAddress) }}> */}
            <img
              style={{
                height: '50px',
                width: '50px',
                borderRadius: 100,
              }}
              src={data.userdetails.userImage ? data.userdetails.userImage : img1}
              alt="Axies"
            />
            {/* </Link> */}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '10px',
          }}>
          <p>Token id: {data.tokenId}</p>
          <p>Bidding Address: {shortenAccount(data.bidderAddress)}</p>
          <p>Bid Amount: {data.price}</p>
          <hr
            style={{
              width: '100%',
              color: 'inherit',
              backgroundColor: 'currentColor',
              opacity: '0.2',
            }}
          />
        </div>
        {data.status == 'pending' && (
          <div>
            <button className="sc-button style fl-button pri-3 no-bg" onClick={() => accept(data)}>
              <span className="pl-0">Accept</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default AcceptBidingList;
