import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import 'react-tabs/style/react-tabs.css';
import img1 from '../assets/images/box-item/image-box-6.jpg';
import avt from '../assets/images/avatar/previewThumb.png';
import { FormCheck } from 'react-bootstrap';
import PyraNftMarket721 from '../ABI/PyraNftMarket.json';
import PyraNftCollection from '../ABI/PyraNftCollection.json';
import PyraMultiToken1155 from '../ABI/PyraMultiToken.json';
import loadWeb3 from '../utils/loadWeb3';
import config from '../lib/config';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import PropertyItemodal from '../components/AddPropertyItemodal';
import { _createNft, _categoryList, _fileupload, _myCollectionList } from '../services/mainApi';
import { toast } from 'react-toastify';
import commonFunction from '../utils/common';
import CreateItemModal from '../components/CreateItemModal';
import CreateCollection from '../components/CreateCollection';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MessageNotifyModal from '../modal/MessageNotifyModal';
import avaxLogo from '../assets/cryptoLogo/avalanche-avax-icon.svg';
import bnbLogo from '../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../assets/cryptoLogo/ethereum-eth-icon.svg';
//import CryptoLogo from '../utils/CryptoLogo';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import sellingOrder from '../nft/SellingNFT';
// import buyingOrder from '../nft/BuyingNFT';
const CreateItem = () => {
  let navigate = useNavigate();
  const {
    ConnectedAccount,
    setBlockChainNetworkValue,
    blockChainNetworkValue,
    networkChain,
    netWrokChainName,
    netWorkSymbol,
  } = useContext(ConnectWalletContext);
  let shortenAccount;
  if (ConnectedAccount) {
    shortenAccount = `${ConnectedAccount.slice(0, 5)}...${ConnectedAccount.slice(
      ConnectedAccount?.length - 4,
    )}`;
  }
  console.log('PRK Connected Chain contract Address from context-->', config.NFTContractAddress);
  console.log('PRK Connected  Approval contract from context-->', config.NFTSingleTokenApproval);
  console.log('PRK Connected Network Name from context-->', netWrokChainName);
  console.log('PRK Connected Network symbol from context-->', netWorkSymbol);
  console.log('PRK Connected Network ChainId from context-->', networkChain);
  commonFunction.CurrencySymbol = netWorkSymbol;
  if (netWrokChainName == 'Avalanche') commonFunction.cryptoLogo = avaxLogo;
  if (netWrokChainName == 'Binance') commonFunction.cryptoLogo = bnbLogo;
  if (netWrokChainName == 'Polygon') commonFunction.cryptoLogo = maticLogo;
  if (netWrokChainName == 'Ethereum') commonFunction.cryptoLogo = ethLogo;

  // const [isUnlockChecked, setisUnlockChecked] = useState(false);
  const [unlockContent, setunlockContent] = useState('');
  const [unlockContentFlag, setunlockContentFlag] = useState(false);
  // const [blockChainName, setblockChainName] = useState();
  //const [networkChainId, setNetworkChainId] = useState(networkChain);
  //const [chainIdSymbol, setChainIdSymbol] = useState(netWorkSymbol);
  //const [chainIdAddress, setChainIdAddress] = useState(config.NFTContractAddress);
  const [show, setShow] = useState(false);
  const [createCollection, setCreateCollection] = useState(false);
  const [showProperty, setShowProperty] = useState(false);
  // const [createProperty, setCreateProperty] = useState(false);
  //nft process
  //const [putOnSaleType] = useState('FixedPrice');
  //Note - putonSale //need to change false as initial setup
  const [putonsale, setPutOnSale] = useState(false);
  const [TokenId, setTokenId] = useState(0);
  const [TokenQuantity, setTokenQuantity] = useState(1);
  const [TokenPrice, setTokenPrice] = useState();
  const [TokenMinBidValue, setTokenMinBidValue] = useState(0);
  const [TokenName, setTokenName] = useState('');
  const [TokenDescription, setTokenDescription] = useState('');
  const [TokenRoyalties, setTokenRoyalties] = useState('');
  const [fileImage, setFileImage] = useState();
  const [uploadedfilePath, setuploadedfilePath] = useState('');
  const [TokenFile, setTokenFile] = useState('');
  //const [TokenFilePreReader, setTokenFilePreReader] = useState('');
  //const [TokenFilePreUrl, setTokenFilePreUrl] = useState('');
  //const [nftIpfsHash, setNftIpfsHash] = useState('');
  //const [ipfsHashImageurl, setIpfsHashImageurl] = useState('');
  //const [IpfsHashMetaData, setIpfsHashMetaData] = useState();
  //const [noOfcopies, setnoOfcopies] = useState('1');
  // const [buyTokenId, setBuyTokenId] = useState('');
  // const [buyTokenPrice, setBuyTokenPrice] = useState(0);
  // const [sellingTokenId, setSellingTokenId] = useState('');
  // const [sellingPrice, setSellingPrice] = useState(0);

  // const [NftTokenServiceFees, setNftTokenServiceFees] = useState(0);
  // const [NftTokenSaleOrderPrice, setNftTokenSaleOrderPrice] = useState(0);
  // const [NftTokenOwner, setNftTokenOwner] = useState('');
  // const [sellingTokenPrice, setSellingTokenPrice] = useState(0);
  const [MintTransHash, setMintTransHash] = useState();
  const [ApproveCallStatus, setApproveCallStatus] = useState('init');
  const [MintCallStatus, setMintCallStatus] = useState('init');
  //const [SignCallStatus, setSignCallStatus] = useState('init');
  // const [SignLockCallStatus, setSignLockCallStatus] = useState('init');
  // const [CollectionStatus, setCollectionStatus] = useState('init');
  // const [BuyingTokenStatus, setBuyingTokenStatus] = useState('init');
  // const [SellingTokenStatus, setSellingTokenStatus] = useState('init');
  const [categorylist, setcategorylist] = useState([]);
  const [selectedCategoryid, setselectedCategoryid] = useState('0');
  //testing
  // const [isGetNft, setIsGetNft] = useState(false);
  //const [getCollectionContract] = useState(localStorage.getItem('CollectionSymbol'));

  //need to this to get the property value
  // useEffect(() => {
  //   if (networkChain != '0x4')
  //     return toast.warning('Please connect with Ethereum rinkeby network!');
  // }, [networkChain]);

  //need to this to get the property value
  const [propertiesList, setPropertiesList] = useState([
    {
      trait_type: '',
      value: '',
    },
  ]);
  // const [selected_category_Value, setselected_category_Value] = useState('');
  const [uploadedfileURL, setuploadedfileURL] = useState();
  const [uploadedMetaURL, setuploadedMetaURL] = useState();
  const [collectionList, setcollectionList] = useState([]);
  const [selectedCollectionid, setselectedCollectionid] = useState();
  const [collectioninputdata, setcollectioninputdata] = useState('None');
  const [collectionContractAddress, setCollctionContractAddress] = useState();
  const [collectionChainName, setCollectionChainName] = useState();
  const [blockChainValue, setBlockChainValue] = useState();
  const [networkChainId, setNetworkChainId] = useState();
  const [select, setSelect] = useState('price');
  //const [chainIdSymbol, setChainIdSymbol] = useState();
  const [s1, setS1] = useState(false);
  //const [chainIdAddress, setChainIdAddress] = useState();
  const [priceValue, setPriceValue] = useState(false);
  const [fileExt, setFileExt] = useState([]);

  // const [currentPage, setcurrentPage] = useState(1);
  // const [hasMore, sethasMore] = useState(true);
  console.log(uploadedfilePath, MintTransHash);
  console.log('CollectionChainName', collectionChainName);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setS1(true);
    setPriceValue(true);
    if (
      TokenRoyalties?.length === 0 ||
      TokenPrice?.length <= 0 ||
      TokenMinBidValue?.length <= 0 ||
      TokenName?.length === 0 ||
      blockChainValue === undefined ||
      selectedCategoryid === '0' ||
      fileImage === undefined
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  const handleCollectionClose = () => setCreateCollection(false);
  const handleCreateCollection = () => setCreateCollection(true);

  //for property
  const handlePropertyClose = value => {
    setShowProperty(false);
    setPropertiesList(value);
  };
  const handlePropertyShow = () => setShowProperty(true);
  const handlepropertysave = value => {
    setPropertiesList(value);
  };
  const _handleChange = () => {
    setPutOnSale(!putonsale);
  };

  //logic to handle to switch the network chain
  const [switchShow, setSwitchShow] = useState(false);
  const handleSwitchNetworkClose = () => {
    setSwitchShow(false);
    setBlockChainValue(netWrokChainName);
  };

  //set input values
  // const priceValues = new RegExp(/^\d*[0.1-9]\d*$/);
  // const priceValues = new RegExp(/^\d*[0-9]\d*$/);
  const inputChange = e => {
    //e.preventDefault();
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      let value = e.target.value;
      switch (e.target.name) {
        case 'TokenPrice':
          if (value < 0) {
            setPriceValue(true);
            console.log('TokenPrice Enter the proper value', priceValue);
          } else {
            setTokenPrice(value);
            setPriceValue(false);
            console.log('TokenPrice', TokenPrice);
          }
          break;
        case 'TokenMinBidValue':
          // if (value !== '' && isNaN(value) === false && value > 0) {
          //   setTokenMinBidValue(value);
          //   console.log('TokenMinBidValue', TokenMinBidValue);
          // } else {
          //   console.log('Enter the proper value');
          // }
          if (value < 0) {
            console.log('TokenMinBidValue', TokenMinBidValue);
          } else {
            setTokenMinBidValue(value);
            console.log('Enter the proper value');
          }
          break;
        case 'TokenName':
          setTokenName(value);
          break;
        case 'TokenDescription':
          setTokenDescription(value);
          break;
        case 'TokenRoyalties':
          setTokenRoyalties(value);
          break;
        case 'TokenQuantity':
          setTokenQuantity(value);
          break;
        case 'unlockContent':
          setunlockContent(value);
          break;
        case 'unlockContentFlag':
          setunlockContentFlag(value);
          break;
        // case 'blockChainName':
        //   setblockChainName(value);
        //   break;
        default:
      }
    }
  };
  const [fileType, setFileType] = React.useState('image');
  const [thumbnailImage, setThumbnailImage] = React.useState();
  const onImageChange = e => {
    if (e.target && e.target.files) {
      console.log('nft Video file size', e.target.files[0].type);

      if (e.target.files[0].size > 209715200) {
        alert('File is too big!');
      } else {
        const [file] = e.target.files;
        setFileExt(e.target.files[0].type);
        console.log('File extenstion type', e.target.files[0].type, fileExt);
        localStorage.setItem('fileExt', e.target.files[0].type);
        setFileImage(URL.createObjectURL(file));
        setTokenFile(file);
        console.log(URL.createObjectURL(file), 'video create files');
        setuploadedfilePath(e.target.files[0]);
        if (e.target.files[0].type.includes('audio')) {
          setFileType('audio');
        } else if (e.target.files[0].type.includes('video')) {
          setFileType('video');
        } else {
          setFileType('image');
        }
        //call the getFileURL to get the fileURL
        //getFileUrl(file);
      }
    }
  };
  const onThumbnailImageChange = e => {
    const [file] = e.target.files;
    setThumbnailImage(URL.createObjectURL(file));
  };
  const getFileURL = async () => {
    var formData = new FormData();
    formData.append('file', TokenFile);
    formData.append('name', TokenName);
    formData.append('description', TokenDescription);
    formData.append('attributes', JSON.stringify(propertiesList));
    const fileuploadpromise = _fileupload(formData);
    fileuploadpromise
      .then(
        res => {
          console.log('url result', res);
          console.log('url result', res?.data?.error);
          if (res?.data?.error === false) {
            //get imageURL
            if (res?.data?.data?.imageURL) {
              setuploadedfileURL(res.data.data.imageURL);
              console.log('ImageUrl', res.data.data.imageURL);
            }
            //get metadataURL
            if (res?.data?.data?.metaURL) {
              setuploadedMetaURL(res.data.data.metaURL);
              console.log('metaUrl', res.data.data.metaURL);
            }
          }
        },
        err => {
          console.log('failerr', err);
        },
      )
      .catch(err => {
        console.log('File uploading error', err.message);
      });
  };

  // const handleUnlockChecked = e => {
  //   if (e.target.name === 'unlockoncepurchased') {
  //     if (isUnlockChecked === false) {
  //       setisUnlockChecked(true);
  //     } else {
  //       setisUnlockChecked(false);
  //     }
  //   }
  // };

  //handle property items
  // const createMoreProperty = () => {
  //   setCreateProperty(true);
  // };

  //------------------------------NFT PROCESS------------------------------//
  //Apprvoal all
  async function handleApproveNFTtoken() {
    setApproveCallStatus('processing');
    console.log('PRK Collection symbol', collectioninputdata);
    console.log('PRK create-item chain collection contract address', collectionContractAddress);
    let web3 = await loadWeb3();
    let nftContract;
    let approveStatus;
    let setForApprovalAddress;
    //look for the nft collection contract
    if (collectioninputdata != 'None' && collectioninputdata != undefined) {
      if (collectionChainName != netWrokChainName) {
        return toast.error(`Please connect with same collection network!${collectionChainName}`);
      }
      console.log('Inside the collection contract');
      nftContract = new web3.eth.Contract(PyraNftCollection.abi, collectionContractAddress);
      setForApprovalAddress = collectionContractAddress;
    } else if (TokenQuantity > 1) {
      //look for the nft Multi contract (erc-1155)
      console.log('Inside the Multi token contract ERC-1155');
      nftContract = new web3.eth.Contract(PyraMultiToken1155, config.NFTMultiTokenContract);
      setForApprovalAddress = config.NFTMultiTokenApproval;
    } else {
      //look for the nft single contract (erc-721)
      console.log('Inside the single contract ERC-721');
      console.log('PRK create-item chain collection contract address', config.NFTContractAddress);

      nftContract = new web3.eth.Contract(PyraNftMarket721, config.NFTContractAddress);
      setForApprovalAddress = config.NFTSingleTokenApproval;
    }
    console.log('Calling approval call');
    await nftContract.methods
      .setApproval(setForApprovalAddress, true)
      .send({
        from: ConnectedAccount,
      })
      .then(async result => {
        approveStatus = result?.events?.Approve?.returnValues?.approved;
        var tokenid = result?.events?.Approve?.returnValues[1]
          ? parseFloat(result.events.Approve.returnValues[1])
          : 0;
        if (tokenid > 0 && approveStatus == true) {
          setTokenId(tokenid);
          //call getFileUrl to get the imageUrl and metadataUrl
          await getFileURL();
        } else {
          setApproveCallStatus('tryagain');
        }
      })
      .catch(() => {
        setApproveCallStatus('tryagain');
      });
    if (approveStatus == true) {
      setApproveCallStatus('done');
    }
  }
  const callcreateNft = param => {
    const promise = _createNft(param);
    promise
      .then(
        () => {
          toast.success('NFT created successfully', commonFunction.toastsettings);
          handleClose();
          navigate('/mynft');
        },
        error => {
          toast.error(error.msg, commonFunction.toastsettings);
        },
      )
      .catch(() => {
        toast.error('Create NFT Failed.Try Again!', commonFunction.toastsettings);
      });
  };
  //Mint NFT
  const handleMintNFTtoken = async () => {
    setMintCallStatus('processing');
    let web3 = await loadWeb3();
    let blockNumber;
    console.log('putonsale flag', putonsale);
    let TokenPriceInStr = 0;
    if (putonsale === true && select === 'price') {
      TokenPriceInStr = web3.utils.toWei(TokenPrice.toString(), 'ether');
    }
    console.log('Create Item TokenId', TokenId);
    console.log('Create Item TokenName', TokenName);
    console.log('Create Item nftIpfsHash', uploadedfileURL);
    console.log('Create Item ipfshash url', uploadedMetaURL);
    console.log('Create Item TokenQuantity', TokenQuantity);
    console.log('Create Item TokenRoyalties', TokenRoyalties);
    console.log('Create Item TokenPriceInStr', TokenPriceInStr);
    console.log('Create Item propertylist', propertiesList);
    console.log('Create Item TokenDescription', TokenDescription);
    console.log('Create Item upload file path', uploadedfilePath);
    console.log('Create Item upload file type,', fileExt);
    //console.log('Tpken Number of count', noOfcopies);

    //call the minToken contract
    let nftContract;
    //look for the nft collection contract
    //Mint only when tokenId is generated/existed
    console.log('PRK create-item chain contract address', config.NFTContractAddress);
    if (TokenId != undefined && TokenId > 0) {
      if (collectioninputdata != 'None' && collectioninputdata != undefined) {
        console.log('Inside the collection contract address', collectionContractAddress);
        nftContract = new web3.eth.Contract(PyraNftCollection.abi, collectionContractAddress);
      } else if (TokenQuantity > 1) {
        //look for the nft multi token contract
        console.log('Inside the Multi token contract minting for erc-1155');
        nftContract = new web3.eth.Contract(PyraMultiToken1155, config.NFTMultiTokenContract);
      } else {
        //look for the nft single token contract
        console.log('Inside the single contract minting for erc-721');
        nftContract = new web3.eth.Contract(PyraNftMarket721, config.NFTContractAddress);
      }
      await nftContract.methods
        .mint(
          TokenId, //TokenId
          TokenName, //name
          uploadedfileURL, //imageUrl
          uploadedMetaURL, //metaDataUrl
          TokenPriceInStr, //value price
          TokenQuantity, //supply TokenQuantity
          TokenRoyalties, //royalty TokenRoyalties
        )
        .send({
          from: ConnectedAccount,
          //gas: 3000000
        })
        .then(async result => {
          var transactionHash = '';
          blockNumber = result?.events?.Transfer?.blockNumber
            ? result?.events?.Transfer?.blockNumber
            : result?.events?.TransferSingle?.blockNumber;
          if (result && result.transactionHash && blockNumber != undefined) {
            transactionHash = result.transactionHash;
            setMintTransHash(transactionHash);
            //Call API to post/create NFT items
            callcreateNft({
              fileUrl: uploadedfileURL,
              title: TokenName,
              description: TokenDescription,
              price: TokenPrice,
              categoryId: selectedCategoryid,
              collectionId: selectedCollectionid,
              putOnMarketplace: putonsale,
              noOfCopies: TokenQuantity,
              properties: propertiesList,
              metadataUrl: uploadedMetaURL,
              tokenId: TokenId,
              minBidValue: TokenMinBidValue,
              isBid: TokenMinBidValue > 0 ? true : false,
              multiToken: TokenQuantity > 1 ? true : false,
              blockChainName: blockChainValue,
              unlockContent: unlockContent, //changes ranjith
              unlockContentFlag: unlockContentFlag,
            });
          } else {
            toast.error('Create NFT Failed.Try Again!', commonFunction.toastsettings);
            setMintCallStatus('tryagain');
          } //end of if tx hash check
        })
        .catch(error => {
          console.log(error);
          setMintCallStatus('tryagain');
        });
      if (blockNumber) {
        setMintCallStatus('done');
      }
    } else {
      toast.error('Not able to mint check the approval status');
      setMintCallStatus('tryagain');
    }
  };
  //commented for timly testing
  // async function handleSignCall() {
  //   let web3 = await loadWeb3();
  //   setSignCallStatus('processing');
  //   web3.eth.personal
  //     .sign('Sign Sell Order', ConnectedAccount)
  //     .then(async result => {
  //       console.log(result);
  //       setSignCallStatus('done');
  //     })
  //     .catch(() => {
  //       setSignCallStatus('tryagain');
  //     });
  // }
  //---------------------------END OF NFT PROCESS-------------------------//

  // const onClickGetNft = () => {
  //   setIsGetNft(true);
  // };

  const find_category = () => {
    let value = categorylist.find(function (element) {
      return element.categoryId === selectedCategoryid;
    })?.name;
    return value ? value : 'Select...';
  };
  // const find_collection = () => {
  //   let value = collectionList.find(function (element) {
  //     return element._id === selectedCollectionid;
  //   })?.name;
  //   return value ? value : '';
  // };
  const listcollection = () => {
    const promise = _myCollectionList(1);
    promise.then(
      res => {
        if (res?.data?.error) {
          setcollectionList([]);
          // sethasMore(false);
        } else {
          let resdata = res?.data?.data?.collectionList ? res.data.data.collectionList : [];
          let data = [
            {
              _id: 0,
              symbol: 'None',
            },
          ];
          resdata?.length > 0 ? (data = [...resdata]) : (data = [...collectionList]);
          let uniqueData = [
            ...data.reduce((map, obj) => map.set(obj._id, obj), new Map()).values(),
          ];
          setcollectionList(uniqueData);
          console.log('collection data', uniqueData);
          // res?.data?.totalItems > res?.data?.currentPage * res?.data?.pageSize && sethasMore(true);
          // setcurrentPage(res?.data?.currentPage);
        }
      },
      () => {
        setcollectionList([]);
        // sethasMore(false);
      },
    );
  };
  //console.log(find_category, 'find_category');
  useEffect(() => {
    const promise = _categoryList();
    promise.then(
      res => {
        !res.error && setcategorylist([...res.data.data]);
      },
      () => {
        console.log('fail');
      },
    );
    //listcollection();
    setBlockChainNetworkValue(blockChainValue);
    //setBlockChainNetwork(blockChainValue);
    console.log(blockChainNetworkValue);
  }, [blockChainValue]);

  useEffect(() => {
    listcollection();
  }, [ConnectedAccount, networkChainId]);

  const addProperty = () => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="col">
          <div className="row-form style-3">
            <div className="p-5">
              <div className="flex flex-col justify-start items-center">
                <div className="row">
                  <div className="flex-col">
                    <h3 className="title-create-item">Properties</h3>
                    <p>Add item properties</p>
                  </div>
                  <div className="ml-4">
                    <button type="button" className="createProperty" onClick={handlePropertyShow}>
                      <h2>+</h2>
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="flex-col ">
                <div
                  style={{ marginTop: '' }}
                  className=" float-left flex justify-center item-center text-right">
                  <h5 className="float-right">Unlockable Content</h5>
                  <input
                    className="ml-5 "
                    type="checkbox"
                    id="unlockoncepurchased"
                    name="unlockoncepurchased"
                    onClick={() => {
                      setunlockContentFlag(!unlockContentFlag);
                    }}
                  />
                  <span className="slider"></span>
                </div>
              </div> */}
            </div>
            {unlockContentFlag ? (
              <div className="mt-5">
                <h6>Content will be unlocked after successful transaction</h6>
                <br />
                <input
                  type="text"
                  id="unlockContent"
                  onChange={inputChange}
                  name="unlockContent"
                  placeholder="Enter Content(access Key,code to redeem,link to a file,etc.)"
                />
                {/* <p>Tip: Markdown syntax is supported</p> */}
              </div>
            ) : (
              ''
            )}
            <div className="row col" style={{ display: 'flex', flexBasis: 'content' }}>
              {propertiesList?.filter(o => o?.trait_type && o?.value)?.length > 0 &&
                propertiesList
                  ?.filter(o => o.trait_type && o.value)
                  .map((property, index) => {
                    return (
                      <div
                        className="col-md-4 col-lg-4 col-sm-6"
                        key={`${index}prop`}
                        style={{ width: '20rem' }}>
                        <div
                          className="ml-2 propertylists"
                          style={{
                            padding: 'inherit',
                            width: '12rem',
                            justifyContent: 'space-between',
                          }}>
                          <h3
                            className="title-create-item text-center"
                            style={{ fontSize: '2rem' }}>
                            {property?.trait_type}
                          </h3>
                          <p className="text-center" style={{ fontSize: '2rem', color: 'cyan' }}>
                            {property?.value}
                          </p>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </>
    );
  };
  const BlockChainList = [
    {
      id: 1,
      coin: 'Avalanche',
      chainId: '0xa869', //43113,
      coinLogo: <img className="cryptoImg" src={avaxLogo} />,
    },
    {
      id: 2,
      coin: 'Ethereum',
      chainId: '0x5', //4
      coinLogo: <img className="cryptoImg" src={ethLogo} />,
    },
    {
      id: 3,
      coin: 'Polygon',
      chainId: '0x13881', //80001
      coinLogo: <img className="cryptoImg" src={maticLogo} />,
    },
    {
      id: 4,
      coin: 'Binance',
      chainId: '0x61', //97
      coinLogo: <img className="cryptoImg" src={bnbLogo} />,
    },
  ];

  const BlockChainSelect = () => {
    return (
      <div className="row-form style-3 blockChain mg-bt-24">
        <div className="inner-row-form">
          <h4 className="title-create-item">
            BlockChain<span className="required">*</span>
          </h4>
          <div className="tf-soft">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                {blockChainValue?.length === undefined ? 'Select BlockChain' : blockChainValue}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ overflow: 'scroll' }}>
                {/* <InfiniteScroll
                  dataLength={collectionList.length}
                  next={onsearchcollection(currentPage + 1)}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  height={400}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }> */}
                {BlockChainList.map((menu, index) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      key={`${index}+collection`}
                      value="ko"
                      onClick={() => {
                        setBlockChainValue(menu?.coin);
                        console.log(
                          'PRK Switch the nework for chain connected in wallet',
                          netWrokChainName,
                        );
                        console.log(
                          'PRK Switch the nework for chain connected in application',
                          menu?.coin,
                        );
                        if (menu?.coin != netWrokChainName) {
                          //call the modal to notify to switch network Prabha
                          setSwitchShow(true);
                          setNetworkChainId(menu?.chainId);
                        }
                      }}>
                      <div className="sort-filter active">
                        <span>
                          {menu?.coinLogo} &nbsp;
                          {menu?.coin}
                        </span>
                      </div>
                    </Dropdown.Item>
                  );
                })}
                {/* </InfiniteScroll> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {s1 && (
            <span className="required-font">
              {blockChainValue === undefined ? 'Select Blockchain' : ''}
            </span>
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    setBlockChainValue(netWrokChainName);
  }, [netWrokChainName]);

  const titledes = () => {
    return (
      <>
        <div className="description-blockchain-div">
          <div className="description-div">
            <h4 className="title-create-item">
              Name<span className="required">*</span>
            </h4>
            <input
              type="text"
              placeholder="Item Name"
              onChange={inputChange}
              id="TokenName"
              name="TokenName"
              required
            />
            {s1 && (
              <span className="required-font">
                {TokenName?.length === 0 ? 'Enter Token Name' : ''}
              </span>
            )}
          </div>

          {/* <div className="blockchain-category-div">{BlockChainSelect()}</div> */}
        </div>

        <h4 className="title-create-item">Description</h4>
        <textarea
          placeholder="Provide a detailed description of your item."
          onChange={inputChange}
          id="TokenDescription"
          name="TokenDescription"></textarea>
      </>
    );
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <div id="item-create" className="dropdown" ref={ref}>
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}>
          {children}
        </Link>
      </div>
    );
  });
  CustomToggle.displayName = 'CustomToggle';
  const categorydropdown = () => {
    return (
      <div className="inner-row-form">
        <h4 className="title-create-item">
          Category<span className="required">*</span>
        </h4>
        <div className="tf-soft">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              {find_category()}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ overflow: 'scroll' }}>
              {categorylist.length > 0 &&
                categorylist.map((menu, index) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      key={`${index}+category`}
                      onClick={() => setselectedCategoryid(menu.categoryId)}>
                      <div className="sort-filter active">
                        <span>{menu.name}</span>
                      </div>
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {s1 && (
          <span className="required-font">
            {selectedCategoryid === '0' ? 'Select Category' : ''}
          </span>
        )}
      </div>
    );
  };

  const onSelectCollection = collectionSelectChain => {
    console.log('PRKS selecting collection network', collectionChainName);
    console.log('PRKS connected network', netWrokChainName);
    if (collectionSelectChain != undefined) {
      if (collectionSelectChain != netWrokChainName) {
        return toast.error(
          `Please choose the same collection network as - ${collectionSelectChain}`,
        );
      }
    }
  };

  const collectiondropdown = () => {
    return (
      <div className="row-form style-3 mg-bt-24">
        <div className="inner-row-form">
          <h4 className="title-create-item">Choose Collection</h4>
          <div className="tf-soft">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                {collectioninputdata}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ overflow: 'scroll', height: '20rem' }}>
                {/* <InfiniteScroll
                  dataLength={collectionList.length}
                  next={onsearchcollection(currentPage + 1)}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  height={400}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }> */}
                {collectionList?.length > 0 &&
                  collectionList?.map((menu, index) => {
                    console.log(menu, ' menu');
                    return (
                      <Dropdown.Item
                        href="#"
                        key={`${index}+collection`}
                        onClick={() => {
                          setselectedCollectionid(menu._id);
                          setcollectioninputdata(menu.symbol);
                          setCollctionContractAddress(menu?.address);
                          setCollectionChainName(menu?.blockChainName);
                          onSelectCollection(menu?.blockChainName);
                        }}>
                        {' '}
                        <div className="sort-filter active">
                          <span>
                            {menu.name} - {menu?.blockChainName}
                          </span>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                {/* </InfiniteScroll> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>{' '}
        </div>
      </div>
    );
  };
  const royalties = () => {
    return (
      <div>
        <h4 className="title-create-item">
          Royalties<span className="required">*</span>
        </h4>
        <input
          type="text"
          placeholder="Enter royalty, default value 2"
          onChange={inputChange}
          id="TokenRoyalties"
          name="TokenRoyalties"
        />
        {s1 && (
          <span className="required-font">
            {TokenRoyalties?.length === 0 ? 'Enter Royalities' : ''}
          </span>
        )}
      </div>
    );
  };

  const noOfTokens = () => {
    return (
      <div>
        {TokenQuantity > 1 ? (
          <h4 className="title-create-item" style={{ marginTop: '9px' }}>
            Number of counts
          </h4>
        ) : (
          <h4 className="title-create-item">Number of counts</h4>
        )}
        {/* <h4 className="title-create-item">Number of counts</h4> */}
        <input
          type="number"
          placeholder="1"
          min="1"
          id="TokenQuantity"
          name="TokenQuantity"
          onChange={inputChange}
        />

        {TokenQuantity > 1 ? <h6 style={{ fontSize: '9px' }}>Entering Multi Token</h6> : ''}
      </div>
    );
  };
  return (
    <div className="create-item">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Create Item</h1>
              </div>
              {/* <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Pages</Link></li>
                                    <li>Create Item</li>
                                </ul>
                            </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <h4 className="title-create-item">Preview item</h4>
              <div className="sc-card-product">
                <div className="card-media">
                  {fileType === 'image' ? (
                    <img src={fileImage ? fileImage : img1} alt="Axies" />
                  ) : (
                    <>
                      {fileType === 'audio' && (
                        <div>
                          <audio
                            controls
                            src={fileImage}
                            type="audio/mp3"
                            controlsList="nodownload"
                            style={{ width: '100%' }}>
                            Your browser does not support the audio element.
                          </audio>
                          <div style={{ height: '250px' }}>
                            <img
                              src={thumbnailImage ? thumbnailImage : img1}
                              alt="Axies"
                              style={{
                                marginTop: '10px',
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {fileType === 'video' && (
                        <div>
                          {/* width="320" height="240" */}
                          <video controls style={{ width: '100%' }}>
                            <source src={fileImage} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          <div style={{ height: '200px' }}>
                            <img
                              src={thumbnailImage ? thumbnailImage : img1}
                              alt="Axies"
                              style={{
                                marginTop: '10px',
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {/* </Link> */}
                  {/* <Link to="/login" className="wishlist-button heart">
                    <span className="number-like"> 100</span>
                  </Link> */}
                  {/* <div className="featured-countdown">
                    <span className="slogan"></span>
                    <Countdown date={Date.now() + 500000000}>
                      <span>You are good to go!</span>
                    </Countdown>
                  </div> */}
                </div>
                <div>
                  {putonsale ? (
                    <p>
                      Price : {TokenPrice} {netWorkSymbol}
                    </p>
                  ) : (
                    ''
                  )}
                </div>

                <div className="card-title" style={{ fontSize: '3rem' }}>
                  <p>{TokenName}</p>

                  {/* <div className="tags">bsc</div> */}
                </div>
                <div className="meta-info">
                  <div className="author">
                    <div className="avatar">
                      <img src={avt} alt="Axies" />
                    </div>

                    <div className="info">
                      <span>Owned By</span>

                      <h6>
                        <Link to="/mynft">{shortenAccount}</Link>
                        <img
                          className="cryptoImg"
                          style={{ marginLeft: '100px' }}
                          src={commonFunction.cryptoLogo}
                        />
                      </h6>
                    </div>
                  </div>
                  {/* <div className="price">
                    <span>Current Bid</span>
                    <h5> 4.89 ETH</h5>
                  </div> */}
                </div>
                {/* <div className="card-bottom">
                  <Link to="/wallet-connect" className="sc-button style bag fl-button pri-3">
                    <span>Place Bid</span>
                  </Link>
                  <Link to="/activity-01" className="view-history reload">
                    View History
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-xl-8 col-lg-6 col-md-12 col-12">
              {/* <div className="col-xl-9 col-lg-6 col-md-12 col-12"> */}
              <div className="form-create-item tab-create-item">
                <form action="#">
                  <div className="blockchain-category-div">{BlockChainSelect()}</div>
                  <h4 className="title-create-item">
                    Upload file<span className="required">*</span>
                  </h4>
                  <label className="uploadFile">
                    <span className="filename">PNG, JPG, GIF, WEBP Max 200mb.</span>
                    <input
                      type="file"
                      className="inputfile form-control"
                      name="file"
                      accept="image/png, image/jpg ,image/gif, image/jpeg, image/webp, video/mp4 ,audio/mp3,"
                      onChange={onImageChange}
                    />
                  </label>
                  {fileType != 'image' && (
                    <div>
                      <h4 className="title-create-item">
                        Upload Preview Thumbnail<span className="required">*</span>
                      </h4>
                      <label className="uploadFile">
                        <span className="filename">PNG, JPG, GIF, WEBP Max 200mb.</span>
                        <input
                          type="file"
                          className="inputfile form-control"
                          name="file"
                          accept="image/png, image/jpg ,image/gif, image/jpeg, image/webp, video/mp4 ,audio/mp3,"
                          onChange={onThumbnailImageChange}
                        />
                      </label>
                    </div>
                  )}
                  {s1 && (
                    <span className="required-font">
                      {fileImage === undefined ? 'Upload Your File' : ''}
                    </span>
                  )}
                  <div className="row-form style-3">
                    <div className=" flex justify-center item-center text-right create-coll">
                      <div className="text-center">
                        <h4 className="title-create-item mr-3">Create Collection</h4>
                      </div>
                      <button
                        type="button"
                        className="createProperty"
                        onClick={handleCreateCollection}>
                        <h2>+</h2>
                      </button>
                    </div>
                    <div className="flex-col ">
                      <div className=" flex justify-content-end item-center text-right">
                        <h4 className="title-create-item">Put on marketplace</h4>
                        <div className="ml-5 mt-1">
                          <FormCheck custom type="switch">
                            <FormCheck.Input onChange={() => _handleChange()} checked={putonsale} />
                          </FormCheck>
                        </div>
                      </div>
                      {/*Note - Commented for timebeing need to work on in next phase */}
                      {/* <div className=" flex justify-center item-center text-right">
                        <h5>Unlock Once Purchased</h5>
                        <input
                          className="ml-3"
                          type="checkbox"
                          id="unlockoncepurchased"
                          name="unlockoncepurchased"
                          onClick={handleUnlockChecked}
                        />
                        <span className="slider"></span>
                      </div>
                      <h6>
                        Content will be unlocked after
                        <br /> successful transaction
                      </h6>
                      {isUnlockChecked ? (
                        <div className="md-10">
                          <input
                            type="text"
                            id="UnLockcontent"
                            onChange={inputChange}
                            name="UnLockcontent"
                            placeholder="enter"
                          />
                          <p>Tip: Markdown syntax is supported</p>
                        </div>
                      ) : (
                        ''
                      )} */}
                    </div>
                  </div>
                  {collectiondropdown()}{' '}
                </form>

                {putonsale && (
                  <div className="flat-tabs tab-create-item ">
                    <h4 className="title-create-item">Select method</h4>
                    <div className="col-xl-8  col-md-12 col-12">
                      <div className="row ">
                        <div
                          className={select === 'price' ? 'tab-change-color' : 'tab-change '}
                          onClick={() => setSelect('price')}>
                          <span className="icon-fl-tag black"></span>&nbsp;
                          <p className="black">Fixed Price</p>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                          className={select === 'bids' ? 'tab-change-color' : 'tab-change '}
                          onClick={() => setSelect('bids')}>
                          <span className="icon-fl-icon-22 black"></span>&nbsp;
                          <p className="black">Open For Bids</p>
                        </div>
                      </div>
                    </div>
                    {/*Note - Commented for time being need to work on next phase */}
                    {/* <Tab>
                          <span className="icon-fl-clock"></span>Time Auctions
                        </Tab> */}
                  </div>
                )}
                <form action="#" className="mg-bt-24">
                  {titledes()}
                  {putonsale && select === 'price' && (
                    <>
                      <h4 className="title-create-item">Price</h4>
                      <input
                        type="number"
                        placeholder="Enter price for one item"
                        onChange={inputChange}
                        id="TokenPrice"
                        name="TokenPrice"
                        defaultValue={TokenPrice}
                      />
                      {/* {s1 ? (
                        <p className="required-font">TokenPrice Enter the proper value</p>
                      ) : (
                        ''
                      )} */}
                      {/* {s1 && (
                        <span className="required-font">
                          {TokenPrice?.length === 0 ? 'TokenPrice Enter the proper value' : ''}
                        </span>
                      )} */}
                      {s1 && (
                        <span className="required-font">
                          {TokenPrice?.length <= 0 ? 'TokenPrice Enter the proper value' : ''}
                        </span>
                      )}
                    </>
                  )}

                  {select === 'bids' && (
                    <>
                      <h4 className="title-create-item">Minimum bid</h4>
                      <input
                        type="number"
                        placeholder="Enter minimum bid"
                        onChange={inputChange}
                        id="TokenMinBid"
                        name="TokenMinBidValue"
                        // defaultValue={TokenMinBidValue}
                      />
                      {/* {TokenMinBidValue?.length === 0 ? (
                        <p className="required-font">TokenPrice Enter the proper value</p>
                      ) : (
                        ''
                      )} */}
                      {s1 && (
                        <span className="required-font">
                          {TokenMinBidValue?.length <= 0 ? 'TokenPrice Enter the proper value' : ''}
                        </span>
                      )}
                    </>
                  )}
                  <div className="row-form style-3 mg-bt-24">
                    <div className="inner-row-form ">{royalties()}</div>
                    <div className="inner-row-form p-4">{noOfTokens()}</div>
                    {categorydropdown()}
                  </div>
                  {addProperty()}
                </form>
                {/*Note - Commented for time being need to work on next phase */}
                {/* <TabPanel>
                  <form action="#">
                    {titledes()}
                    <h4 className="title-create-item">Minimum bid</h4>
                    <input
                      type="number"
                      placeholder="Enter minimum bid"
                      onChange={inputChange}
                      id="TokenMinBid"
                      name="TokenMinBid"
                    />
                    <div className="row-form style-3 mg-bt-24">
                      <div className="inner-row-form">{royalties()}</div>
                      <div className="inner-row-form">{noOfTokens()}</div>
                      {categorydropdown()}
                    </div>
                    {addProperty()}
                  </form>
                </TabPanel> */}
                {/* <TabPanel>
                        <form action="#">
                          <h4 className="title-create-item">Price</h4>
                          <input
                            type="number"
                            placeholder="Enter price for one item (ETH)"
                            onChange={inputChange}
                            id="TokenPrice"
                            name="TokenPrice"
                          />
                          <h4 className="title-create-item">Minimum bid</h4>
                          <input
                            type="number"
                            placeholder="Enter minimum bid"
                            onChange={inputChange}
                            id="MinimumBid"
                            name="MinimumBid"
                          />
                          <div className="row">
                            <div className="col-md-6">
                              <h5 className="title-create-item">Starting date</h5>
                              <input
                                type="date"
                                name="bid_starting_date"
                                id="bid_starting_date2"
                                className="form-control"
                                min="1997-01-01"
                              />
                            </div>
                            <div className="col-md-6">
                              <h4 className="title-create-item">Expiration date</h4>
                              <input
                                type="date"
                                name="bid_expiration_date"
                                id="bid_expiration_date2"
                                className="form-control"
                              />
                            </div>
                          </div>
                          {titledes()}
                          <h4 className="title-create-item">Title</h4>
                          <input
                            type="text"
                            placeholder="Item Name"
                            onChange={inputChange}
                            id="TokenName"
                            name="TokenName"
                          />
                          <h4 className="title-create-item">Description</h4>
                          <textarea placeholder="e.g. “This is very limited item”"></textarea>
                        </form>
                      </TabPanel> */}
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="sc-button fl-button pri-3 no-bg"
                  onClick={() => handleShow()}>
                  <span className="create-item-btn" style={{ paddingLeft: '0px' }}>
                    Create Item
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateItemModal
        show={show}
        handleClose={() => handleClose()}
        handleApproveNFTtoken={() => handleApproveNFTtoken()}
        handleMintNFTtoken={() => handleMintNFTtoken()}
        // handleSignCall={() => handleSignCall()}
        // SignCallStatus={SignCallStatus}
        putonsale={putonsale}
        MintCallStatus={MintCallStatus}
        ApproveCallStatus={ApproveCallStatus}
      />
      {/*Modal to create the collection*/}
      <CreateCollection
        handleCollectionClose={() => handleCollectionClose()}
        createCollection={createCollection}
        listcollection={() => listcollection()}
      />
      {/*Modal to create the property items*/}
      <PropertyItemodal
        show={showProperty}
        data={propertiesList}
        onHide={value => handlePropertyClose(value)}
        handlepropertysave={value => handlepropertysave(value)}
      />
      <MessageNotifyModal
        show={switchShow}
        onHide={handleSwitchNetworkClose}
        chainName={blockChainValue}
        chainId={networkChainId}
        chainIdAddress={config.NFTContractAddress}
      />
      <Footer />
    </div>
  );
};

export default CreateItem;
