import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { checkProperties } from '../utils/helpers';

export default function AddPropertyItemodal(props) {
  const [propertyList, setpropertyList] = useState([...props.data]);

  const handleServiceAdd = () => {
    setpropertyList([
      ...propertyList,
      {
        trait_type: '',
        value: '',
      },
    ]);
  };
  const handlepropertyinput = (value, index, key) => {
    setpropertyList(prevData => {
      let temp = prevData;
      let newArr = [...temp]; // copying the old datas array
      newArr[index][key] = value;
      return newArr;
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide(propertyList.filter(o => o.trait_type && o.value));
        // setpropertyList([
        //   {
        //     trait_type: '',
        //     value: '',
        //   },
        // ]);
      }}
      animation={false}
      centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div>
            <h2 htmlFor="service">Add Properties</h2>
            <p>Properties show up underneath your collection&apos;s sidebar.</p>
            {propertyList?.length > 0 &&
              propertyList.map((singleService, index) => (
                <div key={index} className="services">
                  <div className="mg-bt-10">
                    <div className="row">
                      <div className="col-md-5">
                        {index === 0 ? (
                          <>
                            <h5 className="title-create-item">Type</h5>
                          </>
                        ) : (
                          ''
                        )}
                        <input
                          name="trait_type"
                          type="text"
                          id="service"
                          className="form-control"
                          value={propertyList[index].trait_type}
                          onChange={e => handlepropertyinput(e.target.value, index, 'trait_type')}
                        />
                      </div>
                      <div className="col-md-5">
                        {index === 0 ? (
                          <>
                            <h5 className="title-create-item">Name</h5>
                          </>
                        ) : (
                          ''
                        )}
                        <input
                          name="value"
                          type="text"
                          id="service"
                          className="form-control"
                          value={propertyList[index].value}
                          onChange={e => handlepropertyinput(e.target.value, index, 'value')}
                        />
                      </div>
                      {index !== 0 && (
                        <div
                          className="col-md-2"
                          style={{ textAlign: 'center', alignSelf: 'center' }}>
                          <div
                            onClick={() => {
                              setpropertyList(prevData => {
                                let temp = prevData;
                                temp.splice(index, 1);
                                return temp;
                              });
                              props.handlepropertysave(
                                propertyList.filter(o => o.trait_type && o.value),
                              );
                            }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                              <path
                                fillRule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>

                    {propertyList?.length - 1 === index && (
                      <button
                        type="button"
                        disabled={checkProperties(propertyList[index])}
                        onClick={() => handleServiceAdd()}
                        className="sc-button style bag fl-button pri-3 no-bg mt-4">
                        <span>Add more</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            <div className="text-center">
              <button
                type="button"
                className="sc-button"
                onClick={() => {
                  props.onHide(propertyList.filter(o => o.trait_type && o.value));
                  // props.handlepropertysave());
                }}>
                <span>Save</span>
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
