// const { loadWeb3 } = require('./loadWeb3');
import loadWeb3 from '../utils/loadWeb3';
// import tokenABI from './DooDevToken.json';
const tokenContractABI = require('./DooDevToken.json');

const WalletBalance = async account => {
  const web3 = await loadWeb3();
  //call the below function to get the given token balance
  const getTokenBalance = async (account, contractAddress) => {
    const contract = new web3.eth.Contract(tokenContractABI, contractAddress);
    let tokenBalance = await contract.methods.balanceOf(account).call();
    return tokenBalance;
  };
  //list the token address
  const tokenObj = {
    DooDev: '0x38B1BBe45F319b0A8ddC92327D3E5E2DAAabD385',
    MATIC: '0x0000000000000000000000000000000000001010',
    USDT: '0xe583769738b6dd4E7CAF8451050d1948BE717679',
    USDC: '0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747',
  };
  //get the Matic token balance
  let maticBalance = await getTokenBalance(account, tokenObj.MATIC);
  //get the USDC token balance
  let DooDevBalance = await getTokenBalance(account, tokenObj.DooDev);
  //get the USDT token balance
  let usdtBalance = await getTokenBalance(account, tokenObj.USDT);
  //get the USDC token balance
  let usdcBalance = await getTokenBalance(account, tokenObj.USDC);
  return {
    maticBalance: maticBalance / 10 ** 18,
    DooDevBalance: DooDevBalance / 10 ** 18,
    usdtBalance: usdtBalance / 10 ** 18,
    usdcBalance: usdcBalance / 10 ** 18,
  };
};
//call the below function to get the wallet DooDev token balance
// WalletBalance().then(result => console.log(result));

export default WalletBalance;
// exports.WalletBalance = WalletBalance
