import React from 'react';

export default function History(props) {
  return (
    <div>
      <div className={`sc-card-product explode style2 mg-bt`}>
        <div className="card-media"></div>
        <div className="card-title ml-3">
          <div className="row">
            <div className="infor-list mr-4"></div>
            <div>
              <h6 className="text-center">No active bids yet... Be the first to make a bid!</h6>
              <button
                className="sc-button style fl-button pri-3 no-bg mt-4 mr-4"
                onClick={() => props.modelopen('buy')}>
                <span className="pl-0">Buy now</span>
              </button>
              <button
                className="sc-button style fl-button pri-3 no-bg mt-4"
                onClick={() => props.modelopen('bubity')}>
                <span className="pl-0">Bid now</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
