import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap-accordion';
import { _categoryList } from '../../../services/mainApi';

const SideBar = props => {
  const [dataCate, setdataCate] = useState([]);
  const names = [
    {
      id: 0,
      field: 'All',
    },
    {
      id: 1,
      field: 'Avalanche',
    },
    {
      id: 2,
      field: 'Ethereum',
    },
    {
      id: 3,
      field: 'Polygon',
    },
    {
      id: 4,
      field: 'Binance',
    },
  ];
  const categoryListHere = () => {
    _categoryList().then(res => {
      const data = [
        {
          id: 1234,
          name: 'All',
        },
      ];
      res?.data?.data?.map(item =>
        data.push({
          id: item.categoryId,
          name: item.name,
        }),
      );
      setdataCate(data);
    });
  };
  console.log('sidebar', dataCate);
  React.useEffect(() => {
    categoryListHere();
  }, []);
  // const handleClick = (title, field, checked) => {
  //   setdataCate(a =>
  //     a.map(i1 =>
  //       i1.title === title
  //         ? {
  //             title: i1.title,
  //             content: i1.content.map(i2 =>
  //               i2.field === field
  //                 ? { field: i2.field, checked: !i2.checked }
  //                 : field === 'All' && checked == false
  //                 ? { field: i2.field, checked: false }
  //                 : i2,
  //             ),
  //           }
  //         : i1,
  //     ),
  //   );
  // };
  // const [catVal, setCatVal] = React.useState([]);
  // const handleClick = e => {
  //   catVal.includes(e)
  //     ? setCatVal(catVal.filter(element => element !== e))
  //     : setCatVal([...catVal, e]);
  // };
  // React.useEffect(() => {
  //   // const arr = [];
  //   // dataCate.map(i1 => {
  //   //   i1?.content?.map(i2 => {
  //   //     if (i2.checked === true) {
  //   //       arr.push(i2.field);
  //   //       return i2;
  //   //     }
  //   //   });
  //   // });
  //   props.setFilter(catVal);
  // }, [catVal]);
  // console.log('datas', i2);

  return (
    <div id="side-bar" className="side-bar style-3 item">
      <div className="widget widget-filter style-1 mgbt-0">
        <div className="header-widget-filter">
          <h4 className="title-widget">Filter</h4>
        </div>
      </div>
      <div className="divider"></div>
      <div className="wrap-category">
        <div className="widget widget-category boder-bt">
          <Accordion title="Categories" show={true}>
            <form action="#">
              {dataCate.map(itemm => (
                <div key={itemm.id}>
                  <label>
                    <span
                      onClick={() => {
                        props.setSelectCategory(itemm.id),
                          props.setSelectCoin(''),
                          props.setcurrentCategoryPage(1),
                          console.log(itemm.id, 'looo');
                      }}>
                      {itemm.name}
                    </span>
                    {/* <span className="pst-re">
                      <input
                        type="checkbox"
                        onChange={() => props.setSelectCategory(itemm.name)}
                        // checked={itemm.checked}
                        // checked={catVal.indexOf(itemm?.name) > -1}
                      />
                      <span className="btn-checkbox"></span>
                    </span> */}
                  </label>
                  <br />
                </div>
              ))}
            </form>
          </Accordion>
          <Accordion title="Currencies" show={true}>
            <form action="#">
              {names.map(itemm => (
                <div
                  key={itemm.id}
                  onClick={() => {
                    props.setSelectCoin(itemm.field);
                    props.setcurrentCategoryPage(1), props.setSelectCategory('');
                  }}>
                  <label className="currencylabel">
                    <span>{itemm.field}</span>
                  </label>
                  <br />
                </div>
              ))}
            </form>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
