import React from 'react';
import * as Mui from '@mui/material';
import WalletCreation from '../../../wallet/WalletCreation';

const CreateAccount = props => {
  const [accountName, setAccountName] = React.useState('');
  const WalletCreationHere = async () => {
    let WalletCreate;
    try {
      WalletCreate = await WalletCreation('test!', accountName);
      console.log('WalletDetails', WalletCreate);
      props.setCreateAccountDetails(WalletCreate);
      props.setWalletMode({
        send: false,
        create: false,
        import: false,
      });
    } catch (err) {
      console.log('WalletDetails', err);
    }
  };
  return (
    <Mui.Stack sx={{ padding: '15px 20px' }} spacing={2}>
      <Mui.Typography variant="h4">Account Name</Mui.Typography>

      <Mui.OutlinedInput
        startAdornment={
          <Mui.InputAdornment position="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              className="bi bi-person-rolodex"
              viewBox="0 0 16 16">
              <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
              <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
            </svg>
          </Mui.InputAdornment>
        }
        sx={{
          border: '1px solid grey',
          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            border: 'none',
            '&:focus': {
              border: 'none',
            },
          },
          '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
            border: 'none',
            '&:focus': {
              border: 'none',
            },
          },
          '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
            border: 'none',
            '&:focus': {
              border: 'none',
            },
          },
        }}
        placeholder="Enter Account Name"
        onChange={e => setAccountName(e.target.value)}
      />
      <Mui.Stack direction="row" spacing={6}>
        <Mui.Button
          fullWidth
          variant="outlined"
          sx={{
            padding: '13px 0',
            borderRadius: '20px',
          }}
          onClick={() =>
            props.setWalletMode({
              send: false,
              create: false,
              import: false,
            })
          }>
          Cancel
        </Mui.Button>
        <Mui.Button
          fullWidth
          variant="contained"
          disableElevation
          disableRipple
          sx={{
            backgroundColor: '#5a1986',
            padding: '13px 0',
            borderRadius: '20px',
            '&:hover': {
              border: 'none',
              color: 'white',
              background: '#5a1986',
            },
          }}
          onClick={() => WalletCreationHere()}>
          Create
        </Mui.Button>
      </Mui.Stack>
    </Mui.Stack>
  );
};

export default CreateAccount;
