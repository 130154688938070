import React, { useState, Fragment, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
//import CardModal from '../../CardModal';
import { _categoryList } from '../../../services/mainApi';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import { getshortcode } from '../../../utils/helpers';
import { Dropdown } from 'react-bootstrap';
// import commonFunction from '../../../utils/common';
import PutonsaleModal from '../../PutonsaleModal'; //--
import BuyModal from '../MyItems/BuyModal';
import BuyMutiTokenModal from '../MyItems/BuyMutiTokenModal';
import img1 from '../../../assets/images/avatar/previewThumb.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
//select
// import ListItemText from '@mui/material/ListItemText';

import MessageNotifyModal from '../../../modal/MessageNotifyModal';
import getChainContractAddress from '../../../utils/getChainContractAddress';
import avaxLogo from '../../../assets/cryptoLogo/avalanche-avax-icon.svg';
import bnbLogo from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../../../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../../../assets/cryptoLogo/ethereum-eth-icon.svg';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnlockModal from '../../UnlockModal';
import icons8bag from '../../../assets/images/icon/bagg.png';
import buyIcon from '../../../assets/images/icon/icons8-buying-48.png';
import { Modal } from 'react-bootstrap';
import { FormCheck } from 'react-bootstrap';
import Pagination from './Pagination.jsx';
import {
  // Tab,
  Tabs,
  //  TabList,
  TabPanel,
} from 'react-tabs';
//import config from '../../../lib/config';
import { _exploreAllNft } from '../../../services/mainApi';

const TodayPicks = props => {
  const { ConnectedAccount, netWrokChainName } = useContext(ConnectWalletContext);
  const { data } = props;
  console.log(data, 'pageLoad');
  // const [receivedData] = useState(props.data);
  console.log('location ==>', props?.noData);
  let loadmore = props.loadmore;
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalView, setmodalView] = useState(false);
  const [switchShow, setSwitchShow] = useState(false);
  const [chainContractAddress, setChainContractAddress] = useState();
  const [chainName, setChainName] = useState();
  const [nftChainId, setNftChainId] = useState();
  const [modalShow, setModalShow] = useState(false); //--
  const [buyModalShow, setBuyModalShow] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [priceShow, setpriceShow] = useState(false);
  const [categorylist, setcategorylist] = useState([]);
  const [selectedsortId, setselectedsortId] = useState(0);
  const [selectedcategoryId, setselectedcategoryId] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState(9);
  const [selectedstatusId, setselectedstatusId] = useState(0);
  //   const [selectedquantityId, setselectedquantityId] = useState(0);
  const [cardIndex, setCardIndex] = useState();
  const [selectedid, setselectedid] = useState();
  const [searchData, setSearchData] = useState(''); //--
  //const [cryptoSymbol, setCryptoSymbol] = useState();
  const [pricerangeList] = useState([
    {
      id: 0,
      field: 'Relevance',
    },
    {
      id: 1,
      field: 'Price: Low to High',
    },
    {
      id: 2,
      field: 'Price: High to Low',
    },
  ]);
  const [statusList] = useState([
    {
      id: 0,
      field: 'All',
    },
    {
      id: 1,
      field: 'Buy Now',
    },
    {
      id: 2,
      field: 'Bid Now',
    },
    // {
    //   id: 2,
    //   field: 'Has Offers',
    // },
  ]);
  //   const [itemquantityList] = useState([
  //     {
  //       id: 0,
  //       field: 'All Items',
  //     },
  //     {
  //       id: 1,
  //       field: 'Single Items',
  //     },
  //     {
  //       id: 2,
  //       field: 'Bundles',
  //     },
  //   ]);

  // const [blockChainSelect] = useState([
  //   {
  //     id: 0,
  //     field: 'All Items',
  //   },
  //   {
  //     id: 1,
  //     field: 'Single Items',
  //   },
  //   {
  //     id: 2,
  //     field: 'Bundles',
  //   },
  // ]);
  const ExploreAllData = () => {
    const _exploreAllNftpromise = _exploreAllNft(props.currentPage);
    _exploreAllNftpromise.then(res => {
      if (!res.error) {
        props.explorelistrange(res);
      }
      if (res.data.error) {
        props.setNoData(true);
      }
    });
  };
  const _handleChange = () => {
    setChecked(!checked);
    setSelectedCurrency(9);
    if (checked) {
      setMaxValue('');
      setMinValue('');
      setCoinNameBlockchain(null);
      ExploreAllData();
    }
  };
  function refreshPage() {
    setMaxValue('');
    setMinValue('');
    setCoinNameBlockchain(null);
    ExploreAllData();
    setSelectedCurrency(9);
  }
  const names = [
    {
      id: 0,
      field: 'All',
    },
    {
      id: 1,
      field: 'Avalanche',
    },
    {
      id: 2,
      field: 'Ethereum',
    },
    {
      id: 3,
      field: 'Polygon',
    },
    {
      id: 4,
      field: 'Binance',
    },
  ];
  // const blockchainnames = [
  //   {
  //     id: 1,
  //     field: 'Avalanche',
  //   },
  //   {
  //     id: 2,
  //     field: 'Ethereum',
  //   },
  //   {
  //     id: 3,
  //     field: 'Polygon',
  //   },
  //   {
  //     id: 4,
  //     field: 'Binance',
  //   },
  // ];
  const { buyingModal, setbuyingModal } = React.useContext(ConnectWalletContext);

  const [coinName, setCoinName] = React.useState();
  const [coinNameBlockchain, setCoinNameBlockchain] = React.useState(null);

  const min = 0;
  const max = 100;

  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [priceRangefilter, setPriceRangefilter] = useState([]);

  const FilterHere = () => {
    // if (minValue <= 0 || maxValue <= 0 || coinNameBlockchain === null)
    //   return toast.error('Process failed');
    if (minValue <= 0) return toast.error('Enter Min Value');
    if (maxValue <= 0) return toast.error('Enter Max Value');
    if (coinNameBlockchain === null) return toast.error('Select Blockchain');
    const ExploreMinMax = _exploreAllNft(1, {
      filter: {
        blockChainName: coinNameBlockchain,
        minRange: minValue.toString(),
        maxRange: maxValue.toString(),
      },
    });
    ExploreMinMax.then(res => {
      setPriceRangefilter(res);
    });
  };

  const handleFloorPriceChange = event => {
    const minValue = Math.max(min, Math.min(max, Number(event.target.value)));
    setMinValue(minValue);
  };

  const handleChange1 = event => {
    const maxValue = Math.min(max, Math.max(min, Number(event.target.value)));
    setMaxValue(maxValue);
  };

  useEffect(() => {
    if (minValue <= 0 || maxValue <= 0 || coinNameBlockchain === null) return;
    props.explorelistrange(priceRangefilter);
  }, [priceRangefilter]);

  // const MenuProps = {
  //   PaperProps: {
  //     backgroundColor: 'red',
  //     style: {
  //       maxHeight: 48 * 4.5 + 8,
  //       width: 220,
  //       // backgroundColor: '#343444',
  //       // color: 'white',
  //       fontSize: '15px',
  //     },
  //   },
  // };

  // const StyledSelect = Mui.styled(Select)`
  //   font-size: 15px;
  //   border-radius: 0.4rem;
  //   margin-left:10px;
  //   height:47px;
  //   /* border: 2px solid green; */
  //   & .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  //     border: red;
  //   }
  //   & .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  //     color: white;
  //   }
  //   & .MuiSelect-outlined {
  //     padding:1px 10px;
  //   }
  //   & .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  //     // height: 47px !important,
  //   }
  // `;
  //   const finditem_quantity = () => {
  //     let value = itemquantityList.find(element => element.id === selectedquantityId)?.field
  //       ? itemquantityList.find(element => element.id === selectedquantityId)?.field
  //       : itemquantityList.find(element => element.id === 0)?.field;
  //     return value;
  //   };

  const handleSwitchNetworkClose = () => {
    setSwitchShow(false);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <div id="item-create" className="dropdown" ref={ref}>
        <Link
          to="#"
          className="btn-selector nolink"
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}>
          {children}
        </Link>
      </div>
    );
  });
  CustomToggle.displayName = 'CustomToggle';
  useEffect(() => {
    _categoryList().then(
      res => {
        // console.log(res?.data,"data--->>>");
        if (!res.error) {
          let data = [
            {
              id: 1234,
              field: 'All',
            },
          ];
          res?.data?.data?.map(item =>
            data?.push({
              id: item.categoryId,
              field: item.name,
            }),
          );
          setcategorylist(data);
        }
      },
      () => {
        console.log('fail');
      },
    );
    return () => {};
  }, []);

  // const [visible, setVisible] = useState(1);
  // const showMoreItems = () => {
  //   setVisible(prevValue => prevValue + 5);
  // };

  const listBtn = useRef(null);
  const gridBtn = useRef(null);
  const imgBtn = useRef(null);
  const listContent1 = useRef(null);
  const gridContent1 = useRef(null);
  const imgContent = useRef(null);
  const listToggle1 = () => {
    listBtn.current.classList.add('active');
    gridBtn.current.classList.remove('active');
    imgBtn.current.classList.remove('active');
    listContent1.current.classList.add('open');
    gridContent1.current.classList.remove('open');
    imgContent.current.classList.remove('open');
  };
  const gridToggle1 = () => {
    gridBtn.current.classList.add('active');
    listBtn.current.classList.remove('active');
    imgBtn.current.classList.remove('active');
    gridContent1.current.classList.add('open');
    listContent1.current.classList.remove('open');
    imgContent.current.classList.remove('open');
  };
  const imgToggle = () => {
    imgBtn.current.classList.add('active');
    gridBtn.current.classList.remove('active');
    listBtn.current.classList.remove('active');
    imgContent.current.classList.add('open');
    gridContent1.current.classList.remove('open');
    listContent1.current.classList.remove('open');
  };

  const getBlockChainData = async chainName => {
    setSwitchShow(true);
    setChainName(chainName);
    let chainData = await getChainContractAddress(chainName);
    setChainContractAddress(chainData.NFTcontractAddress);
    setNftChainId(chainData.chainId);
  };
  React.useEffect(() => {
    // setselectedid(index);
    if (buyingModal['item']) {
      if (buyingModal['item']?.blockChainName == netWrokChainName) {
        if (buyingModal['type'] == 'changePrice') {
          setModalShow(true);
        } else {
          setBuyModalShow(true);
        }
      }
      if (buyingModal['item']?.blockChainName != netWrokChainName) {
        getBlockChainData(buyingModal['item']?.blockChainName);
      }
    }
  }, [buyingModal]);
  const [checkValue, setCheckValue] = React.useState(1);
  const HandlePageChange = (event, value) => {
    if (checkValue === value) {
      ('');
    } else {
      if (props?.filterPageCheck) {
        props?.changecategoryPageNumber(value);
        setCheckValue(value);
      } else {
        props.changePageNumber(value);
        setCheckValue(value);
      }
    }
  };

  const GridLayout = () => {
    return (
      // <>
      //   {(data?.length <= 0 && !props?.noData) || props?.filterLoad || props?.pageLoad ? (
      //     <div
      //       style={{
      //         textAlign: 'center',
      //         marginLeft: 'auto',
      //         marginRight: 'auto',
      //       }}>
      //       <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
      //       <h2>Loading...</h2>
      //     </div>
      //   ) : (
      <>
        {console.log('pppp', data)}
        {(data?.length <= 0 && !props?.noData) || props?.filterLoad || props?.pageLoad ? (
          <div
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
            <h2>Loading...</h2>
          </div>
        ) : props?.noData ? (
          <div
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            <h2>No data found...</h2>
          </div>
        ) : (
          data?.map((item, index) => (
            <div
              key={index}
              className={
                props?.home2
                  ? // col-item
                    'col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12'
                  : 'fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6'
              }>
              {/* {console.log('loggingTodaypicks', item)} */}
              <div
                className={
                  props?.home2
                    ? 'sc-card-product sc-card-product-home2 menu_card style-h7 '
                    : `sc-card-product ${item.feature ? 'comingsoon' : ''} `
                }>
                {item?.unlockContentFlag === true ? (
                  <div className="lock">
                    <OverlayTrigger
                      style={{ width: '70px', height: '30px' }}
                      overlay={
                        <Tooltip id="tooltip-disabled">Includes Unlockable Content.!</Tooltip>
                      }>
                      <span className="d-inline-block">
                        <a>
                          <FontAwesomeIcon size="2x" icon={faLock} />
                        </a>
                      </span>
                    </OverlayTrigger>
                    {/* <span className="pl-0">Reveal Unlock Content</span> */}
                  </div>
                ) : (
                  ''
                )}
                <div
                  className={
                    props?.home2
                      ? ' cardimg_sizeNoWidth'
                      : // ? 'card-media cardimg_sizeNoWidth'
                        'card-media cardimg_size'
                  }>
                  {props?.home2 &&
                  ConnectedAccount === (item.walletAddress || item.currentAddress) &&
                  item.price != 0 ? (
                    <div className="home2-btn-div">
                      <button
                        onClick={async () => {
                          setselectedid(index);
                          if (data[index].blockChainName == netWrokChainName) {
                            setModalShow(true);
                          }
                          if (data[index].blockChainName != netWrokChainName) {
                            getBlockChainData(data[index].blockChainName);
                          }
                        }}
                        className="home2-btn">
                        <img
                          src={icons8bag}
                          alt="bag"
                          style={{ width: '18px', marginRight: '7px' }}
                          // className="buyIconDiv"
                        />
                        <span>Change Price</span>
                      </button>
                    </div>
                  ) : props?.home2 && item.price != 0 ? (
                    <>
                      {props?.home2 && ConnectedAccount ? (
                        <div className="home2-btn-div">
                          {/* <Link to={'/buy'} state={item}> */}
                          <button
                            // className="sc-button style bag fl-button pri-3 no-bg"
                            className="home2-btn"
                            onClick={async () => {
                              setselectedid(index);
                              // console.log(data[index], 'fullRes');
                              if (data[index].blockChainName == netWrokChainName) {
                                setBuyModalShow(true);
                              }
                              if (data[index].blockChainName != netWrokChainName) {
                                getBlockChainData(data[index].blockChainName);
                              }
                            }}>
                            <img className="buyIconDiv" src={buyIcon} alt="buy" />

                            <span>Buy now</span>
                          </button>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      {item.isBid == true && item.minBidValue > 0 ? (
                        <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                          <div className="home2-btn-div">
                            <button className="home2-btn">
                              <img
                                src={icons8bag}
                                alt="bag"
                                style={{ width: '18px', marginRight: '7px' }}
                                // className="buyIconDiv"
                              />
                              <span>Bid now</span>
                            </button>
                          </div>
                        </Link>
                      ) : (
                        <div className="home2-btn-div">
                          <button className="home2-btn">
                            <span>Unlisted</span>
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  {console.log(props.globalSearch?.length, item, 'iiiiii')}
                  <div style={{ cursor: 'pointer' }} className="img-div">
                    {props.globalSearch != undefined && props?.globalSearch?.length != 0 ? (
                      <img
                        onClick={() => {
                          ConnectedAccount
                            ? navigate(`/buy/${item?.id || item?._id}`, {
                                state: item,
                              })
                            : handleShow();
                        }}
                        src={item?.fileUrl ? item.fileUrl : img1}
                        alt="axies"
                        className="img-change"
                      />
                    ) : (
                      <img
                        onClick={() => {
                          ConnectedAccount
                            ? navigate(`/buy/${item?.id || item?._id}`, {
                                state: item,
                              })
                            : handleShow();
                        }}
                        src={
                          item?.img?.split('.')[4] == 'mp4' || item?.img?.split('.')[4] == 'mpeg'
                            ? img1
                            : item.img
                        }
                        alt="axies"
                        className="img-change"
                      />
                    )}
                  </div>
                  {props.loadMore && cardIndex === index ? (
                    <div className="wishlist-button-loading">
                      <i
                        className="fa fa-spinner fa-spin fa-3x mb-3"
                        aria-hidden="true"
                        id="circle1"></i>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        {
                          ConnectedAccount
                            ? (props.addOrRemoveFav(item), setCardIndex(index))
                            : handleShow();
                        }
                      }}
                      className={
                        item.favStatus
                          ? props?.home2
                            ? 'wishlist-button icon-fl-heart-filled home2-wish'
                            : 'wishlist-button icon-fl-heart-filled'
                          : props?.home2
                          ? 'wishlist-button heart home2-wish'
                          : 'wishlist-button heart'
                      }>
                      <span className="number-like">{item.wishlist || item.favCount}</span>
                    </div>
                  )}
                  <div className="coming-soon">{item.feature}</div>
                </div>
                <div className={props?.home2 ? 'cardcontent-home2' : ''}>
                  <div className="card-title">
                    <h5 className="style2">
                      {item.blockChainName == 'Avalanche' ? (
                        <img className="cryptoImg" src={avaxLogo} />
                      ) : item.blockChainName == 'Binance' ? (
                        <img className="cryptoImg" src={bnbLogo} />
                      ) : item.blockChainName == 'Ethereum' ? (
                        <img className="cryptoImg" src={ethLogo} />
                      ) : item.blockChainName == 'Polygon' ? (
                        <img className="cryptoImg" src={maticLogo} />
                      ) : (
                        ''
                      )}
                      &nbsp;&nbsp;
                      <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                        {item.title}
                      </Link>
                    </h5>
                    <div className="tags">{item.tags || item?.CategoryData?.name}</div>
                    {/* )} */}
                  </div>
                  <div className={props?.home2 ? 'meta-info tag-explore02' : 'meta-info'}>
                    <div className="author">
                      <div className="avatar">
                        <img
                          src={item?.userImage || item?.userdetails?.userImage || img1}
                          alt="axies"
                        />
                      </div>
                      <div className="info">
                        <span>Owned By</span>
                        {/* <h6> */}
                        {/* <Link to="/authors-02" state={item}>
                                  {getshortcode(item.walletAddress || item.ownerAddress)}
                                </Link> */}
                        <div style={{ cursor: 'pointer' }}>
                          <h6
                            onClick={() => {
                              ConnectedAccount
                                ? navigate(`/authors-02/${item?.userId || item?._id}`, {
                                    state: item,
                                  })
                                : handleShow();
                            }}>
                            {getshortcode(item.walletAddress || item.ownerAddress)}
                          </h6>
                        </div>
                        {/* </h6> */}
                      </div>
                    </div>
                    <div className="price">
                      {/* <span>Current price</span> */}
                      {item?.isBid ? <span>Mid Bid Value</span> : <span>Current price</span>}
                      <h5>
                        {item?.isBid ? item?.minBidValue : item.price} &nbsp;
                        {item.blockChainName == 'Avalanche'
                          ? 'AVAX'
                          : item.blockChainName == 'Binance'
                          ? 'BNB'
                          : item.blockChainName == 'Ethereum'
                          ? 'ETH'
                          : item.blockChainName == 'Polygon'
                          ? 'MATIC'
                          : item.blockChainName == undefined
                          ? 'ETH'
                          : ''}
                      </h5>
                    </div>
                  </div>
                  <div className="card-bottom">
                    {!props?.home2 &&
                    ConnectedAccount === (item.walletAddress || item.currentAddress) &&
                    item.price == 0 ? (
                      <button
                        onClick={() => {
                          setselectedid(index);
                          setModalShow(true);
                        }}
                        className="sc-button style bag fl-button pri-3 no-bg">
                        {/* <span>Put On Sale</span> */}
                        {item?.isBid ? <span>Change bid value</span> : <span>Put On Sale</span>}
                      </button>
                    ) : !props?.home2 &&
                      ConnectedAccount === (item.walletAddress || item.currentAddress) &&
                      item.price != 0 ? (
                      <button
                        onClick={async () => {
                          setselectedid(index);
                          if (data[index].blockChainName == netWrokChainName) {
                            setModalShow(true);
                          }
                          if (data[index].blockChainName != netWrokChainName) {
                            getBlockChainData(data[index].blockChainName);
                          }
                        }}
                        className="sc-button style bag fl-button pri-3 no-bg">
                        <span>Change price</span>
                      </button>
                    ) : (
                      <>
                        {item.price != 0 ? (
                          <>
                            {!props?.home2 && ConnectedAccount ? (
                              <>
                                {/* <Link to={'/buy'} state={item}> */}
                                <button
                                  className="sc-button style bag fl-button pri-3 no-bg"
                                  onClick={async () => {
                                    setselectedid(index);
                                    // console.log(data[index], 'fullRes');
                                    if (data[index].blockChainName == netWrokChainName) {
                                      setBuyModalShow(true);
                                    }
                                    if (data[index].blockChainName != netWrokChainName) {
                                      getBlockChainData(data[index].blockChainName);
                                    }
                                  }}>
                                  <span>Buy now</span>
                                </button>
                                {/* </Link> */}

                                {/* <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                          <button className="sc-button style bag fl-button pri-3 no-bg">
                                            <span>Bid now</span>
                                          </button>
                                        </Link> */}
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <>
                            {!props?.home2 &&
                            item.isBid == true &&
                            item.minBidValue > 0 &&
                            ConnectedAccount ? (
                              <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                <button className="sc-button style bag fl-button pri-3 no-bg">
                                  <span>Bid now</span>
                                </button>
                              </Link>
                            ) : (
                              !props?.home2 &&
                              ConnectedAccount && (
                                <h4 className="sc-button no-bg">
                                  <span>Unlisted</span>
                                </h4>
                              )
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}

        {/* {loadmore === 'filter data' && ''}
        {data?.length < 10 || data?.length === undefined
          ? ''
          : loadmore === 'all data' &&
            data?.length != props?.exploretotalItems - 2 && (
              <div className="col-md-12 wrap-inner load-more text-center">
                {data?.length > 0 && (
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={() => props.increasepagenumber()}>
                    {props.loadMore ? <span>Loading..</span> : <span>Load More</span>}
                    {props.loadMore && (
                      <div className="wishlist-button-loading-loadmore">
                        <i
                          className="fa fa-spinner fa-spin fa-3x mb-3"
                          aria-hidden="true"
                          id="circle1"></i>
                      </div>
                    )}
                  </Link>
                )}
              </div>
            )} */}
      </>
      //   )}
      // </>
    );
  };

  return (
    <Fragment>
      <section className={props?.home2 ? 'section-home2' : 'tf-section sc-explore-1'}>
        {console.log(props?.exploretotalItems, 'pabb')}
        <div className="themesflat-container">
          <div className="row">
            {props?.home2 && (
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                {props?.exploretotalItems != undefined && (
                  <Pagination
                    count={
                      props?.exploretotalItems === 10
                        ? 1
                        : Math.trunc(props?.exploretotalItems / 10)
                    }
                    HandlePageChange={HandlePageChange}
                    defaultPage={
                      props?.filterPageCheck ? props?.currentCategoryPage : props?.currentPage
                    }
                    page={props?.filterPageCheck ? props?.currentCategoryPage : props?.currentPage}
                  />
                )}
              </div>
            )}
            {props?.home2 ? (
              (data?.length <= 0 && !props?.noData) || props?.filterLoad || props?.pageLoad ? (
                <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                  <i
                    className="fa fa-spinner fa-spin fa-3x mb-3"
                    aria-hidden="true"
                    id="circle1"></i>
                  <h2>Loading...</h2>
                </div>
              ) : (
                <>
                  {(data?.length <= 0 && !props?.noData) || props?.filterLoad || props?.pageLoad ? (
                    <div
                      style={{
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}>
                      <i
                        className="fa fa-spinner fa-spin fa-3x mb-3"
                        aria-hidden="true"
                        id="circle1"></i>
                      <h2>Loading...</h2>
                    </div>
                  ) : (
                    <>
                      {props?.noData ? (
                        <div
                          style={{
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}>
                          <h2>No data found...</h2>
                        </div>
                      ) : (
                        GridLayout()
                      )}
                    </>
                  )}
                </>
              )
            ) : (
              <div className="flat-tabs items" style={{ width: '100%' }}>
                <Tabs>
                  <TabPanel key={data.id}>
                    <div
                      className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                      style={{ display: 'flex', width: '87%' }}>
                      <div
                        className="d-flex item-center text-right"
                        style={{
                          minWidth: 'fit-content',
                          marginTop: '3.5rem',
                          marginLeft: '3rem',
                        }}>
                        <h4 className="title-create-item">Floor Price</h4>
                        <div className="ml-5 mt-1">
                          <FormCheck custom type="switch">
                            <FormCheck.Input onChange={() => _handleChange()} checked={checked} />
                          </FormCheck>
                        </div>
                      </div>
                      {props?.exploretotalItems != undefined && (
                        <Pagination
                          count={
                            props?.exploretotalItems === 10
                              ? 1
                              : Math.trunc(props?.exploretotalItems / 10)
                          }
                          HandlePageChange={HandlePageChange}
                          defaultPage={
                            props?.filterPageCheck ? props?.currentCategoryPage : props?.currentPage
                          }
                          page={
                            props?.filterPageCheck ? props?.currentCategoryPage : props?.currentPage
                          }
                        />
                      )}
                    </div>

                    {!checked && (
                      <div className={props?.home2 ? 'none-display' : 'col col-md-12'}>
                        <div className="wrap-box explore-1 flex mg-bt-40">
                          <div className="seclect-box style-1">
                            <div className="col searchinput mg-r-10 d-flex searchinputvalue">
                              <input
                                type="search"
                                placeholder="Search item here.."
                                name="search2"
                                onChange={e => {
                                  setSearchData(e.target.value);
                                }}
                              />
                              <button
                                className=" style pri-3 no-bg pl-0 noborder"
                                onClick={() => {
                                  props.onsearchnft(searchData);
                                  setSearchData('');
                                }}>
                                <i className="fa fa-search"></i>
                              </button>
                            </div>
                            <div id="col item_category" className="tf-soft">
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                  {categorylist?.find(element => element.id === selectedcategoryId)
                                    ?.field
                                    ? categorylist?.find(
                                        element => element.id === selectedcategoryId,
                                      )?.field
                                    : 'All categories'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ overflow: 'scroll' }}>
                                  {categorylist?.length > 0 &&
                                    categorylist?.map((item, index) => {
                                      return (
                                        <Dropdown.Item
                                          href="#"
                                          key={`${index}+collection`}
                                          onClick={() => {
                                            setselectedcategoryId(item.id);
                                            console.log('looooooop', item);
                                            props.filter(
                                              item.id,
                                              //   selectedquantityId,
                                              selectedstatusId,
                                              selectedsortId,
                                              coinName,
                                            );
                                          }}>
                                          <div className="sort-filter active">
                                            <span>{item.field}</span>
                                          </div>
                                        </Dropdown.Item>
                                      );
                                    })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div id="buy" className="col tf-soft">
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                  {statusList.find(element => element.id === selectedstatusId)
                                    ?.field
                                    ? statusList.find(element => element.id === selectedstatusId)
                                        ?.field
                                    : statusList.find(element => element.id === 0)?.field}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ overflow: 'scroll' }}>
                                  {statusList?.length > 0 &&
                                    statusList?.map((item, index) => {
                                      return (
                                        <Dropdown.Item
                                          href="#"
                                          key={`${index}+collection`}
                                          onClick={() => {
                                            setselectedstatusId(item.id);
                                            props.filter(
                                              selectedcategoryId,
                                              //   selectedquantityId,
                                              item.id,
                                              selectedsortId,
                                              coinName,
                                            );
                                            console.log(coinName, 'names');
                                          }}>
                                          <div className="sort-filter active">
                                            <span>{item.field}</span>
                                          </div>
                                        </Dropdown.Item>
                                      );
                                    })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            {/* <div id="all-items" className="tf-soft">
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                              {finditem_quantity()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ overflow: 'scroll' }}>
                              {itemquantityList?.length > 0 &&
                                itemquantityList.map((item, index) => {
                                  return (
                                    <Dropdown.Item
                                      href="#"
                                      key={`${index}+collection`}
                                      onClick={() => {
                                        setselectedquantityId(item.id);
                                        props.filter(
                                          selectedcategoryId,
                                          item.id,
                                          selectedstatusId,
                                          selectedsortId,
                                          coinName,
                                        );
                                      }}>
                                      <div className="sort-filter active">
                                        <span>{item.field}</span>
                                      </div>
                                    </Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}

                            <div id="all-items" className="col tf-soft">
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                  {names?.find(element => element.id === selectedCurrency)?.field
                                    ? names?.find(element => element.id === selectedCurrency)?.field
                                    : 'All Currency'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ overflow: 'scroll' }}>
                                  {names?.length > 0 &&
                                    names?.map((item, index) => {
                                      return (
                                        <Dropdown.Item
                                          href="#"
                                          key={`${index}+collection`}
                                          onClick={() => {
                                            setSelectedCurrency(item?.id);
                                            props.setcurrentCategoryPage(1);
                                            props.setcurrentCategoryPage(1);
                                            setCoinName(
                                              // On autofill we get a stringified value.
                                              item?.field,
                                            );
                                            props.filter('', '', '', '', item?.field);
                                          }}>
                                          <div className="sort-filter active">
                                            <span>{item?.field}</span>
                                          </div>
                                        </Dropdown.Item>
                                      );
                                    })}
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* <Mui.FormControl className="selectmui">
                                <Mui.InputLabel>
                                  <Link to="#" className="select-label">
                                    Select Currency
                                  </Link>
                                </Mui.InputLabel>
                                <StyledSelect
                                  className="styledselect"
                                  // sx={{  }}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  // multiple
                                  value={coinName}
                                  onChange={handleChange}
                                  MenuProps={MenuProps}>
                                  {names.map(name => (
                                    <MenuItem
                                      key={name?.id}
                                      value={name?.field}
                                      className="listitemselect">
                                      <p className="listitem-text">{name?.field}</p>
                                    </MenuItem>
                                  ))}
                                </StyledSelect>
                              </Mui.FormControl> */}
                            </div>
                          </div>
                          <div className="seclect-box style-2 box-right">
                            <div id="sort-by" className="tf-soft" style={{ paddingRight: '' }}>
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                  {pricerangeList.find(element => element.id === selectedsortId)
                                    ?.field
                                    ? pricerangeList.find(element => element.id === selectedsortId)
                                        ?.field
                                    : pricerangeList.find(element => element.id === 0)?.field}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ overflow: 'scroll' }}>
                                  {pricerangeList?.length > 0 &&
                                    pricerangeList.map((item, index) => {
                                      if (item.id !== selectedsortId) {
                                        return (
                                          <Dropdown.Item
                                            key={index}
                                            onClick={() => {
                                              setselectedsortId(item.id);
                                              props.filter(
                                                selectedcategoryId,
                                                // selectedquantityId,
                                                selectedstatusId,
                                                item.id,
                                                coinName,
                                              );
                                            }}>
                                            <div className="sort-filter active">
                                              <span>{item.field}</span>
                                            </div>
                                          </Dropdown.Item>
                                        );
                                      }
                                    })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>{' '}
                          </div>
                          <>
                            <div className="option">
                              {/* <h2 className="title">1,000 Items</h2> */}
                              <div className="view" style={{ paddingLeft: '2rem' }}>
                                <ul style={{ borderRadius: 'none' }}>
                                  <li
                                    onClick={listToggle1}
                                    ref={listBtn}
                                    className="style1 grid active"
                                    style={{ borderRadius: '0px' }}>
                                    <Link to="#">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                                          fill="white"
                                        />
                                      </svg>
                                      {/* <span>Grid</span> */}
                                    </Link>
                                  </li>
                                  <li
                                    onClick={gridToggle1}
                                    ref={gridBtn}
                                    className="style2 list"
                                    style={{ borderRadius: '0px' }}>
                                    <Link to="#">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M21 8H3C2.59 8 2.25 7.09333 2.25 6C2.25 4.90667 2.59 4 3 4H21C21.41 4 21.75 4.90667 21.75 6C21.75 7.09333 21.41 8 21 8Z"
                                          fill="#EBEBEB"
                                        />
                                        <path
                                          d="M21 14H3C2.59 14 2.25 13.0933 2.25 12C2.25 10.9067 2.59 10 3 10H21C21.41 10 21.75 10.9067 21.75 12C21.75 13.0933 21.41 14 21 14Z"
                                          fill="#EBEBEB"
                                        />
                                        <path
                                          d="M21 20H3C2.59 20 2.25 19.0933 2.25 18C2.25 16.9067 2.59 16 3 16H21C21.41 16 21.75 16.9067 21.75 18C21.75 19.0933 21.41 20 21 20Z"
                                          fill="#EBEBEB"
                                        />
                                      </svg>
                                      {/* <span>List</span> */}
                                    </Link>
                                  </li>
                                  <li onClick={imgToggle} ref={imgBtn} className="style3 list">
                                    <Link to="#">
                                      {/* <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                                      fill="white"
                                    />
                                  </svg> */}
                                      <FontAwesomeIcon
                                        style={{ width: '24', height: '24' }}
                                        icon={faThLarge}
                                      />

                                      {/* <span>List1</span> */}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    )}
                    <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <>
                        {checked && (
                          <div className={props?.home2 ? 'none-display' : 'floorPrice'}>
                            {/* //  style={{ backgroundColor: 'tomato' }} */}
                            <div>
                              <h3 style={{ paddingRight: '30px' }}>Floor Price</h3>
                              {minValue > maxValue && (
                                <h6 style={{ color: 'red' }}>
                                  Min value should be less than Max value
                                </h6>
                              )}
                            </div>
                            <div className="">
                              <input
                                className="min-input"
                                style={{ width: '8vw' }}
                                type="number"
                                placeholder="Min"
                                value={minValue}
                                onChange={handleFloorPriceChange}
                              />
                            </div>
                            <span
                              className="col-1"
                              style={{
                                width: '25px',
                                fontSize: '25px',
                              }}>
                              -
                            </span>
                            <div className="">
                              <input
                                className="max-input"
                                style={{ width: '8vw' }}
                                type="number"
                                placeholder="Max"
                                value={maxValue}
                                onChange={handleChange1}
                                required
                              />
                            </div>
                            <div className="col tf-soft blockchain">
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                  {names?.find(element => element.id === selectedCurrency)?.field
                                    ? names?.find(element => element.id === selectedCurrency)?.field
                                    : 'Select Blockchain'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ overflow: 'scroll' }}>
                                  {names?.length > 0 &&
                                    names?.map((item, index) => {
                                      return (
                                        <Dropdown.Item
                                          href="#"
                                          key={`${index}+collection`}
                                          onClick={() => {
                                            setCoinNameBlockchain(
                                              // On autofill we get a stringified value.
                                              item?.field,
                                            );
                                            setSelectedCurrency(item?.id);
                                          }}>
                                          <div className="sort-filter active">
                                            <span>{item?.field}</span>
                                          </div>
                                        </Dropdown.Item>
                                      );
                                    })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div>
                              <button
                                type="button"
                                disabled={minValue > maxValue}
                                className="sc-button fl-button pri-3 no-bg"
                                onClick={FilterHere}>
                                <span className="create-item-btn" style={{ paddingLeft: '0px' }}>
                                  Filter
                                </span>
                              </button>
                            </div>
                            <div style={{ paddingLeft: '30px', height: 'fit-content' }}>
                              {/* <div className="heading-live-auctions clearfilter"> */}
                              <Link
                                // style={{ marginTop: '-5rem', paddingLeft: '5px' }}
                                to="/explore-01"
                                onClick={() => refreshPage()}>
                                <p className="exp style2 ml-2">CLEAR FILTER</p>
                              </Link>
                            </div>
                            {/* </div> */}
                            {/* //edit */}
                            {/* <div className="row d-flex row inputbox-floorprice">
                              <div style={{ fontSize: '2rem', marginTop: '1.5rem' }}>
                                Floor Price
                              </div>
                              <div className="col-2">
                                <input
                                  className="min-input"
                                  style={{ width: '8vw' }}
                                  type="number"
                                  placeholder="Min"
                                  value={minValue}
                                  onChange={handleFloorPriceChange}
                                />
                              </div>
                              <span
                                className="span-line"
                                style={{
                                  width: '25px',
                                  fontSize: '25px',
                                  marginTop: '10px',
                                  // marginLeft: '-65px',
                                }}>
                                -
                              </span>
                              <div className="col-2">
                                <input
                                  className="max-input"
                                  style={{ width: '8vw' }}
                                  type="number"
                                  placeholder="Max"
                                  value={maxValue}
                                  onChange={handleChange1}
                                  required
                                />
                              </div>
                              <div id="all-items" className="col tf-soft selectblockchainvalue">
                                <Mui.FormControl className="selectmui">
                                  <Mui.InputLabel>
                                    <Link to="#" className="select-label">
                                      Select BlockChain
                                    </Link>
                                  </Mui.InputLabel>
                                  <StyledSelect
                                    className="styledselect"
                                    // sx={{ backgroundColor: '#343444', color: 'white', width: '250px' }}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    // multiple
                                    value={coinNameBlockchain}
                                    onChange={valueChange}
                                    MenuProps={MenuProps}>
                                    {blockchainnames.map(name => (
                                      <MenuItem
                                        key={name?.id}
                                        value={name?.field}
                                        className="listitemselect">
                                        <p className="listitem-text">{name?.field}</p>
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                </Mui.FormControl>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="heading-live-auctions clearfilter">
                                  <Link
                                    // style={{ marginTop: '-5rem', paddingLeft: '5px' }}
                                    to="/explore-01"
                                    onClick={() => refreshPage()}
                                    className="exp style2 ml-2">
                                    CLEAR FILTER
                                  </Link>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        )}
                      </>
                    </div>

                    <div className="content-item2" ref={gridContent1}>
                      <div
                        style={{
                          display: 'flex',
                          // justifyContent: 'center',
                          flexFlow: 'wrap',
                        }}>
                        {(data?.length <= 0 && !props?.noData) ||
                        props?.filterLoad ||
                        props?.pageLoad ? (
                          <div
                            style={{
                              textAlign: 'center',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}>
                            <i
                              className="fa fa-spinner fa-spin fa-3x mb-3"
                              aria-hidden="true"
                              id="circle1"></i>
                            <h2>Loading...</h2>
                          </div>
                        ) : (
                          <>
                            {props?.noData ? (
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}>
                                <h2>No data found...</h2>
                              </div>
                            ) : (
                              data?.map((item, index) => (
                                <div
                                  key={index}
                                  className={
                                    props?.home2
                                      ? // col-item
                                        'col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12'
                                      : 'fl-item col-xl-2 col-lg-2 col-md-6 col-sm-6'
                                  }>
                                  {/* {console.log('loggingTodaypicks', item)} */}
                                  <div
                                    className={
                                      props?.home2
                                        ? 'sc-card-product sc-card-product-home2 menu_card style-h7 '
                                        : `sc-card-product ${item.feature ? 'comingsoon' : ''} `
                                    }
                                    style={{ padding: '2px ', display: 'block' }}>
                                    {item?.unlockContentFlag === true ? (
                                      <div className="lock">
                                        <OverlayTrigger
                                          style={{ width: '70px', height: '30px' }}
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              Includes Unlockable Content.!
                                            </Tooltip>
                                          }>
                                          <span className="d-inline-block">
                                            <a>
                                              <FontAwesomeIcon size="2x" icon={faLock} />
                                            </a>
                                          </span>
                                        </OverlayTrigger>
                                        {/* <span className="pl-0">Reveal Unlock Content</span> */}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div
                                      className={
                                        props?.home2
                                          ? ' cardimg_sizeNoWidth'
                                          : '  cardimg_sizeNoWidth'
                                        // 'card-media cardimg_size'
                                      }>
                                      {props?.home2 &&
                                      (item.walletAddress || item.currentAddress) &&
                                      item.price != 0 ? (
                                        <div className="home2-btn-div">
                                          <button
                                            onClick={async () => {
                                              setselectedid(index);
                                              if (data[index].blockChainName == netWrokChainName) {
                                                setModalShow(true);
                                              }
                                              if (data[index].blockChainName != netWrokChainName) {
                                                getBlockChainData(data[index].blockChainName);
                                              }
                                            }}
                                            className="home2-btn">
                                            <img
                                              src={icons8bag}
                                              alt="bag"
                                              style={{ width: '18px', marginRight: '7px' }}
                                              // className="buyIconDiv"
                                            />
                                            <span>Change Price</span>
                                          </button>
                                        </div>
                                      ) : props?.home2 && item.price != 0 ? (
                                        <>
                                          {props?.home2 && ConnectedAccount ? (
                                            <div className="home2-btn-div">
                                              {/* <Link to={'/buy'} state={item}> */}
                                              <button
                                                // className="sc-button style bag fl-button pri-3 no-bg"
                                                className="home2-btn"
                                                onClick={async () => {
                                                  setselectedid(index);
                                                  // console.log(data[index], 'fullRes');
                                                  if (
                                                    data[index].blockChainName == netWrokChainName
                                                  ) {
                                                    setBuyModalShow(true);
                                                  }
                                                  if (
                                                    data[index].blockChainName != netWrokChainName
                                                  ) {
                                                    getBlockChainData(data[index].blockChainName);
                                                  }
                                                }}>
                                                <img
                                                  className="buyIconDiv"
                                                  src={buyIcon}
                                                  alt="buy"
                                                />

                                                <span>Buy now</span>
                                              </button>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.isBid == true && item.minBidValue > 0 ? (
                                            <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                              <div className="home2-btn-div">
                                                <button className="home2-btn">
                                                  <img
                                                    src={icons8bag}
                                                    alt="bag"
                                                    style={{ width: '18px', marginRight: '7px' }}
                                                    // className="buyIconDiv"
                                                  />
                                                  <span>Bid now</span>
                                                </button>
                                              </div>
                                            </Link>
                                          ) : (
                                            <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                              <div className="home2-btn-div">
                                                <button className="home2-btn">
                                                  <img
                                                    src={buyIcon}
                                                    alt="bag"
                                                    style={{ width: '18px', marginRight: '7px' }}
                                                    // className="buyIconDiv"
                                                  />
                                                  <span>Buy now</span>
                                                </button>
                                              </div>
                                            </Link>
                                          )}
                                        </>
                                      )}
                                      <div style={{ cursor: 'pointer' }} className="img-div">
                                        <img
                                          onClick={() => {
                                            ConnectedAccount
                                              ? navigate(`/buy/${item?.id || item?._id}`, {
                                                  state: item,
                                                })
                                              : handleShow();
                                          }}
                                          src={item.img || item.fileUrl}
                                          //src={img1}
                                          alt="axies"
                                          className="img-change"
                                        />
                                      </div>
                                      {props.loadMore && cardIndex === index ? (
                                        <div className="wishlist-button-loading">
                                          <i
                                            className="fa fa-spinner fa-spin fa-3x mb-3"
                                            aria-hidden="true"
                                            id="circle1"></i>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <div className={props?.home2 ? 'cardcontent-home2' : ''}>
                                      <div className="card-title"></div>
                                      <div
                                        className={
                                          props?.home2 ? 'meta-info tag-explore02' : 'meta-info'
                                        }>
                                        <div className="author">
                                          <div className="avatar">
                                            <img
                                              src={
                                                item?.userImage ||
                                                item?.userdetails?.userImage ||
                                                img1
                                              }
                                              alt="axies"
                                            />
                                          </div>
                                          <div className="info">
                                            <span>Owned By</span>
                                            <div style={{ cursor: 'pointer' }}>
                                              <h6
                                                onClick={() => {
                                                  ConnectedAccount
                                                    ? navigate(
                                                        `/authors-02/${item?.userId || item?._id}`,
                                                        {
                                                          state: item,
                                                        },
                                                      )
                                                    : handleShow();
                                                }}>
                                                {getshortcode(
                                                  item.walletAddress || item.ownerAddress,
                                                )}
                                              </h6>
                                            </div>
                                            {/* </h6> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-bottom">
                                        {!props?.home2 &&
                                        ConnectedAccount ===
                                          (item.walletAddress || item.currentAddress) &&
                                        item.price == 0 ? (
                                          ''
                                        ) : !props?.home2 &&
                                          ConnectedAccount ===
                                            (item.walletAddress || item.currentAddress) &&
                                          item.price != 0 ? (
                                          ''
                                        ) : (
                                          <>
                                            {item.price != 0 ? (
                                              <>{!props?.home2 && ConnectedAccount ? <></> : ''}</>
                                            ) : (
                                              <>
                                                {!props?.home2 &&
                                                item.isBid == true &&
                                                item.minBidValue > 0
                                                  ? ''
                                                  : !props?.home2 && ''}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}

                            {loadmore === 'filter data' && ''}
                            {data?.length < 10 || data?.length === undefined
                              ? ''
                              : loadmore === 'all data' &&
                                data?.length != props?.exploretotalItems - 2 && (
                                  <div className="col-md-12 wrap-inner load-more text-center">
                                    {data?.length > 0 && (
                                      <Link
                                        to="#"
                                        id="load-more"
                                        className="sc-button loadmore fl-button pri-3"
                                        onClick={() => props.increasepagenumber()}>
                                        {props.loadMore ? (
                                          <span>Loading..</span>
                                        ) : (
                                          <span>Load More</span>
                                        )}
                                        {props.loadMore && (
                                          <div className="wishlist-button-loading-loadmore">
                                            <i
                                              className="fa fa-spinner fa-spin fa-3x mb-3"
                                              aria-hidden="true"
                                              id="circle1"></i>
                                          </div>
                                        )}
                                      </Link>
                                    )}
                                  </div>
                                )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="content-item open" ref={listContent1}>
                      {GridLayout()}
                    </div>
                    <div className="content-item2" ref={imgContent}>
                      <div style={{ display: 'flex', flexFlow: 'wrap' }}>
                        {(data?.length <= 0 && !props?.noData) ||
                        props?.filterLoad ||
                        props?.pageLoad ? (
                          <div
                            style={{
                              textAlign: 'center',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}>
                            <i
                              className="fa fa-spinner fa-spin fa-3x mb-3"
                              aria-hidden="true"
                              id="circle1"></i>
                            <h2>Loading...</h2>
                          </div>
                        ) : (
                          <>
                            {props?.noData ? (
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}>
                                <h2>No data found...</h2>
                              </div>
                            ) : (
                              data?.map((item, index) => (
                                <div
                                  key={index}
                                  className={
                                    props?.home2
                                      ? // col-item
                                        'col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12'
                                      : 'fl-item col-xl-3 col-lg-2 col-md-6 col-sm-6'
                                  }>
                                  {/* {console.log('loggingTodaypicks', item)} */}
                                  <div
                                    className={
                                      props?.home2
                                        ? 'sc-card-product sc-card-product-home2 menu_card style-h7 '
                                        : `sc-card-product ${item.feature ? 'comingsoon' : ''} `
                                    }
                                    style={{ padding: '2px ' }}>
                                    <div
                                      className={
                                        // props?.home2 ? ' cardimg_sizeNoWidth' : '  cardimg_sizeNoWidth'
                                        // 'card-media cardimg_size'
                                        props?.home2
                                          ? ' cardimg_sizeNoWidth '
                                          : '  cardimg_sizeNoWidth'
                                      }>
                                      {props?.home2 &&
                                      ConnectedAccount ===
                                        (item.walletAddress || item.currentAddress) &&
                                      item.price != 0 ? (
                                        <div className="home2-btn-div">
                                          <button
                                            onClick={async () => {
                                              setselectedid(index);
                                              if (data[index].blockChainName == netWrokChainName) {
                                                setModalShow(true);
                                              }
                                              if (data[index].blockChainName != netWrokChainName) {
                                                getBlockChainData(data[index].blockChainName);
                                              }
                                            }}
                                            className="home2-btn">
                                            <img
                                              src={icons8bag}
                                              alt="bag"
                                              style={{ width: '18px', marginRight: '7px' }}
                                              // className="buyIconDiv"
                                            />
                                            <span>Change Price</span>
                                          </button>
                                        </div>
                                      ) : props?.home2 && item.price != 0 ? (
                                        <>
                                          {props?.home2 && ConnectedAccount ? (
                                            <div className="home2-btn-div">
                                              {/* <Link to={'/buy'} state={item}> */}
                                              <button
                                                // className="sc-button style bag fl-button pri-3 no-bg"
                                                className="home2-btn"
                                                onClick={async () => {
                                                  setselectedid(index);
                                                  // console.log(data[index], 'fullRes');
                                                  if (
                                                    data[index].blockChainName == netWrokChainName
                                                  ) {
                                                    setBuyModalShow(true);
                                                  }
                                                  if (
                                                    data[index].blockChainName != netWrokChainName
                                                  ) {
                                                    getBlockChainData(data[index].blockChainName);
                                                  }
                                                }}>
                                                <img
                                                  className="buyIconDiv"
                                                  src={buyIcon}
                                                  alt="buy"
                                                />

                                                <span>Buy now</span>
                                              </button>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.isBid == true && item.minBidValue > 0 ? (
                                            <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                              <div className="home2-btn-div">
                                                <button className="home2-btn">
                                                  <img
                                                    src={icons8bag}
                                                    alt="bag"
                                                    style={{ width: '18px', marginRight: '7px' }}
                                                    // className="buyIconDiv"
                                                  />
                                                  <span>Bid now</span>
                                                </button>
                                              </div>
                                            </Link>
                                          ) : (
                                            <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                              <div className="home2-btn-div">
                                                <button className="home2-btn">
                                                  <img
                                                    src={buyIcon}
                                                    alt="bag"
                                                    style={{ width: '18px', marginRight: '7px' }}
                                                    // className="buyIconDiv"
                                                  />
                                                  <span>Buy now</span>
                                                </button>
                                              </div>
                                            </Link>
                                          )}
                                        </>
                                      )}
                                      <div style={{ cursor: 'pointer' }} className="img-div">
                                        <img
                                          onClick={() => {
                                            ConnectedAccount
                                              ? navigate(`/buy/${item?.id || item?._id}`, {
                                                  state: item,
                                                })
                                              : handleShow();
                                          }}
                                          src={item.img || item.fileUrl}
                                          //src={img1}
                                          alt="axies"
                                          className="img-change vert-move"
                                        />
                                      </div>
                                      {props.loadMore && cardIndex === index ? (
                                        <div className="wishlist-button-loading">
                                          <i
                                            className="fa fa-spinner fa-spin fa-3x mb-3"
                                            aria-hidden="true"
                                            id="circle1"></i>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}

                            {loadmore === 'filter data' && ''}
                            {data?.length < 10 || data?.length === undefined
                              ? ''
                              : loadmore === 'all data' &&
                                data?.length != props?.exploretotalItems - 2 && (
                                  <div className="col-md-12 wrap-inner load-more text-center">
                                    {data?.length > 0 && (
                                      <Link
                                        to="#"
                                        id="load-more"
                                        className="sc-button loadmore fl-button pri-3"
                                        onClick={() => props.increasepagenumber()}>
                                        {props.loadMore ? (
                                          <span>Loading..</span>
                                        ) : (
                                          <span>Load More</span>
                                        )}
                                        {props.loadMore && (
                                          <div className="wishlist-button-loading-loadmore">
                                            <i
                                              className="fa fa-spinner fa-spin fa-3x mb-3"
                                              aria-hidden="true"
                                              id="circle1"></i>
                                          </div>
                                        )}
                                      </Link>
                                    )}
                                  </div>
                                )}
                          </>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            )}
          </div>
        </div>
      </section>
      {data[selectedid]?.multiToken === true || buyingModal['item']?.multiToken === true ? (
        <BuyMutiTokenModal
          show={buyModalShow}
          onHide={() => setBuyModalShow(false)}
          assetdata={buyingModal['item'] ? buyingModal['item'] : data[selectedid]}
        />
      ) : (
        <BuyModal
          show={buyModalShow}
          onHide={() => setBuyModalShow(false)}
          assetdata={buyingModal['item'] ? buyingModal['item'] : data[selectedid]}
        />
      )}
      <Modal show={show} onHide={handleClose} style={{ marginTop: '20rem' }}>
        <Modal.Header closeButton></Modal.Header>
        <div
          className="modal-body space-y-20 pd-40"
          style={{ marginLeft: '4rem', marginBottom: '3rem' }}>
          <h4 style={{ fontStyle: 'italic' }}>Please connect with wallet!</h4>
        </div>
      </Modal>
      <UnlockModal show={modalView} onHide={() => setmodalView(false)} selecteddata={selectedid} />

      <PutonsaleModal
        show={modalShow}
        onHide={() => {
          setbuyingModal({});
          setModalShow(false);
        }}
        selecteddata={buyingModal['item'] ? buyingModal['item'] : data[selectedid]}
      />
      <MessageNotifyModal
        show={switchShow}
        onHide={handleSwitchNetworkClose}
        chainName={chainName}
        chainId={nftChainId}
        chainIdAddress={chainContractAddress}
      />
    </Fragment>
  );
};

export default TodayPicks;
