const menus = [
  // {
  //   id: 0,
  //   name: 'Home',
  //   links: '/',
  // },
  {
    id: 0,
    name: 'Home',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Home 01',
        links: '/',
      },

      {
        id: 2,
        sub: 'Home 02',
        links: '/home-07',
      },
    ],
  },
  // {
  //   id: 1,
  //   name: 'Home2',
  //   links: '/home-08',
  // },

  {
    id: 1,
    name: 'Explore',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Explore-1',
        links: '/explore-01',
      },
      {
        id: 2,
        sub: 'Explore-2',
        links: '/explore-02',
      },
      {
        id: 3,
        sub: 'Explore-3',
        links: '/explore-03',
      },
    ],
  },

  {
    id: 2,
    name: 'Collectibles',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Browse items',
        links: '/explore-01',
      },
      {
        id: 2,
        sub: 'Browse collections',
        links: '/browse',
      },
    ],
  },
  {
    id: 3,
    name: 'My Profile',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Edit Profile',
        links: '/edit-profile',
      },

      {
        id: 2,
        sub: 'My collections',
        links: '/mycollection',
      },
      {
        id: 3,
        sub: 'My NFTs',
        links: '/mynft',
      },
      {
        id: 4,
        sub: 'My Favorites',
        links: '/myfavourits',
      },
    ],
  },
];

export default menus;
