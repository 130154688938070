//K as admin
//let contractApprovalAccount = '0x1f8b87471F075c9709065860C5F247f4449F7f7a';
////below address worked well before bidding process
//let contractApprovalAccount = '0xb7f4edea1cc0ee5cd377ab262c571e5888ba9b95'; // NFT contract address
//CPK (cryptoKing NFT) -- rinkeby
//let NFTContractAddress = '0xe6eC8285Fc7DB5002E66977063F83e73B326a9D0';
//PyraNftMarket721 --rinkeby
//let NFTContractAddress = '0x24B32a87d8F558EDc46D11E0BeB90AFAf628D6D5';
//below contract having issue while buyingNFT
//let NFTContractAddress = '0xc1005ecec069e8fef0b527423795a3c2c1789cbd';
//below address worked well before bidding process
//let NFTContractAddress = '0xb7f4edea1cc0ee5cd377ab262c571e5888ba9b95'; //worked well before bidding
//below contract has change price/cancel order included
//let NFTContractAddress = '0x5332fc60930745ce24ff44fb6a9dc49ae84f7e97';
//let NFTCollectionAddress = '0x64A2B4AafD6F5541d225c1A717713Bb5582Bea02';

//below address before implement bidding process
// let contractApprovalAccount = '0xb7f4edea1cc0ee5cd377ab262c571e5888ba9b95'; //set approval
// let NFTContractAddress = '0xb7f4edea1cc0ee5cd377ab262c571e5888ba9b95'; //pyranft contract

//below address for ERC-721
//ETH - Ethereum
let decimalvalues = 18;
//Rinkeby
// let NFTSingleTokenApproval = '0x0f3db03c1288a83ded124d188bc46ea52eb33a09'; //set approval
// let NFTContractAddress = '0x0f3db03c1288a83ded124d188bc46ea52eb33a09'; //pyranft contract
//Goerli - Ethereum testNet
let NFTSingleTokenApproval = '0x32Fe2A605BDdB548d3D2b19596a4B762E594c017'; //set approval
let NFTContractAddress = '0x32Fe2A605BDdB548d3D2b19596a4B762E594c017'; ///pyranft contract

//let NFTSingleTokenFlag = ''; //ERC-721

//BNB - Binance
// let NFTSingleTokenApproval = '0x7B23af3109e98A93d54AE0826d7b4A717ddf0eAd'; //set approval
// let NFTContractAddress = '0x7B23af3109e98A93d54AE0826d7b4A717ddf0eAd'; //pyranft contract

//AVAX - Avalanche
// let NFTSingleTokenApproval = '0x5cFC9e7EC2d3f07Bf83934D20e64f2f657C8c48E'; //set approval
// let NFTContractAddress = '0x5cFC9e7EC2d3f07Bf83934D20e64f2f657C8c48E'; //pyranft contract

//MATIC - polygon
// let NFTSingleTokenApproval = '0xd10B9e80c728f9E0E5C83e251C2Ea59e743D98F0'; //set approval
// let NFTContractAddress = '0xd10B9e80c728f9E0E5C83e251C2Ea59e743D98F0'; //pyranft contract

//wETH contract address
//rinkeby-TestNet - 0xc778417E063141139Fce010982780140Aa0cD5Ab
let wrapperContractAddress;
let wETHContractAddress = '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6';
let wAVAXContractAddress = '0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3';
let wBNBContracctAddress = '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd';
let wMATICContractAddress = '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889';
let tokenSymbol = 'ETH';
let biddingToken = 'WETH';

//Pyra MutilToken(ERC-1155) contract address
let NFTMultiTokenApproval = '0xa43a3d63f8d70bcd4aaac36350b6b8ce29a60e1c'; // for set approval
let NFTMultiTokenContract = '0xa43a3d63f8d70bcd4aaac36350b6b8ce29a60e1c';
//let NFTMultiTokenFlag = ''; ///ERC-1155

let vUrl = 'http://139.59.43.169:3000';
//ipfs image url
let IPFS_IMG = 'https://ipfs.io/ipfs';
let toasterOption = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

let key = {
  toasterOption: toasterOption,
  NFTSingleTokenApproval: NFTSingleTokenApproval,
  NFTContractAddress: NFTContractAddress,
  //NFTSingleTokenFlag: NFTSingleTokenFlag,
  //NFTCollectionAddress: NFTCollectionAddress,
  wETHContractAddress: wETHContractAddress,
  IPFS_IMG: IPFS_IMG,
  vUrl: vUrl,
  tokenSymbol: tokenSymbol,
  biddingToken: biddingToken,
  NFTMultiTokenContract: NFTMultiTokenContract,
  NFTMultiTokenApproval: NFTMultiTokenApproval,
  //NFTMultiTokenFlag: NFTMultiTokenFlag,
  decimalvalues: decimalvalues,
  wAVAXContractAddress: wAVAXContractAddress,
  wBNBContracctAddress: wBNBContracctAddress,
  wMATICContractAddress: wMATICContractAddress,
  wrapperContractAddress: wrapperContractAddress,
};

export default key;
