import React, { useState } from 'react';
import BidAcceptlModal from './BidAcceptlModal';
import biddingOrder from '../../../nft/BiddingNFT';
import BidingList from './AcceptBidingList';
import config from '../../../lib/config';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import { useContext } from 'react';

export default function BidsDetails(props) {
  const { ConnectedAccount } = useContext(ConnectWalletContext);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const { explorelist, bidDetails } = props;
  const [bidApproveData, setBidApproveData] = useState({});
  const [acceptBidData, setAcceptBidData] = useState({});

  //get the contract address
  let contractAddress = explorelist?.collectionAddress;
  if (contractAddress == undefined) {
    if (explorelist?.CollectionData?.length > 0) {
      contractAddress = explorelist?.CollectionData[0]?.address;
    } else contractAddress = config.NFTContractAddress;
  }
  console.log(ConnectedAccount);
  console.log('bid details ---->', bidDetails);
  let bidObjectValue = {};
  const onShowAcceptModal = async items => {
    setAcceptBidData(items);
    console.log('Bid Deails items', explorelist);
    setShowAcceptModal(true);
    const biddingOrderObj = await biddingOrder(
      explorelist.tokenId,
      items.bidderAddress,
      contractAddress, //explorelist?.collectionAddress,
    );
    //this bid amount should get from API approved bid amount value
    //let bidAmt = 0.04;
    let bidAmt = items.price;
    //get the user receivable value
    let nftRoy = bidAmt * (biddingOrderObj.NftTokenRoyValue / 10 ** 20);
    let nftServiceFees = bidAmt * (biddingOrderObj.NftServiceFee / 10 ** 20);
    let nftNetAmount = 0;
    //if TokenOwner is seller then royality value will be added to his netAmount
    //else royality will be deducted..
    if (ConnectedAccount == explorelist.currentAddress) {
      nftNetAmount = parseFloat(bidAmt - nftServiceFees).toFixed(4);
    } else {
      nftNetAmount = parseFloat(bidAmt - nftServiceFees - nftRoy).toFixed(4);
    }

    bidObjectValue = {
      bidderAddress: items.bidderAddress,
      serviceFee: biddingOrderObj.NftServiceFee,
      bidAmount: bidAmt, //this value is approved before it accept
      netAmount: nftNetAmount,
    };
    setBidApproveData(bidObjectValue);
  };

  const onCloseAcceptModal = () => {
    setShowAcceptModal(false);
  };

  return (
    <div>
      <div className={`sc-card-product explode style2 mg-bt`}>
        {/* <div> */}
        {/*use below message  if no active bids placed - check from api call*/}
        {/* <h6 className="text-center">No active bids yet... Be the first to make a bid!</h6> */}

        {/* <button
                className="sc-button style fl-button pri-3 no-bg mt-4 mr-4"
                onClick={() => props.modelopen('buy')}>
                <span className="pl-0">Buy now</span>
              </button> */}
        {/* <button
                className="sc-button style fl-button pri-3 no-bg mt-4"
                onClick={() => props.modelopen('bubity')}>
                <span className="pl-0">Bid now</span>
              </button> */}
        {/* </div> */}
        <div>
          <h4 className="text-center">List of active bids!</h4>
          {bidDetails.length >= 1 &&
            bidDetails
              .sort((a, b) => b.price - a.price)
              .filter((d, i) => i < 3)
              .map((item, index) => {
                return (
                  <>
                    <div key={index} className="mt-10">
                      <BidingList data={item} accept={onShowAcceptModal} />
                    </div>
                  </>
                );
              })}
          {bidDetails.length == 0 && (
            <div className="text-center mt-10">
              <h6>No active bids!</h6>
            </div>
          )}
        </div>
      </div>
      <BidAcceptlModal
        show={showAcceptModal}
        onHide={() => onCloseAcceptModal()}
        explorelist={props.explorelist}
        bidObjectValue={bidApproveData}
        acceptBidData={acceptBidData}
      />
    </div>
  );
}
