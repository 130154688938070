import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import shape1 from '../../assets/images/backgroup-secsion/bg-gradient1.png';
import shape2 from '../../assets/images/backgroup-secsion/bg-gradient2.png';
import shape3 from '../../assets/images/backgroup-secsion/bg-gradient3.png';
import imgbg from '../../assets/images/backgroup-secsion/img_bg_page_title.jpg';
import { ConnectWalletContext } from '../../context/ConnectWalletContext';
//import { toast } from 'react-toastify';
//import commonFunction from '../../utils/common';
import { Modal } from 'react-bootstrap';

const Slider = props => {
  const data = props.data;
  const { ConnectedAccount } = useContext(ConnectWalletContext);

  return (
    <div className="mainslider">
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}>
        {data.map((item, index) => (
          <SwiperSlide key={index} className={item.class}>
            <SliderItem item={item} index={index} ConnectedAccount={ConnectedAccount} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  data: PropTypes.array.isRequired,
  control: PropTypes.bool,
  auto: PropTypes.bool,
  timeOut: PropTypes.number,
};
const SliderItem = props => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="flat-title-page" style={{ backgroundImage: `url(${imgbg})` }}>
      <img className="bgr-gradient gradient1" src={shape1} alt="Axies" />
      <img className="bgr-gradient gradient2" src={shape2} alt="Axies" />
      <img className="bgr-gradient gradient3" src={shape3} alt="Axies" />
      <div className="shape item-w-16"></div>
      <div className="shape item-w-22"></div>
      <div className="shape item-w-32"></div>
      <div className="shape item-w-48"></div>
      <div className="shape style2 item-w-51"></div>
      <div className="shape style2 item-w-51 position2"></div>
      <div className="shape item-w-68"></div>
      <div className="overlay"></div>
      <div className="swiper-container mainslider home">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="slider-item">
              <div className="themesflat-container ">
                <div className="wrap-heading flat-slider flex">
                  <div className="content">
                    <h2 className="heading">{props.item.title_1}</h2>
                    <h1 className="heading mb-style">
                      <span className="tf-text s1">{props.item.title_2} &nbsp;</span>
                    </h1>
                    <h1 className="heading">{props.item.title_3}</h1>
                    <p className="sub-heading">{props.item.description}</p>
                    <div className="flat-bt-slider flex style2">
                      <Link
                        to={
                          (props.index === 0 && '/explore-01') ||
                          (props.index === 1 && '/explore-02') ||
                          (props.index === 2 && '/explore-03')
                        }
                        className="sc-button header-slider style style-1 rocket fl-button pri-1">
                        <span>Explore</span>
                      </Link>

                      <button
                        onClick={() =>
                          props.ConnectedAccount ? navigate('/create-item') : handleShow()
                        }
                        className="sc-button header-slider style style-1 note fl-button pri-1">
                        <span>Create</span>
                      </button>
                    </div>
                  </div>
                  <div className="image">
                    {/* <img className="img-bg" src={props.item.imgbg} alt="axies" /> */}
                    {/* <img src={props.item.imgbg} alt="axies" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} style={{ marginTop: '20rem' }}>
        <Modal.Header closeButton></Modal.Header>
        <div
          className="modal-body space-y-20 pd-40"
          style={{ marginLeft: '4rem', marginBottom: '3rem' }}>
          <h4 style={{ fontStyle: 'italic' }}>Please connect with wallet!</h4>
        </div>
      </Modal>
    </div>
  );
};
export default Slider;
