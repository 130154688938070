import loadWeb3 from '../utils/loadWeb3';
import PyraNftMarket721 from '../ABI/PyraNftMarket.json';

import config from '../lib/config';

//Calling smart contract for placing the buying order
const pERCent = async (value1, value2) => {
  //This division (10 **20) followed in the smart contract
  let result = (value1 * value2) / 10 ** 20;
  return result;
};

const buyingOrder = async (tokenId, contractAddress) => {
  let web3 = await loadWeb3();
  //setBuyingTokenStatus('processing')
  //get collection contract address else use sigleContract address as default
  let NFTContractAddress = contractAddress ? contractAddress : config.NFTContractAddress;
  console.log('Buying modal contract address...', NFTContractAddress);
  //convert the address by using checksum
  // console.log('NFTContractAddress', contractAddress);
  // let address = web3.utils.toChecksumAddress(NFTContractAddress);
  // let checkAddress = web3.utils.checkAddressChecksum(contractAddress);
  // console.log('ChekcAddress Chekcsum', checkAddress);
  //get the NFT contract instance
  try {
    const nftContract = new web3.eth.Contract(PyraNftMarket721, NFTContractAddress);
    //get the token Owner address
    let NftTokenOwnerOf = await nftContract.methods.ownerOf(tokenId).call();
    console.log('NFT token Owner ->', NftTokenOwnerOf);
    //setNftTokenOwner(NftTokenOwnerOf)
    //get token selling price
    let NftSaleOrderPrice = await nftContract.methods.order_place(NftTokenOwnerOf, tokenId).call();
    //setNftTokenSaleOrderPrice(NftSaleOrderPrice.price / 10 ** 18)
    //proceed only when selling order price is placed
    if (NftSaleOrderPrice.price > 0) {
      //get serivce fee
      let NftServiceFee = await nftContract.methods.getServiceFee().call();
      let NftTokenServiceFeeCalc = await pERCent(NftSaleOrderPrice.price, NftServiceFee);
      let NftTokenValue = parseFloat(
        parseFloat(NftTokenServiceFeeCalc) + parseFloat(NftSaleOrderPrice.price),
      );
      return {
        NftTokenPrice: NftSaleOrderPrice.price / 10 ** 18,
        NftServiceFee: NftServiceFee / 10 ** 18,
        NftTokenValue: NftTokenValue / 10 ** 18,
      };
    }
  } catch (error) {
    console.log();
  }

  //console.log('Prabu NFT token final value to pay', NftTokenValue)
  //showing in ethers
  /* -- commented - uncomment when need to use these values 
    //get royality
    let NftTokenRoyValue = await nftContract.methods._royal(tokenId).call();
    //calc the royality value
    let NftTokenRoy = await pERCent(NftSaleOrderPrice.price, NftTokenRoyValue);
    //console.log('Prabu NFT royality token value', NftTokenRoy / 10 ** 18)
    //net amount the seller will receive
    let NftFee = parseFloat(NftTokenServiceFeeCalc + NftTokenServiceFeeCalc);
    //console.log('Prabu NFT Fee', NftFee / 10 ** 18)
    let NftNetAmount = parseFloat(NftTokenRoy + NftTokenServiceFeeCalc);
    //console.log('Prabu nft chargeble amount (serviceFee+Roy)', NftNetAmount)
    let sellerReceiveNetAmt = parseFloat(NftSaleOrderPrice.price - NftNetAmount);
    console.log('Buying admin fee', NftFee / 10 ** 18);
    console.log('Buying royality', NftTokenRoy / 10 ** 18);
    //console.log('Prabu nft seller received amount', sellerReceiveNetAmt)
    console.log('seller received Net-amount in wei', sellerReceiveNetAmt / 10 ** 18);
    */
};

export default buyingOrder;
