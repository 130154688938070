import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import { _popularCollectionList } from '../../../services/mainApi';
import shortenAccount from '../../../utils/shortenAccount';

//import imgtop1 from '../../../assets/images/box-item/collection-item-top-1.jpg';
// import imgAuthor1 from '../../../assets/images/avatar/avt-8.jpg';

const PopularCollection = props => {
  const [data, setData] = useState([]);
  useEffect(() => {
    _popularCollectionList().then(res => {
      if (res.data.error == false) {
        setData(res?.data?.data);
        if (props?.Home == 2) {
          props?.settotalItems(res?.data?.data?.length);
        }
      } else {
        props.settotalItems(0);
      }
    });
  }, []);

  return (
    <section className="tf-section popular-collection bg-home-3">
      <div className="themesflat-container">
        {data?.length <= 0 ? (
          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <h2>No data found...</h2>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <h2 className="tf-title style2">Popular Collection</h2>
                <div className="heading-line"></div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="collection">
                <Swiper
                  className={props?.Home == 2 && 'swiperHome2'}
                  modules={[Navigation, Scrollbar, A11y]}
                  spaceBetween={30}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    767: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                  navigation
                  scrollbar={{ draggable: true }}>
                  {data.map((item, index) => (
                    <SwiperSlide key={index}>
                      <PopularCollectionItem Home={props.Home} item={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
PopularCollection.propTypes = {
  data: PropTypes.array.isRequired,
};

const PopularCollectionItem = props => (
  <div className="swiper-container show-shadow carousel4 button-arow-style">
    <div className="swiper-wrapper">
      <div className="swiper-slide">
        <div className="slider-item">
          <div
            className={
              props.Home === 2 ? 'sc-card-collection-sizeHome2' : 'sc-card-collection-size'
            }>
            <div className="sc-card-collection">
              <Link to={`/explore-01/${props.item.collectionId}`}>
                <div className="media-images-box">
                  <div className="collection-img-div">
                    <img src={props?.item?.collectionImage} alt="Axies" />
                  </div>
                  <div className="bottom-media">
                    {props.item.NftItemArray.slice(0, 3).map((e, i) => {
                      return <img key={i} src={e.fileUrl} alt="Axies" />;
                    })}
                  </div>
                </div>
              </Link>
              <div className="card-bottom">
                <div className="author">
                  <div className="sc-author-box style-2">
                    <div className="author-avatar">
                      <img src={props?.item?.collectionImage} alt="Axies" className="avatar" />
                      <div className="badge">
                        <i className="ripple"></i>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to={`/explore-01/${props.item.collectionId}`}>{props.item.name}</Link>
                    </h4>
                    <div className="infor">
                      <span>Created by</span>
                      <span className="name">
                        {/* <Link to="/authors-01">{props.item?.address}</Link> */}
                        {/* <Link to="/authors-02" state={props?.item}> */}
                        <span className="span-tag">{shortenAccount(props.item?.address)}</span>

                        {/* </Link> */}
                        {console.log(props.item, 'prooop')}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <Link to="/login" className="wishlist-button public heart"> */}
                <span className="number-like">{props.item.wishlist}</span>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PopularCollection;
