import React, {
  useState,
  Fragment,
  //  useEffect,
  useContext,
} from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  //  Pagination,
  Scrollbar,
  A11y,
} from 'swiper';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import CardModal from '../../CardModal';
//import CardModal from '../../CardModal';
// import { _categoryList } from '../../../services/mainApi';
import { getshortcode } from '../../../utils/helpers';
import { Dropdown } from 'react-bootstrap';
// import commonFunction from '../../../utils/common';
import PutonsaleModal from '../../PutonsaleModal'; //--
import BuyModal from '../MyItems/BuyModal';
import BuyMutiTokenModal from '../MyItems/BuyMutiTokenModal';
import img1 from '../../../assets/images/avatar/previewThumb.png';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
// import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
//select

// import MenuItem from '@mui/material/MenuItem';
// import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import * as Mui from '@mui/material';
import MessageNotifyModal from '../../../modal/MessageNotifyModal';
import getChainContractAddress from '../../../utils/getChainContractAddress';
import avaxLogo from '../../../assets/cryptoLogo/avalanche-avax-icon.svg';
import bnbLogo from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../../../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../../../assets/cryptoLogo/ethereum-eth-icon.svg';
// import { faLock } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnlockModal from '../../UnlockModal';
import MenuIcon from '../../../assets/images/icon/menu.svg';
import menuIcon from '../../../assets/images/icon/menuIcon.png';
import icons8bag from '../../../assets/images/icon/bagg.png';
import buyIcon from '../../../assets/images/icon/icons8-buying-48.png';
import { Modal } from 'react-bootstrap';

const TodayPicks = props => {
  const { ConnectedAccount, netWrokChainName } = useContext(ConnectWalletContext);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalView, setmodalView] = useState(false);
  const [switchShow, setSwitchShow] = useState(false);
  const [chainContractAddress, setChainContractAddress] = useState();
  const [chainName, setChainName] = useState();
  const [nftChainId, setNftChainId] = useState();
  const [modalShow, setModalShow] = useState(false); //--
  const [buyModalShow, setBuyModalShow] = useState(false);
  // const [priceShow, setpriceShow] = useState(false);
  // const [categorylist, setcategorylist] = useState([]);
  // const [selectedsortId, setselectedsortId] = useState(0);
  // const [selectedcategoryId, setselectedcategoryId] = useState(0);
  // const [selectedstatusId, setselectedstatusId] = useState(0);
  // const [selectedquantityId, setselectedquantityId] = useState(0);
  const [cardIndex, setCardIndex] = useState();
  const [selectedid, setselectedid] = useState();
  // const [searchData, setSearchData] = useState(''); //--
  const data = props.data;
  let loadmore = props.loadmore;

  const { buyingModal, setbuyingModal } = React.useContext(ConnectWalletContext);

  const handleSwitchNetworkClose = () => {
    setSwitchShow(false);
  };

  const getBlockChainData = async chainName => {
    setSwitchShow(true);
    setChainName(chainName);
    let chainData = await getChainContractAddress(chainName);
    setChainContractAddress(chainData.NFTcontractAddress);
    setNftChainId(chainData.chainId);
    //setCryptoSymbol(chainData.cryptoSymbol);
  };
  React.useEffect(() => {
    // setselectedid(index);
    if (buyingModal['item']) {
      if (buyingModal['item']?.blockChainName == netWrokChainName) {
        if (buyingModal['type'] == 'changePrice') {
          setModalShow(true);
        } else {
          setBuyModalShow(true);
        }
      }
      if (buyingModal['item']?.blockChainName != netWrokChainName) {
        getBlockChainData(buyingModal['item']?.blockChainName);
      }
    }
  }, [buyingModal]);

  return (
    <Fragment>
      <section className="tf-explore-2 tf-section live-auctions">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 mg-bt-13">Pyra NFTs Marketplace</h2>
              <p className="sub-title ct small mg-bt-20 pad-420">
                PyraNFT is a NFT Marketplace Framework developed for companies to succeed in the
                web3 space.
              </p>
              <>
                {
                  <>
                    {(data?.length <= 0 && !props?.noData) || props?.filterLoad ? (
                      <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                        <i
                          className="fa fa-spinner fa-spin fa-3x mb-3"
                          aria-hidden="true"
                          id="circle1"></i>
                        <h2>Loading...</h2>
                      </div>
                    ) : (
                      <>
                        {props?.noData ? (
                          <div
                            style={{
                              textAlign: 'center',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}>
                            <h2>No data found...</h2>
                          </div>
                        ) : (
                          <div className="collection">
                            <Swiper
                              className={props?.Home == 2 && 'swiperHome2'}
                              modules={[Navigation, Scrollbar, A11y]}
                              spaceBetween={30}
                              breakpoints={{
                                0: {
                                  slidesPerView: 1,
                                },
                                767: {
                                  slidesPerView: 2,
                                },
                                991: {
                                  slidesPerView: 3,
                                },
                                1200: {
                                  slidesPerView: 4,
                                },
                              }}
                              navigation
                              pagination={{ clickable: true }}
                              scrollbar={{ draggable: true }}>
                              {data?.map((item, index) => (
                                <SwiperSlide key={index}>
                                  <div className="swiper-container show-shadow carousel auctions">
                                    <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                        <div className="slider-item">
                                          <div
                                            className={`sc-card-product ${
                                              item.feature ? 'comingsoon' : ''
                                            } `}>
                                            <div
                                              className={
                                                props?.home2
                                                  ? ' cardimg_sizeNoWidth'
                                                  : // ? 'card-media cardimg_sizeNoWidth'
                                                    'card-media cardimg_size'
                                              }>
                                              <div
                                                style={{ cursor: 'pointer' }}
                                                className="img-div">
                                                <img
                                                  onClick={() => {
                                                    ConnectedAccount
                                                      ? navigate(`/buy/${item?.id || item?._id}`, {
                                                          state: item,
                                                        })
                                                      : handleShow();
                                                  }}
                                                  src={item.img || item.fileUrl}
                                                  alt="axies"
                                                  className="img-change"
                                                />
                                              </div>
                                              {props.loadMore && cardIndex === index ? (
                                                <div className="wishlist-button-loading">
                                                  <i
                                                    className="fa fa-spinner fa-spin fa-3x mb-3"
                                                    aria-hidden="true"
                                                    id="circle1"></i>
                                                </div>
                                              ) : (
                                                <div
                                                  onClick={() => {
                                                    {
                                                      ConnectedAccount
                                                        ? (props.addOrRemoveFav(item),
                                                          setCardIndex(index))
                                                        : handleShow();
                                                    }
                                                  }}
                                                  className={
                                                    item.favStatus
                                                      ? props?.home2
                                                        ? 'wishlist-button icon-fl-heart-filled home2-wish'
                                                        : 'wishlist-button icon-fl-heart-filled'
                                                      : props?.home2
                                                      ? 'wishlist-button heart home2-wish'
                                                      : 'wishlist-button heart'
                                                  }>
                                                  <span className="number-like">
                                                    {item.wishlist || item.favCount}
                                                  </span>
                                                </div>
                                              )}

                                              <div className="coming-soon">{item.feature}</div>
                                            </div>
                                            <div className="card-title">
                                              <h5 className="style2">
                                                {item.blockChainName == 'Avalanche' ? (
                                                  <img className="cryptoImg" src={avaxLogo} />
                                                ) : item.blockChainName == 'Binance' ? (
                                                  <img className="cryptoImg" src={bnbLogo} />
                                                ) : item.blockChainName == 'Ethereum' ? (
                                                  <img className="cryptoImg" src={ethLogo} />
                                                ) : item.blockChainName == 'Polygon' ? (
                                                  <img className="cryptoImg" src={maticLogo} />
                                                ) : (
                                                  ''
                                                )}
                                                &nbsp;&nbsp;
                                                <Link
                                                  to={`/buy/${item?.id || item?._id}`}
                                                  state={item}>
                                                  {item.title}
                                                </Link>
                                              </h5>
                                              {props?.home2 ? (
                                                <Dropdown style={{ width: '150px !important' }}>
                                                  <Dropdown.Toggle
                                                    id="dropdown-basic"
                                                    className="btn-menu dropdown btn-noColr">
                                                    <img
                                                      src={
                                                        localStorage.theme === 'is_dark'
                                                          ? menuIcon
                                                          : MenuIcon
                                                      }
                                                      className="menu-itemImage"
                                                      alt="axies"
                                                    />
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu style={{ margin: 0 }}>
                                                    {ConnectedAccount === item.walletAddress &&
                                                    item.price != 0 ? (
                                                      <>
                                                        <Dropdown.Item
                                                          href="#"
                                                          onClick={() => {
                                                            setselectedid(index);
                                                            if (
                                                              data[index].blockChainName ==
                                                              netWrokChainName
                                                            ) {
                                                              setModalShow(true);
                                                            }
                                                            if (
                                                              data[index].blockChainName !=
                                                              netWrokChainName
                                                            ) {
                                                              getBlockChainData(
                                                                data[index].blockChainName,
                                                              );
                                                            }
                                                            // if (
                                                            //   item?.ownerAddress !== item?.userdetails?.walletAddress
                                                            // ) {
                                                            //   setselectedid(item);
                                                            //   setBuyModalShow(true);
                                                            // }
                                                          }}>
                                                          Change Price
                                                        </Dropdown.Item>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <Dropdown.Item
                                                          href="#"
                                                          onClick={async () => {
                                                            setselectedid(index);
                                                            if (
                                                              data[index].blockChainName ==
                                                              netWrokChainName
                                                            ) {
                                                              setBuyModalShow(true);
                                                            }
                                                            if (
                                                              data[index].blockChainName !=
                                                              netWrokChainName
                                                            ) {
                                                              getBlockChainData(
                                                                data[index].blockChainName,
                                                              );
                                                              // setSwitchShow(true);
                                                              // setChainName(data[index].blockChainName);
                                                              // let chainAdress = await getChainContractAddress(
                                                              //   data[index].blockChainName,
                                                              // );
                                                              // setChainContractAddress(chainAdress.NFTcontractAddress);
                                                              // setNftChainId(chainAdress.chainId);
                                                            }
                                                          }}>
                                                          <img
                                                            className="buyIconDiv"
                                                            src={buyIcon}
                                                            alt="buy"
                                                          />
                                                          Buy now
                                                        </Dropdown.Item>

                                                        <Link
                                                          to={`/buy/${item?.id || item?._id}`}
                                                          state={item}>
                                                          <img
                                                            src={icons8bag}
                                                            alt="bag"
                                                            style={{
                                                              width: '18px',
                                                              marginRight: '7px',
                                                            }}
                                                            // className="buyIconDiv"
                                                          />
                                                          Bid now
                                                        </Link>
                                                      </>
                                                    )}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              ) : (
                                                <div className="tags">
                                                  {item.tags || item?.CategoryData?.name}
                                                </div>
                                              )}
                                            </div>
                                            <div
                                              className={
                                                props?.home2
                                                  ? 'meta-info tag-explore02'
                                                  : 'meta-info'
                                              }>
                                              <div className="author">
                                                <div className="avatar">
                                                  <img
                                                    src={
                                                      item?.userImage ||
                                                      item?.userdetails?.userImage ||
                                                      img1
                                                    }
                                                    alt="axies"
                                                  />
                                                </div>
                                                <div className="info">
                                                  <span>Owned By</span>
                                                  {/* <h6> */}
                                                  {/* <Link to="/authors-02" state={item}>
                                  {getshortcode(item.walletAddress || item.ownerAddress)}
                                </Link> */}
                                                  <div style={{ cursor: 'pointer' }}>
                                                    <h6
                                                      onClick={() => {
                                                        ConnectedAccount
                                                          ? navigate(
                                                              `/authors-02/${
                                                                item?.userId || item?._id
                                                              }`,
                                                              {
                                                                state: item,
                                                              },
                                                            )
                                                          : handleShow();
                                                      }}>
                                                      {getshortcode(
                                                        item.walletAddress || item.ownerAddress,
                                                      )}
                                                    </h6>
                                                  </div>
                                                  {/* </h6> */}
                                                </div>
                                              </div>
                                              <div className="price">
                                                {/* <span>Current price</span> */}
                                                {item?.isBid ? (
                                                  <span>Mid Bid Value</span>
                                                ) : (
                                                  <span>Current price</span>
                                                )}
                                                <h5>
                                                  {item?.isBid ? item?.minBidValue : item.price}{' '}
                                                  &nbsp;
                                                  {item.blockChainName == 'Avalanche'
                                                    ? 'AVAX'
                                                    : item.blockChainName == 'Binance'
                                                    ? 'BNB'
                                                    : item.blockChainName == 'Ethereum'
                                                    ? 'ETH'
                                                    : item.blockChainName == 'Polygon'
                                                    ? 'MATIC'
                                                    : item.blockChainName == undefined
                                                    ? 'ETH'
                                                    : ''}
                                                </h5>
                                              </div>
                                            </div>
                                            <div className="card-bottom">
                                              {ConnectedAccount ===
                                                (item.walletAddress || item.currentAddress) &&
                                              item.price == 0 &&
                                              !props?.home2 ? (
                                                <button
                                                  onClick={() => {
                                                    setselectedid(index);
                                                    setModalShow(true);
                                                  }}
                                                  className="sc-button style bag fl-button pri-3 no-bg">
                                                  {/* <span>Put On Sale</span> */}
                                                  {item?.isBid ? (
                                                    <span>Change Bid Value</span>
                                                  ) : (
                                                    <span>Put On Sale</span>
                                                  )}
                                                </button>
                                              ) : ConnectedAccount === item.walletAddress &&
                                                item.price != 0 &&
                                                !props?.home2 ? (
                                                // <div className="sc-button style bag fl-button pri-3 no-bg">
                                                //   <Link to={'/buy'} state={item}>
                                                //     <span>Change Price</span>
                                                //   </Link>
                                                // </div>
                                                <button
                                                  onClick={async () => {
                                                    setselectedid(index);
                                                    if (
                                                      data[index].blockChainName == netWrokChainName
                                                    ) {
                                                      setModalShow(true);
                                                    }
                                                    if (
                                                      data[index].blockChainName != netWrokChainName
                                                    ) {
                                                      getBlockChainData(data[index].blockChainName);
                                                      // setSwitchShow(true);
                                                      // setChainName(data[index].blockChainName);
                                                      // let chainAdress = await getChainContractAddress(
                                                      //   data[index].blockChainName,
                                                      // );
                                                      // setChainContractAddress(chainAdress.NFTcontractAddress);
                                                      // setNftChainId(chainAdress.chainId);
                                                    }
                                                  }}
                                                  className="sc-button style bag fl-button pri-3 no-bg">
                                                  <span>Change Price</span>
                                                </button>
                                              ) : props?.home2 ? (
                                                ''
                                              ) : (
                                                <>
                                                  {item.price != 0 ? (
                                                    <>
                                                      {ConnectedAccount ? (
                                                        <>
                                                          {/* <Link to={'/buy'} state={item}> */}
                                                          <button
                                                            className="sc-button style bag fl-button pri-3 no-bg"
                                                            onClick={async () => {
                                                              setselectedid(index);
                                                              // console.log(data[index], 'fullRes');
                                                              if (
                                                                data[index].blockChainName ==
                                                                netWrokChainName
                                                              ) {
                                                                setBuyModalShow(true);
                                                              }
                                                              if (
                                                                data[index].blockChainName !=
                                                                netWrokChainName
                                                              ) {
                                                                getBlockChainData(
                                                                  data[index].blockChainName,
                                                                );
                                                                // setSwitchShow(true);
                                                                // setChainName(data[index].blockChainName);
                                                                // let chainAdress = await getChainContractAddress(
                                                                //   data[index].blockChainName,
                                                                // );
                                                                // setChainContractAddress(chainAdress.NFTcontractAddress);
                                                                // setNftChainId(chainAdress.chainId);
                                                              }
                                                            }}>
                                                            <span>Buy now</span>
                                                          </button>
                                                          {/* </Link> */}

                                                          {/* <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                          <button className="sc-button style bag fl-button pri-3 no-bg">
                                            <span>Bid now</span>
                                          </button>
                                        </Link> */}
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {ConnectedAccount ? (
                                                        <>
                                                          {item.isBid == true &&
                                                          item.minBidValue > 0 ? (
                                                            <Link
                                                              to={`/buy/${item?.id || item?._id}`}
                                                              state={item}>
                                                              <button className="sc-button style bag fl-button pri-3 no-bg">
                                                                <span>Bid now</span>
                                                              </button>
                                                            </Link>
                                                          ) : (
                                                            <h4 className="sc-button no-bg">
                                                              <span>Unlisted</span>
                                                            </h4>
                                                          )}
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        )}{' '}
                        {loadmore === 'filter data' && ''}
                        {data?.length < 10 || data?.length === undefined
                          ? ''
                          : loadmore === 'all data' &&
                            data?.length != props?.exploretotalItems - 2 && (
                              <div className="col-md-12 wrap-inner load-more text-center">
                                {data?.length > 0 && (
                                  <Link
                                    to="#"
                                    id="load-more"
                                    className="sc-button loadmore fl-button pri-3"
                                    onClick={() => props.increasepagenumber()}>
                                    {props.loadMore ? (
                                      <span>Loading..</span>
                                    ) : (
                                      <span>Load More</span>
                                    )}
                                    {props.loadMore && (
                                      <div className="wishlist-button-loading-loadmore">
                                        <i
                                          className="fa fa-spinner fa-spin fa-3x mb-3"
                                          aria-hidden="true"
                                          id="circle1"></i>
                                      </div>
                                    )}
                                  </Link>
                                )}
                              </div>
                            )}
                      </>
                    )}
                  </>
                }
              </>
            </div>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
      {data[selectedid]?.multiToken === true || buyingModal['item']?.multiToken === true ? (
        <BuyMutiTokenModal
          show={buyModalShow}
          onHide={() => setBuyModalShow(false)}
          assetdata={buyingModal['item'] ? buyingModal['item'] : data[selectedid]}
        />
      ) : (
        <BuyModal
          show={buyModalShow}
          onHide={() => setBuyModalShow(false)}
          assetdata={buyingModal['item'] ? buyingModal['item'] : data[selectedid]}
        />
      )}
      <Modal show={show} onHide={handleClose} style={{ marginTop: '20rem' }}>
        <Modal.Header closeButton></Modal.Header>
        <div
          className="modal-body space-y-20 pd-40"
          style={{ marginLeft: '4rem', marginBottom: '3rem' }}>
          <h4 style={{ fontStyle: 'italic' }}>Please connect with wallet!</h4>
        </div>
      </Modal>
      <UnlockModal show={modalView} onHide={() => setmodalView(false)} selecteddata={selectedid} />

      <PutonsaleModal
        show={modalShow}
        onHide={() => {
          setbuyingModal({});
          setModalShow(false);
        }}
        selecteddata={buyingModal['item'] ? buyingModal['item'] : data[selectedid]}
      />
      <MessageNotifyModal
        show={switchShow}
        onHide={handleSwitchNetworkClose}
        chainName={chainName}
        chainId={nftChainId}
        chainIdAddress={chainContractAddress}
      />
    </Fragment>
  );
};

export default TodayPicks;
