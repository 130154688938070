import React, { useContext } from 'react';
import loadWeb3 from '../utils/loadWeb3';
import PyraNftMarket721 from '../ABI/PyraNftMarket.json';
import PyraMultiToken1155 from '../ABI/PyraMultiToken.json';
import config from '../lib/config';
import { Modal } from 'react-bootstrap';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import { toast } from 'react-toastify';
import { _updateNft } from '..//services/mainApi';

const TransferModal = props => {
  const { data } = props;
  const [Address, setAddress] = useState('');
  const [progress, setprogress] = React.useState(false);
  const { ConnectedAccount } = useContext(ConnectWalletContext);

  //get the contract address
  let contractAddress = data?.collectionAddress;
  let NFTtokenContract = PyraNftMarket721;
  if (contractAddress == undefined) {
    if (data?.CollectionData?.length > 0) {
      contractAddress = data?.CollectionData[0]?.address;
    } else if (data?.multiToken === true) {
      //check for ERC-1155 then get the respective contract address
      contractAddress = config.NFTMultiTokenContract;
      NFTtokenContract = PyraMultiToken1155;
      console.log('NFT Transferring Muti token ERC1155 Contract address', contractAddress);
    } else {
      //check for ERC-721 then get that contract address
      contractAddress = config.NFTContractAddress;
    }
  }

  const inputChange = e => {
    //e.preventDefault();
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      let value = e.target.value;
      switch (e.target.name) {
        case 'Address':
          setAddress(value);
          break;
        default:
      }
    }
  };
  //Transfer logic
  const onTransferHandle = async () => {
    console.log('Transfer nft data', data);
    console.log('Transfer for contract address', contractAddress);
    console.log('Contract data', NFTtokenContract);
    let web3 = await loadWeb3();
    setprogress(true);
    let nftContract;
    let result;
    try {
      nftContract = new web3.eth.Contract(NFTtokenContract, contractAddress);
      console.log('nftContract', nftContract);
      console.log('Transfer TokenId', data?.tokenId);
      console.log('Transfer From Address', ConnectedAccount);
      console.log('Transfer to Address', Address);

      result = await nftContract.methods
        .safeTransferFrom(ConnectedAccount, Address, data?.tokenId)
        .send({
          from: ConnectedAccount,
        });
      //call updateNFT API once the above BuyingNFT process is done
      if (result && result.transactionHash) {
        console.log('Transfer process starts in API call for trXh', result.transactionHash);
        const _myNftpromise = _updateNft({
          currentAddress: Address,
          fileUrl: data?.fileUrl ? data?.fileUrl : data?.img,
          title: data.title,
          description: data.description,
          categoryId: data.categoryId,
          putOnMarketplace: false,
          noOfCopies: 1,
          _id: data?._id ? data?._id : data?.id,
          price: 0,
          properties: data.properties,
          blockChainName: data?.blockChainName,
          tokenId: data.tokenId,
        });
        _myNftpromise
          .then(
            res => {
              if (!res.error) {
                console.log('Transfer update NFT result', res);
                console.log('Transfer Successfully done');
                //window.location = window.location.href;
                //navigate('/mynft');
              }
            },
            err => {
              console.log('Transfer fail', err.message);
            },
          )

          .catch(err => {
            console.log('Transfer update failed', err.message);
            setprogress(false);
          });
      }
      setprogress(false);
    } catch (error) {
      console.log('Trasnfer process failed with error..', error);
      toast.error('Transfer is not success');
      setprogress(false);
    }
  };
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          props.onHide();
          setAddress();
          setprogress(false);
        }}>
        <Modal.Header closeButton></Modal.Header>

        <div className="modal-body space-y-20 pd-30">
          <Modal.Header className="center">
            <h3 className="alignin-center" style={{ fontSize: '5rem' }}>
              Transfer
            </h3>
          </Modal.Header>
          <>
            {/* {data?.map((item, index) => ( */}
            <div>
              <div className="card143 card-1143 vert-move" style={{ marginLeft: '9vw' }}>
                <img
                  style={{ padding: '3%', borderRadius: '50px', height: '100%', width: '100%' }}
                  src={data?.img || data?.fileUrl}
                  alt="logoimg"
                />
              </div>
              <div className="address-container">
                <div style={{ fontSize: '2rem', fontWeight: '400', ml: '3' }}>Address</div>
                <input
                  name="Address"
                  onChange={inputChange}
                  className="Address"
                  type="text"
                  placeholder="Enter wallet address...."
                  required
                />
              </div>
              <br></br>
              <br></br>
              <div style={{ fontSize: '2rem', fontWeight: '400' }}>
                {data.title}
                {''} will be transferred to {''}
                <span>{Address}</span>
              </div>
              <br></br>
              <br></br>
              <div style={{ fontSize: '1.5rem', fontWeight: '200' }}>
                <FontAwesomeIcon size="1x" icon={faTriangleExclamation} />
                {''} Items sent to the wrong address cannot be recovered
              </div>
              <br></br>
              <br></br>
              {progress ? (
                <div className="center">
                  {' '}
                  <i
                    className="fa fa-spinner fa-spin fa-2x mr-2"
                    aria-hidden="true"
                    id="circle1"></i>
                </div>
              ) : (
                <button className="btn btn-primary" onClick={onTransferHandle}>
                  Transfer
                </button>
              )}
            </div>
          </>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TransferModal;
