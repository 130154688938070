import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CardModal from '../../CardModal';
import { _todaysPicks } from '../../../services/mainApi';
import img1 from '../../../assets/images/avatar/previewThumb.png';
import shortenAccount from '../../../utils/shortenAccount';
//import commonFunction from '../../../utils/common';
import avaxLogo from '../../../assets/cryptoLogo/avalanche-avax-icon.svg';
import bnbLogo from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../../../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../../../assets/cryptoLogo/ethereum-eth-icon.svg';
import { Modal } from 'react-bootstrap';

const TodayPicks = props => {
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [visible, setVisible] = useState(8);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const showMoreItems = () => {
    setPageNumber(pageNumber + 1);
    setVisible(prevValue => prevValue + 4);
  };
  const [modalShow, setModalShow] = useState(false);
  const todayPicks = () => {
    _todaysPicks(pageNumber).then(res => {
      if (res?.data?.error === false) {
        setData(res?.data?.data?.nftList);
        props.settotalItems(res?.data?.data?.totalItems);
        setNoData('data');
      } else {
        setNoData('nodata');
        setData(res?.data);
        props.settotalItems(0);
      }
    });
  };
  useEffect(() => {
    todayPicks();
  }, []);

  // React.useEffect(() => {
  //   const arr = [];
  //   if (props?.selectionFilter?.length > 0) {
  //     props?.selectionFilter.filter(e => {
  //       data.map(item => {
  //         if (e === item.CategoryData.name) {
  //           arr.push(item);
  //         }
  //       });
  //     });
  //     setData(arr);
  //   } else {
  //     todayPicks();
  //   }
  // }, [props.selectionFilter]);
  return (
    <Fragment>
      <section className="tf-section today-pick">
        <div className="themesflat-container">
          {noData === 'nodata' ? (
            <h1
              className="row-margin"
              style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              {data?.msg}
            </h1>
          ) : (
            <div className="row row-margin">
              <div className="col-md-12">
                <div className="">
                  <h2 className="tf-title pb-11">Today&apos;s Picks</h2>
                  <div className="heading-line s1"></div>
                </div>
              </div>

              {data?.map((item, index) => (
                <div
                  key={index}
                  className={
                    props.Home == 2
                      ? 'fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6 minWidth'
                      : 'fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6'
                  }>
                  <div
                    className={`sc-card-product style2 mg-bt ${
                      item?.feature ? 'comingsoon' : ''
                    } `}>
                    <div className="card-media">
                      {item?.fileUrl?.split('.')[4] == 'mp4' ? (
                        <>
                          <video
                            width="320"
                            height="240"
                            controls
                            src={item?.fileUrl}
                            controlsList="nodownload">
                            Your browser does not support the video tag.
                          </video>
                          {/* <Link to={`/buy/${item?.id || item?._id}`} state={item}> */}
                          <a href={`/nft/buy/${item?._id}`}>
                            <h4
                              style={{
                                fontStyle: 'italic',
                                color: 'blue',
                                textDecoration: 'underline',
                              }}
                              className="ml-3 mt-1">
                              Click here for details
                            </h4>
                          </a>
                          {/* </Link> */}
                        </>
                      ) : item?.fileUrl?.split('.')[4] == 'mpeg' ? (
                        <>
                          <audio controls src={item?.fileUrl} controlsList="nodownload">
                            Your browser does not support the audio element.
                          </audio>
                          <Link to={`/buy/${item?._id}`} state={item}>
                            <img src={img1} />
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to={`/buy/${item?._id}`} state={item}>
                            <img src={item?.fileUrl} alt="Axies" />
                          </Link>
                        </>
                      )}
                      {/* <Link to="/login" className="wishlist-button heart">
                      <span className="number-like">{item.wishlist}</span>
                    </Link> */}
                      <div className="coming-soon">{item.feature}</div>
                    </div>
                    <div className="card-title">
                      <h5>
                        {item.blockChainName == 'Avalanche' ? (
                          <img className="cryptoImg" src={avaxLogo} />
                        ) : item.blockChainName == 'Binance' ? (
                          <img className="cryptoImg" src={bnbLogo} />
                        ) : item.blockChainName == 'Ethereum' ? (
                          <img className="cryptoImg" src={ethLogo} />
                        ) : item.blockChainName == 'Polygon' ? (
                          <img className="cryptoImg" src={maticLogo} />
                        ) : (
                          ''
                        )}
                        &nbsp;&nbsp;
                        <Link to={`/buy/${item?._id}`} state={item}>
                          {item.title}
                        </Link>
                      </h5>
                      <div className="tags">{item.CategoryData.name}</div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          {/* <img src={item.imgAuthor} alt="Axies" /> */}
                          <img
                            src={
                              item?.userdetails?.userImage != null
                                ? item.userdetails.userImage
                                : img1
                            }
                            alt="Axies"
                          />
                        </div>
                        <div className="info">
                          <span>Owner</span>
                          <h6>
                            <Link to={`/authors-02/${item?.userId}`} state={item}>
                              {shortenAccount(item.currentAddress)}
                            </Link>
                          </h6>
                        </div>
                      </div>
                      <div className="price">
                        {item?.isBid ? <span>Min Bid Value</span> : <span>Current price</span>}
                        <h5>
                          {item?.isBid ? item.minBidValue : item.price} &nbsp;
                          {item.blockChainName == 'Avalanche'
                            ? 'AVAX'
                            : item.blockChainName == 'Binance'
                            ? 'BNB'
                            : item.blockChainName == 'Ethereum'
                            ? 'ETH'
                            : item.blockChainName == 'Polygon'
                            ? 'MATIC'
                            : ''}
                        </h5>
                      </div>
                    </div>
                    <div className="card-bottom">
                      {/* <button
                      onClick={() => setModalShow(true)}
                      className="sc-button style bag fl-button pri-3 no-bg">
                      <span>Put on Sale</span>
                    </button> */}
                      {/* <Link to="/activity-01" className="view-history reload">
                      View History
                    </Link> */}
                    </div>
                  </div>
                </div>
              ))}

              {visible < data.length && (
                <div className="col-md-12 wrap-inner load-more text-center mg-t-9">
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={showMoreItems}>
                    <span>Load More</span>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
      <Modal show={show} onHide={handleClose} style={{ marginTop: '20rem' }}>
        <Modal.Header closeButton></Modal.Header>
        <div
          className="modal-body space-y-20 pd-40"
          style={{ marginLeft: '4rem', marginBottom: '3rem' }}>
          <h4 style={{ fontStyle: 'italic' }}>Please connect with wallet!</h4>
        </div>
      </Modal>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

TodayPicks.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TodayPicks;
