import React from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { _exploreNft, _addToFav, _removeFromFav } from '../../../services/mainApi';
// import { copytoclipboard } from '../../../utils/helpers';
// import { OverlayTrigger } from 'react-bootstrap';
// import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';

// import previewThumb from '../../../assets/images/avatar/previewThumb.png';
import * as Router from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getshortcode } from '../../../utils/helpers';
import commonFunction from '../../../utils/common';
import * as Mui from '@mui/material';
const ItemDetail = () => {
  const Location = Router.useLocation();
  const { id } = Router.useParams();
  const [data, setData] = React.useState([]);
  const [cardIndex, setCardIndex] = React.useState('');
  const [loading, setLoading] = React.useState({
    dataProgress: false,
    loadFav: false,
  });
  const [load, setLoad] = React.useState('initial');
  const [pageNumber, setPageNumber] = React.useState(1);

  const DataComing = () => {
    setLoading({
      dataProgress: true,
      loadFav: false,
    });
    const exploreNftpromise = _exploreNft(id, pageNumber);
    exploreNftpromise.then(res => {
      if (!res?.data?.error) {
        setData(res.data.data.nftList);
        console.log('caffeee', res.data);
        setLoading({
          dataProgress: false,
          loadFav: false,
        });
      } else {
        setData([]);
        setLoading({
          dataProgress: false,
          loadFav: false,
        });
      }
    });
  };
  React.useEffect(() => {
    DataComing();
  }, [pageNumber]);
  const addOrRemoveFav = item => {
    if (!item.favStatus && (load === 'initial' || load === 'completed')) {
      addToFav(item?._id);
    } else if (load === 'initial' || load === 'completed') {
      removeFav(item?._id);
    }
  };
  const addToFav = id => {
    setLoad('pending');

    setLoading({
      dataProgress: false,
      loadFav: true,
    });
    const payload = {
      nftId: id,
    };
    _addToFav(payload)
      .then(res => {
        if (!res.data.error) {
          toast.success('Added to favorites', '');
          DataComing();
          setLoading({
            dataProgress: false,
            loadFav: false,
          });
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  const removeFav = id => {
    setLoad('pending');

    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        if (!res.data.error) {
          toast.success('Removed from favorites', '');
          DataComing();
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  const loadMore = () => {
    setPageNumber(pageNumber + 1);
    // DataComing();
  };
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">
                  {Location?.state?.field || data[0]?.CategoryData?.name}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="center m-3">
        <div className="col-md-12">
          <div className="d_profile">
            <div className="profile_avatar_item">
              <div className="d_profile_img">
                <div className="ImageScene m-Auto">
                  {Location?.state?.img ? (
                    <img src={Location?.state?.img} alt="" className="mb-3" />
                  ) : (
                    <Mui.Avatar className="avatarFont">
                      {data[0]?.CategoryData?.name?.slice(0, 1)}
                    </Mui.Avatar>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="tf-section sc-explore-1">
        {loading.dataProgress ? (
          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
            <h2>Loading...</h2>
          </div>
        ) : (
          <div className="themesflat-container">
            <div className="row">
              {data?.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className={`sc-card-product ${item.feature ? 'comingsoon' : ''} `}>
                      <div className="card-media cardimg_size">
                        <Link to={`/buy/${item?._id}`} state={item}>
                          <img src={item.fileUrl} alt="axies" />
                        </Link>
                        <div
                          onClick={() => {
                            addOrRemoveFav(item), setCardIndex(index);
                          }}>
                          {loading.loadFav && cardIndex === index ? (
                            <div className="wishlist-button-loading">
                              <i
                                className="fa fa-spinner fa-spin fa-3x mb-3"
                                aria-hidden="true"
                                id="circle1"></i>
                            </div>
                          ) : (
                            <span
                              // className="number-like"
                              className={
                                item.favStatus
                                  ? 'wishlist-button icon-fl-heart-filled'
                                  : 'wishlist-button heart'
                              }>
                              {item.favCount}
                            </span>
                          )}
                        </div>
                        <div className="coming-soon">{item.feature}</div>
                      </div>
                      <div className="card-title">
                        <h5 className="style2">
                          <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                            {item.title}
                          </Link>
                        </h5>
                        <div className="tags">{item.CategoryData.name}</div>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="info">
                            <span>Owned By</span>
                            <h6>{getshortcode(item.currentAddress)}</h6>
                          </div>
                        </div>
                        <div className="price">
                          <span>Current price</span>
                          <h5>
                            {item.price} {commonFunction.CurrencySymbol}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                  <h2>No data found...</h2>
                </div>
              )}
              {data.length > 0 && data.length >= 10 ? (
                <div className="col-md-12 wrap-inner load-more text-center">
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={() => loadMore()}>
                    <span>Load More</span>
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
};

export default ItemDetail;
