import React from 'react';
import * as Mui from '@mui/material';
import Binance from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';

const AccountSelect = props => {
  console.log(props.accountData, 'accccc');
  return (
    <Mui.Stack>
      <Mui.Typography variant="h4" textAlign="center">
        Send
      </Mui.Typography>
      <Mui.Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ padding: '2px 10px', border: '1px solid blue', margin: '10px 5px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="green"
          className="bi bi-check-circle-fill"
          viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
        <Mui.Stack>
          <Mui.Stack direction="row" justifyContent="space-between" alignItems="center">
            <Mui.Typography variant="h5">{props.accountData.accountName}</Mui.Typography>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.setAccount({
                  showAccount: false,
                  accountSelect: false,
                })
              }>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </Mui.Stack>
          <Mui.Typography variant="h6">{props.accountData.accountNo}</Mui.Typography>
        </Mui.Stack>
      </Mui.Stack>
      <Mui.Stack sx={{ borderTop: '1px solid grey' }}>
        <Mui.Stack direction="row" justifyContent="space-around" alignItems="center" mt={2}>
          <Mui.Typography variant="h5">Assets : </Mui.Typography>
          <Mui.Stack
            direction="row"
            sx={{ border: '1px solid #5a1986', padding: '5px 15px', borderRadius: '10px' }}
            spacing={2}>
            <Mui.Stack sx={{ width: '30px', height: '30px' }}>
              <img src={Binance} sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Mui.Stack>
            <Mui.Stack>
              <Mui.Typography variant="h5">BALANCE</Mui.Typography>
              <Mui.Typography variant="h6">1.500874 MATIC</Mui.Typography>
            </Mui.Stack>
          </Mui.Stack>
        </Mui.Stack>
        <Mui.Stack direction="row" justifyContent="space-around" alignItems="center" mt={2}>
          <Mui.Typography variant="h5">Amount : </Mui.Typography>
          <Mui.Stack direction="row">
            <Mui.OutlinedInput
              type="number"
              endAdornment={<Mui.InputAdornment position="end">MATIC</Mui.InputAdornment>}
              sx={{
                width: '170px',
                borderRadius: '10px ',
                border: '1px solid grey',
                '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  width: '200px',
                  '&:focus': {
                    border: 'none',
                  },
                },
                '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
                  border: 'none',
                  '&:focus': {
                    border: 'none',
                  },
                },
                '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                  border: 'none',
                  '&:focus': {
                    border: 'none',
                  },
                },
              }}
              placeholder="Enter $"
            />
          </Mui.Stack>
        </Mui.Stack>
      </Mui.Stack>
      <Mui.Stack direction="row" justifyContent="space-around" mt={4}>
        <Mui.Button
          variant="outlined"
          sx={{ padding: '10px 40px', borderRadius: '20px', fontSize: '12px' }}
          onClick={() =>
            props.setAccount({
              showAccount: false,
              accountSelect: false,
            })
          }>
          Cancel
        </Mui.Button>
        <Mui.Button
          variant="outlined"
          color="success"
          sx={{ padding: '10px 40px', borderRadius: '20px', fontSize: '12px' }}>
          Next
        </Mui.Button>
      </Mui.Stack>
    </Mui.Stack>
  );
};

export default AccountSelect;
