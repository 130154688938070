import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../components/header/Header';
import { _topSellers, _categoryList, _topSellersDetails } from '../services/mainApi';
import shortenAccount from '../utils/shortenAccount';
import Footer from '../components/footer/Footer';
import commonFunction from '../utils/common';
import Tooltip from 'react-bootstrap/Tooltip';
import { copytoclipboard } from '../utils/helpers';
import { getshortcode } from '../utils/helpers';
import { OverlayTrigger } from 'react-bootstrap';
import CardModal from '../components/CardModal';
import avt from '../assets/images/avatar/avt-author-tab.jpg';
// import { ConnectWalletContext } from '../context/ConnectWalletContext';
import * as Router from 'react-router-dom';

const Authors02 = () => {
  // const { alldata } = useContext(ConnectWalletContext);
  const [selectTab, setSelectTab] = React.useState('ALL');
  const [categoryList, setCategoryList] = React.useState([]);
  const [topSellerData, setTopSellerData] = React.useState('');
  const [items, setItems] = React.useState();
  const { id, userId } = Router.useParams();
  console.log(items, 'state');
  const TopSeller = () => {
    const promised = _topSellers();
    promised.then(res => {
      if (res) {
        setTopSellerData(res.data.data);
        // setLoading(false);
      }
    });
  };
  const TopSellerItemsFn = () => {
    _topSellersDetails(id || userId, 1)?.then(res => {
      setItems(res?.data?.data);
    });
  };
  const categoryListHere = () => {
    const data = [
      {
        id: 0,
        name: 'All',
      },
    ];
    _categoryList().then(res => {
      res?.data?.data?.map(item =>
        data.push({
          id: item.categoryId,
          name: item.name,
        }),
      );
      setCategoryList(data);
    });
  };

  useEffect(() => {
    TopSeller();
    TopSellerItemsFn();
    categoryListHere();
  }, []);
  // const [menuTab] = useState([
  //   {
  //     class: 'active',
  //     name: 'ALL',
  //   },
  //   {
  //     class: '',
  //     name: 'ART',
  //   },
  //   {
  //     class: '',
  //     name: 'MUSIC',
  //   },
  //   {
  //     class: '',
  //     name: 'SPORTS',
  //   },
  //   {
  //     class: '',
  //     name: 'COLLECTIBLES',
  //   },
  //   {
  //     class: '',
  //     name: 'CARDS',
  //   },

  //   {
  //     class: '',
  //     name: 'PHOTOGRAPHY',
  //   },

  //   {
  //     class: '',
  //     name: 'METAVERSE',
  //   },
  // ]);
  const [panelTab] = useState([
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
  ]);

  const [visible, setVisible] = useState(7);
  const showMoreItems = () => {
    setVisible(prevValue => prevValue + 4);
  };

  const [modalShow, setModalShow] = useState(false);
  const [copysttaustext, setcopysttaustext] = useState('Copy');

  return (
    <div className="authors-2">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Author</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Pages</Link>
                  </li> */}
                  <li>Author</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {items === undefined ? (
        <div
          className={localStorage?.theme === 'light' ? 'loading-Center-light' : 'loading-Center'}>
          <div>
            <i className="fa fa-spinner fa-spin fa-7x mb-3" aria-hidden="true" id="circle1"></i>
          </div>
          {/* <h2>Loading...</h2> */}
        </div>
      ) : (
        <section className="tf-section authors">
          <div className="themesflat-container">
            <div className="flat-tabs tab-authors">
              <div className="author-profile flex">
                <div className="feature-profile">
                  {topSellerData?.length === 0 ? (
                    <i
                      className="fa fa-spinner fa-spin fa-2x mr-2"
                      aria-hidden="true"
                      id="circle1"></i>
                  ) : (
                    <img
                      src={items?.nftList[0]?.userdetails?.userImage}
                      alt="Axies"
                      className="avatar"
                    />
                  )}
                </div>
                <div className="infor-profile">
                  <span>Author Profile</span>
                  <h2 className="title">{items?.nftList[0]?.userdetails?.username}</h2>
                  {/* <p className="content">{data?.description}</p> */}
                  {/* <input
                    type="text"
                    className="inputcopy"
                    defaultValue={shortenAccount(copyText)}
                    readOnly
                    onMouseOver={() => setcopysttaustext('Copy')}
                    style={{ cursor: 'pointer' }}
                  /> */}
                  {/* <button
                    type="button"
                    className="btn-copycode"
                    onClick={() => navigator.clipboard.writeText(copyText)()}>
                    <i className="icon-fl-file-1"></i>
                  </button> */}

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">{copysttaustext}</Tooltip>}>
                    <div
                      onMouseOver={() => setcopysttaustext('Copy')}
                      onClick={() => {
                        copytoclipboard(items?.nftList[0]?.userdetails?.walletAddress);
                        // navigator.clipboard.writeText(copyText)();
                        setcopysttaustext('Copied');
                      }}
                      className="copytext">
                      <h6 style={{ color: 'darkblue' }}>
                        {shortenAccount(items?.nftList[0]?.userdetails?.walletAddress)}
                      </h6>

                      <button type="button" className="btn-copycode">
                        <i className="icon-fl-file-1"></i>
                      </button>
                    </div>
                  </OverlayTrigger>
                </div>
                {/* <div className="widget-social style-3">
                <ul>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li className="style-2">
                    <Link to="#">
                      <i className="fab fa-telegram-plane"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-youtube"></i>
                    </Link>
                  </li>
                  <li className="mgr-none">
                    <Link to="#">
                      <i className="icon-fl-tik-tok-2"></i>
                    </Link>
                  </li>
                </ul>
                <div className="btn-profile">
                  <Link to="/login" className="sc-button style-1 follow">
                    Follow
                  </Link>
                </div>
              </div> */}
              </div>
              <Tabs>
                <TabList>
                  {categoryList?.map((item, index) => (
                    <Tab key={index} onClick={() => setSelectTab(item?.name)}>
                      {item?.name}
                    </Tab>
                  ))}
                </TabList>

                <div className="content-tab">
                  <div className="content-inner">
                    <div className="row">
                      {panelTab?.map(index => (
                        <>
                          <TabPanel index={index}>
                            {items?.nftList?.map((item, index) => {
                              return (
                                <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                  <div className="sc-card-product explode ">
                                    <div className="card-media">
                                      <Link to={`/buy/${item?._id}`} state={item}>
                                        <img src={item?.fileUrl} alt="Axies" />
                                      </Link>
                                      <div className="button-place-bid ">
                                        <button
                                          onClick={() => setModalShow(true)}
                                          className="sc-button style-place-bid style bag fl-button pri-3">
                                          <span>Place Bid</span>
                                        </button>
                                      </div>
                                      <div
                                        className={
                                          item?.favStatus
                                            ? 'wishlist-button icon-fl-heart-filled'
                                            : 'wishlist-button heart'
                                        }>
                                        <span className="number-like"> {item?.favCount}</span>
                                      </div>
                                    </div>
                                    <div className="card-title">
                                      <h5 className="style2">
                                        <Link to={`/buy/${item?._id}`} state={item}>
                                          {item.title}
                                        </Link>
                                      </h5>
                                      <div className="tags">{item.CategoryData.name}</div>
                                    </div>
                                    <div className="meta-info">
                                      <div className="author">
                                        <div className="avatar">
                                          <img
                                            src={item?.userdetails?.userImage || avt}
                                            alt="axies"
                                          />
                                        </div>
                                        <div className="info">
                                          <span>Owned By</span>
                                          <h6>
                                            {/* <Link to="/authors-02" state={item}> */}
                                            {getshortcode(item.ownerAddress)}
                                            {/* </Link> */}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="price">
                                        <span>Current price</span>
                                        <h5>
                                          {item.price}
                                          {commonFunction.CurrencySymbol}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* ))} */}
                            {visible.length == 0 && (
                              <div className="col-md-12 wrap-inner load-more text-center">
                                <Link
                                  to="#"
                                  id="load-more"
                                  className="sc-button loadmore fl-button pri-3"
                                  onClick={showMoreItems}>
                                  <span>Load More</span>
                                </Link>
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Arts' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Arts')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Photography' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Photography')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Music' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Music')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Metaverse' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Metaverse')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Videos' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Videos')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Collectibles' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Collectibles')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Sports' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Sports')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                          <TabPanel index={index}>
                            {selectTab === 'Card' &&
                              items?.nftList
                                ?.filter(c => c?.CategoryData.name === 'Card')
                                .map((item, index) => {
                                  return (
                                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <div className="sc-card-product explode ">
                                        <div className="card-media">
                                          <Link to="/buy" state={item}>
                                            <img src={item?.fileUrl} alt="Axies" />
                                          </Link>
                                          <div className="button-place-bid ">
                                            <button
                                              onClick={() => setModalShow(true)}
                                              className="sc-button style-place-bid style bag fl-button pri-3">
                                              <span>Place Bid</span>
                                            </button>
                                          </div>
                                          <Link to="/login" className="wishlist-button heart">
                                            <span className="number-like"> {item?.wishlist}</span>
                                          </Link>
                                        </div>
                                        <div className="card-title">
                                          <h5 className="style2">
                                            <Link to="/buy" state={item}>
                                              {item.title}
                                            </Link>
                                          </h5>
                                          <div className="tags">{item.CategoryData.name}</div>
                                        </div>
                                        <div className="meta-info">
                                          <div className="author">
                                            <div className="avatar">
                                              <img
                                                src={item?.userdetails?.userImage || avt}
                                                alt="axies"
                                              />
                                            </div>
                                            <div className="info">
                                              <span>Owned By</span>
                                              <h6>
                                                {/* <Link to="/authors-02" state={item}> */}
                                                {getshortcode(item.ownerAddress)}
                                                {/* </Link> */}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="price">
                                            <span>Current price</span>
                                            <h5>
                                              {item.price}
                                              {commonFunction.CurrencySymbol}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                          </TabPanel>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </section>
      )}
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
      <Footer />
    </div>
  );
};

export default Authors02;
