import React from 'react';
import { _updateBid } from '../../../services/mainApi';
import CancleBidingList from './CancleBidingList';
import { toast } from 'react-toastify';
//import { useNavigate } from 'react-router-dom';

export default function BidsDetailsView(props) {
  const { bidDetails, connectedAccount } = props;
  //const navigate = useNavigate();
  console.log('PK Bid details view..', bidDetails);

  const cancelBid = items => {
    const payload = {
      price: items.price,
      nftId: items.nftId,
      status: 'delete',
      _id: items._id,
      tokenId: items.tokenId,
    };
    _updateBid(payload)
      .then(res => {
        if (!res.data.error) {
          toast.success('Bid cancelled successfully');
          //window.location.reload();
          //window.location = `/nft/buy/${items?.nftId}`;
          window.location = window.location.href;
          //navigate('/explore-01');
          //return;
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch(() => {
        toast.error('Bid not cancelled un successfully');
      });
  };

  return (
    <div>
      <div className={`sc-card-product explode style2 mg-bt`}>
        <div>
          <h4 className="text-center">List of active bids!</h4>

          {bidDetails?.length >= 1 &&
            bidDetails
              .sort((a, b) => b.price - a.price)
              .filter((d, i) => i < 3 && d.userdetails.walletAddress == connectedAccount)
              .map(item => {
                return (
                  <>
                    <div key={item.id} className="mt-10">
                      <CancleBidingList data={item} cancel={cancelBid} />
                    </div>
                  </>
                );
              })}
          {bidDetails.length == 0 && (
            <div className="mt-10 text-center">
              <h6>No active bids, Be the first to make a bid!</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
