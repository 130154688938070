import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { _createwallet } from '../services/onboardApi';
import { toast } from 'react-toastify';
import commonFunction from '../utils/common';
import { _getProfileDetails } from '../services/mainApi';
import config from '../lib/config';

export const ConnectWalletContext = React.createContext();
const { ethereum } = window;
// if (ethereum) {
//   var provider = new ethers.providers.Web3Provider(ethereum);
// }
export const ConnectionProvider = ({ children }) => {
  // let navigate = useNavigate();
  const [ConnectedAccount, setConnectedAccount] = useState('');
  const [alldata, setAllData] = useState('');
  const [blockChainNetworkValue, setBlockChainNetworkValue] = useState('');
  const [userProfileData, setUserProfileData] = useState();
  const [networkChain, setNetworkChain] = useState();
  const [netWrokChainName, setNetWorkChainName] = useState();
  const [netWorkSymbol, setNetworkSymbol] = useState();
  const [buyingModal, setbuyingModal] = useState({});
  const [searchGlobally, setSearchGlobally] = useState('');

  //set list of network id and networknName - this might get increase going forward
  //test Netork chains
  // let network = {
  //   rinkeby: 4,
  //   avalanche: 43113,
  // };
  //get the chainId name
  useEffect(() => {
    if (networkChain == '0xa869') {
      setNetWorkChainName('Avalanche');
      setNetworkSymbol('AVAX');
      config.NFTContractAddress = '0x5cFC9e7EC2d3f07Bf83934D20e64f2f657C8c48E';
      config.NFTSingleTokenApproval = '0x5cFC9e7EC2d3f07Bf83934D20e64f2f657C8c48E';
    }
    if (networkChain == '0x5') {
      setNetWorkChainName('Ethereum');
      setNetworkSymbol('ETH');
      config.NFTContractAddress = '0x32Fe2A605BDdB548d3D2b19596a4B762E594c017';
      config.NFTSingleTokenApproval = '0x32Fe2A605BDdB548d3D2b19596a4B762E594c017';
    }
    if (networkChain == '0x13881') {
      setNetWorkChainName('Polygon');
      setNetworkSymbol('MATIC');
      config.NFTContractAddress = '0xd10B9e80c728f9E0E5C83e251C2Ea59e743D98F0';
      config.NFTSingleTokenApproval = '0xd10B9e80c728f9E0E5C83e251C2Ea59e743D98F0';
    }
    if (networkChain == '0x61') {
      setNetWorkChainName('Binance');
      setNetworkSymbol('BNB');
      config.NFTContractAddress = '0x7B23af3109e98A93d54AE0826d7b4A717ddf0eAd';
      config.NFTSingleTokenApproval = '0x7B23af3109e98A93d54AE0826d7b4A717ddf0eAd';
    }
  }, [networkChain]);

  const createaccount = (account, updatestatus) => {
    const result = _createwallet({
      walletAddress: `${account}`,
      walletName: 'metamask',
    });
    result.then(
      res => {
        if (res?.data?.data) {
          localStorage.setItem('Token', res?.data?.data.accessToken);
          updatestatus && setConnectedAccount(account);
          // if (updatestatus) {
          //   //toast.success('Successfully connected to wallet!', commonFunction.toastsettings);
          //   //window.location = '/nft'; //commented for testing .. later uncomment this
          // } else window.location.reload();

          return true;
        }
        toast.error('Failed to connect wallet!', commonFunction.toastsettings);
        return false;
      },
      () => {
        toast.error('Failed to connect wallet!', commonFunction.toastsettings);
        return false;
      },
    );
  };

  const getWalletConnect = async () => {
    try {
      if (!ethereum) return alert('Please install MetaMask!');

      const walletChain = await ethereum.request({
        method: 'eth_chainId', // net_version
      });

      if (ConnectedAccount) {
        return toast.warning('Already connected to wallet!', commonFunction.toastsettings);
      }
      const provider = new ethers.providers.Web3Provider(ethereum);
      // MetaMask requires requesting permission to connect users accounts
      const accounts = await provider.send('eth_requestAccounts', []);
      console.log('Checking Network chain', walletChain);
      console.log('Checking Network chain hex format', parseInt(walletChain, 16));
      //--
      // if (
      //   parseInt(walletChain, 16) != network.rinkeby ||
      //   parseInt(walletChain, 16) != network.avalanche
      // ) {
      //   //createaccount(accounts[0], 1); //take the current account
      //   toast.warning('Please connect with correct network chain!', commonFunction.toastsettings);
      //   return true;
      // }
      // if (walletChain == network.rinkeby || parseInt(walletChain, 16) == network.avalanche) {
      //   createaccount(accounts[0], 1); //take the current account
      //   toast.success('Successfully connected to wallet!', commonFunction.toastsettings);
      // }
      if (walletChain) {
        createaccount(accounts[0], 1); //take the current account
        toast.success('Successfully connected to wallet!', commonFunction.toastsettings);
      }
      //window.location = '/nft';
      //window.location.reload();
    } catch (err) {
      throw new Error('No Ethereum Object found');
    }
  };

  //check wallet is already connected
  const checkIsWalletConnected = async () => {
    try {
      if (!ethereum) return alert('Please install MetaMask!');
      ////if ethereum is connected the get the connected current account
      const accounts = await ethereum.request({
        method: 'eth_accounts',
      });
      //--
      const walletChain = await ethereum.request({
        method: 'eth_chainId',
      });

      setNetworkChain(walletChain);
      console.log('Prabha Network chain id hex format', walletChain);
      // console.log('Network decmial chain id', parseInt(walletChain, 16));
      //--
      // if (walletChain != network.rinkeby || walletChain != network.avalanche) {
      //   toast.warning('Please connect with supported network!', commonFunction.toastsettings);
      createaccount(accounts[0], 1); //take the current account
      //return true;
      //}
      if (accounts.length) {
        setConnectedAccount(accounts[0]);
        //window.location.href = '/nft';
        //window.location = '/nft';
      }
    } catch (err) {
      console.log('Wallet is connection error', err.message);
      throw new Error('No Ethereum Object found');
    }
  };
  // async function getAccount() {
  //   const accounts = await ethereum.enable();
  //   const account = accounts[0];
  //   createaccount(account);
  //   // do something with new account here
  // }
  // const isMetaMaskConnected = async () => {
  //   const accounts = await provider.listAccounts();
  //   return accounts.length > 0;
  // };

  const accountWasChanged = accounts => {
    createaccount(accounts[0], accounts[0] ? 1 : 0);
  };

  useEffect(() => {
    const checkConnect = async () => {
      if (!window.ethereum) {
        // Nothing to do here... no ethereum provider found
        return;
      }
      //check network connected with rinkeby and naviage the page once it connected
      window.ethereum.on('chainChanged', chainId => {
        console.log('Chain changed', chainId);
        console.log('window.location.href', window.location.href);
        window.location = window.location.href;
      });
      window.ethereum.on('accountsChanged', accountWasChanged);
      // return () => {
      //   window.ethereum.off('accountsChanged', accountWasChanged);
      // };
    };
    checkConnect();
  }, []);

  useEffect(() => {
    _getProfileDetails().then(res => {
      console.log('profile data', res.data);
      const result = {
        bio: res.data.data.bio,
        customURL: res.data.data.customURL,
        discord: res.data.data.discord,
        email: res.data.data.email,
        facebook: res.data.data.facebook,
        twiter: res.data.data.twiter,
        userImage: res.data.data.userImage,

        username: res.data.data.username,
      };
      setUserProfileData(result);
    });
  }, [ConnectedAccount]);
  //check wallet is connected
  useEffect(() => {
    checkIsWalletConnected();
  }, []);

  return (
    <ConnectWalletContext.Provider
      value={{
        getWalletConnect,
        ConnectedAccount,
        setAllData,
        alldata,
        setBlockChainNetworkValue,
        blockChainNetworkValue,
        userProfileData,
        networkChain,
        netWrokChainName,
        netWorkSymbol,
        buyingModal,
        setbuyingModal,
        searchGlobally,
        setSearchGlobally,
      }}>
      {children}
    </ConnectWalletContext.Provider>
  );
};
