import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import menus from '../../pages/menu';
import DarkMode from './DarkMode';
//import logoheader from '../../assets/images/logo/logo.png';
//import logoheader2x from '../../assets/images/logo/logo@2x.png';
//import logodark from '../../assets/images/logo/logo_dark.png';
//import logodark2x from '../../assets/images/logo/logo_dark@2x.png';
//Pyramidions logo
// import logoheader from '../../assets/images/logo/CompanyLogoDark.png';
// import logoheader2x from '../../assets/images/logo/CompanyLogoDark.png';
// import logodark from '../../assets/images/logo/CompanyLogoDark.png';
// import logodark2x from '../../assets/images/logo/CompanyLogoDark.png';
//pyraNFT logo
//import img1 from '../../assets/images/avatar/previewThumb.png';
import logoheader from '../../assets/images/logo/pyranft_logo.png';
import logoheader2x from '../../assets/images/logo/pyranft_logo.png';
import logodark from '../../assets/images/logo/pyranft_logo.png';
import logodark2x from '../../assets/images/logo/pyranft_logo.png';
// import imgsun from '../../assets/images/icon/sun.png';
import { ConnectWalletContext } from '../../context/ConnectWalletContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { getshortcode } from '../../utils/helpers'; //copytoclipboard
import loadWeb3 from '../../utils/loadWeb3';
import * as Mui from '@mui/material';
import Wallet from './wallet/wallet';

const Header = () => {
  const { ConnectedAccount, netWrokChainName, netWorkSymbol } =
    React.useContext(ConnectWalletContext);
  const { pathname } = useLocation();
  const [searchdata, setsearchdata] = useState('');

  const headerRef = useRef(null);
  //const [accountConnected, setaccountConnected] = useState(false);
  const [userWalletBalance, setUserWalletBalance] = React.useState(0);
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  // useEffect(async () => {
  //   await isMetaMaskConnected().then(async connected => {
  //     if (connected) {
  //       setaccountConnected(connected);
  //       let web3 = await loadWeb3();
  //       let userAccountBalance = await web3.eth.getBalance(ConnectedAccount);
  //       setUserWalletBalance(
  //         parseFloat(web3.utils.fromWei(userAccountBalance, 'ether')).toFixed(3),
  //       );

  //       // metamask is connected
  //     } else {
  //       setaccountConnected(false);
  //       // metamask is not connected
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (ConnectedAccount) {
      const getWalletBalance = async () => {
        let web3 = await loadWeb3();
        let getBalance = await web3.eth.getBalance(ConnectedAccount);
        setUserWalletBalance(parseFloat(getBalance / 10 ** 18).toFixed(4));
      };
      getWalletBalance();
    }
  }, [ConnectedAccount]);

  const isSticky = () => {
    const header = document.querySelector('.js-header');
    const scrollTop = window.scrollY;
    scrollTop >= 300 ? header?.classList?.add('is-fixed') : header.classList.remove('is-fixed');
    scrollTop >= 400 ? header?.classList?.add('is-small') : header.classList.remove('is-small');
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle('active');
    btnToggle.current.classList.toggle('active');
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = index => {
    setActiveIndex(index);
  };

  // const popoverClickRootClose = (
  //   <Popover
  //     id="popover-trigger-click-root-close"
  //     title="Popover bottom"
  //     style={{ background: '#14141f', padding: '20px' }}>
  //     <div className="avatar_popup mt-20">
  //       <div className="d-flex align-items-center copy-text justify-content-between">
  //         <h5 className="sub-heading text-white">{getshortcode(ConnectedAccount)} </h5>
  //         <h5
  //           to="/"
  //           className="ml-2"
  //           onClick={() => {
  //             copytoclipboard(ConnectedAccount);
  //           }}>
  //           <i className="fal fa-copy "></i>
  //         </h5>
  //       </div>
  //       <div className="mt-10">
  //         {/* <img className="coin" src={imgsun} alt="/" /> */}
  //         <div className="info">
  //           <p className="text-sm font-book text-gray">Balance</p>
  //           <p className="w-full text-sm font-bold text-white">{userWalletBalance} ETH</p>
  //         </div>
  //       </div>
  //       <hr className="hr" />
  //       <div className="links mt-20">
  //         <h5 className="mt-10 text-white" href="#" id="logout">
  //           <i className="fal fa-sign-out text-white"></i> <span> Logout</span>
  //         </h5>
  //       </div>
  //     </div>
  //   </Popover>
  //);

  const popoverClickRootClose = (
    <Popover
      id="popover-trigger-click-root-close"
      title="Popover bottom"
      className="wallet-connect style-2">
      <div style={{ fontSize: '10px' }}>Wallet Connected</div>
    </Popover>
  );
  useEffect(() => {
    console.log(searchdata, 'from header');
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-containers">
        <div className="header-flex">
          <div className="header-flex-logo-div">
            <Link to="/" rel="home" className="">
              <div className="logo-change-width">
                <img
                  className="logo-dark"
                  id="logo_header"
                  src={logodark}
                  srcSet={`${logodark2x}`}
                  alt="nft-gaming"
                />
              </div>
              <img
                className="logo-light"
                id="logo_header"
                src={logoheader}
                srcSet={`${logoheader2x}`}
                alt="nft-gaming"
              />
            </Link>
          </div>
          <div className="search-div">
            <input
              type="search"
              id="s"
              className="textfield-search"
              placeholder="Search..."
              name="s"
              title="Search for"
              onChange={e => setsearchdata(e.target.value)}
              value={searchdata}
            />
            <Link to={searchdata.length >= 3 ? '/explore-01' : '#'} state={searchdata}>
              <div className="search icon-search" type="submit" title="Search">
                <i className="icon-fl-search-filled "></i>
              </div>
            </Link>
          </div>
          <nav id="main-nav" className="main-nav relative-change" ref={menuLeft}>
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, index) => (
                <li
                  key={index}
                  onClick={() => handleOnClick(index)}
                  className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''}
                         ${activeIndex === index ? 'active' : ''} `}>
                  <Link to={data.links}>{data.name}</Link>
                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map(submenu => (
                        <li
                          key={submenu.id}
                          className={
                            pathname === submenu.links ? 'menu-item current-item' : 'menu-item'
                          }>
                          {ConnectedAccount ? (
                            <Link to={submenu.links}>{submenu.sub}</Link>
                          ) : (
                            <Link to="/wallet-connect">{submenu.sub}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          {/* <div className="hstack p-4 m-2" onClick={event => setAnchorEl(event.currentTarget)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-meta"
              viewBox="0 0 16 16">
              <path
                fillRule="evenodd"
                d="M8.217 5.243C9.145 3.988 10.171 3 11.483 3 13.96 3 16 6.153 16.001 9.907c0 2.29-.986 3.725-2.757 3.725-1.543 0-2.395-.866-3.924-3.424l-.667-1.123-.118-.197a54.944 54.944 0 0 0-.53-.877l-1.178 2.08c-1.673 2.925-2.615 3.541-3.923 3.541C1.086 13.632 0 12.217 0 9.973 0 6.388 1.995 3 4.598 3c.319 0 .625.039.924.122.31.086.611.22.913.407.577.359 1.154.915 1.782 1.714Zm1.516 2.224c-.252-.41-.494-.787-.727-1.133L9 6.326c.845-1.305 1.543-1.954 2.372-1.954 1.723 0 3.102 2.537 3.102 5.653 0 1.188-.39 1.877-1.195 1.877-.773 0-1.142-.51-2.61-2.87l-.937-1.565ZM4.846 4.756c.725.1 1.385.634 2.34 2.001A212.13 212.13 0 0 0 5.551 9.3c-1.357 2.126-1.826 2.603-2.581 2.603-.777 0-1.24-.682-1.24-1.9 0-2.602 1.298-5.264 2.846-5.264.091 0 .181.006.27.018Z"
              />
            </svg>
          </div> */}
          <div className="nav-btn-div">
            {ConnectedAccount && (
              <div>
                <div className="sc-btn-top mg-r-12" id="site-header">
                  <Link
                    to="/create-item"
                    className="sc-button header-slider style style-1 note fl-button pri-1 mr-3">
                    {/* <span>{blockChainNetworkValue}</span> */}
                    <span>Create</span>
                  </Link>
                </div>
              </div>
            )}
            <div className="sc-btn-top mg-r-12 " id="site-header">
              {ConnectedAccount ? (
                <OverlayTrigger rootClose placement="bottom" overlay={popoverClickRootClose}>
                  <div className="wallet-connect style-1 connect-wallet-change">
                    <h6>{getshortcode(ConnectedAccount)} </h6>
                    <h6>
                      {userWalletBalance} {netWorkSymbol}
                    </h6>
                    {netWrokChainName ? (
                      <h6 style={{ fontSize: '12px' }}>{netWrokChainName}</h6>
                    ) : (
                      ''
                    )}
                  </div>
                </OverlayTrigger>
              ) : (
                <Link
                  to="/wallet-connect"
                  className="sc-button header-slider style style-1 wallet fl-button pri-1 ">
                  <span>Connect Wallet</span>
                </Link>
              )}
            </div>
          </div>

          <Mui.Popover
            // id={id}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 350,
                minHeight: 480,
                bgcolor: 'white',
                overflow: 'visible',
                // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                // borderRadius: theme => theme.spacing(1),
                // marginTop: 1.5,
                '& .MuiAvatar-root': {
                  // width: 32,
                  // height: 32,
                  // marginLeft: -0.5,
                  // marginRight: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 25,
                  width: 10,
                  height: 10,
                  bgcolor: '#5a1986',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}>
            <Wallet />
          </Mui.Popover>
          <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
            <span></span>
          </div>
          <DarkMode />
        </div>
      </div>
    </header>
  );
};

export default Header;
