import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import { toast } from 'react-toastify';
import Footer from '../components/footer/Footer';
import avt from '../assets/images/avatar/avata_profile.jpg';
import { _fileupload, _getProfileDetails, _updateProfileDetails } from '../services/mainApi';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
// import { Modal } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import bg1 from '../assets/images/backgroup-secsion/option1_bg_profile.jpg';
// import bg2 from '../assets/images/backgroup-secsion/option2_bg_profile.jpg';

const EditProfile = () => {
  // const { ConnectedAccount } = React.useContext(ConnectWalletContext);
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShowmodal = () => setShow(true);
  // const [fileImage, setFileImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadFilePath, setUploadFilePath] = useState('');
  const [s1, setS1] = useState(false);
  // const [profile, setProfile] = useState('');
  // const [username, setUsername] = useState('');
  // const [email, setEmail] = useState('');
  const { ConnectedAccount } = React.useContext(ConnectWalletContext);
  console.log(ConnectedAccount, 'connected');
  console.log(uploadFilePath);
  const handleShow = () => {
    setS1(true);
    // setPriceValue(true);
    // if
    //   email ||
    //   username ||
    //   // blockChainValue === undefined ||
    //   // selectedCategoryid === '0' ||
    //   profile
    //   // TokenPrice?.length === 0
    //  {
    //   setShow(false);
    // } else {
    //   setShow(true);
    // }
  };
  const [formData, setFormData] = useState({
    bio: null,
    customURL: null,
    discord: null,
    email: null,
    facebook: null,
    twiter: null,
    userImage: null,
    username: null,
  });

  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  console.log(formData, 'values');

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    getFileURL();
  };

  const getProfileDetails = () => {
    setLoading(true);
    _getProfileDetails()
      .then(res => {
        console.log('profile data', res.data);
        const result = {
          bio: res.data.data.bio,
          customURL: res.data.data.customURL,
          discord: res.data.data.discord,
          email: res.data.data.email,
          facebook: res.data.data.facebook,
          twiter: res.data.data.twiter,
          userImage: res.data.data.userImage,
          username: res.data.data.username,
        };
        setFormData(result);
        setLoading(false);
      })
      .catch(err => {
        console.log(err.data), setLoading(false);
      });
  };

  const onImageChange = e => {
    if (e.target && e.target.files) {
      if (e.target.files[0].size > 209715200) {
        alert('File is too big!');
      } else {
        setFormData({ ...formData, ['userImage']: URL.createObjectURL(e.target.files[0]) });
        setUploadFilePath(e.target.files[0]);
      }
    }
  };
  const getFileURL = async () => {
    var formDatas = new FormData();
    const payload = formData;
    formData.bio == '' || formData.bio == null ? delete payload.bio : '';
    formData.discord == '' || formData.discord == null ? delete payload.discord : '';
    formData.facebook == '' || formData.facebook == null ? delete payload.facebook : '';
    formData.twiter == '' || formData.twiter == null ? delete payload.twiter : '';
    formData.customURL == '' || formData.customURL == null ? delete payload.customURL : '';
    if (formData.userImage != null && uploadFilePath?.name != undefined) {
      formDatas.append('file', uploadFilePath);
      formDatas.append('name', uploadFilePath.name);
      formDatas.append('description', 'profile image');
      formDatas.append('attributes', JSON.stringify(['test']));
      _fileupload(formDatas)
        .then(res => {
          console.log('url result', res);
          console.log('url result', res?.data?.error);
          if (res?.data?.error === false) {
            //get imageURL
            if (res?.data?.data?.imageURL) {
              console.log('ImageUrl', res.data.data.imageURL);
            }
            //get metadataURL
            if (res?.data?.data?.metaURL) {
              console.log('metaUrl', res.data.data.metaURL);
            }
            payload.userImage = res.data.data.imageURL;

            _updateProfileDetails(payload)
              .then(res => {
                console.log(res.data);
                getProfileDetails();
                toast.success('Profile updated successfully');
              })
              .catch(err => {
                console.log(err.data);
                setLoading(false);
              });
          }
        })
        .catch(err => {
          console.log('File uploading error', err.message);
          setLoading(false);
        });
    } else {
      _updateProfileDetails(payload)
        .then(res => {
          console.log(res.data);
          getProfileDetails();
          toast.success('Profile updated successfully');
        })
        .catch(err => {
          console.log(err.data);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getProfileDetails();
  }, [ConnectedAccount]);

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Account Details</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Pages</Link>
                  </li>
                  <li>Edit Profile</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
              <div className="sc-card-profile text-center">
                <div className="card-media">
                  <img
                    id="profileimg"
                    src={formData.userImage != null ? formData.userImage : avt}
                    alt="Axies"
                  />
                </div>
                <div id="upload-profile">
                  <div to="#" className="btn-upload">
                    Upload New Photo
                  </div>
                  <input
                    id="tf-upload-img"
                    type="file"
                    name="profile"
                    accept="image/png, image/jpg ,image/gif, image/jpeg, image/webp, video/mp4,"
                    onChange={onImageChange}
                    required
                  />
                </div>
                {s1 && (
                  <span className="required-font">{!formData.userImage && 'Upload Your File'}</span>
                )}
                {/* <div to="#" style={{cursor:'pointer'}}
                  className="btn-upload style2"
                  onClick={() =>
                    setFormData({
                      ...formData,
                      ['userImage']: null,
                    })
                  }> 
                  Delete
                </div> */}
              </div>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-12 col-12">
              <div className="form-upload-profile">
                {/* <h4 className="title-create-item">Choice your Cover image</h4>
                <div className="option-profile clearfix">
                  <form action="#">
                    <label className="uploadFile">
                      <input type="file" className="inputfile form-control" name="file" />
                    </label>
                  </form>
                  <div className="image">
                    <img src={bg1} alt="Axies" />
                  </div>
                  <div className="image style2">
                    <img src={bg2} alt="Axies" />
                  </div>
                </div> */}

                <form action="#" className="form-profile" onSubmit={handleSubmit}>
                  <div className="form-infor-profile">
                    <div className="info-account">
                      <h4 className="title-create-item">Account info</h4>
                      <div>
                        <fieldset>
                          <h4 className="title-infor-account">Display name</h4>
                          <input
                            type="text"
                            placeholder="Trista Francis"
                            onChange={handleChange}
                            name="username"
                            value={formData.username}
                            required
                          />
                          {s1 && (
                            <span className="required-font">
                              {!formData.username ? 'Enter User Name' : ''}
                            </span>
                          )}
                        </fieldset>
                      </div>
                      <fieldset>
                        <h4 className="title-infor-account">Custom URL</h4>
                        <input
                          type="text"
                          placeholder="Axies.Trista Francis.com/"
                          onChange={handleChange}
                          name="customURL"
                          value={formData.customURL}
                        />
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">Email</h4>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          onChange={handleChange}
                          name="email"
                          value={formData.email}
                          required
                        />
                        {s1 && (
                          <span className="required-font">
                            {!formData.email && 'Enter Mail Id'}
                          </span>
                        )}
                      </fieldset>

                      <fieldset>
                        <h4 className="title-infor-account">Bio</h4>
                        <textarea
                          tabIndex="4"
                          rows="5"
                          onChange={handleChange}
                          name="bio"
                          value={formData.bio}></textarea>
                      </fieldset>
                    </div>
                    <div className="info-social">
                      <h4 className="title-create-item">Your Social media</h4>
                      <fieldset>
                        <h4 className="title-infor-account">Facebook</h4>
                        <input
                          type="text"
                          placeholder="Facebook username"
                          onChange={handleChange}
                          name="facebook"
                          value={formData.facebook}
                        />
                        {/* <Link to="#" className="connect">
                          <i className="fab fa-facebook"></i>Connect to face book
                        </Link> */}
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">Twitter</h4>
                        <input
                          type="text"
                          placeholder="Twitter username"
                          onChange={handleChange}
                          name="twiter"
                          value={formData.twiter}
                        />
                        {/* <Link to="#" className="connect">
                          <i className="fab fa-twitter"></i>Connect to Twitter
                        </Link> */}
                      </fieldset>
                      <fieldset>
                        <h4 className="title-infor-account">Discord</h4>
                        <input
                          type="text"
                          placeholder="Discord username"
                          onChange={handleChange}
                          name="discord"
                          value={formData.discord}
                        />
                        {/* <Link to="#" className="connect">
                          <i className="icon-fl-vt"></i>Connect to Discord
                        </Link> */}
                      </fieldset>
                    </div>
                  </div>
                  <button
                    className="tf-button-submit mg-t-15"
                    type="submit"
                    onClick={() => handleShow()}
                    // onClick={() => {
                    //   ConnectedAccount ?  handleShow() : handleShowmodal();
                    // }}
                    // onClick={() => {
                    //   !ConnectedAccount ?  handleShow():  handleShowmodal();
                    // }}
                  >
                    <span className="create-item-btn" style={{ paddingLeft: '0px' }}>
                      {loading && (
                        <i
                          className="fa fa-spinner fa-spin fa-2x mr-2"
                          aria-hidden="true"
                          id="circle1"></i>
                      )}
                      Update Profile
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* <Modal show={show} onHide={handleClose} style={{ marginTop: '20rem' }}>
        <Modal.Header closeButton></Modal.Header>
        <div
          className="modal-body space-y-20 pd-40"
          style={{ marginLeft: '4rem', marginBottom: '3rem' }}>
          <h4 style={{ fontStyle: 'italic' }}>Please connect with wallet!</h4>
        </div>
      </Modal> */}
    </div>
  );
};

export default EditProfile;
