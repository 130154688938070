import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
//import PutonsaleModal from '../../PutonsaleModal';
import OwnedNFTSaleModal from '../../OwnedNFTSaleModal';
import { getshortcode } from '../../../utils/helpers';
//import commonFunction from '../../../utils/common';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import MessageNotifyModal from '../../../modal/MessageNotifyModal';
import getChainContractAddress from '../../../utils/getChainContractAddress';
import avaxLogo from '../../../assets/cryptoLogo/avalanche-avax-icon.svg';
import bnbLogo from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../../../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../../../assets/cryptoLogo/ethereum-eth-icon.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TransferModal from '../../../modal/TransferModal';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import img1 from '../../../assets/images/avatar/previewThumb.png';
import img from '../../../assets/images/avatar/previewThumb.png';

const MyNft_items = props => {
  const data = props?.data;
  console.log('MyNft_items', data, img);
  const { netWrokChainName } = React.useContext(ConnectWalletContext);
  const [modalShow, setModalShow] = useState(false);
  const [selectedid, setselectedid] = useState();
  const [switchShow, setSwitchShow] = useState(false);
  const [chainContractAddress, setChainContractAddress] = useState();
  const [chainName, setChainName] = useState();
  const [nftChainId, setNftChainId] = useState();
  const [transform, setTransform] = useState([]);
  const [showTrans, setShowTrans] = useState(false);
  const handleCloseTrans = () => setShowTrans(false);
  const transferModal = () => setShowTrans(true);

  const handleSwitchNetworkClose = () => {
    setSwitchShow(false);
  };
  // function getFileNameWithExt() {
  //   console.log('file Extentions is video');
  // }
  React.useEffect(() => {
    console.log('file Extentions from local storage', localStorage.getItem('fileExt'));
  }, []);
  console.log(props.progress, 'Rrrrr');
  return (
    <Fragment>
      <section className="tf-section sc-explore-1">
        {props.progress ? (
          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
            <h2>Loading...</h2>
          </div>
        ) : (
          <div className="themesflat-container">
            <div className="row">
              {data?.length > 0
                ? data
                    ?.filter(item => !item.putOnMarketplace)
                    ?.map((item, index) => (
                      <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className={`sc-card-product ${item.feature ? 'comingsoon' : ''} `}>
                          <div className="card-media ">
                            {/* <Link to={`/buy/${item?.id || item?._id}`} state={item}> */}
                            {/* <img
                                src={item.img || item.fileUrl}
                                className="cardimg_size"
                                alt="axies"
                              /> */}
                            {item?.fileUrl?.split('.')[4] == 'mp4' ? (
                              <>
                                <video
                                  width="320"
                                  height="240"
                                  controls
                                  src={item?.fileUrl}
                                  controlsList="nodownload">
                                  Your browser does not support the video tag.
                                </video>
                                {/* <Link to={`/buy/${item?.id || item?._id}`} state={item}> */}
                                <a href={`/nft/buy/${item?.id || item?._id}`}>
                                  <h4
                                    style={{
                                      fontStyle: 'italic',
                                      color: 'blue',
                                      textDecoration: 'underline',
                                    }}
                                    className="ml-3 mt-1">
                                    Click here for details
                                  </h4>
                                </a>
                                {/* </Link> */}
                              </>
                            ) : item?.fileUrl?.split('.')[4] == 'mpeg' ? (
                              <>
                                <audio controls src={item?.fileUrl} controlsList="nodownload">
                                  Your browser does not support the audio element.
                                </audio>
                                <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                  <img src={img} />
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                  <img
                                    src={item.img || item.fileUrl}
                                    className="cardimg_size"
                                    alt="axies"
                                  />
                                </Link>
                              </>
                            )}
                            {/* </Link> */}
                            {/* {item.wishlist > 0 && (
                            <Link
                              to={`/buy/${item?.id || item?._id}`}
                              state={item}
                              className="wishlist-button heart">
                              <span className="number-like">{item.wishlist}</span>
                            </Link>
                          )} */}
                            <div className="coming-soon">{item.feature}</div>
                          </div>
                          <div className="card-title">
                            <h5 className="style2">
                              {item.blockChainName == 'Avalanche' ? (
                                <img className="cryptoImg" src={avaxLogo} />
                              ) : item.blockChainName == 'Binance' ? (
                                <img className="cryptoImg" src={bnbLogo} />
                              ) : item.blockChainName == 'Ethereum' ? (
                                <img className="cryptoImg" src={ethLogo} />
                              ) : item.blockChainName == 'Polygon' ? (
                                <img className="cryptoImg" src={maticLogo} />
                              ) : item.blockChainName == undefined ? (
                                <img className="cryptoImg" src={ethLogo} />
                              ) : (
                                ''
                              )}
                              &nbsp;&nbsp;
                              <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                                {item.title}
                              </Link>
                            </h5>
                            <div className="tags">{item.tags || item.CategoryData.name}</div>
                          </div>
                          <div className="meta-info">
                            <div className="author">
                              <div className="info">
                                <span>Owned By</span>
                                <h6>{getshortcode(item.currentAddress)}</h6>
                              </div>
                            </div>
                            <div className="price">
                              <span>Current price</span>
                              <h5>
                                {item.price} &nbsp;
                                {item.blockChainName == 'Avalanche'
                                  ? 'AVAX'
                                  : item.blockChainName == 'Binance'
                                  ? 'BNB'
                                  : item.blockChainName == 'Ethereum'
                                  ? 'ETH'
                                  : item.blockChainName == 'Polygon'
                                  ? 'MATIC'
                                  : item.blockChainName == undefined
                                  ? 'ETH'
                                  : ''}
                              </h5>
                            </div>
                            <div className="price">
                              <span>Token Owned</span>
                              <h5>{item.noOfCopies}</h5>
                            </div>
                          </div>
                          <div className="card-bottom">
                            {item.putOnMarketplace ? (
                              <>
                                <button
                                  onClick={async () => {
                                    setselectedid(index);
                                    if (data[index].blockChainName == netWrokChainName) {
                                      setModalShow(true);
                                    }
                                    if (data[index].blockChainName != netWrokChainName) {
                                      setSwitchShow(true);
                                      setChainName(data[index].blockChainName);
                                      let chainAdress = await getChainContractAddress(
                                        data[index].blockChainName,
                                      );
                                      setChainContractAddress(chainAdress.NFTcontractAddress);
                                      setNftChainId(chainAdress.chainId);
                                    }
                                  }}
                                  className="sc-button style fl-button pri-3 no-bg mt-4">
                                  <span className="pl-0">Change price</span>
                                </button>
                                {/* <Link to={'/buy'} state={item}>
                            <button className="sc-button style bag fl-button pri-3 no-bg">
                              <span>Bid now</span>
                            </button>
                          </Link> */}
                              </>
                            ) : (
                              <div>
                                <button
                                  onClick={async () => {
                                    //onHandleClick(index);
                                    setselectedid(index);
                                    if (data[index].blockChainName == netWrokChainName) {
                                      setModalShow(true);
                                    }
                                    if (data[index].blockChainName != netWrokChainName) {
                                      setSwitchShow(true);
                                      setChainName(data[index].blockChainName);
                                      let chainAdress = await getChainContractAddress(
                                        data[index].blockChainName,
                                      );
                                      setChainContractAddress(chainAdress.NFTcontractAddress);
                                      setNftChainId(chainAdress.chainId);
                                    }
                                  }}
                                  className="sc-button style bag fl-button pri-3 no-bg">
                                  <span>Put on sale</span>
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">Transfer Content.!</Tooltip>
                                  }>
                                  <a
                                    onClick={async () => {
                                      //onHandleClick(index);
                                      setselectedid(index);
                                      if (data[index].blockChainName == netWrokChainName) {
                                        transferModal();
                                        setTransform(data[index]);
                                      }
                                      if (data[index].blockChainName != netWrokChainName) {
                                        setSwitchShow(true);
                                        setChainName(data[index].blockChainName);
                                        let chainAdress = await getChainContractAddress(
                                          data[index].blockChainName,
                                        );
                                        setChainContractAddress(chainAdress.NFTcontractAddress);
                                        setNftChainId(chainAdress.chainId);
                                      }
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon
                                      style={{ width: '18px', height: '18px' }}
                                      icon={faPaperPlane}
                                    />
                                  </a>
                                </OverlayTrigger>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                : props.progress === false &&
                  data?.length === 0 && (
                    <>
                      <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                        <h2>No data found...</h2>
                      </div>
                      <br></br>
                      {/* <div
                    className="col-md-12 wrap-inner load-more text-center"
                    style={{ paddingTop: '2rem' }}>
                    <Link
                      to="#"
                      // id="load-more"
                      className="sc-button loadmore fl-button pri-3"
                      onClick={() => props.previouspageowned()}>
                      <span>Previous Page</span>
                    </Link>
                  </div> */}
                      {/* <a
                    className="col-md-12 wrap-inner load-more text-center"
                    style={{ paddingTop: '2rem', cursor: 'pointer' }}
                    onClick={() => props.previouspageowned()}>
                    <span>Previous Page</span>
                  </a> */}
                    </>
                  )}
              {data?.length === 0 ? (
                ''
              ) : (
                <div className="col-md-12 wrap-inner load-more text-center">
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={() => props.increasepagenumberowned()}>
                    <span>Load More</span>
                  </Link>
                </div>
              )}

              {/* {data.length === 10 && (
                // <div className="col-md-12 wrap-inner load-more text-center">
                //   <Link
                //     to="#"
                //     id="load-more"
                //     className="sc-button loadmore fl-button pri-3"
                //     onClick={() => props.previouspage()}>
                //     <span>Previous Page</span>
                //   </Link>
                // </div>
                <a
                  className="col-md-12 wrap-inner load-more text-center"
                  style={{ paddingTop: '2rem', cursor: 'pointer' }}
                  onClick={() => props.previouspageowned()}>
                  <span>Previous Page</span>
                </a>
              )} */}
            </div>
          </div>
        )}
      </section>
      <OwnedNFTSaleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selecteddata={data[selectedid]}
      />
      <MessageNotifyModal
        show={switchShow}
        onHide={handleSwitchNetworkClose}
        chainName={chainName}
        chainId={nftChainId}
        chainIdAddress={chainContractAddress}
      />
      <TransferModal data={transform} show={showTrans} onHide={handleCloseTrans} />
    </Fragment>
  );
};

export default MyNft_items;
