import React, { useState, Fragment, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
import { _topSellers } from '../../../services/mainApi';
import img1 from '../../../assets/images/avatar/previewThumb.png';
import shortenAccount from '../../../utils/shortenAccount';
import { useNavigate } from 'react-router-dom';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import { Modal } from 'react-bootstrap';

const TopSeller = props => {
  const [data, setdata] = useState([]);
  // const { ConnectedAccount, netWrokChainName } = useContext(ConnectWalletContext);
  // let navigate = useNavigate();
  useEffect(() => {
    const promise = _topSellers();
    promise.then(res => {
      if (!res.error) {
        setdata(res?.data?.data);
        props?.settotalItems(res?.data?.data?.length);
      }
    });
  }, []);
  console.log('datasTopSeller', data);
  return (
    <section className="tf-section top-seller bg-home-3">
      <div className="themesflat-container">
        <div className="row row-margin">
          <div className="col-md-12">
            <div className="">
              <h2 className="tf-title style2">Top Seller</h2>
              <div className="heading-line s1"></div>
            </div>
          </div>
          {data?.length > 0 && data.map((item, index) => <TopSellerItem key={index} item={item} />)}
        </div>
      </div>
    </section>
  );
};

const TopSellerItem = props => {
  let navigate = useNavigate();
  const { ConnectedAccount } = useContext(ConnectWalletContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        <div className="sc-author-box">
          <div className="author-avatar">
            {/* <Link
              to={`/user/${props.item.userId}`}
              state={{ ownerAccount: shortenAccount(props.item.walletAddress) }}>
              <img
                src={props.item.userImage ? props.item.userImage : img1}
                style={props.item.userImage ? { borderRadius: 1000 } : {}}
                alt="Axies"
                className="avatar"
              />
            </Link> */}
            <div style={{ cursor: 'pointer' }}>
              <img
                onClick={() => {
                  ConnectedAccount
                    ? navigate(`/user/${props.item.userId}`, {
                        state: { ownerAccount: shortenAccount(props.item.walletAddress) },
                      })
                    : handleShow();
                }}
                src={props.item.userImage ? props.item.userImage : img1}
                style={props.item.userImage ? { borderRadius: 1000 } : {}}
                alt="Axies"
                className="avatar"
              />
            </div>
            <div className="badge">
              <i className="ripple"></i>
            </div>
          </div>
          <div className="author-infor">
            {/* <h5 className="style2"> */}
            {/* <Link
                to={`/user/${props.item.userId}`}
                state={{ ownerAccount: shortenAccount(props.item.walletAddress) }}>
                {shortenAccount(props.item.walletAddress)}
              </Link> */}
            <h5
              style={{ cursor: 'pointer' }}
              className="style2"
              onClick={() => {
                ConnectedAccount
                  ? navigate(`/user/${props.item.userId}`, {
                      state: { ownerAccount: shortenAccount(props.item.walletAddress) },
                    })
                  : handleShow();
              }}>
              {shortenAccount(props.item.walletAddress)}
            </h5>
            {/* </h5> */}
            <span className="price">{props.item.noOfNftAmount}</span>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} style={{ marginTop: '20rem' }}>
        <Modal.Header closeButton></Modal.Header>
        <div
          className="modal-body space-y-20 pd-40"
          style={{ marginLeft: '4rem', marginBottom: '3rem' }}>
          <h4 style={{ fontStyle: 'italic' }}>Please connect with wallet!</h4>
        </div>
      </Modal>
    </Fragment>
  );
};
export default TopSeller;
