import img1 from '../images/blog/thumb-1.jpg';
import img2 from '../images/blog/thumg-3-1.jpg';
import img3 from '../images/blog/thumb-4.jpg';
import img4 from '../images/blog/thumb-2.jpg';
import img5 from '../images/blog/thumb-5.jpg';
import img6 from '../images/blog/thumb-6-1.jpg';
import imga1 from '../images/avatar/avt-2.jpg';
import imga2 from '../images/avatar/avt-3.jpg';
import imga3 from '../images/avatar/avt-6.jpg';
import imga4 from '../images/avatar/avt-4.jpg';
import imga5 from '../images/avatar/avt-8.jpg';
import imga6 from '../images/avatar/avt-6.jpg';
const blogData = [
  {
    img: img1,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga1,
    nameAuthor: 'SalvadorDali',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img2,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga2,
    nameAuthor: 'Tyler Covington',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img3,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga3,
    nameAuthor: 'Freddie Carpenter',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img4,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga4,
    nameAuthor: 'SalvadorDali',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img5,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga5,
    nameAuthor: 'Tyler Covington',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img6,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga6,
    nameAuthor: 'Freddie Carpenter',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img1,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga1,
    nameAuthor: 'SalvadorDali',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img2,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga2,
    nameAuthor: 'Tyler Covington',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img3,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga3,
    nameAuthor: 'Freddie Carpenter',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img4,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga4,
    nameAuthor: 'SalvadorDali',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img5,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga5,
    nameAuthor: 'Tyler Covington',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
  {
    img: img6,
    title: 'The Next Big Trend In Crypto',
    imgAuthor: imga6,
    nameAuthor: 'Freddie Carpenter',
    time: 'Feb 19, 2021',
    content:
      'Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...',
  },
];

export default blogData;
