import img1 from '../images/slider/slide_1.png';
import img2 from '../images/slider/slide_2.png';
import imgbg1 from '../images/slider/slide11.png';
// import imgbg2 from '../images/slider/slide11.png';

const heroSliderData = [
  {
    title_1: 'Discover, Buy',
    title_2: 'Curate & Sell ',
    title_3: 'Unique NFTs',
    description: 'A Unique NFT Marketplace for creators and collectors',
    class: 'center',
  },
  {
    title_1: 'Discover, Buy',
    title_2: 'Curate & Sell',
    title_3: 'Unique NFTs',
    description: 'A Unique NFT Marketplace for creators and collectors',
    img: img1,
    imgbg: imgbg1,
    class: 'left',
  },
  {
    title_1: 'Discover, Buy',
    title_2: 'Curate & Sell',
    title_3: 'Unique NFTs',
    description: 'A Unique NFT Marketplace for creators and collectors',
    img: img2,
    imgbg: imgbg1,
    class: 'right',
  },
];

export default heroSliderData;
