import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { ConnectionProvider } from './context/ConnectWalletContext';

ReactDOM.render(
  <ConnectionProvider>
    <BrowserRouter basename="/nft">
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </ConnectionProvider>,
  document.getElementById('root'),
);
