import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import commonFunction from '../utils/common';
import sellingNFT from '../nft/SellingNFT';
import loadWeb3 from '../utils/loadWeb3';
import PyraNftMarket721 from '../ABI/PyraNftMarket.json';
import PyraMultiToken1155 from '../ABI/PyraMultiToken.json';
import config from '../lib/config';
import { _updateNft } from '../services/mainApi';
import { useNavigate } from 'react-router-dom';
//import getChainContractAddress from '../utils/getChainContractAddress';

const PutonsaleModal = props => {
  let navigate = useNavigate();
  let data = props.selecteddata;
  let TokenContract;
  console.log('PRK from Put on sale data module', data);
  const { ConnectedAccount, netWrokChainName } = React.useContext(ConnectWalletContext);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [sellerReceivedValue, setSellerReceivedValue] = useState(0);
  const [progress, setprogress] = useState(false);
  //const [creatorAddress, setCreatorAddress] = useState();
  const [TotalQuantity, setTotalQuantity] = useState(0);
  const [validateValue, setvalidateValue] = useState('');
  const [validateError, setValidateError] = useState(false);

  let currency;
  if (data?.blockChainName == 'Avalanche') {
    currency = 'AVAX';
  }
  if (data?.blockChainName == 'Ethereum' || data?.blockChainName == undefined) {
    currency = 'ETH';
  }
  if (data?.blockChainName == 'Polygon') {
    currency = 'MATIC';
  }
  if (data?.blockChainName == 'Binance') {
    currency = 'BNB';
  }

  //get the shortenAccount value
  let shortenAccount;
  if (ConnectedAccount) {
    shortenAccount = `${ConnectedAccount.slice(0, 5)}...${ConnectedAccount.slice(
      ConnectedAccount.length - 4,
    )}`;
  }
  //get the contract address
  let contractAddress = data?.collectionAddress;
  TokenContract = PyraNftMarket721;
  // const getContractAddress = async () => {
  //   let contractChainAddress = await getChainContractAddress(data?.blockChainName);
  //   contractAddress = contractChainAddress.NFTcontractAddress;
  //   console.log('PRK chain contract address from getchainContract..', contractAddress);
  // };
  if (contractAddress == undefined) {
    if (data?.CollectionData?.length > 0) {
      contractAddress = data?.CollectionData[0]?.address;
      //TokenContract = PyraNftMarket721;
    } else if (data?.multiToken === true) {
      //check for ERC-1155 then get the respective contract address
      contractAddress = config.NFTMultiTokenContract;
      TokenContract = PyraMultiToken1155;
    } else {
      //check for ERC-721 then get that contract address
      contractAddress = config.NFTContractAddress;
      console.log('PRK puton sale contract address', contractAddress);
      console.log('PRK chain name from create-item', data?.blockChainName);
      //getContractAddress();
    }
  }
  //check the current chain
  // useEffect (() => {

  // }, [netwo])

  //call below function to get serviceFee value from the smart contract
  React.useEffect(() => {
    //if(netWrokChainName != data?.blockChainName)
    //if(data?.blockChainName != undefined)
    console.log('PRK netWrokChainName', netWrokChainName);
    console.log('PRK data?.blockChainName', data?.blockChainName);
    if (serviceFee === 0) {
      const getServiceFee = async () => {
        let web3 = await loadWeb3();
        try {
          const nftContract = new web3.eth.Contract(TokenContract, contractAddress);
          let NftServiceFee = await nftContract.methods.getServiceFee().call();
          //get royality
          setServiceFee(NftServiceFee);
        } catch (err) {
          console.log();
        }

        //console.log('getServiceFee', NftServiceFee);
      };
      getServiceFee();
    }
    return () => {};
  });

  //call updateNFT api once sale order is placed.
  const updatesoldNft = () => {
    const _myNftpromise = _updateNft({
      currentAddress: ConnectedAccount,
      fileUrl: data?.fileUrl ? data?.fileUrl : data?.img,
      title: data.title,
      description: data.description,
      categoryId: data.categoryId,
      price: data?.isBid ? 0 : tokenPrice, //if bid is true then put zero for price field
      minBidValue: data?.isBid ? tokenPrice : 0, //if bid true here then put minbid value
      putOnMarketplace: true,
      noOfCopies: data.noOfCopies,
      _id: data?._id ? data?._id : data?.id,
      properties: data.properties,
      blockChainName: data?.blockChainName,
      tokenId: data.tokenId,
    });
    _myNftpromise
      .then(
        res => {
          if (!res.error) {
            toast.success('Successfully Processed', commonFunction.toastsettings);
            console.log(res);
            navigate('/mynft');
            window.location.reload();
          }
        },
        err => {
          toast.error('Process failed', commonFunction.toastsettings);
          console.log('fail', err.message);
        },
      )
      .catch(err => {
        console.log('update failed', err.message);
      });
  };
  //--------------SellingNFT---------------------//
  const Validate = () => {
    console.log('validateee', validateValue, 'vali', sellerReceivedValue);
    if (validateValue === '' || validateValue <= 0 || validateValue === 0) {
      setValidateError(true);
    } else {
      setValidateError(false);
      continuesale(data.tokenId, tokenPrice, ConnectedAccount);
    }
  };
  const continuesale = async (tokenId, tokenPrice, ConnectedAccount) => {
    setprogress(true);
    let result = await sellingNFT(tokenId, tokenPrice, ConnectedAccount, contractAddress);
    //call updateNFT API once the above sellingNFT is done
    // setvalidateValue('');
    if (result && result.transactionHash) {
      setprogress(false);
      props.onHide();
      updatesoldNft();
      console.log('window.location.href', window.location.href);
      //window.location = '/nft';
      console.log('errrno');
    } else {
      setprogress(false);
      toast.error('Process failed', commonFunction.toastsettings);
      console.log('errryess');
    }
    //here need to update the respective NFT token with price and putonsale flag as true
    //there after this token should go under onSale option - need to test on this case
  };

  const sellingOrder = async e => {
    // console.log('selling order token', data.tokenId);
    // console.log('PRK Creator address', creatorAddress);
    // console.log('PRK currentAddress address', data.currentAddress);
    // console.log('PRK Connected address', ConnectedAccount);
    const web3 = await loadWeb3();
    let nftContract;
    try {
      nftContract = new web3.eth.Contract(TokenContract, contractAddress);
    } catch (err) {
      console.log();
    }
    //get creator address
    let creatorAddress;
    if (data?.tokenId) {
      creatorAddress = await nftContract.methods._creator(data?.tokenId).call();
    }
    //get the royality
    const nftRoyality = await nftContract.methods._royal(data.tokenId).call();
    let nftRoy = (e.target.value * nftRoyality) / 10 ** 20;
    console.log('royality', nftRoy);
    setTokenPrice(e.target.value);
    let nftServiceFees = (e.target.value * serviceFee) / 10 ** 20;
    console.log('calc value', nftServiceFees);
    console.log('Seller receiving value', e.target.value - nftServiceFees - nftRoy);
    console.log('RPK creator Address outside', creatorAddress);
    //if seller is the owner of the token
    if (ConnectedAccount == data.currentAddress) {
      //if creator and seller both are same then royality will not be deducted else deducted
      if (creatorAddress?.toLowerCase() == ConnectedAccount) {
        if (data?.multiToken === true) {
          console.log('Entered quantity', TotalQuantity);
          setSellerReceivedValue(
            parseFloat(e.target.value * TotalQuantity - nftServiceFees).toFixed(4),
          );
          setvalidateValue(parseFloat(e.target.value * TotalQuantity - nftServiceFees).toFixed(4));
        } else {
          setSellerReceivedValue(parseFloat(e.target.value - nftServiceFees).toFixed(4));
          setvalidateValue(parseFloat(e.target.value - nftServiceFees).toFixed(4));
        }
      } else {
        if (data?.multiToken === true) {
          setSellerReceivedValue(
            parseFloat(e.target.value * TotalQuantity - nftServiceFees - nftRoy).toFixed(4),
          );
          setvalidateValue(
            parseFloat(e.target.value * TotalQuantity - nftServiceFees - nftRoy).toFixed(4),
          );
        } else {
          setSellerReceivedValue(parseFloat(e.target.value - nftServiceFees - nftRoy).toFixed(4));
          setvalidateValue(parseFloat(e.target.value - nftServiceFees - nftRoy).toFixed(4));
        }
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide();
        setTokenPrice(0);
        setSellerReceivedValue(0);
        setvalidateValue('');
        setValidateError(false);
        setprogress(false);
      }}>
      <Modal.Header closeButton className="center">
        <h3 className="alignin-center">NFT for Sale</h3>
      </Modal.Header>
      <div className="modal-body space-y-20">
        {/* <div className={`p-0 center${data?.feature ? 'comingsoon' : ''} `}> */}
        <div className="card-media  center">
          <img
            className="cardimg_size"
            src={data?.fileUrl ? data?.fileUrl : data?.img}
            alt="axies"
          />
        </div>
        {/* </div> */}
        {/* <p className="text-center">You are about to place order for</p> */}
        {data?.isBid ? (
          <p className="text-center">You are about to update the bid value for</p>
        ) : (
          <p className="text-center">You are about to place order for</p>
        )}
        <p className="text-center mt-0">
          <span className="price color-popup">{data?.title} </span> {' from '}
          <span className="price color-popup">{shortenAccount}</span>
        </p>
        {data?.multiToken === true ? (
          <div className="row">
            <input
              type="number"
              min="1"
              className="form-control ml-5"
              placeholder="Enter Quantity"
              style={{ width: '20rem' }}
              onChange={e => setTotalQuantity(e.target.value)}
            />
            <input
              type="number"
              min="1"
              className="form-control ml-5"
              placeholder="Enter New Price"
              style={{ width: '20rem' }}
              onChange={sellingOrder}
            />
            <h6 className="mt-2 ml-5">Available {data?.noOfCopies}</h6>
          </div>
        ) : (
          <>
            <input
              type="number"
              min="1"
              className="form-control"
              placeholder={data?.isBid ? 'Enter bid value' : 'New price Value'}
              onChange={sellingOrder}
            />

            {validateError && <p className="required-font m-t-1">Enter values correctly</p>}
          </>
        )}

        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p> Service fee:</p>
          <p className="text-right price color-popup">{serviceFee / 10 ** 18}% </p>
        </div>

        <div className="d-flex justify-content-between">
          <p>You will get:</p>
          <p className="text-right price color-popup">
            {sellerReceivedValue} {currency}
          </p>
        </div>
        {progress ? (
          <div className="center">
            {' '}
            <i className="fa fa-spinner fa-spin fa-2x mr-2" aria-hidden="true" id="circle1"></i>
          </div>
        ) : (
          <button className="btn btn-primary" onClick={() => Validate()}>
            Continue
          </button>
        )}
      </div>
    </Modal>
  );
};

export default PutonsaleModal;
