import Axios from 'axios';
const BaseUrl = process.env.REACT_APP_BASEURL;

export const common = async ({ reqmethod, url, param, reqdata }) => {
  return Axios({
    method: reqmethod,
    url: BaseUrl + url,
    data: reqdata,
    params: param,
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('Token')}`,
    },
  });
};
export const commonFormData = async ({ reqmethod, url, param, reqdata }) => {
  return Axios({
    method: reqmethod,
    url: BaseUrl + url,
    data: reqdata,
    params: param,
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${localStorage.getItem('Token')}`,
    },
  });
};
