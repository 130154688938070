import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function CreateItemModal({
  show,
  handleClose,
  MintCallStatus,
  handleApproveNFTtoken,
  ApproveCallStatus,
  handleMintNFTtoken,
  // handleSignCall,
  // putonsale,
  // SignCallStatus,
}) {
  return (
    <Modal show={show} onHide={handleClose} animation={false} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Follow Steps</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mg-bt-22">
          <h6 className="text-muted">Approve and mint the NFT</h6>
          <h4 className="title-create-item">Approve performing transactions with your wallet</h4>
          <div className="text-center">
            <button
              type="button"
              // disabled={MintCallStatus === ('processing' || 'done')
              onClick={() => handleApproveNFTtoken()}
              className="sc-button fl-button pri-3 no-bg">
              <span className="create-item-btn" style={{ paddingLeft: '0px' }}>
                {ApproveCallStatus === 'processing' && (
                  <i
                    className="fa fa-spinner fa-spin fa-2x mr-2"
                    aria-hidden="true"
                    id="circle1"></i>
                )}
                {ApproveCallStatus === 'done' && (
                  <i className="fa fa-check mr-2" aria-hidden="true"></i>
                )}
                Approve
              </span>
              {/* {ApproveCallStatus==='init' && 'Approve'}
                {ApproveCallStatus==='processing' && 'In-progress...'}
                {ApproveCallStatus==='done' && 'Done'}
                {ApproveCallStatus==='tryagain' && 'Try Again'} */}
            </button>
          </div>
        </div>
        <div className="mg-bt-22">
          <h6 className="text-muted">Upload and mint the NFT</h6>
          <h4 className="title-create-item">Upload your file and mint the NFT</h4>
          <div className="text-center">
            <button
              type="button"
              onClick={() => handleMintNFTtoken()}
              // disabled={ApproveCallStatus !== 'done'}
              className="sc-button fl-button pri-3 no-bg">
              <span className="create-item-btn" style={{ paddingLeft: '0px' }}>
                {MintCallStatus === 'processing' && (
                  <i
                    className="fa fa-spinner fa-spin fa-2x mr-2"
                    aria-hidden="true"
                    id="circle1"></i>
                )}{' '}
                {MintCallStatus === 'done' && (
                  <i className="fa fa-check mr-2" aria-hidden="true"></i>
                )}
                Mint
              </span>
            </button>
          </div>
        </div>
        {/*commented for timely testing */}
        {/* {putonsale && (
          <div className="mg-bt-22">
            <h6 className="text-muted">Sign Sale Order</h6>
            <h4 className="title-create-item">Sign Sale order to put NFT for Sale</h4>
            <div className="text-center">
              <button
                disabled
                type="button"
                className="sc-button header-slider style style-1 fl-button pri-1"
                onClick={handleClose}>
                <span
                  className="create-item-btn text-muted"
                  style={{ paddingLeft: '0px' }}
                  onClick={() => handleSignCall()}>
                  {SignCallStatus === 'processing' && (
                    <i className="fa fa-spinner fa-spin fa-1x" aria-hidden="true" id="circle1"></i>
                  )}
                  Sign
                </span>
              </button>
            </div>
          </div>
        )} */}
      </Modal.Body>
    </Modal>
  );
}
