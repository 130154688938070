import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PutonsaleModal from '../../PutonsaleModal';
import { getshortcode } from '../../../utils/helpers';
//import commonFunction from '../../../utils/common';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import MessageNotifyModal from '../../../modal/MessageNotifyModal';
import getChainContractAddress from '../../../utils/getChainContractAddress';
import avaxLogo from '../../../assets/cryptoLogo/avalanche-avax-icon.svg';
import bnbLogo from '../../../assets/cryptoLogo/binance-coin-bnb-icon.svg';
import maticLogo from '../../../assets/cryptoLogo/polygon-matic-icon.svg';
import ethLogo from '../../../assets/cryptoLogo/ethereum-eth-icon.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnlockModal from '../../UnlockModal';
const MyNft_itemsOnSale = props => {
  const data = props.data;
  console.log(data, 'data---->>>');
  const [modalView, setmodalView] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [cardIndex, setCardIndex] = useState();
  const [selectedid, setselectedid] = useState();
  const { ConnectedAccount, netWrokChainName } = React.useContext(ConnectWalletContext);
  const [switchShow, setSwitchShow] = useState(false);
  const [chainContractAddress, setChainContractAddress] = useState();
  const [chainName, setChainName] = useState();
  const [nftChainId, setNftChainId] = useState();

  // const onChangePrice = async index => {
  //   console.log('PRK my item sale change price blockchain name..', data[index].blockChainName);
  //   console.log('PRK my item sale connected blockchain name', netWrokChainName);
  // };

  const handleSwitchNetworkClose = () => {
    setSwitchShow(false);
  };
  console.log(props.progress, 'Rrrrr');
  return (
    <Fragment>
      <section className="tf-section sc-explore-1">
        {props.progress ? (
          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
            <h2>Loading...</h2>
          </div>
        ) : (
          <div className="themesflat-container">
            <div className="row">
              {data?.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className={`sc-card-product ${item.feature ? 'comingsoon' : ''} `}>
                      {item?.unlockContentFlag === true ? (
                        <div>
                          <OverlayTrigger
                            style={{ width: '70px', height: '30px' }}
                            overlay={
                              <Tooltip id="tooltip-disabled">Includes Unlockable Content.!</Tooltip>
                            }>
                            <span className="d-inline-block">
                              <a>
                                <FontAwesomeIcon
                                  size="2x"
                                  style={{ marginBottom: '5px' }}
                                  icon={faLock}
                                />
                              </a>
                            </span>
                          </OverlayTrigger>
                          {/* <span className="pl-0">Reveal Unlock Content</span> */}
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="card-media ">
                        <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                          <img src={item.fileUrl} className="cardimg_size" alt="axies" />
                        </Link>
                        {/* {item.wishlist > 0 && (
                          <Link to={'/buy'} state={item} className="wishlist-button heart">
                            <span className="number-like">{item.wishlist}</span>
                          </Link>
                        )} */}
                        {props.loading && cardIndex === index ? (
                          <div className="wishlist-button-loading">
                            <i
                              className="fa fa-spinner fa-spin fa-3x mb-3"
                              aria-hidden="true"
                              id="circle1"></i>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              {
                                ConnectedAccount
                                  ? (props.addOrRemoveFav(item), setCardIndex(index))
                                  : '';
                              }
                            }}
                            className={
                              item.favStatus
                                ? 'wishlist-button icon-fl-heart-filled'
                                : 'wishlist-button heart'
                            }>
                            <span className="number-like">{item.wishlist || item.favCount}</span>
                          </div>
                        )}
                        <div className="coming-soon">{item.feature}</div>
                      </div>
                      <div className="card-title">
                        <h5 className="style2">
                          {item.blockChainName == 'Avalanche' ? (
                            <img className="cryptoImg" src={avaxLogo} />
                          ) : item.blockChainName == 'Binance' ? (
                            <img className="cryptoImg" src={bnbLogo} />
                          ) : item.blockChainName == 'Ethereum' ? (
                            <img className="cryptoImg" src={ethLogo} />
                          ) : item.blockChainName == 'Polygon' ? (
                            <img className="cryptoImg" src={maticLogo} />
                          ) : item.blockChainName == undefined ? (
                            <img className="cryptoImg" src={ethLogo} />
                          ) : (
                            ''
                          )}
                          &nbsp;&nbsp;
                          <Link to={`/buy/${item?.id || item?._id}`} state={item}>
                            {item.title}
                          </Link>
                        </h5>
                        <div className="tags">{item.CategoryData.name}</div>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="info">
                            <span>Owned By</span>
                            <h6>{getshortcode(item.currentAddress)}</h6>
                          </div>
                        </div>
                        <div className="price">
                          {item?.isBid ? <span>Min bid value</span> : <span>Current price</span>}
                          {/* <span>Current price</span> */}
                          <h5>
                            {item?.isBid ? item?.minBidValue : item.price} &nbsp;
                            {item.blockChainName == 'Avalanche'
                              ? 'AVAX'
                              : item.blockChainName == 'Binance'
                              ? 'BNB'
                              : item.blockChainName == 'Ethereum'
                              ? 'ETH'
                              : item.blockChainName == 'Polygon'
                              ? 'MATIC'
                              : ''}
                          </h5>
                        </div>
                      </div>
                      <div className="card-bottom">
                        {item.putOnMarketplace ? (
                          <>
                            <button
                              onClick={async () => {
                                setselectedid(index);
                                if (data[index].blockChainName == netWrokChainName) {
                                  setModalShow(true);
                                }
                                if (data[index].blockChainName != netWrokChainName) {
                                  setSwitchShow(true);
                                  setChainName(data[index].blockChainName);
                                  let chainAdress = await getChainContractAddress(
                                    data[index].blockChainName,
                                  );
                                  setChainContractAddress(chainAdress.NFTcontractAddress);
                                  setNftChainId(chainAdress.chainId);
                                }
                                //onChangePrice(index);
                              }}
                              className="sc-button style fl-button pri-3 no-bg mt-4 ml-5">
                              {/* <span className="pl-0">Change Price</span> */}
                              {item?.isBid ? (
                                <span className="pl-0">Change bid value</span>
                              ) : (
                                <span className="pl-0">Change Price</span>
                              )}
                            </button>
                            {/* <Link to={'/buy'} state={item}>
                            <button className="sc-button style bag fl-button pri-3 no-bg">
                              <span>Bid now</span>
                            </button>
                          </Link> */}
                          </>
                        ) : (
                          <button
                            onClick={() => {
                              setselectedid(index);
                              setModalShow(true);
                            }}
                            className="sc-button style bag fl-button pri-3 no-bg">
                            <span>Put on sale</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : props.progress === false ? (
                <>
                  <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                    <h2>No data found...</h2>
                  </div>
                  <br></br>
                  {/* <div
                    className="col-md-12 wrap-inner load-more text-center"
                    style={{ paddingTop: '2rem' }}>
                    <Link
                      to="#"
                      // id="load-more"
                      className="sc-button loadmore fl-button pri-3"
                      onClick={() => props.previouspage()}>
                      <span>Previous Page</span>
                    </Link>
                  </div> */}
                  {/* <a
                    className="col-md-12 wrap-inner load-more text-center"
                    style={{ paddingTop: '2rem', cursor: 'pointer' }}
                    onClick={() => props.previouspage()}>
                    <span>Previous Page</span>
                  </a> */}
                </>
              ) : (
                <></>
              )}
              {data?.length === 10 && (
                <div className="col-md-12 wrap-inner load-more text-center">
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={() => props.increasepagenumberowned()}>
                    <span>Load More</span>
                  </Link>
                </div>
              )}
              {/* {data.length === 10 && (
                // <div className="col-md-12 wrap-inner load-more">
                //   <Link
                //     to="#"
                //     id="load-more"
                //     className="sc-button loadmore fl-button pri-3"
                //     onClick={() => props.previouspage()}>
                //     <span>Previous Page</span>
                //   </Link>
                // </div>
                <a
                  className="col-md-12 wrap-inner load-more text-center"
                  style={{ paddingTop: '2rem', cursor: 'pointer' }}
                  onClick={() => props.previouspage()}>
                  <span>Previous Page</span>
                </a>
              )} */}
            </div>
          </div>
        )}
      </section>
      <UnlockModal
        show={modalView}
        onHide={() => setmodalView(false)}
        selecteddata={data[selectedid]}
      />
      <PutonsaleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selecteddata={data[selectedid]}
      />

      <MessageNotifyModal
        show={switchShow}
        onHide={handleSwitchNetworkClose}
        chainName={chainName}
        chainId={nftChainId}
        chainIdAddress={chainContractAddress}
      />
    </Fragment>
  );
};

export default MyNft_itemsOnSale;
