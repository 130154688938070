import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import imga1 from '../../../assets/images/avatar/avt-11.jpg';
import previewuser from '../../../assets/images/avatar/previewThumb.png';

const Collection = props => {
  const data = props.data;
  console.log(props?.item?._id, 'loadmore');

  // window.location.reload();
  console.log('Collection data from collection', data);

  return (
    <section className="tf-section live-auctions">
      {props.progress ? (
        <div style={{ textAlignLast: 'center' }}>
          <i className="fa fa-spinner fa-spin fa-3x mb-3" aria-hidden="true" id="circle1"></i>
          <h2>Loading...</h2>
        </div>
      ) : (
        <div className="themesflat-container">
          <div className="row">
            {/* <div className="col-md-12">
            <h2 className="tf-title-heading style-1 ct">Live Auctions</h2>
          </div> */}

            {data?.length > 0
              ? data?.map((item, index) => <CollectionItem key={index} item={item} />)
              : props.progress === false && (
                  <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                    <h2>No data found...</h2>
                  </div>
                )}

            {data?.length >= 10 && (
              <div className="col-md-12 wrap-inner load-more text-center">
                <Link
                  to="#"
                  id="load-more"
                  className="sc-button loadmore fl-button pri-3"
                  onClick={() => props.increasepagenumber()}>
                  {props.loadMore ? <span>Loading..</span> : <span>Load More</span>}
                  {props.loadMore && (
                    <div className="wishlist-button-loading-loadmore">
                      <i
                        className="fa fa-spinner fa-spin fa-3x mb-3"
                        aria-hidden="true"
                        id="circle1"></i>
                    </div>
                  )}
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

Collection.propTypes = {
  data: PropTypes.array.isRequired,
};

const CollectionItem = props => (
  <div className="fl-item col-xl-3 col-lg-3 col-md-3">
    <div className="sc-card-product">
      <div className="card-media nft_wrap">
        <Link to={`/explore-01/${props?.item?._id}`}>
          <img
            className=""
            src={props?.item?.collectionImage ? props.item.collectionImage : imga1}
            alt="axies"
          />
        </Link>
      </div>
      <div className="nft_coll_pp">
        <div className="meta-info">
          <div className="author">
            <div className="avatar">
              <img src={previewuser} alt="axies" />
            </div>
          </div>
        </div>
      </div>
      <div className="mg-bt-16  center">
        <Link to={`/explore-01/${props?.item?._id}`}>
          <h5>{props?.item?.name ? props.item.name : ''}</h5>
        </Link>
      </div>
      <div className="info desgray center mg-bt-16">
        <span>
          <h6>{props?.item?.description ? props.item.description : ''}</h6>
        </span>
      </div>
    </div>
  </div>
);

export default Collection;
