import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import { _categoryList } from '../../../services/mainApi';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import arts from '../../../assets/images/home7/arts.png';
import photography from '../../../assets/images/home7/photography.png';
import sports from '../../../assets/images/home7/sports.png';
import videos from '../../../assets/images/home7/videos.png';
import collectibles from '../../../assets/images/home7/collectibles.png';
import music from '../../../assets/images/home7/music.png';
import metaverse from '../../../assets/images/home7/metaverse.png';
import cards from '../../../assets/images/home7/cards.png';

const Category = () => {
  const [categoryList, setcategorylist] = React.useState([]);
  const ImgArr = [arts, photography, music, metaverse, videos, collectibles, sports, cards];
  React.useEffect(() => {
    const Data = [];
    _categoryList().then(res => {
      if (!res.error) {
        res?.data?.data?.map((item, i) =>
          Data?.push({
            id: item.categoryId,
            field: item.name,
            img: ImgArr[i],
          }),
        );
        setcategorylist(Data);
      }
    });
    console.log(categoryList, 'cateee');
  }, []);
  return (
    <section className="tf-section category">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title pb-39">All Categories</h2>
            </div>
          </div>
          <div className="col-md-12">
            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              spaceBetween={25}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                767: {
                  slidesPerView: 4,
                },
                991: {
                  slidesPerView: 6,
                },
              }}
              navigation
              scrollbar={{ draggable: true }}>
              {categoryList.map((item, index) => (
                <SwiperSlide key={index}>
                  <CategoryItem item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

const CategoryItem = props => (
  <div className="swiper-container carousel11">
    <div className="swiper-wrapper">
      <div className="swiper-slide">
        <div className="slider-item">
          <div className="sc-categoty">
            <div className="card-media ImageScene" onClick={() => console.log(props.item, 'catee')}>
              <Link to={`/itemDetail/${props.item.id}`} state={props.item}>
                <img src={props.item.img} alt="Axies" className="imageScene-img" />
              </Link>
            </div>
            <div className="card-title">
              <Link to={`/itemDetail/${props.item.id}`} state={props.item}>
                <h4>{props.item.field}</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Category;
