import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Collection from '../components/layouts/collection';
import { _myCollectionList } from '../services/mainApi';

const MyCollection = () => {
  const [collectionList, setcollectionList] = useState([]);
  const [progress, setprogress] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalItems, settotalItems] = useState(0);
  const [loadMore, setLoadMore] = useState(false);

  const increaseexplorepagenumber = () => {
    setcurrentPage(currentPage + 1);
    setLoadMore(true);
  };
  const storedata = res => {
    let resdata = res?.data?.data?.collectionList ? res.data.data.collectionList : [];
    let data;
    if (collectionList.length > 0) {
      data = [...collectionList, ...resdata];
    } else {
      data = [...resdata];
    }
    let uniqueData = [
      ...data.reduce((map, obj) => map.set(obj.collectionId, obj), new Map()).values(),
    ];
    setcollectionList(uniqueData);
    settotalItems(res?.data?.data?.totalItems);
  };
  const onsearchcollection = req => {
    if (req?.length > 3) {
      const promise = _myCollectionList(currentPage);
      promise.then(
        res => {
          if (!res.error) {
            storedata(res);
          }
          setprogress(false);
        },
        () => {
          setprogress(false);
        },
      );
    }
  };
  useEffect(() => {
    setprogress(true);
    onsearchcollection('test');
  }, [currentPage]);
  return (
    <div className="auctions">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">My collections</h1>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Auctions</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Collection
        data={collectionList}
        progress={progress}
        totalItems={totalItems}
        increasepagenumber={() => increaseexplorepagenumber()}
        loadMore={loadMore}
      />
      <Footer />
    </div>
  );
};

export default MyCollection;
