import loadWeb3 from '../utils/loadWeb3';
import PyraNftMarket721 from '../ABI/PyraNftMarket.json';
import config from '../lib/config';

const sellingOrder = async (sellingTokenId, sellingPrice, ConnectedAccount, contractAddress) => {
  let web3 = await loadWeb3();
  console.log('Put on sale process starts...');
  console.log('Wallet Connected account', ConnectedAccount);
  console.log('Selling token id', sellingTokenId);

  //if contract address is received from collection then use that address else use sigle deployed address
  let NFTContractAddress = contractAddress ? contractAddress : config.NFTContractAddress;
  console.log('Contract address', NFTContractAddress);
  try {
    const nftContract = new web3.eth.Contract(PyraNftMarket721, NFTContractAddress);
    let sellingTokenValue = 0;
    if (sellingPrice != 0) {
      sellingTokenValue = web3.utils.toWei(sellingPrice.toString(), 'ether');
      console.log('Selling token price ie wei', sellingTokenValue);
    }
    let response = await nftContract.methods //--
      .orderPlace(
        //Accounts, //from onwer
        sellingTokenId, //from owner account `tokenId` token must exist and be owned by `from`.
        sellingTokenValue, //selling price
      )
      .send({
        from: ConnectedAccount,
        //gas: 3000000,
      }); //--
    return response;
  } catch (err) {
    console.log();
  }
};

export default sellingOrder;
