import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import loadWeb3 from '../../../utils/loadWeb3';
import biddingOrder from '../../../nft/BiddingNFT';
import { ConnectWalletContext } from '../../../context/ConnectWalletContext';
import commonFunction from '../../../utils/common';
import shortenAccount from '../../../utils/shortenAccount';
//import PyraNftMarket721 from '../../../ABI/PyraNftMarket.json';
import wETHABI from '../../../ABI/WETHABI.json';
import config from '../../../lib/config';
import { toast } from 'react-toastify';
//import { useNavigate } from 'react-router-dom';
import { _raiseBid } from '../../../services/mainApi';

const BidModal = props => {
  const { assetdata } = props;
  //const navigate = useNavigate();
  console.log('PK Bidding token data', assetdata, 'nftid', assetdata?.id, assetdata?._id);
  const { ConnectedAccount, netWorkSymbol } = React.useContext(ConnectWalletContext);
  const [wETHBalance, setWETHBalance] = useState(0);
  const [useWalletBalance, setUseWalletBalance] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  //const [royalityValue, setRoyalityValue] = useState(0);
  const [bidPayableValue, setBidPayableValue] = useState(0);
  //const [bidApprovedValue, setBidApprovedValue] = useState(0);
  const [bidAmount, setBidAmount] = useState(0);
  const [progress, setprogress] = React.useState(false);

  //get the contract address
  let contractAddress = assetdata?.collectionAddress;
  if (contractAddress == undefined) {
    if (assetdata?.CollectionData?.length > 0) {
      contractAddress = assetdata?.CollectionData[0]?.address;
    } else contractAddress = config.NFTContractAddress;
  }

  React.useEffect(() => {
    const getWETHBalance = async () => {
      console.log('PK Bidding Calling Bid modal for contract....', contractAddress);
      let web3 = await loadWeb3();
      // let contractAddress = assetdata?.collectionAddress;
      // // ? assetdata?.CollectionData[0]?.address
      // // : assetdata?.collectionAddress;
      // if (contractAddress == undefined) {
      //   if (assetdata?.CollectionData?.length > 0) {
      //     contractAddress = assetdata?.CollectionData[0]?.address;
      //   }
      // }
      console.log('PK Bidding token Id', assetdata?.tokenId);
      console.log('PK Bidding Connected address', ConnectedAccount);
      console.log('PK Bidding Contract Address for bidding', contractAddress);
      //&& assetdata?.tokenId != undefined
      if (ConnectedAccount && assetdata?.tokenId != undefined) {
        try {
          console.log('PK Calling Bid order Object');
          const biddingOrderObj = await biddingOrder(
            assetdata?.tokenId,
            ConnectedAccount,
            contractAddress, //assetdata?.collectionAddress,
            0,
            assetdata?.blockChainName,
          );
          console.log('PK Calling Bid order Object', biddingOrderObj);
          let userAccountBalance = await web3.eth.getBalance(ConnectedAccount);
          setWETHBalance(parseFloat(biddingOrderObj?.wETHBalance / 10 ** 18).toFixed(3));
          setUseWalletBalance(parseFloat(userAccountBalance / 10 ** 18).toFixed(4));
          setServiceFee(biddingOrderObj?.NftServiceFee);
          //setRoyalityValue(biddingOrderObj?.NftTokenRoyValue);
        } catch (err) {
          //toast.warning('Please verify the chain and switch it!!');
          console.log();
        }
      }
    };
    getWETHBalance();
  }, [ConnectedAccount, assetdata?.tokenId]);

  //call below function to palce the Bid order by approving it
  //below function will be get called when entered the bid value
  const onBidOrder = async e => {
    console.log('PK Bid calling onBidORder process....');
    let amount = e.target.value;
    setBidAmount(amount);
    console.log('PK Bidder WETH balance', wETHBalance);
    if (wETHBalance == 0 || wETHBalance < parseFloat(amount).toFixed(3)) {
      toast.error('Insufficient WETH Balance', commonFunction.toastsettings);
      return;
    }
    // //get the collectionAddress
    // let contractAddress = assetdata?.CollectionData[0]?.address
    //   ? assetdata?.CollectionData[0]?.address
    //   : assetdata?.collectionAddress;

    console.log('PK Bidding amount entered', amount);
    const biddingValue = await biddingOrder(
      assetdata.tokenId,
      ConnectedAccount,
      contractAddress, //assetdata?.collectionAddress,
      amount,
    );
    console.log('PK BidValue - bidder need to pay', biddingValue.bidValue);
    //this bid value is derived with service fee
    setBidPayableValue(biddingValue.bidValue);
    //parseFloat(biddingOrderObj.bidValue).toFixed(4)
    //setBidApprovedValue(biddingOrderObj.bidValue);
  };

  const callRaiseBid = param => {
    _raiseBid(param)
      .then(res => {
        if (!res.data.error) {
          toast.success('Bid Added successfully', commonFunction.toastsettings);
          //window.location.reload();
          //navigate('/buy');
          window.location = window.location.href;

          // return;
        } else {
          toast.error(res.data.msg, commonFunction.toastsettings);
        }
      })
      .catch(() => {
        toast.error('NFT Bid Raised Failed.Try Again!', commonFunction.toastsettings);
      });
  };
  //below function will be get called when click place a bid button
  const callBidApproval = async () => {
    console.log('PK  Bid Amount quoated', assetdata.tokenId, bidAmount);

    // console.log('Bid approval for WethBalance', wETHBalance); //like 1.000/ 1.03/ 1.00034
    // console.log('Bidder bidding value', parseFloat(bidAmount).toFixed(3)); //like 0.05 / 1.023 etc..
    // console.log('Bidder bidding converted value', bidAmount * 10 ** 18); // like 50000000000000000

    if (wETHBalance == 0 || wETHBalance < parseFloat(bidPayableValue).toFixed(3)) {
      toast.error('Insufficient WETH Balance', commonFunction.toastsettings);
      setprogress(false);
      return;
    }

    //if minBidValue is quoted then check the placed bidding value
    if (assetdata?.minBidValue > 0 && bidAmount < assetdata?.minBidValue) {
      toast.warning('Bid value should meet minimum bid value', commonFunction.toastsettings);
      return;
    }
    let web3 = await loadWeb3();
    setprogress(true);
    const instance = new web3.eth.Contract(wETHABI, config.wrapperContractAddress);
    //console.log(instance);
    //Call the contract methods to set approval for making bid
    //get existing allowance

    try {
      // var getAllowance = await instance.methods
      //   .allowance(ConnectedAccount, config.NFTContractAddress)
      //   .call();
      // let sendApprovalValue =
      //   parseFloat(getAllowance ? getAllowance / 10 ** 18 : 0) + parseFloat(bidAmount * 10 ** 18);

      // console.log(
      //   'palceBidAmt :',
      //   parseFloat(getAllowance ? getAllowance / 10 ** 18 : 0),
      //   bidAmount,
      //   sendApprovalValue,
      // );
      // console.log(
      //   'Approval amount in wei',
      //   web3.utils.fromWei(sendApprovalValue.toString(), 'wei'),
      // );
      //below approve method set allowance [bidderAddress, NftContractAddress] = bidValue
      //Bidder Address - who like to bid (connectedAccount)
      //NftContractAddress - ERC721 (pyraNFT contract address) -- check in the config file
      //---
      // //get the collectionAddress
      // let contractAddress = assetdata?.CollectionData[0]?.address
      //   ? assetdata?.CollectionData[0]?.address
      //   : assetdata?.collectionAddress;
      // //if collectionContract is not there then use nft contract
      // contractAddress = contractAddress ? contractAddress : config.NFTContractAddress;
      console.log('PK bidder Payable Bid Value', bidPayableValue);
      let sendBidValue = web3.utils.toWei(bidPayableValue.toString(), 'ether');
      console.log('PK bidder send bid value', sendBidValue);
      // let contractAddress = assetdata?.collectionAddress
      //   ? assetdata?.collectionAddress
      //   : config.NFTContractAddress;

      console.log('PK Bid-approve process started -----');
      console.log('PK Bid-approve bidder address', ConnectedAccount);
      console.log('PK Bid-approve contract address', contractAddress);
      console.log('PK Bid-approve approvedValue ', sendBidValue);
      console.log('PK Bid-approve bidAmount', bidAmount);
      //call the approve smart contract function
      if (sendBidValue > 0) {
        let result = await instance.methods
          .approve(contractAddress, sendBidValue)
          .send({ from: ConnectedAccount });
        console.log('PK Contract transaction address', result.transactionHash);
        console.log('PK  Bid-approve process calling raised bid API');
        //if approve transaction is completed then call the API to load/store the bid information
        if (result && result.transactionHash) {
          if (assetdata?.tokenId) {
            callRaiseBid({
              price: bidAmount,
              nftId: assetdata?._id,
              tokenId: assetdata?.tokenId,
            });
          }

          //Note while passing bidAmount pass the actual bid value that recevied- below one
          console.log('PK  Bid Amount quoated', assetdata.tokenId, bidAmount);
          //toast.success('Bid added successfully!!', commonFunction.toastsettings);
          //window.location = '/nft/buy';
          //navigate('/buy');
          //return;
        }
        //---
        setprogress(false);
        props.onHide();
      }
    } catch (err) {
      console.log('Err message from bidding process', err.message);
      setprogress(false);
      props.onHide();
    }
  };
  const onResetBidValue = () => {
    setBidPayableValue(0);
    setBidAmount(0);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={() => {
          props.onHide();
          setprogress(false);
        }}>
        <Modal.Header closeButton onClick={onResetBidValue}></Modal.Header>

        <div className="modal-body space-y-15 pd-40">
          <h3>Please sign the bidding action</h3>
          <p className="text-center">You are about to place a bid for</p>
          <h4 className="text-center">{assetdata?.title}</h4>
          <div className="d-flex justify-content-between">
            <div>
              <p>
                For:
                {shortenAccount(
                  assetdata?.walletAddress ? assetdata.walletAddress : assetdata?.currentAddress,
                )}
              </p>
            </div>
            <div>
              <p>By : {shortenAccount(ConnectedAccount)}</p>
            </div>
          </div>
          <p className="text-center">Your Bid</p>
          <div className="widget widget-subcribe">
            <div className="form-subcribe">
              <form id="subscribe-form">
                <input
                  name="amt"
                  type="text"
                  className="email"
                  placeholder="Enter your bid amount"
                  onChange={onBidOrder}
                />
                <span
                  className="center"
                  style={{ width: 'auto', borderRadius: '10px', color: 'cyan', fontSize: '15px' }}>
                  {`W${netWorkSymbol}`}
                </span>
              </form>
            </div>
          </div>
          <div className="hr"></div>
          <div className="d-flex justify-content-between">
            <p>Your balance</p>
            <p className="text-right price color-popup">
              {useWalletBalance} {netWorkSymbol}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p> Your bidding balance</p>
            <p className="text-right price color-popup">
              {wETHBalance} {`W${netWorkSymbol}`}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p> Service fee</p>
            <p className="text-right price color-popup">{serviceFee / 10 ** 18} %</p>
          </div>
          {assetdata?.minBidValue > 0 ? (
            <div className="d-flex justify-content-between">
              <p> Minimun Bid Value</p>
              <p className="text-right price color-popup">{assetdata?.minBidValue}</p>
            </div>
          ) : (
            ''
          )}

          <div className="d-flex justify-content-between">
            <p> You will pay</p>
            <p className="text-right price color-popup">
              {parseFloat(bidPayableValue).toFixed(4)} {`W${netWorkSymbol}`}
            </p>
          </div>
          {progress ? (
            <div className="center">
              <i className="fa fa-spinner fa-spin fa-2x mr-2" aria-hidden="true" id="circle1"></i>
            </div>
          ) : (
            <div className="text-center">
              <button
                type="button"
                className="sc-button fl-button pri-3 no-bg"
                onClick={callBidApproval}>
                <span className="create-item-btn" style={{ paddingLeft: '0px' }}>
                  Place A Bid
                </span>
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default BidModal;
