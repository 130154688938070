import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TodayPicks from '../components/layouts/explore-03/TodayPicks';
// import todayPickData from '../assets/fake-data/data-today-pick';
import {
  _exploreAllNft,
  _exploreCollectionNft,
  _searchNFT,
  _addToFav,
  _removeFromFav,
} from '../services/mainApi';
import imga1 from '../assets/images/avatar/avt-1.jpg';
import imgCollection1 from '../assets/images/avatar/avt-18.jpg';
import { srt } from '../utils/helpers';
import { useParams } from 'react-router-dom';
import { ConnectWalletContext } from '../context/ConnectWalletContext';
import previewuser from '../assets/images/avatar/previewThumb.png';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const Explore03 = props => {
  const { ConnectedAccount, setAllData } = useContext(ConnectWalletContext);
  const location = useLocation();
  const { collectionId } = useParams();
  const id = props;
  console.log(id, 'prooooowned');
  const [explorelist, setexplorelist] = useState([]);
  const [explorelistfilter, setexplorelistfilter] = useState([]);
  const [nftlist, setnftlist] = useState([]);
  const [totalItems, settotalItems] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchdata, setsearchdata] = useState('');
  const [noData, setNoData] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);

  const increaseexplorepagenumber = () => {
    setcurrentPage(currentPage + 1);
    setLoadMore(true);
  };
  const handlesearchinput = value => {
    setsearchdata(value);
    onsearchnft(value);
  };
  const StoreAllData = React.useCallback((res, checkVar = false) => {
    setNoData(false);
    setFilterLoad(false);
    let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
    let resdata = [];
    temp?.length > 0 &&
      temp.map(item => {
        return resdata.push({
          categoryId: item.categoryId ? item.categoryId : '',
          description: item.description,
          id: item._id,
          img: item.fileUrl,
          imgAuthor: previewuser,
          imgCollection: imgCollection1,
          metadataUrl: item?.metadataUrl ? item?.metadataUrl : '',
          nameAuthor: item.userdetails.username,
          nameCollection: '',
          noOfCopies: item.noOfCopies,
          properties: item?.properties ? item.properties : [],
          price: item.price,
          priceChange: '',
          putOnMarketplace: item.putOnMarketplace,
          tags: item.CategoryData.name,
          title: item.title,
          tokenId: item?.tokenId ? item.tokenId : '',
          walletAddress: item.currentAddress,
          wishlist: item.favCount,
          favStatus: item.favStatus,
          ownerAddress: item.ownerAddress,
          currentAddress: item.currentAddress,
          minBidValue: item.minBidValue,
          isBid: item.isBid,
          collectionAddress: item?.CollectionData[0]?.address,
          collectionSymbol: item?.CollectionData[0]?.symbol,
          collectionDescription: item?.CollectionData[0]?.name,
          multiToken: item.multiToken,
          userId: item?.userdetails?.userId,
          userImage: item?.userdetails?.userImage,
          unlockContentFlag: item?.unlockContentFlag,
          unlockContent: item?.unlockContent,
          blockChainName: item?.blockChainName,
        });
      });
    let data = [...explorelist, ...resdata];
    let uniqueData = [...data.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    console.log('locationnnn', checkVar, 'stored');
    // setexplorelist(uniqueData);

    if (checkVar) {
      setexplorelist(resdata);
    } else {
      setexplorelist(uniqueData);
    }
    setAllData(uniqueData);
    setnftlist(uniqueData);
    settotalItems(res?.data?.data?.totalItems);
  });
  const onsearchnft = req => {
    console.log(req, 'searchlistttt');
    if (req?.length >= 1) {
      const promise = _searchNFT({ text: req });
      promise
        .then(res => {
          if (!res.error) {
            let temp = res?.data?.data?.nftList ? res?.data?.data?.nftList : [];
            let resdata = [];
            temp?.length > 0 &&
              temp.map(item => {
                return resdata.push({
                  categoryId: item?.categoryId ? item.categoryId : '',
                  description: item.description,
                  id: item._id,
                  img: item.fileUrl,
                  imgAuthor: imga1,
                  imgCollection: imgCollection1,
                  metadataUrl: item?.metadataUrl ? item?.metadataUrl : '',
                  nameAuthor: item.userdetails.username,
                  nameCollection: '',
                  noOfCopies: item.noOfCopies,
                  properties: item?.properties ? item.properties : [],
                  price: item.price,
                  priceChange: '',
                  putOnMarketplace: item.putOnMarketplace,
                  tags: item.CategoryData.name,
                  title: item.title,
                  tokenId: item?.tokenId ? item.tokenId : '',
                  walletAddress: item.currentAddress,
                  currentAddress: item.currentAddress,
                  wishlist: item.favCount,
                  // favStatus: item.favStatus,
                  ownerAddress: item.ownerAddress,
                  minBidValue: item.minBidValue,
                  isBid: item.isBid,
                  favStatus: item.favStatus,
                  multiToken: item.multiToken,
                  blockChainName: item?.blockChainName,
                });
              });
            setsearchdata(req);
            console.log(req, 'searchlisttttreqin');
            if (res?.data?.data?.nftList) {
              setexplorelistfilter(res?.data?.data?.nftList);
            } else {
              setexplorelistfilter([]);
            }
            settotalItems(res?.data?.data?.totalItems);
          }
        })
        .catch(e => {
          console.log('errorLogged', e);
        });
    } else if (req?.length == 0) {
      const _exploreAllNftpromise = _exploreAllNft(currentPage);
      _exploreAllNftpromise.then(res => {
        if (!res.error) {
          StoreAllData(res);
          console.log('empty');
        }
      });
    }
  };

  const filterBlockChain = chainNameHere => {
    setFilterLoad(true);
    if (chainNameHere === 'All') {
      ExploreAllCoinsData();
    } else if (chainNameHere != 'All') {
      setcurrentPage(1);
      const _exploreAllNftpromise = _exploreAllNft(currentPage, {
        filter: { blockChainName: chainNameHere },
      });
      _exploreAllNftpromise.then(res => {
        if (!res?.data?.error) {
          StoreAllData(res, true);
        } else {
          setexplorelist([]);
          setFilterLoad(false);
          setNoData(true);
        }
      });
    }
  };
  const handle_filter = (
    selectedcategoryId,
    selectedquantityId,
    selectedstatusId,
    selectedsortId,
    coinName,
  ) => {
    let temp = [...nftlist];
    console.log(coinName, 'coinnnnn');
    if (coinName) {
      filterBlockChain(coinName);
    }
    temp = temp
      .filter(item => {
        if (selectedcategoryId && item.categoryId === selectedcategoryId) return item;
        else if (selectedcategoryId === 0) return item;
      })
      .filter(item => {
        if (selectedquantityId === 0) return item;
        else if (selectedquantityId === 1) {
          if (parseInt(item.noOfCopies) === 1) return item;
        } else if (selectedquantityId === 2) {
          if (parseInt(item.noOfCopies) > 1) return item;
        }
      })
      .filter(item => {
        if (selectedstatusId === 1 && ConnectedAccount !== item.walletAddress) return item;
        else if (
          selectedstatusId === 2 &&
          item.putOnMarketplace === true &&
          ConnectedAccount === item.walletAddress
        )
          return item;
        else if (selectedstatusId === 0) return item;
      })
      .sort(srt({ key: 'price', string: true }, selectedsortId === 1 ? false : true));
    setexplorelist([...temp]);
    setexplorelistfilter([...temp]);
  };

  const ExploreAllCoinsData = () => {
    const _exploreAllNftpromise = _exploreAllNft(currentPage);
    _exploreAllNftpromise.then(res => {
      setFilterLoad(false);
      setLoadMore(false);
      if (!res?.data?.error) {
        StoreAllData(res);
      } else {
        setexplorelist([]);
        setNoData(true);
        console.log(res, 'locationnnii');
      }
    });
  };
  useEffect(() => {
    if (collectionId) {
      const _exploreNftpromise = _exploreCollectionNft(collectionId, currentPage);
      _exploreNftpromise.then(res => {
        if (!res.error) {
          StoreAllData(res);
        }
      });
    } else {
      ExploreAllCoinsData();
    }
  }, [collectionId, currentPage]);
  const [load, setLoad] = React.useState('initial');

  const addOrRemoveFav = item => {
    setLoadMore(true);
    if (item?.favStatus === true && (load === 'initial' || load === 'completed')) {
      removeFav(item?.id || item?._id);
    } else {
      if (load === 'initial' || load === 'completed') {
        addToFav(item?.id || item?._id);
      }
    }
  };

  const addToFav = id => {
    setLoad('pending');
    const payload = {
      nftId: id,
    };
    _addToFav(payload)
      .then(res => {
        setLoadMore(true);
        if (!res?.data?.error) {
          //     // let favStatusTemp = data;
          // var addChange = function change() {
          // // favStatusTemp[index].favStatus = true;
          // favStatusTemp[index].wishlist = favStatusTemp[index].wishlist + 1;
          // };
          ExploreAllCoinsData();
          toast.success('Added to favorites');
          onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err.message);
  };
  // console.log(addToFav,"favorite")
  const removeFav = id => {
    setLoad('pending');

    const payload = {
      nftId: id,
    };
    _removeFromFav(payload)
      .then(res => {
        setLoadMore(true);

        if (!res?.data?.error) {
          ExploreAllCoinsData();
          toast.error('Removed from favorites', '');
          onsearchnft(location?.state);
          // setsearchdata(location?.state);
        }
        setLoad('completed');
      })
      .catch(err => err?.message);
  };

  useEffect(() => {
    if (location?.state?.length === 0) {
      console.log('nolength');
      setsearchdata('');
      onsearchnft('');
    } else {
      console.log('length', location?.state);
      setsearchdata('');
      onsearchnft(location?.state);
    }
  }, [location?.state]);
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Explore 3</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Explore 3</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TodayPicks
        // data={todayPickData}
        loadmore={
          location?.state === null || searchdata?.length === 0 ? 'all data' : 'filter data '
        }
        data={searchdata === null || searchdata?.length === 0 ? explorelist : explorelistfilter}
        // categoryselect={id => handlecategoryselect(id)}
        increasepagenumber={() => increaseexplorepagenumber()}
        exploretotalItems={totalItems}
        onsearchnft={value => onsearchnft(value)}
        handlesearchinput={value => handlesearchinput(value)}
        filter={(
          selectedcategoryId,
          selectedquantityId,
          selectedstatusId,
          selectedsortId,
          coinName,
        ) =>
          handle_filter(
            selectedcategoryId,
            selectedquantityId,
            selectedstatusId,
            selectedsortId,
            coinName,
          )
        }
        addOrRemoveFav={addOrRemoveFav}
        loadMore={loadMore}
        filterLoad={filterLoad}
        noData={noData}
      />
      <Footer />
    </div>
  );
};

export default Explore03;
